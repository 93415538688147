import React, { useState } from 'react';
import { Container, Btn } from 'Components/Styled';
import { ReactComponent as TrashIcon } from 'Assets/metronic/General/Trash.svg';
import { ReactComponent as EditIcon } from 'Assets/metronic/Design/Edit.svg';
import createStore from 'Store/composer';
import Composer from 'Components/Composer/Container';
import client from 'Clients/rest';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import './style.scss';

import { toast } from 'react-toastify';

const colSize = { minWidth: 200, width: 200 };

export default function DraftItem({ item: draft, fetchData: refresh }) {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(false);
  const [showResponder, setShowResponder] = useState(false);
  const user = useSelector((state) => state.user);

  const account = useSelector(
    (s) => s.user.accounts.find((a) => a.id === draft.account_id) || {}
  );

  const recipient = get(draft, 'to[0]', {});

  const edit = (e) => {
    e.stopPropagation();
    const redux = createStore({ name: `Responder-Draft-${draft.id}` });
    setStore(redux);
    setShowResponder(true);
    // if (draft.thread_id) history.push(`/inbox/thread/${draft.thread_id}`);
  };

  const discard = async (e) => {
    e.stopPropagation();

    try {
      setLoading(true);
      await client.delete(`/drafts/${draft.id}`, {
        params: { account_id: draft.account_id, version: draft.version },
      });
      toast('Draft removed');
      await refresh();
    } catch (err) {
      console.log('Error deleting draft', err.message);
      toast('Error removing draft');
    } finally {
      setLoading(false);
    }
  };

  const afterClose = () => {
    console.warn('after close -- draftitem');
    refresh();
  };

  const accountList = user.accounts;

  return (
    <>
      <Container
        className="draft-item d-flex align-items-center justify-content-start mb-1"
        onClick={edit}
      >
        <div
          className="truncated"
          style={{ ...colSize, minWidth: 100, width: 100 }}
        >
          <strong className="text-danger">[ Draft ]</strong>
        </div>
        <div
          className="truncated"
          style={{ ...colSize, minWidth: 190, width: 190 }}
        >
          <div className="text-muted">{account.email}</div>
        </div>
        <div className="truncated" style={colSize}>
          <strong>To:</strong> {recipient.name} {recipient.email}
        </div>

        <div className="flex-grow-1">
          {draft.subject} {draft.snippet}
        </div>
        <div className="hover-actions">
          <Btn
            onClick={edit}
            title="Edit Draft"
            className="mr-2"
            type="button"
            disabled={loading}
          >
            <EditIcon />
          </Btn>
          <Btn
            onClick={discard}
            title="Delete Draft"
            className="ml-0"
            type="button"
            disabled={loading}
          >
            <TrashIcon />
          </Btn>
        </div>
      </Container>

      {showResponder && (
        <Composer
          store={store}
          accounts={accountList}
          draftId={draft.id}
          close={() => setShowResponder(false)}
          afterSend={discard}
          key={draft.id}
          afterClose={afterClose}
        />
      )}
    </>
  );
}
