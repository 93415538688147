import React from 'react';
import { Form, Field } from 'react-final-form';
import { Row, Col, FormGroup, Button, Spinner } from 'reactstrap';
import { Input, ButtonGroup } from 'Components/Form';
import useConnect from 'Hooks/use-connect';

export default function ConnectForm({ onSkip }) {
  const { onSubmit, validate, loading, validateEmailAccount } = useConnect();
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          {/* For invites only */}
          <FormGroup>
            <Field
              label="Select a type of account you want to connect"
              component={ButtonGroup}
              allowNull
              initialValue
              name="shared"
              options={[
                { value: true, name: 'Shared email' },
                { value: false, name: 'Private email' },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Field
              label="Email"
              component={Input}
              name="email"
              placeholder="your email"
              showValid
              showInvalid
              validate={validateEmailAccount}
            />
          </FormGroup>

          <FormGroup className="mt-md-5 mt-3">
            <Row>
              {onSkip && (
                <Col md={6}>
                  <Button block color="link" onClick={onSkip}>
                    Sign out
                  </Button>
                </Col>
              )}
              <Col md={onSkip ? 6 : 12} className="mt-3 mt-sm-3 mt-md-0">
                <Button
                  block
                  disabled={invalid || loading}
                  color="primary"
                  size="lg"
                  className="font-weight-bold"
                >
                  Next
                  {loading && (
                    <Spinner color="light" size="sm" className="ml-3" />
                  )}
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </form>
      )}
    />
  );
}
