import React from 'react';
import { Route } from 'react-router-dom';
import Landing from 'Pages/landing';
import ProtectedRoute from 'Components/ProtectedRoute';
import Toaster from 'Components/Toaster';
import Settings from 'Pages/settings';
import AppRoutes from 'Pages/app';
import AuthRoutes from 'Pages/auth';
import StudioRoutes from 'Pages/studio';

const Router = () => (
  <>
    <Route exact path="/" component={Landing} />
    <AppRoutes />
    <AuthRoutes />
    <ProtectedRoute path="/settings" component={Settings} />
    <ProtectedRoute path="/studio" component={StudioRoutes} />
    <Toaster />
  </>
);
export default Router;
