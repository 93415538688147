import React from 'react';
import { useSelector } from 'react-redux';
import recentlyConnected from 'Utils/account-recently-connected';
import './style.scss';

export default function AccountSync() {
  const accounts = useSelector((s) => s.user.accounts);
  const syncing = accounts.filter(recentlyConnected);
  if (!syncing.length) return null;
  return (
    <div className="account-sync">
      {syncing.map((account) => {
        return (
          <div className="account-sync__item truncated" key={account.id}>
            <div className="spinner-border" role="status" />
            <strong>{account.email}</strong> is syncing...
          </div>
        );
      })}
    </div>
  );
}
