import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';

const iframeResizerOptions = {
  // log: true,
  autoResize: true,
  checkOrigin: false,
  // resizeFrom: 'parent',
  heightCalculationMethod: 'max',
  // initCallback: () => { console.log('ready!'); },
  // resizedCallback: () => { console.log('resized!'); },
};

export default function DynamicIFrame(props) {
  const { src } = props;

  return (
    <ReactIframeResizer
      iframeResizerOptions={iframeResizerOptions}
      content={src}
      style={{ width: '1px', minWidth: '100%', minHeight: 'auto' }}
      frameBorder={0}
    />
  );
}
