import { findSelector } from 'Store/api/users';
import { useSelector } from 'react-redux';

export default function useUsers() {
  const { data } = useSelector(findSelector);

  const get = (userId) => data.find((usr) => usr.id === userId);

  return {
    data,
    get,
  };
}
