import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/OPTIONS/SET');
const initialState = {};

export default createReducer(
  initialState, // initial state
  {
    // 'COMPOSER/RESET': () => {
    //   return initialState;
    // },
    [updateAction]: (state, action) => {
      return action.payload;
    },
  }
);
