import { useDispatch } from 'react-redux';
import { next, prev, go } from 'Store/router';
import qs from 'query-string';

export default function useRouter() {
  const dispatch = useDispatch();

  const query = qs.parse(document.location.search, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });

  return {
    next: (name, payload) => dispatch(next(name, payload)),
    prev: (name, payload) => dispatch(prev(name, payload)),
    go: (name, action, payload) => dispatch(go(name, action, payload)),
    query,
  };
}
