import React from 'react';
import { Route } from 'react-router-dom';
import MessageAnnotationView from 'Components/studio';
import AnnotationList from 'Components/studio/AnnotationList';
import AnnotationStats from 'Components/studio/AnnotationStats';
import Navbar from 'Components/studio/Navbar';
import MerchantsList from 'Components/studio/MerchantsList';
import MerchantStats from 'Components/studio/MerchantStats';

export default function StudioRoutes() {
  return (
    <div className="studio">
      <Navbar />
      <Route exact path="/studio" component={AnnotationList} />
      <Route exact path="/studio/stats" component={AnnotationStats} />
      <Route exact path="/studio/merchants" component={MerchantsList} />
      <Route
        exact
        path="/studio/merchants/:merchantName"
        component={MerchantStats}
      />

      <Route
        path="/studio/annotate/:messageId?/:annotationId?"
        component={MessageAnnotationView}
      />
    </div>
  );
}
