import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  replaceAction,
  addAction,
  removeAction,
} from 'Store/studio/annotations';
import { get } from 'lodash';

export default function useAnnotationsStore({ messageId } = {}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useSelector((s) => s.annotations);
  const count = useMemo(() => data.length, [data]);

  const current = useMemo(() => {
    return data.find((x) => x.message_id === messageId);
  }, [messageId, data]);

  const currentIndex = useMemo(() => {
    return data.indexOf(current);
  }, [data, current]);

  // Handlers
  const handleNavigate = (direction = 1) => {
    if (currentIndex !== -1) {
      const nextItem = data[currentIndex + direction];
      if (nextItem) {
        history.replace(`/studio/annotate/${nextItem.message_id}`);
      }
    }
  };
  const next = () => handleNavigate(1);
  const prev = () => handleNavigate(-1);
  const replace = (threads) => {
    const messageIds = threads
      .map((t) => {
        return get(t, 'message_ids[0]');
      })
      .filter((x) => x);
    dispatch(replaceAction({ messageIds }));
    const [firstId] = messageIds;
    if (firstId) history.push(`/studio/annotate/${firstId}`);
  };

  const isSelected = (item) => {
    const target = data.find((x) => x.annotation_id === item.id);
    return !!target;
  };
  const select = (item) => {
    if (!isSelected(item)) {
      dispatch(
        addAction({ message_id: item.message_id, annotation_id: item.id })
      );
    } else {
      dispatch(removeAction(item.id));
    }
    return {};
  };
  return {
    data,
    count,
    current,
    prev,
    next,
    currentIndex,
    replace,
    select,
    isSelected,
  };
}
