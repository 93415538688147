import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get, some } from 'lodash';
import client from 'Clients/rest';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { updateAction as setSubject } from 'Store/composer/message/subject';
import { updateAction as setBody } from 'Store/composer/message/body';
import { updateAction as setTo } from 'Store/composer/message/to';
import { updateAction as setFrom } from 'Store/composer/message/from';
import { updateAction as updateUI } from 'Store/composer/ui';
import { updateAction as setCC } from 'Store/composer/message/cc';
import { updateAction as setBCC } from 'Store/composer/message/bcc';
import { updateAction as setAccount } from 'Store/composer/account';
import { send as sendAction } from 'Store/composer/send';
import fetchInitialData from 'Store/composer/fetch-initial-data';
import mustache from 'mustache';
// import handlebars from 'handlebars';
import debounce from 'lodash/debounce';
import { ReactComponent as TrashIcon } from 'Assets/metronic/General/Trash.svg';
import { Btn } from 'Components/Styled';
import TemplatePicker from './Select/Template';

import AttachmentButton from './Attachments/AttachmentButton';
import Attachments from './Attachments';
import SendLaterPicker from './SendLaterPicker';
import AlertBar from './AlertBar';
import TinyMCE from './TinyMCE';
import RecipientsPicker from './Select/Recipients';
import Header from './Header';
import SendButton from './SendButton';
import Subject from './Subject';
import SenderPicker from './Select/Sender';
import EditQuotedTextBtn from './EditQuotedText';

import './composer.scss';

const SelectContainer = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  border-bottom: 1px solid #ebedf2;
`;

const ExtraButtonsContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  display: flex;
`;

const CCBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
  font-size: 13px;
  transition: 0.3s color;
  color: #74788d;
  &:hover {
    color: #5d78ff;
  }
`;

const SelectLabel = styled.span`
  color: #74788d;
  font-size: 12px;
  width: 28px;
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: spce;
  border-top: 1px solid #ebedf2;
  padding: 10px 10px;
`;

function Composer({
  close,
  subject,
  updateSubject,
  updateTo,
  updateCC,
  updateBCC,
  updateBody,
  contacts,
  options,
  minimized,
  toggleMinimize,
  updateAccount,
  showCC,
  showBCC,
  updateFrom,
  internalAccounts,
  accounts, // prop
  ui,
  replyAll,
  internalFrom,
  internalTo,
  to,
  cc,
  bcc,
  dispatch,
  inline,
  height,
  message,
  lastReset,
  beforeSend,
  afterSend,
  replyToMessageId,
  forwardToMessageId,
  style,
  discard,
  account,
  draftId,
  body,
  afterClose,
  draft,
  onDraftSaved,
}) {
  const minimize = () => toggleMinimize(!minimized);
  const handleFromChange = (payload) => {
    updateAccount(payload);
    updateFrom({
      name: payload.name || payload.email_address,
      email: payload.email_address,
    });
  };

  const handleClose = async (msg) => {
    if (close) close();

    const draftEligible = some([
      !inline && to && to.length,
      cc && cc.length,
      bcc && bcc.length,
      !inline && subject,
      inline && body,
      body,
    ]);

    const data = {};
    data.account_id = account.id;
    Object.keys(message).forEach((k) => {
      if (message[k].data) data[k] = message[k].data;
    });

    if (draftEligible && draftId) {
      // Autoupdate draft
      const upd = await client.patch(`/drafts/${draftId}`, {
        ...data,
        version: draft.version,
      });
      if (onDraftSaved) onDraftSaved(upd, 'update');
    }
    if (draftEligible && !draftId && !msg) {
      const ask = confirm(`Do you want to ${draftId ? 'update' : 'save'} this draft?`); // eslint-disable-line
      if (ask) {
        try {
          const upd = await client.post('/drafts', data);
          if (onDraftSaved) onDraftSaved(upd, 'insert');
        } catch (e) {
          console.log('Could not save draft', data);
          toast('could not save draft');
        }
      }
    }
    dispatch({ type: 'COMPOSER/RESET' });
    if (afterClose) afterClose();
  };

  React.useEffect(() => {
    dispatch(
      fetchInitialData({
        reply_to_message_id: replyToMessageId,
        forward_message_id: forwardToMessageId,
        subject,
        to,
        accounts,
        draft_id: draftId,
        reply_all: replyAll,
      })
    );
  }, [lastReset]); // eslint-disable-line

  // unmount
  React.useEffect(() => {
    return () => {
      dispatch({ type: 'COMPOSER/RESET' });
    };
  }, [dispatch]);

  const setTemplate = (val) => {
    const rendered = mustache.render(val.body, {
      recipient: {
        name: get(message, 'to.data[0].name'),
        email: get(message, 'to.data[0].email'),
      },
    });
    dispatch(setBody(rendered));
  };

  // const [preparedMessage, valid] = useSelector(({ composer: state }) => {
  //   const isValid = Object.keys(state.message).every((key) => {
  //     return state.message[key].valid;
  //   });
  //   return [state.message, isValid];
  // });
  //
  // const handleSend = async () => {
  //   console.log('Sending', preparedMessage, valid);
  //   if (!valid) return;
  //   if (beforeSend) beforeSend(preparedMessage);
  //   const res = await dispatch(send(preparedMessage));
  //   if (afterSend) afterSend(res);
  //   close();
  // };

  return (
    <div
      style={style}
      className={classnames({
        'nylas-composer': true,
        inline,
        maximized: ui.maximized && !inline && !ui.minimized,
      })}
    >
      {!inline && (
        <Header
          subject={subject}
          toggleMinimize={minimize}
          close={handleClose}
        />
      )}
      {(!minimized || inline) && (
        <>
          <AlertBar />
          {internalAccounts.length > 1 && (
            <SelectContainer style={{ paddingRight: 80 }}>
              <SelectLabel>From:</SelectLabel>
              <SenderPicker
                onChange={handleFromChange}
                options={internalAccounts}
                value={internalFrom}
              />
            </SelectContainer>
          )}

          <SelectContainer style={{ paddingRight: 80 }}>
            <SelectLabel>To:</SelectLabel>
            <RecipientsPicker
              onChange={updateTo}
              options={contacts}
              value={internalTo}
            />
            <ExtraButtonsContainer>
              {!ui.cc && <CCBtn onClick={showCC}>CC</CCBtn>}
              {!ui.bcc && <CCBtn onClick={showBCC}>BCC</CCBtn>}
            </ExtraButtonsContainer>
          </SelectContainer>
          {(ui.cc || (cc && cc.length > 0)) && (
            <SelectContainer>
              <SelectLabel>CC:</SelectLabel>
              <RecipientsPicker
                tabIndex={-20}
                onChange={updateCC}
                options={contacts}
                value={cc}
              />
            </SelectContainer>
          )}
          {(ui.bcc || (bcc && bcc.length > 0)) && (
            <SelectContainer>
              <SelectLabel>BCC:</SelectLabel>
              <RecipientsPicker
                tabIndex={-30}
                onChange={updateBCC}
                options={contacts}
                value={bcc}
              />
            </SelectContainer>
          )}

          {!inline && <Subject subject={subject} onChange={updateSubject} />}
          <TinyMCE onChange={updateBody} html={options.body} height={height} />
          <div className="ml-2 mb-2">
            <EditQuotedTextBtn />
          </div>
          <Attachments />

          <Footer>
            <SendButton
              close={handleClose}
              beforeSend={beforeSend}
              afterSend={afterSend}
            />
            <AttachmentButton />

            <div className="d-flex flex-grow-1 justify-content-end">
              {account && (
                <TemplatePicker account={account} onChange={setTemplate} />
              )}
              {discard && (
                <Btn
                  onClick={() => handleClose()}
                  title="Discard message"
                  className="ml-2"
                >
                  <TrashIcon />
                </Btn>
              )}
            </div>
          </Footer>
          {/* Modals */}
          {ui.sendLaterPicker && <SendLaterPicker />}
        </>
      )}
    </div>
  );
}

// TODO: move to container
export default connect(
  ({ composer: state }) => {
    const accountsWithAliases = [];
    state.accounts.data.forEach((acc) => {
      accountsWithAliases.push(acc);
      if (acc.aliases)
        acc.aliases.forEach((alias) => {
          accountsWithAliases.push({ ...acc, ...alias });
        });
    });

    return {
      body: state.message.body.data,
      subject: state.message.subject.data,
      contacts: state.contacts.data,
      internalAccounts: accountsWithAliases,
      account: state.account,
      minimized: state.ui.minimized,
      options: state.options,
      ui: state.ui,
      internalFrom: state.message.from.data,
      internalTo: state.message.to.data,
      to: state.message.to.data,
      cc: state.message.cc.data,
      bcc: state.message.bcc.data,
      lastReset: state.ui.last_reset_at,
      message: state.message,
      sendState: state.sendState,
      draft: state.draft,
    };
  },
  (dispatch) => ({
    dispatch,
    showCC: () => dispatch(updateUI({ cc: true })),
    showBCC: () => dispatch(updateUI({ bcc: true })),
    toggleMinimize: (val) => dispatch(updateUI({ minimized: val })),
    updateSubject: (text) => dispatch(setSubject(text)),
    updateFrom: (selection) => dispatch(setFrom(selection)),
    updateTo: (selection) => dispatch(setTo(selection)),
    updateCC: (selection) => dispatch(setCC(selection)),
    updateBCC: (selection) => dispatch(setBCC(selection)),
    updateAccount: (selection) => dispatch(setAccount(selection)),
    send: (data) => dispatch(sendAction(data)),
    // Debounce redux updates because payloads can be large
    updateBody: debounce((html) => {
      dispatch(setBody(html));
    }, 50),
  })
)(Composer);
