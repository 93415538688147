import React from 'react';
import useMagicLink from 'Hooks/use-magic-link';
import AuthLayout from 'Components/AuthLayout';
import Loader from 'Components/NylasLoader';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
// Route where you accept the magic link

export default function LoginMagicLink() {
  const { access_token } = useMagicLink();
  // TODO: one edge case is not handled (when access token is invalid it will show loader infinitely)
  return (
    <AuthLayout logo={false}>
      {access_token && (
        <div className="text-center">
          <h2>Please wait</h2>
          <p className="mt-1 text-muted">Logging in with magic link</p>
          <Loader loading className="my-5" />
        </div>
      )}
      {!access_token && (
        <div className="text-center">
          <h2>Forbidden</h2>
          <p className="mt-1 text-muted">No access token found in the URL.</p>
          <Button
            to="/forgot"
            size="lg"
            color="primary"
            className="mt-3"
            tag={Link}
            block
          >
            Request Link
          </Button>
          <Button
            to="/login"
            size="lg"
            color="primary"
            className="mt-1"
            tag={Link}
            outline
            block
          >
            Login
          </Button>
        </div>
      )}
    </AuthLayout>
  );
}
