import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { isEqual, isEmpty } from 'lodash';
import { resetRequests } from '@redux-requests/core';

export default ({ fetch, query, selector, reset, initialFetch = true }) => {
  const [paramsState, setParamsState] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, data, error } = useSelector(selector);

  const params = qs.parse(history.location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });

  const perPage = 20;
  const page = params.$skip ? params.$skip / perPage : 0;

  // If query is supplied it will ignore params

  // Actions
  const fetchData = () => dispatch(fetch(isEmpty(query) ? params : query));

  const setPage = (p) => () => {
    // Pagination function
    const $skip = p * perPage;
    // merge params
    if (isEmpty(query)) {
      const newQ = qs.stringify({ ...params, $skip });
      history.push(`?${newQ}`);
    }
  };

  // Used only for params
  useEffect(() => {
    if (!initialFetch) return;
    if (isEqual(paramsState, params)) return;
    if (reset) dispatch(resetRequests(reset));
    fetchData();
    setParamsState(params);
  }, [params, paramsState]); // eslint-disable-line

  return { fetchData, setPage, page, perPage, loading, data, error, params };
};
