import React from 'react';
import AsyncSelect from 'react-select/async';
import client from 'Clients/rest';
import { useSelector } from 'react-redux';
import pDebounce from 'p-debounce';
import selectStyle from 'Components/Styled/Select';

const autocomplete = pDebounce(async (term, accountId) => {
  return client.get('/autocomplete/users', {
    params: { term, account_ids: accountId, $limit: 10 },
  });
}, 250);

const StyledSelect = selectStyle(AsyncSelect);

export default function UserSearch({
  accountId,
  onChange,
  className,
  placeholder,
  additionalOptions,
  loading,
  style,
  disabled,
}) {
  const user = useSelector((state) => state.user);

  const handleChange = (data) => {
    if (onChange) onChange(data);
  };

  const loadOptions = async (term) => {
    // initial fetch
    if (!term) {
      const accountUsers = await client.get('/users', {
        params: { account_ids: accountId, $limit: 20 },
      });
      const finalOptions = [...accountUsers, ...(additionalOptions || [])];
      finalOptions.map((opt) => {
        if (opt.id === user.id) opt.username = `Me (${user.username})`;
        return opt;
      });
      return finalOptions;
    }
    const res = await autocomplete(term, accountId);
    return Promise.resolve(res);
  };

  return (
    <div style={style} className={className}>
      <StyledSelect
        className="react-select"
        classNamePrefix="select"
        isLoading={loading}
        placeholder={placeholder || 'select user'}
        name="user-picker"
        isClearable
        isDisabled={loading || disabled}
        getOptionValue={(x) => x.id}
        getOptionLabel={(x) => x.username || x.label}
        onChange={handleChange}
        loadOptions={loadOptions}
        defaultOptions
      />
    </div>
  );
}
