import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import './style.scss';

export default function StudioNavbar() {
  return (
    <div className="studio-navbar">
      <div className="left">
        <div className="brand">Studio</div>

        <Link exact to="/studio">
          Annotations
        </Link>
        <Link exact to="/studio/merchants">
          Merchants Summary
        </Link>
        <Link exact to="/studio/stats">
          Users Summary
        </Link>
      </div>
      <div className="right" style={{ flexGrow: 1 }}>
        <Link to="/inbox?query=assigned-to-me">Back to Inbox</Link>
      </div>
    </div>
  );
}
