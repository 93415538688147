import React from 'react';
import { match } from 'styled-is';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';

export const Label = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  padding: 3px 5px;
  border-radius: 6px;
  font-size: 10px;

  ${match('color', 'success')`
    color: #0abb87;
    background: rgba(10, 187, 135, 0.1);
  `};
  ${match('color', 'warning')`
    color: #ffb822;
    background: rgba(255, 184, 34, 0.1);
  `};
  ${match('color', 'danger')`
  color: #f64e60;
  background-color: #ffe2e5;
  `};
  ${match('color', 'primary')`
    color: #3699ff;
    background-color: #e1f0ff;
  `};
  ${match('color', 'info')`
    color: #8950fc;
    background-color: #eee5ff;
  `};
  ${match('color', 'muted')`
  background-color: #ebedf3;
  color: #3f4254;  
  `};
`;

export default function Tag({ tag, maxChars, style }) {
  if (!tag) return null;
  return (
    <>
      <Tooltip effect="solid" id={tag.text} />
      <Label
        color={tag.class_name}
        data-tip={tag.text}
        data-for={tag.text}
        style={style}
      >
        {tag.text && tag.text.length > maxChars
          ? `${tag.text.substring(0, maxChars || 120)}...`
          : tag.text}
      </Label>
    </>
  );
}
