import { getQuerySelector, getMutationSelector } from '@redux-requests/core';
import { compile } from 'path-to-regexp';

// Create reusable actions that map to feathers endpoints
export default function createFeathersActions({
  name,
  url,
  meta = {},
  request = {},
}) {
  const GET = `${name}/GET`;
  const FIND = `${name}/FIND`;
  const CREATE = `${name}/CREATE`;
  const PATCH = `${name}/PATCH`;
  const REMOVE = `${name}/REMOVE`;
  // if (name === 'THREADS') console.log(meta); // use for debugging
  // Find
  const get = (id, params, options = {}) => {
    const parsed = compile(`${url}/${id}`)(options.url);
    return {
      type: GET,
      request: {
        method: 'get',
        url: parsed,
        params,
        ...request.get,
      },
      meta: {
        takeLatest: true,
        ...(meta.get instanceof Function ? meta.get(id, params) : meta.get),
      },
    };
  };
  const getSelector = getQuerySelector({ type: GET });
  const getSelectorByKey = (id) =>
    getQuerySelector({ type: GET, requestKey: id });

  // Find
  const find = (params, options = {}) => {
    const parsed = compile(url)(options.url);
    return {
      type: FIND,
      request: {
        method: 'get',
        url: parsed,
        params,
        ...request.find,
      },
      meta: {
        takeLatest: true,
        ...(meta.find instanceof Function ? meta.find(params) : meta.find),
      },
    };
  };
  const findSelector = getQuerySelector({ type: FIND, multiple: true });
  const findSelectorByKey = (id) =>
    getQuerySelector({ type: FIND, multiple: true, requestKey: id });

  // Create
  const create = (data, params, options = {}) => {
    const parsed = compile(url)(options.url);
    return {
      type: CREATE,
      request: options.replaceRequest
        ? options.replaceRequest
        : {
            method: 'post',
            url: parsed,
            data,
            params,
            ...request.create,
            ...options.request,
          },
      meta: {
        mutations: {
          [FIND]: (current, item) => {
            if (current) return [...current, item];
            return [item];
          },
          [GET]: (current, item) => {
            if (current && current._id === item._id) {
              return item;
            }
            return current;
          },
        },
        ...(meta.create instanceof Function
          ? meta.create(data, params)
          : meta.create),
        ...options.meta,
      },
    };
  };
  const createSelector = getMutationSelector({ type: CREATE });
  const createSelectorByKey = (id) =>
    getMutationSelector({ type: CREATE, requestKey: id });

  // Patch
  const patch = function Patch(id, data, params, options = {}) {
    if (this) this.id = id;
    const parsed = compile(`${url}/${id}`)(options.url);
    return {
      type: PATCH,
      request: {
        method: 'patch',
        url: parsed,
        data,
        params,
        ...request.patch,
      },
      meta: {
        mutations: {
          [FIND]: (current, update) => {
            if (!current) return;
            return current.map((item) => {
              if (item._id === update._id) item = update;
              return item;
            });
          },
          [GET]: (current, item) => {
            if (current && current._id === item._id) {
              return item;
            }
            return current;
          },
        },

        ...(meta.patch instanceof Function
          ? meta.patch(id, data, params)
          : meta.patch),
      },
    };
  };
  const patchSelector = getMutationSelector({ type: PATCH });
  const patchSelectorByKey = (id) =>
    getMutationSelector({ type: PATCH, requestKey: id });

  // Remove
  const remove = (id, params, options = {}) => {
    const parsed = compile(`${url}/${id}`)(options.url);
    return {
      type: REMOVE,
      request: {
        method: 'delete',
        url: parsed,
        ...request.remove,
      },
      meta: {
        mutations: {
          [FIND]: (current, item) => {
            if (!current) return;
            return current.filter((x) => x._id !== item._id);
          },
        },
        ...(meta.remove instanceof Function
          ? meta.remove(id, params, options)
          : meta.remove),
        ...options.meta,
      },
    };
  };
  const removeSelector = getMutationSelector({ type: REMOVE });
  const removeSelectorByKey = (id) =>
    getMutationSelector({ type: REMOVE, requestKey: id });

  return {
    GET,
    get,
    getSelector,
    getSelectorByKey,
    FIND,
    find,
    findSelector,
    findSelectorByKey,
    CREATE,
    create,
    createSelector,
    createSelectorByKey,
    PATCH,
    patch,
    patchSelector,
    patchSelectorByKey,
    REMOVE,
    remove,
    removeSelector,
    removeSelectorByKey,
  };
}
