export default {
  position: 'relative',
  control: {
    fontSize: 12,
    fontWeight: 'normal',
    height: 45,
  },

  '&singleLine': {
    display: 'inline-block',
    width: '100%',
    outline: 0,
    borderRadius: 6,
    position: 'relative',
    highlighter: {
      border: '0px inset transparent',
    },
    input: {
      padding: 10,
      height: 45,
      border: '0px inset',
      outline: '0 !important',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
