import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as FixIcon } from 'Assets/metronic/Tools/Tools.svg';
import { Spinner } from 'reactstrap';
import useThread from 'Hooks/use-thread';

import { Btn } from '../Styled';

export default function FixThreadBtn({ thread, reloadThread }) {
  const { id } = thread;
  const { patch } = useThread(thread);
  const [fixThreadPending, setFixThreadPending] = React.useState(false);

  const threadFix = async () => {
    setFixThreadPending(true);
    try {
      const response = await patch(id, {
        action: 'thread-fix',
        account_id: thread.account_id,
      });
      if (response.status === 200) {
        reloadThread();
        toast('Thread fix completed');
      } else {
        toast.error(response?.error?.message || 'Error fixing thread');
      }
    } catch (e) {
      toast.error('Error fixing thread');
    }
    setFixThreadPending(false);
  };

  return (
    <>
      {fixThreadPending ? (
        <Spinner size="sm" color="nylas" className="mr-3" />
      ) : (
        <Btn title="Fix Thread" onClick={threadFix}>
          <FixIcon title />
        </Btn>
      )}
    </>
  );
}
