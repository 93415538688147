import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from 'Components/AuthLayout';
import { Form, Field } from 'react-final-form';
import { FormGroup, Button, Spinner, Alert } from 'reactstrap';
import useMagicLink from 'Hooks/use-magic-link';
import APIError from 'Components/APIError';
import { Input } from 'Components/Form';
import Flex from 'Components/Flex';
import { ChevronLeft } from 'Assets';
import { ReactComponent as InvitesSentImage } from 'Assets/svg/invite-sent.svg';

export default function MagicLink() {
  const { sendLink, validate, error, loading, data } = useMagicLink();
  
  return (
    <AuthLayout logo={false} title="Login Link">
      <Form
        onSubmit={sendLink}
        validate={validate}
        render={({ handleSubmit, invalid, values }) => (
          <>
            {data && (
              <Flex column alignCenter justifyCenter>
                <center className="mb-3">
                  <InvitesSentImage />
                </center>
                <div>
                  <h2 className="text-center my-4">
                    <strong>Magic Link Sent!</strong>
                  </h2>
                  <p className="text-center py-2">
                    We sent a magic link to {values.email} so that will sign you
                    in instantly!
                  </p>
                  <p className="text-center py-2 text-muted">
                    You can safely close this window.
                  </p>
                  <p className="text-center py-2 text-muted">
                    <small>
                      Need help? Reach out to us at{' '}
                      <strong>
                        <a href="mailto: support@nylas.com">
                          support@nylas.com
                        </a>
                      </strong>
                    </small>
                  </p>
                </div>
              </Flex>
            )}
            {!data && (
              <form onSubmit={handleSubmit}>
                <h5 className="mb-2 mt-4 text-muted">
                  <strong>Forgot password</strong>
                </h5>
                <h3 className="mb-2 mt-4">
                  <strong>Magic Link Request</strong>
                </h3>
                {data && (
                  <Alert color="success">
                    <strong>Success!</strong> We sent you a login link to{' '}
                    <strong>{values.email}</strong>, please check your email.{' '}
                    <br />
                    You can safely close this window.
                  </Alert>
                )}
                {error && <APIError error={error} entity="Account" />}

                <p className="my-4 text-muted">
                  We'll email you a magic link that will let tou log in without
                  your password.
                </p>
                {/* <p>Pease enter the email you used to create your account.</p> */}

                <FormGroup>
                  <Field
                    label="Email"
                    component={Input}
                    disabled={data}
                    name="email"
                    placeholder="enter your email"
                    subtext="Pease enter the email you used to create your account."
                  />
                </FormGroup>
                <FormGroup className="mt-5">
                  <Flex alignCenter justifyBetween>
                    <Button
                      to="/login"
                      size="lg"
                      color="primary"
                      className="mr-2"
                      tag={Link}
                      outline
                      block
                    >
                      <ChevronLeft
                        title
                        style={{ width: 15, height: 15, opacity: 0.5 }}
                        className="mr-2"
                      />
                      Back to Login
                    </Button>
                    <Button
                      block
                      disabled={invalid || loading || data}
                      color="primary"
                      size="lg"
                      type="submit"
                      className="font-weight-bold mt-0"
                    >
                      Send link
                      {loading && (
                        <Spinner color="light" size="sm" className="ml-3" />
                      )}
                    </Button>
                  </Flex>
                </FormGroup>
              </form>
            )}
          </>
        )}
      />
    </AuthLayout>
  );
}
