import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm } from 'Components/Toasts';
import { toast } from 'react-toastify';
// import { resetRequests } from '@redux-requests/core';
import {
  // FIND,
  find as findUsers,
  findSelector,
  removeSelector,
  remove as removeAction,
} from 'Store/api/accounts/users';

export default function useEmailTemplates(
  options = { accountId: null, fetch: false, get: false }
) {
  const dispatch = useDispatch();
  const defaultParams = {
    '$sort[created_at]': -1,
  };

  const {
    data,
    loading: findPending,
    error: findError,
  } = useSelector(findSelector);
  const { loading: removePending, error: removeError } =
    useSelector(removeSelector);
  useEffect(() => {
    if (!options.fetch) return;
    dispatch(
      findUsers(options.params || defaultParams, {
        url: { accountId: options.accountId },
      })
    );
    // return () => {
    //   dispatch(resetRequests([FIND]));
    // };
  }, []); // eslint-disable-line

  const removeUser = (...args) => dispatch(removeAction(...args));
  const remove = (...args) => {
    toast(
      <Confirm
        confirm={() => removeUser(...args)}
        text="Are you sure you want to remove this user from account? This action cannot be undone."
      />
    );
  };
  return {
    data,
    findPending,
    findError,
    removePending,
    removeError,
    remove,
  };
}
