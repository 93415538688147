import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import nylas from 'Clients/nylas-api';

const { log } = console;

export default function useMessageBody(message) {
  const [html, setHtml] = useState();
  const [filteredFiles, setFilteredFiles] = useState([]);
  const accounts = useSelector((s) => s.user.accounts);

  useEffect(() => {
    setFilteredFiles(message.files || []);
    try {
      const div = document.createElement('div');
      div.innerHTML = message.body;

      // adds _blank to existing links
      const links = div.querySelectorAll('a');
      [...links].forEach((link) => {
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
      });

      // Nylas Quoted
      const quotedTarget = div.querySelector('.quoted-text');
      if (quotedTarget) {
        try {
          div.removeChild(quotedTarget);
        } catch (e) {
          log('failed to remove quotedTarget');
        }
      }
      // Gmail
      const gmailTarget = div.querySelector('.gmail_quote');
      if (gmailTarget && !quotedTarget) {
        try {
          div.removeChild(gmailTarget);
        } catch (e) {
          log('failed to remove gmailTarget');
        }
      }

      const imageNodes = div.querySelectorAll('img');
      [...imageNodes].forEach(async (node) => {
        if (!node.src.startsWith('cid:')) return;
        try {
          const { files } = message;
          const file = files.find((f) => f.filename === node.alt);
          const conf = `${node.width || 200}x${node.height || 200}`;
          node.src = `https://via.placeholder.com/${conf}?text=Loading+Image...`;
          node.classList.add('pi-inline-image');
          node.setAttribute('data-file-id', file.id);

          setFilteredFiles((s) => s.filter((f) => f.id !== file.id));

          const accessToken = accounts.find(
            (a) => a.id === message.account_id
          ).access_token;
          if (!file) return;
          const dl = await nylas.get(`/files/${file.id}/download`, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            responseType: 'arraybuffer',
          });
          const toBase = Buffer.from(dl, 'binary').toString('base64');
          node.src = `data:image/jpeg;base64, ${toBase}`;
          setHtml(div.innerHTML); // keep this
        } catch (e) {
          log('Could not fetch inline image', e.message);
        }
      });
      // Nylas signature -- works only on top level
      const signature = div.querySelector('.nylas-signature');
      if (signature) {
        try {
          signature.parentNode.removeChild(signature);
        } catch (e) {
          log('failed to remove signature', e.message);
        }
      }
      setHtml(div.innerHTML);
      // RISKY!
      // [...div.childNodes].forEach((node) => {
      //   if (node.innerHTML && node.innerHTML.includes('&gt; wrote:<br>')) {
      //     div.removeChild(node);
      //     this.html = div.innerHTML;
      //   }
      // });
    } catch (e) {
      log('failed to remove quoted text', e.message);
      setHtml(message.body);
    }
  }, [message.body, message.chatter_body]); // eslint-disable-line
  return { html, filteredFiles };
}
