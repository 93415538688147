import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import client from 'Clients/rest';
import JSONTree from 'react-json-tree';
import nylas from 'Clients/nylas-api';

export default function FullEmail({ close, file }) {
  const [docAi, setDocAi] = useState(null);
  const [loading, setLoading] = useState(false);

  const account = useSelector((s) =>
    s.user.accounts.find((a) => a.id === file.account_id)
  );

  const requestDocOcr = async () => {
    setLoading(true);
    const dl = await nylas.get(`/files/${file.id}/download`, {
      headers: { authorization: `Bearer ${account.access_token}` },
      responseType: 'blob',
    });
    const form = new FormData();
    form.append('file', dl, 'file.pdf');
    const fromApi = await client.post(`/external/ml-kit/doc-ai-ocr`, form);
    setLoading(false);
    setDocAi(fromApi);
  };

  return (
    <Modal isOpen toggle={close} size="xl" scrollable>
      <ModalHeader toggle={close}>{file.filename}</ModalHeader>
      <ModalBody>
        {loading && (
          <div>
            {' '}
            <div className="spinner-border" /> Loading
          </div>
        )}
        {docAi && (
          <div>
            <h2>OCR Results</h2>
            <JSONTree data={docAi} invertTheme />
          </div>
        )}

        {!docAi && !loading && (
          <button className="btn btn-primary ml-2" onClick={requestDocOcr}>
            Run OCR
          </button>
        )}
        {/* {jobData.map((item, i) => {
          return <div key={i}>{get(item, 'fullTextAnnotation.text', '')}</div>;
        })} */}
        {/* <div dangerouslySetInnerHTML={{ __html: cached.body }} /> */}
      </ModalBody>
    </Modal>
  );
}
