import { useDispatch, useSelector } from 'react-redux';

export default function useMutation({ actions }) {
  if (!actions) throw new Error('actions are required in useMutation');
  const dispatch = useDispatch();

  const { loading: createPending, error: createError } = useSelector(
    actions.createSelector
  );

  const { loading: patchPending, error: patchError } = useSelector(
    actions.patchSelector
  );

  const { loading: removePending, error: removeError } = useSelector(
    actions.removeSelector
  );

  return {
    create: (...args) => dispatch(actions.create(...args)),
    createPending,
    createError,
    patch: (...args) => dispatch(actions.patch(...args)),
    patchPending,
    patchError,
    remove: (...args) => dispatch(actions.remove(...args)),
    removePending,
    removeError,
  };
}
