const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    fontFamily: '"Poppins", Helvetica, Arial, sans-serif;',
    fontSize: 13,
  }),
  menu: (provided) => ({
    ...provided,
    background: '#fff',
    padding: 5,
  }),
  container: (native) => ({
    ...native,
    flexGrow: 1,
  }),
  option: (native, state) => ({
    ...native,
    fontSize: 13,
    borderRadius: 4,
    color: state.isSelected ? 'white' : '#595d6e',
  }),
  control: (native) => {
    return {
      ...native,
      border: 'none',
      borderRadius: 0,
      fontSize: 12,
      borderColor: 'transparent',
      minHeight: '35px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'transparent',
      },
    };
  },
  singleValue: (native) => ({
    ...native,
    color: '#595d6e',
  }),
  multiValue: (native) => ({
    ...native,
    background: 'rgba(93, 120, 255, 0.1)',
  }),
  multiValueLabel: (native) => ({
    ...native,
    color: '#5d78ff',
  }),
  multiValueRemove: (native) => ({
    ...native,
    color: '#5d78ff',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

export default customStyles;
