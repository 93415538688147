import React from 'react';
import { Label, Input } from 'reactstrap';

export default function FormCheckbox(props) {
  const { input } = props;
  return (
    <Label check>
      <Input {...input} type="checkbox" /> {props.children}
    </Label>
  );
}
