import { useMemo } from 'react';
import prettyBytes from 'pretty-bytes';

/**
 ** @argument size{Number}
 ** @returns {String | Null} -- nicely formatted size, eg. 1MB
 */

export default (size) =>
  useMemo(() => {
    if (!size) return null;
    return prettyBytes(size);
  }, [size]);
