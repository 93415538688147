import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import restClient from 'Clients/rest';
import composer from './reducers';
import ComposerClient from './client';

export default ({ name } = {}) =>
  configureStore({
    reducer: { composer },
    devTools: {
      name,
    }, // process.env.NODE_ENV !== 'production',
    middleware: [
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: { restClient, composer: new ComposerClient() },
        },
      }),
    ],
  });
// The store now has redux-thunk added and the Redux DevTools Extension is turned on
