import { useDispatch, useSelector } from 'react-redux';
import { toggleAccountsAction } from 'Store/settings-sidebar';

export default () => {
  const dispatch = useDispatch();
  const data = useSelector((s) => s.settingsSidebar);

  const toggleAccounts = (val) => {
    dispatch(toggleAccountsAction(val));
  };

  return {
    data,
    toggleAccounts,
  };
};
