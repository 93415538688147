import { useDispatch, useSelector } from 'react-redux';
import batch, { selector } from 'Store/api/threads/batch';

import {
  add,
  remove,
  replace as replaceAction,
  clear as clearAction,
} from 'Store/thread-select';

export default function useThreadMultiSelect(threadId) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selector);
  const patch = (...args) => dispatch(batch(...args));

  const ids = useSelector((state) => state.threadMultiSelect);
  const isActive = ids.length > 0;

  const isSelected = ids.find((x) => x === threadId);
  const isLoading = loading && isSelected;

  const select = () => {
    if (!threadId) return;
    if (isSelected) {
      dispatch(remove(threadId));
    } else {
      dispatch(add([threadId]));
    }
  };
  const clear = () => dispatch(clearAction());
  const replace = (arr) => dispatch(replaceAction(arr));

  return {
    ids,
    select,
    isSelected,
    isActive,
    clear,
    replace,
    loading,
    error,
    patch,
    isLoading,
  };
}
