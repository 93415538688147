import React from 'react';
import Flex from 'Components/Flex';
import { Jumbotron, Container } from 'reactstrap';
import styled from 'styled-components';

const Root = styled(Jumbotron)`
  border-radius: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  background: ${(p) => p.theme.bg};
`;

export default function SettingsEmptyState(props) {
  return (
    <Root fluid>
      <Container fluid>
        <Flex justifyCenter alignCenter>
          <Flex alignCenter justifyBetween column>
            <h1 className="mb-4 font-weight-bold">{props.title}</h1>
            {props.subtitle && (
              <h4 className="mb-5 text-muted">{props.subtitle}</h4>
            )}
            {props.children && props.children}
          </Flex>
        </Flex>
      </Container>
    </Root>
  );
}
