import {
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  InputGroupText,
  Spinner,
} from 'reactstrap';
import React, { useState, useRef, useEffect } from 'react';
import { LockIcon } from 'Assets';
import styled from 'styled-components';

const Lock = styled(LockIcon)`
  width: 15px;
  height: 15px;
  path {
    fill: ${(p) => p.theme.muted};
  }
`;

const StringInput = styled.input`
  &:disabled {
    color: ${(p) => p.theme.muted};
  }
  &:focus {
    border: 1px solid ${(p) => p.theme.primary};
  }
`;

export default function StringEditor({
  caption,
  value,
  onChange,
  type,
  placeholder,
  name,
  editable = true,
}) {
  const [val, setVal] = useState('');
  const [edit, toggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputEl = useRef(null);

  useEffect(() => {
    setVal(value);
  }, [value, setVal]);

  useEffect(() => {
    if (edit) {
      if (inputEl) inputEl.current.focus();
    }
  }, [edit, inputEl]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await onChange({ name, value: val });
      setLoading(false);
    } catch (e) {
      setVal(value);
      setLoading(false);
    }
    toggle(false);
  };

  const handleCancel = () => {
    setVal(value);
    toggle(false);
  };

  const handleClick = () => {
    if (!editable || edit) return;
    toggle(true);
  };

  const handleBlur = () => {
    if (val === value) toggle(false);
  };
  return (
    <>
      <FormGroup>
        <Label>{caption}</Label>
        <InputGroup onClick={handleClick}>
          <StringInput
            className="form-control"
            ref={inputEl}
            type={type || 'text'}
            name={name}
            placeholder={!edit ? placeholder : ''}
            value={val}
            disabled={!editable}
            onChange={(e) => setVal(e.target.value)}
            onBlur={handleBlur}
          />
          {edit && (
            <InputGroupAddon addonType="append">
              <Button
                size="sm"
                style={{ width: 80 }}
                color="primary"
                onClick={handleSave}
                disabled={loading}
              >
                Save
                {loading && (
                  <Spinner color="success" size="sm" className="ml-2" />
                )}
              </Button>
              <Button
                size="sm"
                style={{ width: 80 }}
                color="primary"
                outline
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </InputGroupAddon>
          )}
          {!editable && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <Lock />
              </InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
      </FormGroup>
    </>
  );
}
