import React from 'react';
import GeneralSettings from 'Pages/settings/general';
import Account from 'Pages/settings/account';
import ProtectedRoute from 'Components/ProtectedRoute';
import SettingsLayout from 'Components/SettingsLayout';

export default function SettingsPages() {
  return (
    <SettingsLayout>
      <ProtectedRoute
        exact
        path="/settings/general"
        component={GeneralSettings}
      />
      <ProtectedRoute
        path="/settings/accounts/:accountId"
        component={Account}
      />
    </SettingsLayout>
  );
}
