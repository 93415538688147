import { useMemo } from 'react';

/**
 ** @argument file{Object}
 ** @returns {String | Null} -- extension, eg "pdf" or null
 */

export default (file) =>
  useMemo(() => {
    if (!file.filename) return null;
    if (!file.filename.includes('.')) return null;
    const arr = file.filename.split('.');
    const ext = arr[arr.length - 1];
    if (ext) return ext.toLowerCase();
  }, [file.filename]);
