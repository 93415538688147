import axios from 'axios';
import appConfig from '../app.config';

const client = axios.create({
  baseURL: appConfig.nylasUrl,
  timeout: 15000,
});

client.interceptors.request.use((request) => {
  // Format response
  return request;
});

client.interceptors.response.use((response) => {
  // Format response
  return response.data;
});

export default client;
