import { success } from '@redux-requests/core';
import { FIND } from './index';

export const CREATE = success('ACTIVITIES/CREATE');

export const create = (activity) => ({
  type: CREATE,
  meta: {
    mutations: {
      [FIND]: {
        updateData: (data) => [activity, ...data],
        local: true,
      },
    },
  },
});
