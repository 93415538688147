import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { RefreshIcon, AngleLeft, AngleRight } from 'Assets';
import ThreadFilters from 'Components/ThreadFilters';
import { Spinner } from 'reactstrap';
import AccountUsers from './AccountUsers';
import QueryInfo from './QueryInfo';
import { Btn } from '../Styled';

// Checkbox
const Container = styled(Flex)`
  padding: 20px 15px;
  padding-bottom: 5px;
  position: relative;
`;

const PaginationInfo = styled.div`
  font-weight: bold;
  margin-left: 10px;
  @media ${(p) => p.theme.breakpoints.belowSm} {
    display: none;
  }
`;

export default function Toolbar({
  setPage,
  page,
  perPage,
  loading,
  refresh,
  params,
  threads,
  multiselect,
  // importEML,
}) {
  return (
    <Container justifyBetween>
      <QueryInfo params={params} />
      <Flex alignCenter>
        <AccountUsers params={params} />
        <PaginationInfo>
          {page * perPage || 1} - {page * perPage + perPage || perPage}
        </PaginationInfo>
        {loading && <Spinner className="mx-3" color="primary" />}
        <Btn
          lg
          title="Previous page"
          style={{ marginLeft: 15 }}
          disabled={page === 0 || multiselect}
          onClick={setPage(page - 1)}
        >
          <AngleLeft title />
        </Btn>
        <Btn
          lg
          onClick={setPage(page + 1)}
          title="Next page"
          disabled={multiselect || threads.length === 0}
        >
          <AngleRight title />
        </Btn>
        <ThreadFilters />

        <Btn onClick={refresh} lg title="Refresh" disabled={multiselect}>
          <RefreshIcon title />
        </Btn>
        {/* {params && params.query.startsWith("account-") && (
          <Btn onClick={importEML} lg title="Import EML" disabled={multiselect}>
            <ImportEMLIcon title />
          </Btn>
        )} */}
      </Flex>
    </Container>
  );
}
