import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AttachmentCard from './AttachmentCard';

const AttachmentsContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;
`;

export default function Attachments() {
  const attachments = useSelector((state) => state.composer.files.data);
  if (!attachments.length) return null;
  return (
    <AttachmentsContainer>
      {attachments.map((attachment) => (
        <AttachmentCard key={attachment.id} attachment={attachment} />
      ))}
    </AttachmentsContainer>
  );
}
