import { combineReducers } from 'redux';
import subject from './message/subject';
import body from './message/body';
import from from './message/from';
import cc from './message/cc';
import bcc from './message/bcc';
import to from './message/to';
import reply_to_message_id from './message/reply-to-message-id';
import send_at from './message/send-at';
import file_ids from './message/file-ids';

import files from './files';
import ui from './ui';
import replyToMessage from './reply-to-message';
import forwardMessage from './forward-message';
import contacts from './contacts';
import accounts from './accounts';
import options from './options';
import sendState from './send';
import account from './account';
import quoted_text from './quoted-text';
import draft from './draft';

export default combineReducers({
  message: combineReducers({
    subject,
    body,
    from,
    to,
    cc,
    bcc,
    send_at,
    reply_to_message_id,
    file_ids,
  }),
  sendState,
  contacts,
  account,
  accounts,
  files,
  options,
  replyToMessage,
  forwardMessage,
  quoted_text,
  ui,
  draft,
});
