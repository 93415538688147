import React from 'react';
import useActionBar from 'Hooks/use-action-bar';
import './style.scss';

export default function ActionBarHeader({ title }) {
  const { toggle } = useActionBar();
  return (
    <div className="actionbar-header d-flex align-items-center justify-content-between px-4 py-3">
      <h2>{title}</h2>
      <button className="actionbar-header__close" onClick={() => toggle(false)}>
        &times;
      </button>
    </div>
  );
}
