import React from 'react';
import { Alert, Button } from 'reactstrap';

const DEFAULT_MESSAGE = 'Unknown server error.';

// This component handles errors from API and renders them nicely
export default function APIError({ error, entity, useErrorMessage }) {
  const [expand, setExpand] = React.useState(false);
  let message = DEFAULT_MESSAGE;
  const entityName = entity || 'Entity';
  let additionalInfo = [];

  switch (error.code || error.status) {
    case 409:
      if (error.message.includes(': value already exists')) {
        const ent = error.message.split(':')[0];
        message = `${ent} already exists.`;
      } else {
        message = `${entityName} already exists.`;
      }
      break;
    case 401:
      message = 'Wrong credentials.';
      break;
    case 404:
      message = `${entityName} not found`;
      break;

    case 400:
      message = 'Please check your input data.';
      if (error.errors) {
        Object.keys(error.errors).forEach((key) => {
          const val = error.errors[key];
          if (Array.isArray(val)) {
            additionalInfo = [...additionalInfo, val];
          }
        });
      }
      break;
    default:
      message = DEFAULT_MESSAGE;
  }
  if (useErrorMessage) {
    message = error.message;
  }

  return (
    <Alert color="danger" data-cy="api-error">
      <strong>Error!</strong> {message}{' '}
      {!expand && additionalInfo.length > 0 && (
        <Button size="sm" color="danger" onClick={() => setExpand(true)}>
          More...
        </Button>
      )}
      {expand && additionalInfo.length > 0 && (
        <ul>
          {additionalInfo.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </Alert>
  );
}
