import React from 'react';
import styled from 'styled-components';
import useAttachment from 'Hooks/use-attachment';
import Flex from 'Components/Flex';
import { Btn } from 'Components/Styled';
import { CloseIcon } from 'Assets';

const Container = styled(Flex)`
  padding: 10px 10px;
  line-height: 1.5;
  border-bottom: 1px solid ${(p) => p.theme.border};
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const Info = styled(Flex)`
  flex-grow: 1;
`;

export default function AttachmentCard({ file, removeFile, loading }) {
  const { size, iconUrl } = useAttachment(file);
  return (
    <Container justifyBetween alignCenter>
      <Icon src={iconUrl} />
      <Info grow column className="ml-3">
        <div>{file.filename}</div>
        <div className="text-muted">{size}</div>
      </Info>
      {removeFile && (
        <Btn
          as="button"
          type="button"
          onClick={() => removeFile(file.id)}
          disabled={loading}
        >
          <CloseIcon />
        </Btn>
      )}
    </Container>
  );
}
