import { isEmpty, get } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getMutation } from '@redux-requests/core';
import { toast } from 'react-toastify';
import useAnnotationsStore from './use-annotations-store';

const ANNOTATIONS_UPSERT = 'STUDIO/ANNOTATIONS_UPSERT';
const ANNOTATIONS_PATCH = 'STUDIO/ANNOTATIONS_PATCH';
const ANNOTATIONS_FIND = 'STUDIO/ANNOTATIONS_FIND';

const saveAnnotation = (annotation) => ({
  type: ANNOTATIONS_UPSERT,
  request: {
    url: `/annotations`,
    method: 'post',
    data: annotation,
  },
  meta: {
    asMutation: true,
  },
});

const getAnnotationAction = (message_id, annotation_id) => ({
  type: ANNOTATIONS_FIND,
  request: {
    url: `/annotations`,
    params: { message_id, id: annotation_id },
  },
});

const removeAnnotation = (id) => ({
  type: 'STUDIO/ANNOTATIONS_REMOVE',
  request: {
    url: `/annotations/${id}`,
    method: 'delete',
  },
});

const patchAnnotation = (id, data) => ({
  type: ANNOTATIONS_PATCH,
  request: {
    url: `/annotations/${id}`,
    method: 'patch',
    data,
  },
});

const popupConfig = { position: 'top-right' };

function sanitizeData(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => ![null, '', undefined].includes(v)) // Base cleanup, remove null '' and undefined
      .map(([k, v]) => {
        // If Array of {label, value} return value, else call fn for every array item
        if (Array.isArray(v)) {
          if (v.length === 1 && v[0] === '') {
            return [k, undefined];
          }
          const firstItem = get(v, ['0', 'value']);
          if (firstItem !== undefined) {
            return [k, v.map((x) => x.value).filter((x) => x)];
          }
          return [k, v.map(sanitizeData)];
        }
        // If Date field -- convert to UNIX
        if (v instanceof Date) {
          return [k, moment(v).unix()];
        }
        // For objects excluding array ({})
        return [k, v === Object(v) && !Array.isArray(v) ? sanitizeData(v) : v];
      })
      .filter(([, v]) => {
        // Remove empty objects (isEmpty also removes numbers because they have length 0)
        return typeof v === 'number' || !isEmpty(v, true);
      })
      .map(([k, v]) => [k, v.value && v.label ? v.value : v]) // extract values from {label: '', value: ''}
      .map(([k, v]) => {
        // Cast to proper types
        // Int
        const ints = [
          'total',
          'tax_total',
          'discount_total',
          'refund_total',
          'quantity',
          'unit_price_after_discount',
          'unit_price',
          'coupon',
          'gift_card',
          'shipping_total',
        ];
        if (ints.includes(k)) {
          v = parseInt(v, 0);
        }
        const urls = ['tracking_link', 'image_url', 'url'];
        if (urls.includes(k)) {
          v = encodeURI(v);
        }
        return [k, v];
      })
  );
}

export default function useAnnotations({ messageId } = {}) {
  const { next } = useAnnotationsStore({ messageId });
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const { loading, error } = getMutation(state, { type: ANNOTATIONS_UPSERT });
  const { loadingPatch, errorPatch } = getMutation(state, {
    type: ANNOTATIONS_PATCH,
  });

  const create = async (data) => {
    try {
      const sanitized = sanitizeData(data);
      await dispatch(saveAnnotation(sanitized));
      toast.success(
        'Email Annotation saved successfully. Email is now in QA stage',
        popupConfig
      );
      next();
    } catch (e) {
      toast.error(`Error saving message: ${e.message}`, popupConfig);
      // TODO handling
    }
  };

  const remove = (id) => {
    return dispatch(removeAnnotation(id));
  };

  const fetchSingle = (id) => {
    dispatch(getAnnotationAction(messageId, id));
  };

  const patch = async (id, data) => {
    await dispatch(patchAnnotation(id, data));
    await fetchSingle(id);
    toast.success('Annotated email saved into Ground Truth.', popupConfig);
    next();
  };

  return {
    create,
    remove,
    patch,
    get: fetchSingle,
    loading: loading || loadingPatch,
    error: error || errorPatch,
  };
}
