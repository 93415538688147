import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LOCALSTORAGE_KEY = 'alert__no-email';

const AlertBody = styled.div`
  a {
    color: white;
  }
`;

const CloseButton = styled.button`
  color: #fff;
  font-size: 20px;
`;

export default function NoEmailAlert() {
  const [dismissed, setDismiss] = React.useState(
    localStorage.getItem(LOCALSTORAGE_KEY)
  );

  const dismiss = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, true);
    setDismiss(true);
  };
  if (dismissed) return null;
  return (
    <div
      className="alert alert-danger d-flex align-items-center justify-content-between"
      style={{ borderRadius: 0 }}
    >
      <AlertBody>
        Dont't get locked in. Your user account does not have email address.
        Please update it by clicking{' '}
        <Link to="/settings/general" onClick={() => setDismiss(true)}>
          here
        </Link>
        . You can later login with email, or request password reset.
      </AlertBody>
      <CloseButton onClick={dismiss}>&times;</CloseButton>
    </div>
  );
}
