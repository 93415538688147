import React, { useState } from 'react';
import useEmailTemplates from 'Hooks/use-email-templates';
import TemplateModal from 'Components/TemplateModal';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { Btn } from 'Components/Styled';
import { EditIcon, TrashIcon } from 'Assets';
import { Button } from 'reactstrap';

const Root = styled(Flex)`
  padding: 15px 0;
  border-top: 1px solid ${(p) => p.theme.border};
`;

export default function TemplateItem({ template, accountId, isOwner }) {
  const [modal, toggleModal] = useState(false);
  const toggle = () => toggleModal(!modal);
  const hook = useEmailTemplates();
  return (
    <Root alignCenter>
      <Flex grow column>
        <span style={{ fontSize: 15 }} className="font-weight-bold">
          {template.name}
        </span>
        <div className="text-muted">
          {template.user && (
            <span>
              Created by <strong>{template.user.username}</strong>
            </span>
          )}
        </div>
      </Flex>
      {isOwner && (
        <Flex alignCenter>
          <Btn
            title="Remove"
            onClick={() => hook.remove(template.id)}
            disabled={hook.removePending}
          >
            <TrashIcon title />
          </Btn>
          <Btn title="Edit" onClick={toggle}>
            <EditIcon title />
          </Btn>
        </Flex>
      )}
      {!isOwner && (
        <Flex alignCenter>
          <Button color="light" size="sm" onClick={toggle}>
            Preview Template
          </Button>
        </Flex>
      )}

      <TemplateModal
        open={modal}
        accountId={accountId}
        template={template}
        toggle={toggle}
        isOwner={isOwner}
      />
    </Root>
  );
}
