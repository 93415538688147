import React from 'react';
import DataTable from 'Components/DataTable';
import AppLayout from 'Components/AppLayout';
import { fetchFiles, filesSelector } from 'Store/api/files';
import FileListItem from 'Components/FileListItem';
import { AttachmentIcon } from 'Assets';

export default function FilesPage() {
  return (
    <AppLayout>
      <DataTable
        icon={AttachmentIcon}
        fetchAction={fetchFiles}
        selector={filesSelector}
        component={FileListItem}
        title="All Accounts"
        description="Files"
      />
    </AppLayout>
  );
}
