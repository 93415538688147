import React from 'react';
import { Label, FormFeedback } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';

export default function FormEditor(props) {
  const { input, meta, label, disabled } = props;
  let errorString;
  if (meta.error && Array.isArray(meta.error)) {
    [errorString] = meta.error;
  }
  if (
    !meta.dirtySinceLastSubmit &&
    meta.submitError &&
    Array.isArray(meta.submitError)
  ) {
    [errorString] = meta.submitError;
  }
  return (
    <>
      {label && <Label for={input.name}>{label}</Label>}
      <Editor
        value={input.value}
        disabled={disabled}
        textareaName={input.name}
        apiKey="yoes14bmgp0y9c64v3nf5v0pi4vkma9921u7mmu9972br4l4"
        init={{
          placeholder: 'Enter template here...',
          height: 400,
          menubar: false,
          statusbar: false,
          content_css: '/tinymce.css',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            !disabled &&
            `formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | code preview`,
        }}
        onEditorChange={input.onChange}
      />
      <FormFeedback>{errorString}</FormFeedback>
    </>
  );
}
