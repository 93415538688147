import React from 'react';
import Header from '../Header';

export default function Retool() {
  return (
    <>
      <Header title="Retool" />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <iframe
          title="retool"
          className="actionbar-iframe"
          src="https://retool.nylas.com/apps/Development/Cortex%20Mobile%20Responsive#q="
        />
      </div>
    </>
  );
}
