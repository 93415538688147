import { createReducer } from '@reduxjs/toolkit';
import { addAction, removeAction } from '../../files';

const initialState = { data: [], valid: true };

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [addAction]: (state, action) => {
      state.data = [...state.data, ...action.payload.map((f) => f.id)];
    },
    [removeAction]: (state, action) => {
      state.data = state.data.filter((id) => id !== action.payload);
    },
  }
);
