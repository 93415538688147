import { useEffect } from 'react';
import { find, findSelector, FIND } from 'Store/api/threads';
import { useSelector } from 'react-redux';
import useFetch from 'Hooks/use-fetch';
import accountConnected from 'Utils/account-recently-connected';

let interval;

export default function useThreads() {
  const fetchHook = useFetch({
    fetch: find,
    selector: findSelector,
    reset: [FIND],
  });

  const multiselect = useSelector(
    (state) => state.threadMultiSelect.length > 0
  );

  const accounts = useSelector((s) => s.user.accounts || []);
  const accountRecentlyConnected = accounts.filter(accountConnected);

  const isInitialSync =
    fetchHook.data.length === 0 && accountRecentlyConnected.length > 0;

  // Polling on account connect
  useEffect(() => {
    if (!accountRecentlyConnected.length) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        fetchHook.fetchData();
      }, 1000 * 10);
    }
    return () => clearInterval(interval);
  }, [accountRecentlyConnected]); // eslint-disable-line

  return {
    ...fetchHook,
    isInitialSync,
    multiselect,
  };
}
