import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import classnames from 'classnames';
import './style.scss';

import {
  Logo,
  ArchiveIcon,
  SpamIcon,
  TrashIcon,
  UserIcon,
  InboxIcon,
  SentIcon,
  OutboxIcon,
  ClockIcon,
  DraftIcon,
  AtIcon,
  CheckboxIcon,
  EllipsisHorizontal,
  AttachmentIcon,
  ImportedMailIcon,
} from 'Assets';

import AccountSync from 'Components/AccountSync';

import { updateAction as updateUi } from 'Store/ui';
import EMLImporter from '../EMLImporter';
import SidemenuNavigation from './Navigation';

const MainLogo = styled(Logo)`
  fill: ${(p) => p.theme.nylas};
  width: 26px;
  height: 26px;
`;

export default function Sidemenu() {
  const ui = useSelector((s) => s.ui);
  const user = useSelector((s) => s.user);
  const [linkState, setLinkState] = useState({});
  const dispatch = useDispatch();

  const compose = () => {
    dispatch(updateUi({ composer: true }));
  };

  const collapse = () => {
    if (!ui.sidemenu) return;
    dispatch(updateUi({ sidemenu: false }));
  };

  const privateAccounts = user.accounts
    .filter((account) => !account.shared)
    .map((a) => ({
      id: `private-${a.id}`,
      caption: a.email,
      active: (match, loc) => loc.search.includes(`account-${a.id}`),
      to: `/inbox?query=account-${a.id}`,
    }))
    .sort((a, b) => a.caption.localeCompare(b.caption));

  const sharedAccounts = user.accounts
    .filter((account) => account.shared)
    .map((a) => ({
      id: `private-${a.id}`,
      caption: a.email,
      active: (match, loc) => loc.search.includes(`account-${a.id}`),
      to: `/inbox?query=account-${a.id}`,
    }))
    .sort((a, b) => a.caption.localeCompare(b.caption));

  const links = [
    {
      caption: 'Assigned to me',
      to: `/inbox?query=assigned-to-me`,
      icon: CheckboxIcon,
      active: (match, loc) => loc.search.includes(`query=assigned-to-me`),
      id: 'assigned-to-me',
    },
    {
      caption: 'Mentions',
      to: `/inbox?query=mentions-me`,
      icon: AtIcon,
      active: (match, loc) => loc.search.includes(`query=mentions-me`),
      id: 'mentions-me',
    },
    {
      caption: 'Files',
      to: `/files?${qs.stringify({
        '$sort[message_date]': -1,
      })}`, // NOTE: this is cutoff because older threads don't have all the props
      icon: AttachmentIcon,
      active: (match, loc) => loc.pathname.includes(`/files`),
      id: 'files',
    },
    // {
    //   caption: 'Contacts',
    //   to: `/contacts?given_name[$ne]=null`,
    //   icon: UserIcon,
    //   active: (match, loc) => loc.pathname.includes(`/contacts`),
    //   id: 'contacts',
    // },
    {
      id: 'divider-1',
      type: 'divider',
    },
    {
      id: 'general-inbox',
      caption: 'Inbox',
      to: `/inbox?query=private-inbox`,
      icon: InboxIcon,
      active: (match, loc) => {
        return loc.search.includes(`query=private-inbox`);
      },
      items: [...(privateAccounts || [])],
    },
    {
      id: 'team-inbox',
      caption: 'Team Inbox',
      to: '/inbox?query=shared-inbox',
      icon: UserIcon,
      active: (match, loc) => loc.search.includes(`query=shared-inbox`),
      items: [...sharedAccounts],
    },

    {
      id: 'divider-2',
      type: 'divider',
    },
    {
      id: 'more',
      caption: 'More',
      to: '/inbox?query=all-mail',
      icon: EllipsisHorizontal,
      active: (match, loc) =>
        loc.search.includes(`query=sent`) ||
        loc.search.includes('query=snoozed') ||
        loc.pathname.startsWith('/drafts') ||
        loc.search.includes(`query=all-mail`) ||
        loc.search.includes(`query=spam`) ||
        loc.search.includes(`query=trash`) ||
        loc.pathname.includes('/outbox'),
      items: [
        {
          id: 'all-mail',
          caption: 'All mail',
          to: '/inbox?query=all-mail',
          active: (match, loc) => loc.search.includes(`query=all-mail`),

          icon: ArchiveIcon,
        },
        {
          id: 'imported',
          caption: 'Imported mail',
          to: '/inbox?query=imported-emails',
          active: (match, loc) => loc.search.includes('query=imported-emails'),
          icon: ImportedMailIcon,
        },
        {
          id: 'drafts',
          caption: 'Drafts',
          to: '/drafts',
          icon: DraftIcon,
          active: (match, loc) => loc.pathname.startsWith('/drafts'),
        },
        {
          id: 'outbox',
          caption: 'Outbox',
          to: '/outbox',
          icon: OutboxIcon,
          active: (match, loc) => loc.pathname.includes('/outbox'),
        },
        {
          id: 'sent',
          caption: 'Sent',
          to: '/inbox?query=sent',
          active: (match, loc) => loc.search.includes(`query=sent`),

          icon: SentIcon,
        },
        {
          id: 'snoozed',
          caption: 'Snoozed',
          to: '/inbox?query=snoozed',
          icon: ClockIcon,
          active: (match, loc) => loc.search.includes('query=snoozed'),
        },
        {
          id: 'spam',
          caption: 'Spam',
          to: '/inbox?query=spam',
          active: (match, loc) => loc.search.includes(`query=spam`),
          icon: SpamIcon,
        },
        {
          id: 'trash',
          caption: 'Trash',
          to: '/inbox?query=trash',
          active: (match, loc) => loc.search.includes(`query=trash`),
          icon: TrashIcon,
        },

        // {
        //   id: 'reports',
        //   caption: 'Reports',
        //   to: '/reports',
        //   icon: ReportsIcon,
        //   active: () => false,
        // },
      ],
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {ui.sidemenu && <div className="sidemenu__overlay" onClick={collapse} />}
      <div className={classnames('sidemenu', { open: ui.sidemenu })}>
        <div>
          <div className="pb-4 pl-3">
            <MainLogo />
          </div>
          <center>
            <button
              className="btn btn-primary my-3"
              style={{ width: '200px' }}
              onClick={compose}
              data-cy="compose"
            >
              New Message
            </button>
            <button
              className="btn btn-primary my-3"
              style={{ width: '200px' }}
              onClick={() => setShowModal(!showModal)}
              data-cy="importEML"
            >
              Import EML
            </button>
            <EMLImporter show={showModal} handleClose={hideModal} />
          </center>

          <div className="my-2" />
          <SidemenuNavigation
            links={links}
            linkState={linkState}
            setLinkState={setLinkState}
          />
        </div>
        <AccountSync />
      </div>
    </>
  );
}
