import React, { useState } from 'react';
import { Btn } from 'Components/Styled';
import { EditIcon, TrashIcon } from 'Assets';
import Flex from 'Components/Flex';
import useAliases from 'Hooks/use-aliases';
import AliasModal from 'Components/AliasModal';
import styled from 'styled-components';

const Root = styled(Flex)`
  padding: 15px 0;
  border-top: 1px solid ${(p) => p.theme.border};
`;

export default function AliasItem({ alias, isOwner }) {
  const hook = useAliases();
  const [edit, setToggle] = useState(false);
  const toggle = () => setToggle(!edit);
  return (
    <Root alignCenter>
      <Flex grow>
        <div className="signature-item__name">
          <span style={{ fontSize: 15 }} className="font-weight-bold">
            {alias.email}
          </span>
          <br />
          <span className="text-muted">{alias.name}</span>
        </div>
      </Flex>
      {isOwner && (
        <Flex alignCenter>
          <Btn
            title="Remove"
            onClick={() => hook.remove(alias.account_id, alias._id)}
            disabled={hook.removePending}
          >
            <TrashIcon title />
          </Btn>
          <Btn title="Edit" onClick={() => setToggle(true)}>
            <EditIcon title />
          </Btn>
        </Flex>
      )}
      <AliasModal open={edit} toggle={toggle} alias={alias} />
    </Root>
  );
}
