import { useMemo } from 'react';
import validator from 'Utils/validator';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout, createSelector } from 'Store/api/auth';
import qs from 'query-string';

export default function useLogin() {
  const { loading, error } = useSelector(createSelector);
  const query = qs.parse(document.location.search);

  const initialValues = useMemo(() => {
    const iv = {};
    if (query.email) {
      iv.username = query.email;
    }
    return iv;
  }, [query.email]);

  const dispatch = useDispatch();
  const validate = validator({
    username: {
      presence: true,
      length: { minimum: 3, maximum: 30 },
    },
    password: { presence: true, length: { minimum: 8 } },
  });

  const handleLogin = async (data) => {
    dispatch(login(data));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return { handleLogin, handleLogout, validate, loading, error, initialValues };
}
