import React from 'react';
import AppLayout from 'Components/AppLayout';
import qs from 'query-string';
import ThreadItem from 'Components/ThreadListItem';
import { useHistory } from 'react-router-dom';
import { Container, Btn } from 'Components/Styled';
import './style.scss';
import useThreadsSearch from 'Hooks/use-threads-search';
import useAccount from 'Hooks/use-account';

import NylasLoader from 'Components/NylasLoader';
import AccountSelector from 'Components/AccountSelector';
import { ReactComponent as ReadIcon } from 'Assets/metronic/Communication/Mail-opened.svg';
import { ReactComponent as StarIcon } from 'Assets/metronic/General/Star.svg';
import { ReactComponent as FileIcon } from 'Assets/metronic/General/Clip.svg';

function SearchPage() {
  const history = useHistory();
  const query = qs.parse(document.location.search);
  const {
    data,
    findPending,
    loadMore,
    toggleUnread,
    toggleStarred,
    toggleAttachment,
    handleAccChange,
  } = useThreadsSearch(query.account_id, query.q);

  const handleThreadClick = (t) => {
    history.push(`/inbox/thread/${t.id}`);
  };
  const { account } = useAccount(query.account_id);
  return (
    <AppLayout onBackClick={() => history.push('/')}>
      <Container className="p-3 d-flex align-items-center">
        <div style={{ width: 200 }}>
          <AccountSelector
            onChange={handleAccChange}
            value={account ? account.id : ''}
          />
        </div>
        <Btn
          active={query.q && query.q.includes('is:unread')}
          onClick={toggleUnread}
          title="Unread"
        >
          <ReadIcon />
        </Btn>
        <Btn
          active={query.q && query.q.includes('is:starred')}
          onClick={toggleStarred}
          title="Starred"
        >
          <StarIcon />
        </Btn>
        <Btn
          active={query.q && query.q.includes('has:attachment')}
          onClick={toggleAttachment}
          title="Has attachment"
        >
          <FileIcon />
        </Btn>
      </Container>
      {((findPending && !data.length) || !data.length) && (
        <NylasLoader
          loading={findPending}
          noResults={!data.length}
          loadingCaption="Searching"
        />
      )}
      <Container className="m-3">
        {account &&
          data.map((t) => (
            <ThreadItem
              key={t.id}
              thread={t}
              onClick={() => handleThreadClick(t)}
            />
          ))}
        {data.length > 10 && (
          <center>
            <button
              className="btn btn-outline-primary btn-sm my-4"
              disabled={findPending}
              onClick={loadMore}
            >
              {findPending ? 'Loading' : 'Load more...'}
            </button>
          </center>
        )}
      </Container>
    </AppLayout>
  );
}

export default SearchPage;
