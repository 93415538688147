import currencyCodes from 'currency-codes';

export const email_categories = [
  { label: 'Order Shipped', value: 'order_shipped' },
  { label: 'Order In Transit', value: 'order_in_transit' },
  { label: 'Order Delivered', value: 'order_delivered' },
  { label: 'Order Pickup Available', value: 'order_pickup_available' },
  { label: 'Order Confirmation', value: 'order_confirmation' },
  { label: 'Order Cancelled', value: 'order_cancelled' },
  { label: 'Order Payment Due', value: 'order_payment_due' },
  { label: 'Order Processing', value: 'order_processing' },
  { label: 'Refund Initiated', value: 'refund_initiated' },
  { label: 'Refund Issued', value: 'refund_issued' },
  { label: 'Return Accepted', value: 'return_accepted' },
  { label: 'Other', value: 'other' },
  { label: 'Not an order/tracking email', value: 'true_negative' },
];

export const tracking_categories = [
  {
    label: 'Information Received',
    value: 'info_received',
    description:
      'Carrier has received request from shipper and is about to pick up the shipment.',
  },
  {
    label: 'In transit',
    value: 'in_transit',
    description:
      'Carrier has accepted or picked up shipment from shipper. The shipment is on the way.',
  },
  {
    label: 'Out for Delivery',
    value: 'out_for_delivery',
    description:
      'Carrier is about to deliver the shipment , or it is ready to pickup.',
  },
  {
    label: 'Failed Attempt',
    value: 'failed_attempt',
    description:
      'Carrier attempted to deliver but failed, and usually leaves a notice and will try to deliver again.',
  },
  {
    label: 'Delivered',
    value: 'delivered',
    description: 'The shipment was delivered successfully.',
  },
  {
    label: 'Available for Pickup',
    value: 'pickup_available',
    description:
      'The package arrived at a pickup point near you and is available for pickup.',
  },
  {
    label: 'Exception',
    value: 'exception',
    description:
      'Custom hold, undelivered, returned shipment to sender or any shipping exceptions.',
  },
  {
    label: 'Expired',
    value: 'expired',
    description:
      'Shipment has no tracking information for 30 days since added.',
  },
  {
    label: 'Pending',
    value: 'pending',
    description:
      'New shipments added that are pending to track, or new shipments without tracking information available yet.',
  },
];

export const currency_codes = currencyCodes
  .codes()
  .map((x) => ({ label: x, value: x }));

export const order_statuses = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Order Delivered', value: 'delivered' },
  { label: 'Order In Transit', value: 'in_transit' },
  { label: 'Order Payment Due', value: 'payment_due' },
  { label: 'Order Pickup Available', value: 'pickup_available' },
  { label: 'Order Problem', value: 'problem' },
  { label: 'Order Processing', value: 'processing' },
  { label: 'Order Returned', value: 'returned' },
];

export const return_statuses = [
  { label: 'Refund Initiated', value: 'refund_initiated' },
  { label: 'Refund Issued', value: 'refund_issued' },
  { label: 'Return Accepted', value: 'return_accepted' },
];

export const service_types = [
  { label: 'Ground', value: 'ground' },
  { label: 'Express', value: 'express' },
  { label: 'Standard', value: 'standard' },
  { label: 'Next Day', value: 'next_day' },
  { label: 'Overnight', value: 'overnight' },
  { label: 'International', value: 'international' },
  { label: 'Two Day', value: '2day' },
  { label: 'Three Day', value: '3day' },
  { label: 'Same Day', value: 'same_day' },
];

export const carriers = [
  { label: 'Airmee', value: 'airmee' },
  { label: 'APC', value: 'apc' },
  { label: 'Aramex', value: 'aramex' },
  { label: 'Australia Post', value: 'australia_post' },
  { label: 'Austria Post', value: 'austria_post' },
  { label: 'Bpost', value: 'bpost' },
  { label: 'Bring', value: 'bring' },
  { label: 'Budbee', value: 'budbee' },
  { label: 'Canada Post', value: 'canada_post' },
  { label: 'Chronopost', value: 'chronopost' },
  { label: 'Colis Privé', value: 'colis_privé' },
  { label: 'Collissimo', value: 'collissimo' },
  { label: 'Correos Express', value: 'correos_express' },
  { label: 'Couriers Please', value: 'couriers_please' },
  { label: 'CTT', value: 'ctt' },
  { label: 'DB schenker', value: 'db_schenker' },
  { label: 'Deutsche Post', value: 'deutsche_post' },
  { label: 'DHL', value: 'dhl' },
  { label: 'DPD', value: 'dpd' },
  { label: 'eVRi', value: 'evri' },
  { label: 'Envialia', value: 'envialia' },
  { label: 'FedEx', value: 'fedex' },
  { label: 'FirstMile', value: 'firstmile' },
  { label: 'GLS', value: 'gls' },
  { label: 'Helthjem', value: 'helthjem' },
  { label: 'InPost Paczkomaty', value: 'inpost_paczkomaty' },
  { label: 'Instabox', value: 'instabox' },
  { label: 'La Poste', value: 'la_poste' },
  { label: 'Lasership', value: 'lasership' },
  { label: 'Lone Star', value: 'lone_star' },
  { label: 'Matkahuolto', value: 'matkahuolto' },
  { label: 'My Hermes', value: 'myhermes' },
  { label: 'MRW Spain', value: 'mrw_spain' },
  { label: 'Newgistics', value: 'newgistics' },
  { label: 'New Zealand Courriers', value: 'new_zealand_courriers' },
  { label: 'OnTrac', value: 'ontrac' },
  { label: 'Parcel Force', value: 'parcel_force' },
  { label: 'Poczta Polska', value: 'poczta_polska' },
  { label: 'Poste Italiane', value: 'poste_italiane' },
  { label: 'Posten Norge', value: 'posten_norge' },
  { label: 'Posti', value: 'posti' },
  { label: 'PostNL', value: 'postnl' },
  { label: 'PostNord', value: 'postnord' },
  { label: 'Royal Mail', value: 'royal_mail' },
  { label: 'SEUR Spain', value: 'seur_spain' },
  { label: 'Swiss Post', value: 'swiss_post' },
  { label: 'TNT', value: 'tnt' },
  { label: 'Toll', value: 'toll' },
  { label: 'UK Mail', value: 'uk_mail' },
  { label: 'UPS', value: 'ups' },
  { label: 'USPS', value: 'usps' },
  { label: 'Yodel', value: 'yodel' },
];
