import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Tooltip from 'react-tooltip';
import classnames from 'classnames';
import { get, pick } from 'lodash';
import DatePicker from 'react-datepicker';
import useAnnotations from 'Hooks/use-annotations';
import moment from 'moment';
import { WandIcon } from 'Assets';

import LineItems from './LineItems';
import TrackingItems from './TrackingItems';
import Tip from './Tip';
import 'react-datepicker/dist/react-datepicker.css';
import Input from './Input';
import StringArray from './StringArray';
import ReviewButton from '../ReviewButton';
import GoogleOCRModal from '../GoogleOCRModal';
import {
  email_categories,
  currency_codes,
  order_statuses,
  return_statuses,
} from './categories';
import './form.scss';

const getOptionLabel = (arr) => (opt) => {
  const found = arr.find((x) => x.value === opt.value);
  if (found) {
    return found.label;
  }

  if (opt.value) return opt.value;
  return 'Unknown';
};

const Label = ({ text, caption, children, active }) => (
  // PreventDefault is only because of datePicker auto-close on click
  // eslint-disable-next-line
  <label className={classnames({ active })} onClick={(e) => e.preventDefault()}>
    <Tip text={text} />
    {caption}
    {children}
  </label>
);

const initialValues = {
  email_category: [],
  tracking: {
    tracking_numbers: [''],
    tracking_links: [''],
    tracking_objects: [{}],
  },
  order: {
    line_items: [],
  },
  pii: {
    names: [''],
    usernames: [''],
    emails: [''],
    ip_addresses: [''],
    credit_cards: [''],
    addresses: [''],
    phone_numbers: [''],
    billing_addresses: [''],
    map_links: [''],
    personal_dates: [''],
    personal_times: [''],
    personal_document_links: [''],
  },
  merchant: {
    phone_numbers: [''],
    addresses: [''],
    emails: [''],
    fax_numbers: [''],
  },
};

const hydrate = (annotation) => {
  if (!annotation) return initialValues;

  const createSelectData = (arr = []) =>
    arr.map((x) => ({ label: x, value: x }));
  const strings = pick(annotation, [
    'account_id',
    'message_id',
    'order.id',
    'order.currency',
    'order.discount_total',
    'order.refund_total',
    'order.return_status',
    'order.shipping_total',
    'order.tax_total',
    'order.total',
    'order.coupon',
    'order.gift_card',
    'merchant.name',
    'merchant.domain',
    'tracking.ship_to_address',
    'username',
  ]);
  const out = { ...initialValues, ...strings };
  out.email_category = createSelectData(get(annotation, 'email_category'));

  const selectValue = (path, obj = annotation) => {
    const target = get(obj, path);
    if (target) {
      return { value: target, label: target };
    }
  };

  const parseDate = (input_date) => {
    if (input_date) return moment(input_date, 'X').toDate();
    return null;
  };

  const parseDateFromPath = (path, obj = annotation) => {
    const target = get(obj, path);
    return parseDate(target);
  };

  out.tracking.tracking_objects = get(
    annotation,
    'tracking.tracking_objects',
    []
  );

  out.tracking.tracking_objects = out.tracking.tracking_objects.map((obj) => {
    obj.carrier_name = selectValue('carrier_name', obj);
    obj.status = selectValue('status', obj);
    obj.service_type = selectValue('service_type', obj);
    obj.estimated_delivery_date = parseDate(obj.estimated_delivery_date);
    if (obj.tracking_link !== undefined) {
      obj.tracking_link = decodeURI(obj.tracking_link);
    }
    return obj;
  });

  // Create empty tracking object
  if (!out.tracking.tracking_objects.length) {
    out.tracking.tracking_objects = [{}];
  }

  out.order.status = selectValue('order.status');
  out.order.currency = selectValue('order.currency');
  out.order.return_status = selectValue('order.return_status');
  out.order.line_items = get(annotation, 'order.line_items', []);

  out.order.purchase_date = parseDateFromPath('order.purchase_date');

  out.pii.names = createSelectData(get(annotation, 'pii.names', ['']));
  out.pii.usernames = createSelectData(get(annotation, 'pii.usernames', ['']));
  out.pii.emails = createSelectData(get(annotation, 'pii.emails', ['']));
  out.pii.ip_addresses = createSelectData(
    get(annotation, 'pii.ip_addresses', [''])
  );
  out.pii.credit_cards = createSelectData(
    get(annotation, 'pii.credit_cards', [''])
  );
  out.pii.addresses = createSelectData(get(annotation, 'pii.addresses', ['']));
  out.pii.phone_numbers = createSelectData(
    get(annotation, 'pii.phone_numbers', [''])
  );
  out.pii.billing_addresses = createSelectData(
    get(annotation, 'pii.billing_addresses', [''])
  );
  out.pii.map_links = createSelectData(get(annotation, 'pii.map_links', ['']));
  out.pii.personal_dates = createSelectData(
    get(annotation, 'pii.personal_dates', [''])
  );
  out.pii.personal_times = createSelectData(
    get(annotation, 'pii.personal_times', [''])
  );
  out.pii.personal_document_links = createSelectData(
    get(annotation, 'pii.personal_document_links', [''])
  );

  out.merchant.phone_numbers = createSelectData(
    get(annotation, 'merchant.phone_numbers', [''])
  );
  out.merchant.addresses = createSelectData(
    get(annotation, 'merchant.addresses', [''])
  );
  out.merchant.emails = createSelectData(
    get(annotation, 'merchant.emails', [''])
  );
  out.merchant.fax_numbers = createSelectData(
    get(annotation, 'merchant.fax_numbers', [''])
  );

  out.order.line_items = out.order.line_items.map((obj) => {
    if (obj.image_url !== undefined) {
      obj.image_url = decodeURI(obj.image_url);
    }
    if (obj.url !== undefined) {
      obj.url = decodeURI(obj.url);
    }
    return obj;
  });

  return out;
};

export default function AnnotationForm({ msg, annotation }) {
  const { create } = useAnnotations({ messageId: msg.id });
  const [ts] = React.useState(moment().unix());
  const [modal, setModal] = useState(false);

  const hydratedState = React.useMemo(() => {
    return hydrate(annotation);
  }, [annotation]);

  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: hydratedState });
  const domain = React.useMemo(() => {
    try {
      const from = get(msg, ['from', 'email']);
      return from.split('@')[1];
    } catch (e) {
      // NOOP
    }
  }, [msg]);

  React.useEffect(() => {
    // Unregister component on unmount
    return () => unregister();
  }, [unregister]);

  const onAutoAnnotate = (data) => {
    // console.log('Auto annotate done');
    const list = data.entity_types.map((key, idx) => {
      const val = data.entity_text[idx];
      return { value: val, id: key };
    });
    const map = (mappingId, field, formatter) => {
      try {
        const val = list.find((x) => x.id === mappingId);
        if (val) {
          setValue(field, formatter ? formatter(val.value) : val.value);
        }
      } catch (e) {
        // NO_OP
        // eslint-disable-next-line no-console
        console.warn(`Could not map ${mappingId}:${field}`, e);
      }
    };
    // console.log({ list });

    const currencyFormatter = (currency) => {
      currency = Number(currency.replace(/[^0-9.]+/g, '')) * 100;
      return currency;
    };

    map('supplier_name', 'merchant.name');
    map('purchase_order', 'order.id');
    map('ship_to_address', 'tracking.ship_to');
    map('total_amount', 'order.total', currencyFormatter);
    map('carrier', 'tracking.carrier_name');
  };

  return (
    <>
      <Tooltip
        id="annotation-form"
        place="right"
        delayShow={300}
        effect="solid"
        style={{ fontSize: '11px', width: '300px' }}
      />
      <form
        onSubmit={handleSubmit(create)}
        className="form"
        autoComplete="false"
      >
        <button
          type="button"
          className="btn btn-sm btn-info btn-block"
          onClick={() => setModal(true)}
        >
          <WandIcon
            style={{
              width: 16,
              height: 16,
              fill: 'white',
              marginRight: 5,
              transform: 'translateY(-2px)',
            }}
          />
          Auto Annotate
        </button>
        {modal && (
          <GoogleOCRModal
            onSuccess={onAutoAnnotate}
            message={msg}
            toggle={() => setModal(false)}
          />
        )}
        {annotation && (
          <ReviewButton
            annotation={annotation}
            save={handleSubmit(create)}
            ts={ts}
          />
        )}
        {annotation && (
          <input
            {...register('annotation_id')}
            defaultValue={annotation.id}
            type="hidden"
          />
        )}
        <input
          {...register('timestamp')}
          value={ts}
          type="number"
          style={{ display: 'none' }}
        />
        <Label
          text="The purpose or type of the email. Can select more than one category, if the email contains multiple type of information."
          caption="Email Category"
        >
          <Controller
            name="email_category"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={email_categories}
                getOptionLabel={getOptionLabel(email_categories)}
                isMulti
              />
            )}
          />
          {errors.email_category && (
            <span className="text-danger">This field is required</span>
          )}
        </Label>

        <center>Merchant</center>

        {/* INPUT BY USER */}
        <Input
          r={register}
          name="merchant.name"
          caption="Merchant name:"
          text="
        Name of the store or organization from which the email was an order. <br/><br/>
        The merchant is usually mentioned in the body of the email or subject line, sometimes with phrases like “Thanks for shopping at Target!”, where Target is the merchant. <br/><br/>
        Please only label the merchant name. 
        "
        />

        <input
          {...register('message_id')}
          defaultValue={msg.id}
          type="hidden"
        />
        <Input
          r={register}
          name="merchant.domain"
          caption="Merchant domain:"
          defaultValue={domain}
        />
        <StringArray
          control={control}
          register={register}
          name="merchant.phone_numbers"
          caption="Phone number:"
          text="Phone number(s) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="merchant.fax_numbers"
          caption="Fax number:"
          text="Fax number(s) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="merchant.addresses"
          caption="Address:"
          text="Address(es) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="merchant.emails"
          caption="Email:"
          text="Emails(s) appearing in the email"
        />
        <div>
          <center>Tracking</center>
          <hr />
          <Input
            r={register}
            name="tracking.ship_to_address"
            caption="Ship to address:"
            text="Physical address where the package is being shipped, including street number, city, country, zip code."
          />
          <TrackingItems control={control} register={register} />
        </div>
        <hr />
        <center>Order</center>
        <hr />
        <Label
          caption="Order Status:"
          text="Current status of the order. Please look for indicators in the body of the email or the subject line for phrases that describe the status of the order. Text like “your order is on its way”, or “The order was cancelled” will indicate order status. "
        >
          <Controller
            name="order.status"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={order_statuses}
                isClearable
                getOptionLabel={getOptionLabel(order_statuses)}
              />
            )}
          />
        </Label>
        <Input
          r={register}
          name="order.id"
          caption="Order Number:"
          text="A sequence of letters and numbers that denotes a particular order. Usually near the top on an email, referred to with the words“Order Number”, “Order ID” or “Order #”. Please only label the sequence and not the phrase."
        />
        <div className="row">
          <div className="form-info">
            Multiply all prices by 100 and enter them as whole number, eg.
            <code>$23.41</code> as <code>2341</code>. Discount field must be
            negative value, eg. if discount is <code>$5.33</code> enter{' '}
            <code>-533</code>.
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              r={register}
              name="order.total"
              type="number"
              caption="Order Total:"
              text="Total price for an order, summed over all of the items. Usually found in the order summary section of an order. Please label the number and not the currency symbol. "
            />
          </div>
          <div className="col">
            <label htmlFor="currency">
              <Tip text="Currency for which the purchase was made. Please label the symbol and or letters" />
              Currency code:
              <Controller
                name="order.currency"
                control={control}
                render={({ field }) => (
                  <Select {...field} options={currency_codes} isClearable />
                )}
              />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              r={register}
              name="order.gift_card"
              type="number"
              max="0"
              caption="Gift card:"
              text="Gift card info. Accept only negative values. "
            />
          </div>
          <div className="col">
            <Input
              r={register}
              name="order.coupon"
              type="number"
              max="0"
              caption="Coupon:"
              text="Coupon info. Accept only negative values. "
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              r={register}
              name="order.shipping_total"
              caption="Shipping fees:"
              type="number"
              text="
        Cost for shipping the order or package.Usually listed in the summary charges for an order.  Please don't include any currency symbols in the label.<br/>
        FREE shipping should be annotated as 0
        "
            />
          </div>
          <div className="col">
            <Input
              r={register}
              name="order.tax_total"
              caption="Order total tax:"
              text="Cost of tax applied to the entire order. Usually listed in the summary charges for an order. Please only include the number and not the symbols for currency"
              type="number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              r={register}
              name="order.discount_total"
              caption="Total discount:"
              max="0"
              text="Where applicable, sometimes a discount or promotion is applied to an order. If it is applied as a dollar amount, please label the dollar amount without the currency or without any negative symbols."
              type="number"
            />
          </div>
          <div className="col">
            <Input
              r={register}
              name="order.refund_total"
              caption="Refund total:"
              text="If applicable, and a refund was requested this field indicates the total amount of money that was refunded back to the purchaser. Please only label the numbers and not any currencies or negative symbols."
              type="number"
            />
          </div>
        </div>

        <Label
          caption="Purchase date:"
          text="Date on which customer placed the order, sometimes denoted by the text “purchase date”, or “date of order” in the main text or body of the email. "
        >
          <Controller
            name="order.purchase_date"
            control={control}
            render={({ field }) => (
              <DatePicker {...field} selected={field.value} />
            )}
          />
        </Label>

        <Label
          caption="Return status:"
          text="If applicable and a return or refund was requested, current status of the return. Please look for indicators in the body of the email or the subject line for phrases that describe the return status of the order. Text like “your request for a refund was received”, “a full refund was issued”"
        >
          <Controller
            name="order.return_status"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={return_statuses}
                isClearable
                getOptionLabel={getOptionLabel(return_statuses)}
              />
            )}
          />
        </Label>
        <hr />
        <center>Products (Line Items)</center>
        <LineItems control={control} register={register} />
        <hr />

        <center>Personally Identifiable Information</center>
        <hr />

        <StringArray
          control={control}
          register={register}
          name="pii.usernames"
          caption="Username:"
          text="Username(s) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.names"
          caption="Name:"
          text="Name(s) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.emails"
          caption="Email Address:"
          text="Personal email address"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.ip_addresses"
          caption="IP Address:"
          text="Personal IP Address"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.credit_cards"
          caption="Credit Card:"
          text="Personal Credit cards information"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.addresses"
          caption="Address:"
          text="Address(es) appearing in email"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.phone_numbers"
          caption="Phone Numbers:"
          text="Personal phone numbers"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.billing_addresses"
          caption="Billing addresses:"
          text="Billing address(es) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.map_links"
          caption="Map link:"
          text="Map link(s) appearing in the email"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.personal_dates"
          caption="Personal date:"
          text="Personal date(s)"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.personal_times"
          caption="Personal time:"
          text="Personal time(s)"
        />
        <StringArray
          control={control}
          register={register}
          name="pii.personal_document_links"
          caption="Personal document link:"
          text="Personal document link(s) appearing in the email"
        />
        <hr />

        <input
          type="submit"
          value="Complete Annotation"
          className="btn btn-primary"
        />
      </form>
    </>
  );
}
