import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm } from 'Components/Toasts';
import { toast } from 'react-toastify';
// import { resetRequests } from '@redux-requests/core';
import {
  // FIND,
  // get as getAction,
  find as findInvites,
  // getSelector,
  findSelector,
  // createSelector,
  patchSelector,
  removeSelector,
  patch as patchAction,
  // create as createAction,
  remove as removeAction,
} from 'Store/api/accounts/invites';

export default function useEmailTemplates(
  options = { accountId: null, fetch: false, get: false }
) {
  const dispatch = useDispatch();
  const defaultParams = {
    status: 'pending',
    '$sort[created_at]': -1,
    account_id: options.accountId,
  };

  const {
    data,
    loading: findPending,
    error: findError,
  } = useSelector(findSelector);
  const { loading: removePending, error: removeError } =
    useSelector(removeSelector);
  const { loading: patchPending, error: patchError } =
    useSelector(patchSelector);
  useEffect(() => {
    if (!options.fetch) return;
    dispatch(
      findInvites(options.params || defaultParams, {
        url: { accountId: options.accountId },
      })
    );
    // return () => {
    //   dispatch(resetRequests([FIND]));
    // };
  }, []); // eslint-disable-line

  const patchInvite = (...args) => dispatch(patchAction(...args));
  const removeInvite = (...args) => dispatch(removeAction(...args));
  const remove = (...args) => {
    toast(
      <Confirm
        confirm={() => removeInvite(...args)}
        text="Are you sure you want to remove this invite? This action cannot be undone."
      />
    );
  };
  const patch = (...args) => {
    toast(
      <Confirm
        confirm={() => patchInvite(...args)}
        text="Are you sure you want to resend this invite? This action cannot be undone."
      />
    );
  };
  return {
    data,
    findPending,
    patchPending,
    removePending,
    findError,
    patchError,
    removeError,
    patch,
    remove,
  };
}
