import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { UserIcon, InboxIcon } from 'Assets';

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  strong {
    font-size: 18px;
    display: block;
    line-height: 1;
  }
  span.info {
    font-size: 12px;
    color: ${(p) => p.theme.muted};
    font-weight: bold;
  }
  .account-info__icon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    svg,
    path {
      fill: ${(p) => p.theme.muted};
    }
  }
  @media ${(p) => p.theme.breakpoints.belowSm} {
    .query-info__avatar {
      display: none !important;
    }
  }
`;

export default function QueryInfo({ params = {} }) {
  const user = useSelector((state) => state.user);
  let title;
  let subtitle;
  let Icon;
  let name;
  if (params && params.query && params.query.startsWith('account-')) {
    // Account
    const accountId = params.query.split('account-')[1];
    const acc = user.accounts.find((a) => a.id === accountId);
    if (acc) {
      title = acc.email;
      subtitle = acc.shared ? 'Team Inbox' : 'Private Inbox';
      Icon = acc.shared ? UserIcon : InboxIcon;
    } else {
      title = 'Account';
    }
  } else if (params.query === 'assigned-to-me') {
    name = 'A';
    title = 'Assigned to Me';
    subtitle = 'Threads assigned to me';
  } else if (params.query === 'imported-emails') {
    name = 'I';
    title = 'Imported Emails';
    subtitle = 'Emails uploaded to PI from all users';
  } else if (params.query === 'mentions-me') {
    name = 'M';
    title = 'Mentions';
    subtitle = 'Threads that mention me';
  } else if (params.query === 'private-inbox') {
    name = 'p';
    title = 'Private inbox';
    subtitle = 'Your private emails';
    Icon = InboxIcon;
  } else if (params.query === 'shared-inbox') {
    name = 's';
    title = 'Shared inbox';
    subtitle = 'Shared email accounts';
    Icon = UserIcon;
  } else if (params.query === 'all-mail') {
    name = 'A m';
    title = 'All Mail';
    subtitle = 'All email accounts (shared and private)';
  } else if (params.query === 'sent') {
    name = 'S';
    title = 'Sent';
    subtitle = 'Emails sent by you';
  } else if (params.query === 'snoozed') {
    name = 'S';
    title = 'Snoozed';
    subtitle = 'Snoozed emails';
  } else if (params.query === 'spam') {
    name = 'S';
    title = 'Spam';
    subtitle = 'Spam emails';
  } else if (params.query === 'trash') {
    name = 'T';
    title = 'Trash';
    subtitle = 'Trash emails';
  }

  return (
    <AccountInfo className="query-info">
      <Avatar
        name={name || title}
        style={{ marginRight: 10 }}
        size={32}
        round="4px"
        className="query-info__avatar"
      />
      <div>
        {title && <strong>{title}</strong>}
        {Icon && <Icon className="account-info__icon" />}
        {subtitle && <span className="info">{subtitle}</span>}
      </div>
    </AccountInfo>
  );
}
