import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/FROM/UPDATE');
const initialState = { data: [], valid: false };

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [updateAction]: (state, action) => {
      state.data = [
        {
          name: action.payload.name,
          email: action.payload.email,
        },
      ];
      state.valid = !!action.payload;
    },
  }
);
