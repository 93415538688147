import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Form from './Form';

export default function InviteUsersModal({ open, toggle, accountId }) {
  const onSuccess = () => {
    toast('Invites sent!');
    toggle();
  };
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Invite Users</ModalHeader>
      <ModalBody style={{ padding: '40px 60px' }}>
        <Form accountId={accountId} onSuccess={onSuccess} />
      </ModalBody>
    </Modal>
  );
}
