import { createReducer, createAction } from '@reduxjs/toolkit';

export const add = createAction('THREAD_SELECT/ADD');
export const remove = createAction('THREAD_SELECT/REMOVE');
export const clear = createAction('THREAD_SELECT/CLEAR');
export const replace = createAction('THREAD_SELECT/REPLACE');

const initialState = []; // initial state

export default createReducer(
  initialState, // initial state
  {
    [add]: (state, action) => {
      if (!action.payload) return state;
      return [...state, ...action.payload];
    },
    [replace]: (state, action) => {
      return action.payload;
    },
    [remove]: (state, action) => {
      return state.filter((id) => id !== action.payload);
    },
    [clear]: () => {
      return initialState;
    },
  }
);
