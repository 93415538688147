import store from 'Store';

export default (thread) => {
  const state = store.getState();
  const myEmails = [];
  state.user.accounts.forEach((acc) => {
    myEmails.push(acc.email_address.toLowerCase());
    if (acc.aliases)
      acc.aliases.forEach((alias) => {
        const eml = alias.email || '';
        myEmails.push(eml.toLowerCase());
      });
  });

  if (thread.participants) {
    const [first] = thread.participants.filter((participant) => {
      const p = participant.email || '';
      return !myEmails.includes(p.toLowerCase());
    });
    if (first)
      return {
        name: first.name || first.email,
        email: first.email,
      };

    const [fallback] = thread.participants || [];
    if (fallback)
      return {
        name: fallback.name || fallback.email,
        email: fallback.email,
      };
  }

  return {
    name: 'N/A',
    email: 'N/A',
  };
};
