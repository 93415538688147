import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvatarStack from 'Components/AvatarStack';
import { DownloadIcon, OpenIcon } from 'Assets';
import { Btn } from 'Components/Styled';
import useAttachment from 'Hooks/use-attachment';
import Flex from 'Components/Flex';
import FileViewer from 'Components/FileViewer';

const Root = styled(Flex)`
  padding: 12px 15px;
  border-bottom: 1px solid ${(p) => p.theme.border};
  color: ${(p) => p.theme.muted};
  position: relative;
  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.bg};
    .actions {
      display: flex;
    }
  }
  .actions {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 5px;
    background: ${(p) => p.theme.bg};
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    min-width: 100px;
  }

  .size {
    width: 60px;
    flex-shrink: 0;
    text-align: right;
  }

  .date {
    text-align: right;
    flex-shrink: 0;
    width: 80px;
  }

  .filename {
    font-weight: 600;
    color: ${(p) => p.theme.dark};
  }
`;

const From = styled.div`
  width: 300px;
  text-align: right;
`;

export default function FileListItem({ item: file }) {
  const hook = useAttachment(file);
  const {
    size,
    date,
    download,
    jumpToThread,
    viewer,
    toggleViewer,
    iconUrl,
  } = hook;
  const participants = file.last_message_participants;
  const from = file.last_message_from;

  return (
    <Root center onClick={() => toggleViewer(true)}>
      <Flex grow alignCenter>
        <img src={iconUrl} className="mr-3" alt="" style={{ width: 20 }} />
        <div className="filename">{file.filename || 'N/A'}</div>
      </Flex>
      {from && (
        <From noShrink justifyEnd className="truncated text-small mr-3">
          Last message from <strong> {from.name || from.email}</strong>
        </From>
      )}
      {participants && participants.length && (
        <AvatarStack
          users={participants}
          title={(u) => `${u.email}`}
          name={(u) => `${u.name || u.email}`}
          max={5}
        />
      )}
      <div className="size text-small">{size}</div>
      <div className="date text-small">{date}</div>
      <div className="actions">
        {file.last_thread_id && (
          <Btn title="Jump to thread" onClick={jumpToThread}>
            <OpenIcon title />
          </Btn>
        )}
        <Btn onClick={download} title="Download">
          <DownloadIcon title />
        </Btn>
      </div>
      {viewer && (
        <FileViewer
          file={file}
          close={(e) => {
            if (e) e.stopPropagation();
            toggleViewer(!viewer);
          }}
        />
      )}
    </Root>
  );
}

FileListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
