import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TrackingLinkButton from './TrackingLinkButton';

function ResultShow({result}) {  
    if (!result.length) {
        return (
            <div className="tracking-box">Could not extract tracking number</div>
        )
    }
    return (
        <div>
        {result.map(function(d, idx){
            return (
                <form key="{d.key}">
                    <div className="formItem">
                        <div className="frRow">
                            <div className="frCell">Tracking Number:</div>
                            <div className="frCell">{d.value}</div>
                            <TrackingLinkButton data={d.value} />
                        </div>
                    </div>
                </form>
            )
        })}
        </div>
    );
}

export default function AnalyzeItemModal({ open, toggle, result }) {
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Analyze link</ModalHeader>
      <ModalBody style={{ padding: '60px 60px' }}>
        <ResultShow result={result} />
      </ModalBody>
    </Modal>
  );
}
