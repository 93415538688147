import { getQuery } from '@redux-requests/core';

export const FETCH_FILES = 'FETCH_FILES';

// Here we fetch  nylas-send/outbox for all accounts one-by-one
export const fetchFiles = (params) => ({
  type: FETCH_FILES,
  request: {
    url: '/files',
    params: { ...params },
  },
});

export const filesSelector = (state) =>
  getQuery(state, { type: FETCH_FILES, multiple: true });
