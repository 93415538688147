import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'query-string';

const visit = (...args) => put(push(...args));

const routes = {
  // protectedRoute uses redirect (Components/ProtectedRoute)
  landing: {
    *next() {
      yield visit(`/inbox?query=private-inbox`);
    },
  },
  login: {
    *next(payload, ctx) {
      yield visit(ctx.query.entry || '/');
    },
  },
  inviteCode: {
    *next({ invite_code }) {
      yield visit(`/signup?${qs.stringify({ invite_code })}`);
    },
    *prev() {
      yield visit('/login');
    },
  },
  signup: {
    *next() {
      // if user does not have account_ids it will be redirected to /connect by protected route
      return yield visit('/');
    },
    *prev() {
      yield visit(`/login`);
    },
    // it is calling auth after signup (no next)
  },
  connectCallback: {
    *next(payload, ctx) {
      const account = yield select((s) => s.user.accounts);
      const { accountId, reconnect } = ctx.query;
      if (account && account.shared && !reconnect) {
        return yield visit(
          `/invite-users/accounts?${qs.stringify({ accountId })}`
        );
      }
      if (!ctx.query.error) return yield visit('/');
    },
    *prev() {
      return yield visit('/connect');
    },
  },
  inviteUsers: {
    *next(n, ctx) {
      if (ctx.query.entry) {
        return yield visit(ctx.query.entry);
      }
      yield visit('/');
    },
  },
  invite: {
    *signup(data) {
      yield visit(
        `/signup?${qs.stringify({
          email: data.email,
          invite_code: data.invite_code,
          invite_id: data.id,
        })}`
      );
    },
    *login(data) {
      yield visit(
        `/login?${qs.stringify({
          entry: `/invite?invite_code=${data.invite_code}&invite_id=${data.id}`,
          email: data.email,
        })}`
      );
    },
    *accept() {
      yield visit('/');
    },
    *reject() {
      yield visit('/');
    },
    *skip() {
      yield visit('/');
    },
  },
};

export default routes;
