import { getQuery } from '@redux-requests/core';
import appConfig from 'app.config';

export const FETCH_OUTBOX = 'FETCH_OUTBOX';

export const fetchOutbox = (params) => {
  return {
    type: FETCH_OUTBOX,
    request: [], // set in meta.onRequest
    meta: {
      // Here we fetch  nylas-send/outbox for all accounts one-by-one
      onRequest(request, requestAction, store) {
        const state = store.getState();
        const { accounts } = state.user;
        return accounts
          .filter((a) => a.access_token)
          .map((account) => ({
            baseURL: appConfig.nylasOutboxUrl,
            url: '/outbox',
            headers: { authorization: `Bearer ${account.access_token}` },
            params: { ...params },
          }));
      },
    },
  };
};

export const outboxSelector = (state) =>
  getQuery(state, { type: FETCH_OUTBOX, multiple: true });
