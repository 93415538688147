import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/SUBJECT/UPDATE');
const initialState = { data: '', valid: true };
export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [updateAction]: (state, action) => {
      state.data = action.payload;
    },
  }
);
