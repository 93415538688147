const basePath = `/file-icons`;
const combine = (ext) => `${basePath}/${ext}.svg`;

export default (file) => {
  if (!file.filename && !file.content_type) return combine('generic');
  const extension = file.filename
    ? file.filename.split('.').pop().toLowerCase()
    : file.content_type.split('/')[1].toLowerCase();

  switch (extension) {
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return combine('image');
    case 'mp3':
      return combine(extension);
    case 'pdf':
      return combine(extension);
    case 'csv':
      return combine(extension);
    case 'xlr':
    case 'xlsx':
      return combine('xls');
    case 'xls':
      return combine(extension);
    case 'm4a':
      return combine(extension);
    case 'mov':
      return combine(extension);
    case 'mp4':
      return combine('video');
    case 'wav':
      return combine(extension);
    case 'ai':
      return combine(extension);
    case 'ae':
      return combine(extension);
    case 'avi':
      return combine(extension);
    case 'dat':
      return combine(extension);
    case 'eps':
      return combine(extension);
    case 'ttf':
    case 'otf':
    case 'fon':
    case 'fnt':
      return combine('font');
    case 'key':
      return combine('keychain');
    case 'm4v':
      return combine(extension);
    case 'psd':
      return combine(extension);
    case 'sketch':
      return combine(extension);
    case 'docx':
      return combine('doc');
    case 'doc':
      return combine('doc');
    case 'pps':
    case 'pptx':
      return combine('ppt');
    case 'ppt':
      return combine('ppt');
    case 'vid':
      return combine(extension);
    case 'txt':
      return combine(extension);
    case 'xlm':
      return combine(extension);
    case 'svg':
      return combine(extension);
    case 'odt':
    case 'rtf':
    case 'tex':
    case 'wpd':
    case 'wps':
      return combine('text');
    case 'prpro':
      return combine('premiere');
    case 'zip':
    case 'rar':
    case '7z':
    case 'pkg':
      return combine('compressed');

    default:
      return combine('generic');
  }
};
