import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from 'Components/ProtectedRoute';

import Login from 'Pages/auth/login';
import InviteCode from 'Pages/auth/invite-code';
import Signup from 'Pages/auth/signup';
import Connect from 'Pages/auth/connect';
import InviteUsers from 'Pages/auth/invite-users';
import Invite from 'Pages/auth/invite';
import ConnectCallback from 'Pages/auth/connect-callback';
import SendMagicLink from 'Pages/auth/magic-link/send';
import LoginMagicLink from 'Pages/auth/magic-link';

export default function AuthRoutes() {
  return (
    <>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/forgot" component={SendMagicLink} />
      <Route exact path="/magic-link" component={LoginMagicLink} />
      {/* note: connect cannot be protected because of redirect in ProtectedRoute */}
      <Route exact path="/connect" component={Connect} />
      <ProtectedRoute
        exact
        path="/connect-callback"
        component={ConnectCallback}
      />
      <Route exact path="/invite" component={Invite} />
      <Route exact path="/invite-code" component={InviteCode} />
      <ProtectedRoute component={InviteUsers} path="/invite-users/:method?" />
    </>
  );
}
