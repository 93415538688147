import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { updateAction as setQuotedText } from 'Store/composer/quoted-text';
import { updateAction as setBody } from 'Store/composer/message/body';
import { theme } from 'Components/Styled';
import { ReactComponent as Icon } from 'Assets/ionic/ellipsis-horizontal.svg';

const Btn = styled.button`
  cursor: pointer;
  background: ${theme.border};
  border-radius: 10px;
  padding: 2px 10px;
  svg {
    width: 14px;
    height: 14px;
    fill ${theme.muted};
  }
  &:hover {
    svg {
      fill: ${theme.primary};
    }
  }
`;

export default function EditQuotedTextBtn() {
  const quotedText = useSelector((state) => state.composer.quoted_text.data);
  const body = useSelector((state) => state.composer.message.body.data);
  const dispatch = useDispatch();
  const inlineQuotedText = () => {
    dispatch(setQuotedText(''));
    dispatch(setBody(body + quotedText));
  };

  if (!quotedText) return null;
  return (
    <Btn onClick={inlineQuotedText}>
      <Icon />
    </Btn>
  );
}
