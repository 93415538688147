import React from 'react';
import Draggable from 'react-draggable';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DropZone from './DropZone';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    color: 'white',
    background: '#1e1e2d',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// const Modal = styled.div`
//   position: fixed;
//   /* top: 0;
//   left: 0; */
//   /* width: 100%;
//   height: 100%; */
//   /* background: rgba(0, 0, 0, 0.1); */
//   display: none;
//   z-index: 11;

//   ${(props) =>
//     props.showModal &&
//     css`
//       display: block;
//     `}
// `;

// const ModalMain = styled.section`
//   position: fixed;
//   background: white;
//   width: 40%;
//   min-width: 600px;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//   border-radius: 8px;
//   max-height: 800px;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EMLImporter({ handleClose, show }) {
  return (
    <>
      {/* <Modal showModal={show}>
        <ModalMain>
          <HeaderContainer>
            <div>Upload EMLs</div>
            <div>
              <button onClick={handleClose}>&times;</button>
            </div>
          </HeaderContainer>
          <DropZone />
        </ModalMain>
      </Modal> */}
      <Dialog
        hideBackdrop
        disableEnforceFocus
        open={show}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        PaperComponent={PaperComponent}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Upload EMLs
        </DialogTitle>
        <DropZone />
      </Dialog>
    </>
  );
}
