import React from 'react';
import AuthLayout from 'Components/AuthLayout';
import { Form, Field } from 'react-final-form';
import { FormGroup, Button, FormText, Spinner } from 'reactstrap';
import { Input } from 'Components/Form';
import { Link } from 'react-router-dom';
import useLogin from 'Hooks/use-login';
import APIError from 'Components/APIError';

export default function Signup() {
  const { handleLogin, validate, initialValues, error, loading } = useLogin();
  return (
    <AuthLayout>
      <Form
        onSubmit={handleLogin}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <h1 className="mb-5 mt-4">Sign In</h1>
            {error && <APIError error={error} />}
            {/* For invites only */}
            <Field component={Input} name="name" type="hidden" />
            <FormGroup>
              <Field
                label="Username or email"
                component={Input}
                name="username"
                placeholder="your username or email"
              />
            </FormGroup>
            <FormGroup>
              <Field
                label="Password"
                component={Input}
                type="password"
                name="password"
                placeholder="your password"
              />
            </FormGroup>
            <FormGroup>
              <Link to="/forgot" className="font-weight-bold">
                Forgot your password?
              </Link>
            </FormGroup>

            <FormGroup className="mt-4">
              <Button
                block
                disabled={invalid || loading}
                color="primary"
                size="lg"
                type="submit"
                className="font-weight-bold"
              >
                Login
                {loading && (
                  <Spinner color="light" size="sm" className="ml-3" />
                )}
              </Button>
            </FormGroup>
            <FormText className="text-center my-3">or</FormText>
            <FormGroup className="mt-1">
              <Button
                block
                outline
                color="primary"
                size="lg"
                type="button"
                tag={Link}
                disabled={loading}
                to="/invite-code"
                className="font-weight-bold"
              >
                Signup
              </Button>
            </FormGroup>
          </form>
        )}
      />
    </AuthLayout>
  );
}
