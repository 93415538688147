import nylas from 'Clients/nylas-api';
import sendApi from 'Clients/nylas-outbox';

class ComposerClient {
  constructor() {
    this.findContacts = this._findContacts.bind(this);

    // nylas
    this.nylas = nylas;
    this.getAccount = this._getAccount.bind(this);
    this.getMessage = this._getMessage.bind(this);
    this.upload = this._upload.bind(this);
    this.removeAttachment = this._removeAttachment;
    this.send = this._send.bind(this);

    // nylas-composer
    this.sendApi = sendApi;
  }

  _getAccount(accessToken) {
    if (!accessToken)
      throw new Error('getAccount expects argument "accessToken"');
    return nylas.get('/account', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  _getMessage(id, accessToken) {
    if (!accessToken)
      throw new Error('getMessage expects argument "accessToken"');

    return nylas.get(`/messages/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  _findContacts(term, accessToken) {
    if (!accessToken)
      throw new Error('findContacts expects argument "accessToken"');

    return this.sendApi.get('/escontacts', {
      params: {
        search: term,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  _send(data, accessToken) {
    if (!accessToken) throw new Error('send expects argument "accessToken"');
    return this.sendApi.post('/outbox', data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  _upload(formData, accessToken) {
    if (!accessToken) throw new Error('upload expects argument "accessToken"');

    return this.nylas.post('/files', formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  _removeAttachment(id, accessToken) {
    if (!accessToken)
      throw new Error('removeAttachment expects argument "accessToken"');
    return this.nylas.delete(`/files/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export default ComposerClient;
