import React, { useState } from 'react';
import Select from 'react-select';
import selectStyle from 'Components/Styled/Select';

const StyledSelect = selectStyle(Select);

export default function AccountSelector({
  onChange,
  placeholder,
  defaultValue,
}) {
  const [localValue, setLocalValue] = useState(defaultValue || null);
  const options = [
    { label: 'Starred', value: { starred: true }, id: 'starred' },
    // { label: 'Not Starred', value: { starred: false }, id: 'not-starred' },
    // { label: 'Read', value: { unread: true }, id: 'unread' },
    { label: 'Unread', value: { unread: true }, id: 'unread' },
    { label: 'Archived', value: { archived: true }, id: 'archived' },
    // { label: 'Not archived', value: { archived: false }, id: 'not-archived' },
    { label: 'Spam', value: { spam: true }, id: 'spam' },
    // { label: 'Not spam', value: { spam: false }, id: 'not-spam' },
    { label: 'Trash', value: { trash: true }, id: 'trash' },
    // { label: 'Not trash', value: { trash: false }, id: 'not-trash' },
    {
      label: 'Has Attachment',
      value: { has_attachments: true },
      id: 'has_attachments',
    },
  ];

  const handleChange = (data) => {
    setLocalValue(data);
    if (onChange) onChange(data ? data.value : null);
  };

  React.useEffect(() => {
    const val = options.find((x) => x.id === defaultValue);
    setLocalValue(val);
  }, [defaultValue]); // eslint-disable-line

  return (
    <StyledSelect
      className="basic-single flex-grow-1"
      options={options}
      classNamePrefix="select"
      isClearable
      placeholder={placeholder || 'Query filter'}
      name="query-picker"
      value={localValue}
      getOptionValue={(x) => {
        if (!x) return null;
        return x.id;
      }}
      onChange={handleChange}
    />
  );
}
