import client from 'Clients/rest';
import conf from '../app.config';

class Metrics {
  constructor() {
    this.session_id = Date.now();
    localStorage.setItem(conf.localStorageSessionKey, this.session_id);
    this.startSession();
  }

  logEvent(data) {
    return client.post('/metrics', { ...data }); // session-id picked from headers
  }

  startSession() {
    if (!('navigator' in window)) return;
    const form = new FormData();
    form.append('session_id', this.session_id);
    form.append('event', 'session-start');
    navigator.sendBeacon(`${conf.restUrl}/metrics`, form);
  }

  endSession() {
    if (!('navigator' in window)) return;
    const form = new FormData();
    form.append('session_id', this.session_id);
    form.append('event', 'session-end');
    navigator.sendBeacon(`${conf.restUrl}/metrics`, form);
  }
}

export default new Metrics();
