import moment from 'moment';

export default (body, msg) => {
  // if reply fields are set, get the sender
  const sender = msg.from[0];

  // e.g., Thu, Mar 2, 2017 at 6:56 PM
  const timestamp = moment
    .unix(msg.date)
    .format('ddd, MMM D, YYYY [at] h:mm A');

  // append the quoted text
  const reply =
    `<div class="quoted-text"><p class="MsoNormal">On ${timestamp} ${sender.name} ` +
    `&lt;<a href="mailto:${sender.email}" target="_blank">${sender.email}</a>&gt; ` +
    `wrote:<u></u><u></u></p><blockquote style="margin-top:5.0pt;margin-bottom:5.0pt">` +
    `<div><p class="MsoNormal">${body || msg.body}</div>`;

  return reply;
};
