import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { updateAction as updateUi } from 'Store/composer/ui';
import { ReactComponent as Expand } from 'Assets/metronic/General/Expand-arrows.svg';
import { theme } from 'Components/Styled';
import HeaderContainer from 'Components/Styled/HeaderContainer'

const ExpandIcon = styled(Expand)`
  fill: red;
  transform: translateY(-3px);
  width: 20px;
  height: 20px;
  cursor: pointer;
  path {
    fill: ${theme.muted};
  }
  &:hover {
    path {
      fill: #fff;
    }
  }
`;

export default function Header({ subject, close, toggleMinimize }) {
  const sending = useSelector((state) => state.composer.sendState.sending);
  const expanded = useSelector((state) => state.composer.ui.maximized);
  const dispatch = useDispatch();

  const toggleExpand = () => {
    dispatch(updateUi({ maximized: !expanded }));
  };

  return (
    <HeaderContainer>
      {sending && <div>Sending...</div>}
      {!sending && <div>{subject || 'New Message'}</div>}
      {!sending && (
        <div>
          <ExpandIcon onClick={toggleExpand} />
          <button
            style={{ display: 'inline-block', transform: 'translateY(-5px)' }}
            onClick={toggleMinimize}
          >
            _
          </button>
          <button onClick={close}>&times;</button>
        </div>
      )}
    </HeaderContainer>
  );
}
