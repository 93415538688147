import { useDispatch, useSelector } from 'react-redux';
import * as uploads from 'Store/api/uploads';

export default function useUploads(accountId) {
  const dispatch = useDispatch();

  const createUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('account_id', accountId);
    return dispatch(uploads.create(formData));
  };

  const removeUpload = (id) => {
    return dispatch(uploads.remove(id));
  };

  const { loading: createPending, error: createError } = useSelector(
    uploads.createSelector
  );
  const { loading: removePending, error: removeError } = useSelector(
    uploads.removeSelector
  );

  return {
    createUpload,
    removeUpload,
    createPending,
    removePending,
    removeError,
    createError,
  };
}
