import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { send } from 'Store/composer/send';
import SendLater from '../SendLaterButton';
import './send-button.scss';

// QUEUE UNTIL

export default function SendButton({ close, beforeSend, afterSend }) {
  const dispatch = useDispatch();
  const sendAt = useSelector((state) => state.composer.message.send_at.data);

  const [loading, error, account] = useSelector(({ composer: state }) => [
    state.sendState.sending,
    state.sendState.error,
    state.account,
  ]);

  const [message, valid] = useSelector(({ composer: state }) => {
    const isValid = Object.keys(state.message).every((key) => {
      return state.message[key].valid;
    });
    return [state.message, isValid];
  });

  const handleSend = async () => {
    if (beforeSend) beforeSend(message);
    const res = await dispatch(send(message));
    if (res && afterSend) afterSend(res);
    close(res);
  };

  const invalid = !valid || loading || !account;
  return (
    <div className="nlyas-composer__send-group">
      <button
        disabled={invalid || loading}
        onClick={handleSend}
        type="button"
        className="nylas-composer__send"
      >
        {loading ? 'Sending...' : 'Send'}
        {sendAt &&
          !loading &&
          ` on ${moment(sendAt, 'X').format('ddd, DD MMM hh:mma')}`}
      </button>
      <SendLater disabled={invalid} />

      {error && <span>Send Error</span>}
    </div>
  );
}
