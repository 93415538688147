import moment from 'moment';

export default (a) => {
  if (!a.last_connected_at) return false;
  const now = moment();
  const connectedAt = moment(a.last_connected_at, 'X');
  const diff = now.diff(connectedAt, 'minutes');
  if (diff > 15) return false; // show for 15 minutes
  return a;
};
