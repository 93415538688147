// This is landing page and just handles redirection
import React, { useEffect } from 'react'; // eslint-disable-line
import useRouter from 'Hooks/use-router';

export default function LandingPage() {
  const { next } = useRouter();
  useEffect(() => {
    next('landing');
  }, [next]);
  return null;
}
