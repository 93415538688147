import React from 'react';
import AuthLayout from 'Components/AuthLayout';
import useLogin from 'Hooks/use-login';
import ConnectForm from 'Components/Connect/Form';

export default function Signup() {
  const { handleLogout } = useLogin();
  return (
    <AuthLayout>
      <h2 className="mb-5 mt-4">Connect an email address</h2>
      <ConnectForm onSkip={handleLogout} />
    </AuthLayout>
  );
}
