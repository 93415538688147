import { updateAction as setAccounts } from 'Store/composer/accounts';
import { updateAction as setFrom } from 'Store/composer/message/from';
import { updateAction as setReplyToMessage } from 'Store/composer/reply-to-message';
import { updateAction as setReplyToMessageId } from 'Store/composer/message/reply-to-message-id';
import { updateAction as setTo } from 'Store/composer/message/to';
import { updateAction as setCc } from 'Store/composer/message/cc';
import { updateAction as setBcc } from 'Store/composer/message/bcc';
import { setDraft } from 'Store/composer/draft';
import { updateAction as setQuotedText } from 'Store/composer/quoted-text';
import { updateAction as setBody } from 'Store/composer/message/body';

import { updateAction as setSubject } from 'Store/composer/message/subject';
import { updateAction as setForwardToMessage } from 'Store/composer/forward-message';
import { updateAction as setAccount } from 'Store/composer/account';
import { addAction as setFiles } from 'Store/composer/files';

import createQuotedText from 'Utils/quoted-reply';
import createForwardText from 'Utils/quoted-forward';
import { compose, uniqBy, filter } from 'lodash/fp';
import rest from 'Clients/rest';
import juice from 'juice';

const { error, log } = console;

export default (config) =>
  async (dispatch, rootState, { composer: client }) => {
    let account = null;
    let draft = null;

    if (config.accounts) {
      [account] = config.accounts;
      dispatch(setAccounts(config.accounts));
      log('ACCOUNT', account);
      if (account) dispatch(setAccount(account));
      const accPayload = {
        name: account.name,
        email: account.email_address,
      };
      dispatch(setFrom(accPayload));
    }
    log('Initing composer with config', config);
    // Draft
    if (config.draft_id) {
      try {
        draft = await rest.get(`/drafts/${config.draft_id}`);
        const draftAccount = config.accounts.find(
          (a) => a.id === draft.account_id
        );
        dispatch(setDraft(draft));
        dispatch(setAccount(draftAccount));
        if (draft.from && draft.from.length) {
          dispatch(setFrom(draft.from[0]));
        } else {
          dispatch(
            setFrom({
              name: draftAccount.name,
              email: draftAccount.email,
            })
          );
        }

        if (draft.to) dispatch(setTo(draft.to));
        if (draft.subject) dispatch(setSubject(draft.subject));
        if (draft.body) dispatch(setBody(draft.body));
        if (draft.cc) dispatch(setCc(draft.cc));
        if (draft.bcc) dispatch(setBcc(draft.bcc));
        if (draft.files) {
          dispatch(setFiles(draft.files));
        }
        log('Recover from draft', draft);
      } catch (e) {
        log('Load from draft error', e.message);
      }
    }

    // Reply to message
    try {
      const replyToMessageId = config.reply_to_message_id;
      if (replyToMessageId) {
        const replyToMessage = await client.getMessage(
          replyToMessageId,
          account.access_token
        );
        log('Replying to message', replyToMessage);
        // From
        const payload = {
          name: account.name,
          email: account.email_address,
        };
        dispatch(setFrom(payload));
        dispatch(setAccount(account));
        dispatch(setSubject(`Re:${replyToMessage.subject}`));
        dispatch(setReplyToMessage(replyToMessage));
        // set reply to message
        dispatch(setReplyToMessageId(replyToMessageId));
        // Set To
        if (config.reply_all) {
          log(replyToMessage);
          const from = replyToMessage.from || [];
          const to = replyToMessage.to || [];
          const cc = replyToMessage.cc || [];
          // BCC omited
          const recipients = compose(
            filter((x) => x.email !== account.email),
            uniqBy('email')
          )([...from, ...to, ...cc]);

          dispatch(setTo(recipients));
        } else {
          dispatch(setTo(replyToMessage.from));
        }
        let { body } = replyToMessage;
        // Add quoted text to body
        try {
          body = juice(replyToMessage.body, {
            preserveMediaQueries: false,
          });
          log('Using inlined message body');
        } catch (e) {
          // no op
          log('failed to create inlined message body', e.message);
        }

        const quotedText = `${createQuotedText(body, replyToMessage)}`;
        dispatch(setQuotedText(quotedText));
      }
    } catch (e) {
      error('Could not fetch reply_to message', e.message);
    }
    // Forward to message
    try {
      const forwardToMessageId = config.forward_message_id;
      log('Forward message', config.forward_message_id);
      if (forwardToMessageId) {
        const forwardToMessage = await client.getMessage(
          forwardToMessageId,
          account.access_token
        );
        log('Forwarding message', forwardToMessage);
        dispatch(setSubject(`FWD:${forwardToMessage.subject}`));
        dispatch(setForwardToMessage(forwardToMessage));
        let { body } = forwardToMessage;
        // Add quoted text to body
        try {
          body = juice(forwardToMessage.body, {
            preserveMediaQueries: false,
          });
          log('Using inlined message body');
        } catch (e) {
          // no op
          log('failed to create inlined message body', e.message);
        }

        // Attacments
        try {
          const { files } = forwardToMessage;
          if (files && files.length) {
            log('FILES', files);
            dispatch(
              setFiles(files.filter((f) => f.filename && f.size > 2000))
            );
          }
        } catch (e) {
          log('Failed to add files', e.message);
        }

        const quotedText = `${createForwardText(body, forwardToMessage)}`;
        dispatch(setQuotedText(quotedText));
      }
    } catch (e) {
      error('Could not fetch forward_to_message', e.message);
    }

    // Other defaults
    const { subject } = config;
    // if (to) {
    //   dispatch(setTo(to));
    // }
    if (subject) {
      dispatch(setSubject(subject));
    }
  };
