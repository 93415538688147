import React from 'react';
import useActionBar from 'Hooks/use-action-bar';
import Navigation from './Navigation';
import Activities from './Activities';
import Retool from './Retool';
import Zendesk from './Zendesk';

import './style.scss';

export default function ActionBar() {
  const {
    data: { open, route },
  } = useActionBar();

  return (
    <>
      <div className="actionbar-container">
        {open && (
          <div className="actionbar">
            {route === 'notifications' && <Activities />}
            {route === 'zendesk' && <Zendesk />}
            {route === 'retool' && <Retool />}
          </div>
        )}
        <Navigation />
      </div>
    </>
  );
}
