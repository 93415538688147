import createActions from '../common/create-feathers-actions';

const { get, getSelector, create, createSelector } = createActions({
  name: 'INVITES',
  url: '/invites',
});

const join = (account_id, data, params) => ({
  type: 'INVITES/CREATE',
  request: {
    method: 'post',
    url: `/accounts/${account_id}/join`,
    data,
    params,
  },
});

export { get, getSelector, create, createSelector, join };
