import React from 'react';
import styled from 'styled-components';
import { UserIcon, CloseIcon } from 'Assets';
import UserSearch from 'Components/UserSearch';
import ClickOutside from 'Components/ClickOutside';
import useAssignThread from 'Hooks/use-assign-thread';
import Flex from 'Components/Flex';
import Avatar from 'react-avatar';
import { Btn, Container } from '../Styled';

const Modal = styled(Container)`
  position: absolute;
  top: 32px;
  right: 0;
  padding: 15px;
  min-width: 200px;
  z-index: 100;
  box-shadow: ${(p) => p.theme.shadow};
  border: 1px solid ${(p) => p.theme.border};
`;

const UserCard = styled(Flex)`
  font-size: 12px;
`;

function AssignedUser({ user, handleRemove }) {
  return (
    <UserCard justifyBetween alignCenter className="mb-1">
      <Avatar name={user.username} className="mr-2" size={24} round="26px" />

      <Flex grow alignCenter justifyBetween>
        <Flex>{user.username}</Flex>
        <Btn title="Unassign user" blank onClick={() => handleRemove(user)}>
          <CloseIcon title />
        </Btn>
      </Flex>
    </UserCard>
  );
}

export default function AssignThread({ thread }) {
  const { handleSave, handleRemove, isActive, users } = useAssignThread(thread);
  const [open, toggle] = React.useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <Btn
        active={isActive}
        onClick={() => toggle(!open)}
        title="Assign to user"
        tooltip={{ position: 'top' }}
      >
        <UserIcon title />
      </Btn>

      {open && (
        <ClickOutside onClose={() => toggle(false)}>
          <Modal>
            <div className="text-small  mb-2">Assign to user:</div>
            <UserSearch accountId={thread.account_id} onChange={handleSave} />
            {isActive && (
              <>
                <div className="text-small text-muted my-2">Assigned to:</div>
                {users.map((user, idx) => (
                  <AssignedUser
                    key={idx}
                    user={user}
                    handleRemove={handleRemove}
                  />
                ))}
              </>
            )}
            {!isActive && (
              <Flex center className="my-5 text-small text-muted">
                No users assigned
              </Flex>
            )}
          </Modal>
        </ClickOutside>
      )}
    </div>
  );
}
