import React, { useEffect } from 'react'; // eslint-disable-line
import useRouter from 'Hooks/use-router';
import { Button } from 'reactstrap';
import AuthLayout from 'Components/AuthLayout';
import { ReactComponent as ErrorImg } from 'Assets/svg/connect-error.svg';
import styled from 'styled-components';

const ErrorImage = styled(ErrorImg)`
  display: inline-block;
`;

// This happens on succesful connect or on connect error
export default function ConnectCallback() {
  const { next, prev, query } = useRouter();

  useEffect(() => {
    next('connectCallback');
  }, []); // eslint-disable-line
  if (query.error)
    return (
      <AuthLayout logo={false}>
        <div className="text-center mb-4">
          <ErrorImage />
        </div>
        <h2 className="my-2 text-center">Error connecting your account</h2>
        <p className="text-center mb-5">
          We were not able to connect your account.
        </p>
        <Button
          onClick={() => prev('connectCallback')}
          size="lg"
          color="primary"
          block
        >
          Try again
        </Button>
      </AuthLayout>
    );
  return null;
}
