import { takeLatest } from 'redux-saga/effects';
import ws from 'Clients/ws';
import { LOGIN, LOGOUT } from 'Store/api/auth';

// Side Effects
export function onLogin() {
  try {
    ws.io.open();
  } catch (e) {
    console.warn('Failed to connect to socket'); // eslint-disable-line
  }
}

function onLogout() {
  try {
    ws.io.close();
  } catch (e) {
    console.warn('Failed to disconnect from socket'); // eslint-disable-line
  }
}

export default function* saga() {
  yield takeLatest(LOGIN, onLogin);
  yield takeLatest(LOGOUT, onLogout);
}
