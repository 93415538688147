import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { updateAction } from 'Store/composer/message/send-at';
import { updateAction as updateUI } from 'Store/composer/ui';

import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { ReactComponent as DownIcon } from './triangle-down.svg';

const Menu = styled.div`
  position: absolute;
  z-index: 10000;
  background: #fff;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.09);
  border-radius: 4px;
  font-size: 13px;
  min-width: 200px;
  bottom: 25px;
  left: 0px;
  padding: 0;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      border-bottom: 1px solid #ebedf2;
      color: #595d6e;
      padding: 10px 10px;
      cursor: pointer;
      &:hover {
        background: #f2f3f7;
      }
    }
  }
`;

const Icon = styled(DownIcon)``;

const values = [
  {
    caption: 'Tomorrow (8AM)',
    value: () => moment().add(1, 'days').hours(8).minutes(0).unix(),
  },
  {
    caption: 'Next Week (Mon 8AM)',
    value: () =>
      moment()
        .add(1, 'week')
        .startOf('week')
        .add(1, 'days')
        .hours(8)
        .minutes(0)
        .unix(),
  },
  {
    caption: 'Later Today',
    value: () => moment().add(4, 'hours').minutes(0).unix(),
  },
];

export default function SendLater({ disabled }) {
  const [menu, setMenu] = useState(false);
  const toggle = () => setMenu(!menu);
  const isActive = useSelector((state) => state.composer.message.send_at.data);
  const dispatch = useDispatch();
  const setSendUntil = (val) => {
    const ts = val.value();
    dispatch(updateAction(ts));
    toggle();
  };

  const handleCancel = () => {
    dispatch(updateAction(undefined));
    toggle();
  };
  const openModal = () => {
    toggle();
    dispatch(updateUI({ sendLaterPicker: true }));
  };
  return (
    <>
      {menu && (
        <Menu>
          <ul>
            {values.map((item, idx) => (
              // eslint-disable-next-line
              <li key={idx} onClick={() => setSendUntil(item)}>
                {item.caption}
              </li>
            ))}
            <li onClick={openModal}>Select time</li>
            <li onClick={handleCancel}>Cancel</li>
          </ul>
        </Menu>
      )}
      <button
        className="nylas-composer__btn-later"
        onClick={toggle}
        disabled={disabled}
      >
        <Icon className={classnames({ icon: true, active: isActive })} />
      </button>
    </>
  );
}
