import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink as Tab, Button, Spinner } from 'reactstrap';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import Flex from 'Components/Flex';
import { Label } from 'Components/Tag';
import useAccount from 'Hooks/use-account';

const AccountNav = styled(Nav)`
  margin: 30px 0;
  background: ${(p) => p.theme.bg};

  a {
    color: ${(p) => p.theme.muted};
    &:hover {
      background: ${(p) => p.theme.bgDarker};
    }
  }
`;

const Link = ({ to, children }) => (
  <NavItem>
    <Tab tag={NavLink} to={to}>
      {children}
    </Tab>
  </NavItem>
);

export default function SettingsAccountHeader({ accountId }) {
  const {
    account,
    connect,
    isSyncing,
    isOwner,
    shareAccount,
    remove,
    patchPending,
  } = useAccount(accountId);

  if (!account) return null;
  return (
    <>
      <Flex justifyBetween alignCenter>
        <Flex alignCenter>
          <Avatar
            size={56}
            name={account.name || account.email}
            round="6px"
            className="mr-3"
          />
          <Flex column>
            <h2>{account.name || 'N/A'}</h2>
            <Flex>
              <strong className="text-muted">{account.email}</strong>
              <div className="mx-5 text-muted">
                <small className="mr-2">Type</small>
                <Label
                  color={account.shared ? 'primary' : 'info'}
                  className="px-3"
                >
                  {account.shared ? 'Shared account' : 'Private account'}
                </Label>
              </div>
              <div className="mx-5 text-muted">
                <small className="mr-2">Sync state</small>
                <Label
                  color={
                    account.sync_state === 'running' ? 'success' : 'danger'
                  }
                  className="px-3"
                >
                  {account.sync_state}
                </Label>
              </div>
            </Flex>
          </Flex>
        </Flex>
        {/* Right Buttons */}
        <Flex>
          {patchPending && <Spinner color="primary" className="mr-3" />}

          {isOwner && (
            <Button
              color="danger"
              size="sm mr-1"
              disabled={patchPending}
              onClick={remove}
            >
              Delete
            </Button>
          )}
          {isOwner && !isSyncing && (
            <Button
              color="warning"
              size="sm mr-1"
              onClick={() =>
                connect({ login_hint: account.email, reconnect: true })
              }
              disabled={patchPending}
            >
              Reconnect
            </Button>
          )}
          {isOwner && !account.shared && (
            <Button
              color="primary"
              size="sm"
              disabled={patchPending}
              onClick={() => shareAccount(accountId, { shared: true })}
            >
              Share Account
            </Button>
          )}
          {isOwner && account.shared && (
            <Button
              color="primary"
              size="sm"
              disabled={patchPending}
              onClick={() => shareAccount(accountId, { shared: false })}
            >
              Make Private
            </Button>
          )}
        </Flex>
      </Flex>
      <AccountNav pills justified>
        <Link to={`/settings/accounts/${accountId}/profile`}>Profile</Link>
        <Link to={`/settings/accounts/${accountId}/signatures`}>
          Signatures
        </Link>
        <Link to={`/settings/accounts/${accountId}/aliases`}>Aliases</Link>
        <Link to={`/settings/accounts/${accountId}/templates`}>Templates</Link>
        {account.shared && (
          <Link to={`/settings/accounts/${accountId}/sharing`}>Team</Link>
        )}
      </AccountNav>
    </>
  );
}
