import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useThread from 'Hooks/use-thread';
import useMessages from 'Hooks/use-messages';
import { get } from 'lodash';

export default function useThreadView(threadId) {
  const { data: thread, getPending: threadGetPending } = useThread(threadId, {
    fetch: true,
  });
  const bodyRef = useRef(null);
  // Messages Query
  const [query, setQuery] = useState({
    $skip: 0,
    $limit: 10,
    thread_id: threadId,
    '$sort[date]': -1,
  });

  const messages = useMessages({ threadId, query, setQuery });

  const reloadThread = () => {
    messages.fetchData();
  };

  const account = useSelector((s) =>
    s.user.accounts.find((a) => a.id === get(thread, 'account_id'))
  );

  const showChatResponder = account && account.shared;

  // Scrolling logic
  const lastMessage = messages.lastMessage || {};
  useEffect(() => {
    if (!lastMessage._id) return;
    const el = document.getElementById(`message-${lastMessage._id}`);
    if (!el) return;
    const body = el.querySelector('message-body');

    const listener = () => {
      el.scrollIntoView(true); // align to top
    };

    if (el) {
      if (body)
        body.addEventListener('content-loaded', listener, { once: true });
    }
  }, [lastMessage._id]);

  return {
    thread,
    threadGetPending,
    reloadThread,
    messages,
    showChatResponder,
    bodyRef,
  };
}
