import useMutation from './use-mutation';
import * as messageActions from '../Store/api/messages';

export default function useMessageId() {
  const mutations = useMutation({ actions: { ...messageActions } });

  return {
    ...mutations,
  };
}
