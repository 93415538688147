import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetRequests } from '@redux-requests/core';
import { Confirm } from 'Components/Toasts';
import {
  FIND,
  get as getAction,
  find as findTemplates,
  getSelector,
  findSelector,
  createSelector,
  patchSelector,
  removeSelector,
  patch as patchAction,
  create as createAction,
  remove as removeAction,
} from 'Store/api/email-templates';
import validator from 'Utils/validator';
import { toast } from 'react-toastify';

export default function useEmailTemplates(
  options = { accountId: null, templateId: null, fetch: false, get: false }
) {
  const dispatch = useDispatch();
  const defaultParams = {
    $populate: 'user account',
    '$sort[created_at]': -1,
    account_id: options.accountId,
  };

  const {
    data,
    loading: findPending,
    error: findError,
  } = useSelector(findSelector);
  const {
    data: getData,
    loading: getPending,
    error: getError,
  } = useSelector(getSelector);
  const { loading: createPending, error: createError } =
    useSelector(createSelector);
  const { loading: patchPending, error: patchError } =
    useSelector(patchSelector);
  const { loading: removePending, error: removeError } =
    useSelector(removeSelector);

  useEffect(() => {
    if (!options.fetch) return;
    dispatch(findTemplates(options.params || defaultParams));
    return () => {
      dispatch(resetRequests([FIND]));
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!options.templateId || options.get) return;
    dispatch(getAction(options.templateId));
  }, [options.templateId]); // eslint-disable-line

  const create = (...args) => dispatch(createAction(...args));
  const patch = (...args) => dispatch(patchAction(...args));
  const removeTemplate = (...args) => dispatch(removeAction(...args));
  const get = (...args) => dispatch(getAction(...args));
  const remove = (...args) => {
    toast(
      <Confirm
        confirm={() => removeTemplate(...args)}
        text="Are you sure you want to remove this email template? This action cannot be undone."
      />
    );
  };
  const validate = validator({
    name: { presence: true, length: { minimum: 3 } },
    body: { presence: true, length: { minimum: 3 } },
  });

  const handleForm = async (payload) => {
    if (payload.id) {
      await patch(payload.id, payload);
      toast('Email template updated.');
    } else {
      // console.log(payload);
      await create(payload, { $populate: 'user account' });
      toast('Email template created.');
    }
  };
  return {
    data,
    getData,
    findPending,
    createPending,
    patchPending,
    removePending,
    handleForm,
    validate,
    getPending,
    findError,
    createError,
    patchError,
    removeError,
    getError,
    get,
    create,
    patch,
    remove,
  };
}
