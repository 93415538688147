import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from './Input';

export default function StringArray({
  register,
  control,
  name,
  caption,
  text,
  type = 'text',
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  return (
    <>
      {!fields.length && append({ value: '' })}
      {fields.map((p, index) => (
        <div key={p.id} className="formrow">
          <Input
            r={register}
            name={`${name}.${index}.value`}
            caption={caption}
            text={text}
            type={type}
          />
          {fields.length > 1 && (
            <button
              className="btn-x"
              onClick={() => remove(p.id)}
              style={{ transform: 'translateY(7px)' }}
              type="button"
              tabIndex={-1}
            >
              ✕
            </button>
          )}
        </div>
      ))}
      <button
        className="btn-add"
        onClick={() => append({ value: '' })}
        style={{ marginBottom: 10 }}
        type="button"
        tabIndex={-1}
      >
        Add more...
      </button>
    </>
  );
}
