import React from 'react';
import './picker.scss';
import moment from 'moment';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';
import { updateAction as updateUI } from 'Store/composer/ui';
import { updateAction as setSendAt } from 'Store/composer/message/send-at';

import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Clock } from './time.svg';

const Content = styled.div`
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.13);
  background: #fff;
  margin: 10px;
  border-radius: 9px;
  font-size: 14px;
`;
const Header = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebedf2;
`;

const Title = styled.div`
  font-size: 16px;
`;

const Btn = styled.button`
  background: none;
  border: none;
  font-size: 15px;
  color: #74788d;
  cursor: pointer;
  &:hover {
    color: #595d6e;
  }
  &:disabled {
    opacity: 0.3;
  }
`;

const Footer = styled.div`
  border-top: 1px solid #ebedf2;
  padding: 15px;
`;

const Close = styled.div`
  font-size: 20px;
  cursor: pointer;
  color: #74788d;
`;

const PickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border-top: 1px solid #ebedf2;
  border-bottom: 1px solid #ebedf2;
  width: 100%;
  margin-top: 10px;
`;

const TimeIcon = styled(Clock)`
  width: 24px;
  margin-right: 10px;
  height: 24px;
  fill: #74788d;
`;

export default function SendLaterPicker() {
  const dispatch = useDispatch();
  const sendAt = useSelector((state) => state.composer.message.send_at.data);
  const date = sendAt ? moment(sendAt, 'X').toDate() : undefined;
  let time = sendAt ? moment(sendAt, 'X').format('HH:mm') : undefined;
  if (time === '00:00') time = undefined;

  const handleClose = () => {
    dispatch(updateUI({ sendLaterPicker: false }));
    dispatch(setSendAt(undefined));
  };
  const handleDateChange = (val) => {
    const d = moment(val);
    const t = moment(time, 'HH:mm');
    const hours = t.format('HH');
    const minutes = t.format('mm');
    d.hours(hours);
    d.minutes(minutes);
    dispatch(setSendAt(d.unix()));
  };
  const handleTimeChange = (val) => {
    const d = moment(date);
    const t = moment(val, 'HH:mm');
    d.hours(t.hours);
    d.minutes(t.minutes);
    dispatch(setSendAt(t.unix()));
  };

  const handleSave = () => {
    handleClose();
  };
  return (
    <div className="nylas__datepicker">
      <Content>
        <Header>
          <Title>Select time</Title>
          <Close onClick={handleClose}>&times;</Close>
        </Header>
        <Calendar onChange={handleDateChange} value={date} />
        <PickerContainer>
          <TimeIcon />
          <TimePicker disableClock value={time} onChange={handleTimeChange} />
        </PickerContainer>

        <Footer>
          <Btn onClick={handleSave} disabled={!time || !date}>
            Ok
          </Btn>
          <Btn onClick={handleClose}>Cancel</Btn>
        </Footer>
      </Content>
    </div>
  );
}
