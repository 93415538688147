import React from 'react';
import styled from 'styled-components';
import useThreadView from 'Hooks/use-thread-view';
import { Button, Spinner } from 'reactstrap';
import NylasLoader from 'Components/NylasLoader';
import IsTyping from 'Components/IsTyping';
import ThreadViewers from 'Components/ThreadViewers';
import { Container } from '../Styled/index';
import Toolbar from './Toolbar';
import Message from '../Message';
import ChatMessage from '../Message/ChatMessage';
import Responder from '../ChatResponder/ChatResponder';
import ThreadWithoutMessages from './ThreadWithoutMessages';
import FixThreadBtn from './FixThreadBtn';

const Body = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: ${(p) => (p.grow ? 1 : 0)};
  overflow-y: auto;
  padding: 20px;
  padding-top: 0;
`;

const Spacer = styled.div`
  flex: 1 1 auto;
  flex-shrink: 0;
  flex-grow: 1;
  height: 0;
`;

const TopMargin = styled.div`
  height: 20px;
  flex-shrink: 0;
`;

const ResponderContainer = styled.div`
  border-radius: 6px;
  background: ${(p) => p.theme.backing};
  margin: 10px 20px;
  padding: 3px 0;
`;

const NoMessagesWarning = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  h2 {
    text-align: center;
    font-size: 2.2rem;
    padding: 3rem 0 1.5rem;
    border-bottom: 1px solid #000;
    margin-bottom: 2rem;
  }
  div {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
    button {
      display: inline-block;
      svg {
      }
    }
  }
`;

export default function ThreadView({ threadId }) {
  // Fetching threads
  const {
    thread,
    showChatResponder,
    bodyRef,
    reloadThread,
    messages: {
      data: messages,
      hasMore,
      loadMore,
      loading,
      loadMorePending,
      shouldCollapse,
    },
  } = useThreadView(threadId);
  if (thread && !messages.length)
    return (
      <>
        <Container>
          <ThreadWithoutMessages
            thread={thread}
            loading={loading}
            reloadThread={reloadThread}
          />
        </Container>

        <NoMessagesWarning>
          <h2>No messages found</h2>
          <div>
            Consider using the Fix button{' '}
            <FixThreadBtn thread={thread} reloadThread={reloadThread} />
          </div>
        </NoMessagesWarning>
      </>
    );
  if (!thread || !messages.length) return <NylasLoader loading />;
  return (
    <>
      <Container>
        <Toolbar
          thread={thread}
          loading={loading}
          reloadThread={reloadThread}
        />
        <ThreadViewers thread={thread} />
      </Container>

      <Body grow={1} ref={bodyRef}>
        {/* This ensures that threads are on top */}
        <Spacer />

        {/* Loader for Thread */}
        <NylasLoader
          loading={loading && !messages.length}
          noResults={!messages.length}
        />

        {/* Messages */}
        {messages.map((msg) => {
          return msg.object === 'chat' ? (
            <ChatMessage
              thread={thread}
              key={msg._id || msg.__id}
              message={msg}
              style={{ marginLeft: 20, marginTop: 10 }}
            />
          ) : (
            <Message
              key={msg._id}
              message={msg}
              threadSubject={thread.subject}
              collapsed={shouldCollapse(msg._id)}
            />
          );
        })}

        {/* Load More Button */}
        {hasMore && (
          <center>
            <Button
              color="primary"
              onClick={loadMore}
              style={{ borderRadius: 30, padding: '3px 15px' }}
              size="sm"
              className="mb-3"
              disabled={loadMorePending}
            >
              Load Older Messages
              {loadMorePending && (
                <Spinner className="ml-2" size="sm" color="light" />
              )}
            </Button>
          </center>
        )}
        <TopMargin />
      </Body>

      {/* Chat Related */}
      <IsTyping thread={thread} />
      {showChatResponder && (
        <ResponderContainer>
          <Responder thread={thread} />
        </ResponderContainer>
      )}
    </>
  );
}
