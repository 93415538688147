import React from 'react';
import AuthLayout from 'Components/AuthLayout';
import { Form, Field } from 'react-final-form';
import { Row, Col, FormGroup, Button, Spinner } from 'reactstrap';
import { Input, Checkbox } from 'Components/Form';
import useInviteCode from 'Hooks/use-invite-code';

export default function Signup() {
  const { onSubmit, validate, validateCode, back } = useInviteCode();
  return (
    <AuthLayout>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, validating }) => (
          <form onSubmit={handleSubmit}>
            <h1 className="mb-5 mt-4">Enter your invite code</h1>
            {/* For invites only */}
            <FormGroup>
              <Field
                label="Invite code"
                component={Input}
                name="invite_code"
                placeholder="your invite code"
                showValid
                showInvalid
                validate={validateCode}
              />
            </FormGroup>
            <FormGroup className="pl-4">
              <Field name="agreement" component={Checkbox} type="checkbox">
                I agree to Nylas{' '}
                <a
                  href="https://www.nylas.com/legal/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href="https://www.nylas.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Field>
            </FormGroup>

            <FormGroup className="mt-md-5 mt-3">
              <Row>
                <Col md={6}>
                  <Button
                    block
                    outline
                    color="primary"
                    size="lg"
                    type="button"
                    onClick={back}
                  >
                    Back to login
                  </Button>
                </Col>
                <Col md={6} className="mt-3 mt-sm-3 mt-md-0">
                  <Button
                    block
                    disabled={hasValidationErrors || validating}
                    color="primary"
                    size="lg"
                    className="font-weight-bold"
                  >
                    Next
                    {validating && (
                      <Spinner color="light" size="sm" className="ml-3" />
                    )}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </form>
        )}
      />
    </AuthLayout>
  );
}
