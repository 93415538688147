import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/CONTACTS/REPLACE');

const initialState = { data: [], loading: false, error: false };

export default createReducer(
  initialState, // initial state
  {
    [updateAction]: (state, action) => {
      state.data = action.payload.map((contact) => ({
        ...contact,
        id: contact.id,
        value: contact.email,
        label: `${contact.name || contact.email} (${contact.email})`,
      }));
    },
  }
);
