import React from 'react'
import EmailHeader from './EmailHeader'

export default function EmailHeaderArea({ header }) {

  return (
    <>
      <EmailHeader>
        <textarea
          className="textarea"
          value={JSON.stringify(header,null,2)}
          style={{ 
              maxWidth: "100%", 
              width: "100%",
              borderRadius: "3px",
              border: "1px solid#ccc",
          }}
          rows={15}
        />
      </EmailHeader>
    </>
  )
}