import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/FORWARD_TO_MESSAGE/UPDATE');

const initialState = null;
export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [updateAction]: (state, action) => {
      if (action.payload) {
        return action.payload;
      }
      return null;
    },
  }
);
