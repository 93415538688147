import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { Spinner, Modal, ModalBody, ModalHeader, FormGroup } from 'reactstrap';
import { ArchiveIcon, SpamIcon, TrashIcon, StarIcon, MenuIcon } from 'Assets';
import { toast } from 'react-toastify';
import { ReactComponent as ReadIcon } from 'Assets/metronic/Communication/Mail-opened.svg';
import useThread from 'Hooks/use-thread';
import AvatarStack from 'Components/AvatarStack';
import AssignThread from 'Components/AssignThread';
import client from 'Clients/rest';
import ThreadAnnotationStatus from 'Components/studio/ThreadAnnotationStatus';
import { useSelector } from 'react-redux';

import { Form } from 'react-final-form';
import Dropdown from 'Components/Dropdown';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { theme, Btn } from '../Styled';
import FixThreadBtn from './FixThreadBtn';

const Container = styled.div`
  border-bottom: 1px solid ${theme.border};
  padding: 20px 20px;
`;

const Subject = styled.div`
  font-size: 22px;
  letter-spacing: -0.3px;
  font-weight: 900;
  color: ${theme.dark};
`;

export default function Toolbar({ thread, loading, reloadThread }) {
  const { patch, patchPending } = useThread(thread);
  const { id } = thread;
  const [open, toggle] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [fade, setFade] = React.useState(false);
  const [targetAccount, setTargetAccount] = React.useState('');
  const history = useHistory();

  const action = async (name, data) => {
    const res = await patch(id, { action: name, ...data });
    if (res.data) {
      toast('Thread updated');
    } else {
      toast.error('Error updating thread');
    }
  };

  const annotate = () => {
    try {
      const msgId = thread.message_ids[0];
      history.push(`/studio/annotate/${msgId}`);
    } catch (e) {
      // NO OP
    }
  };

  const star = () =>
    action(`mark-as-${thread.starred ? 'unstarred' : 'starred'}`);
  const read = () => action(`mark-as-${thread.unread ? 'read' : 'unread'}`);
  const trash = () => action('move-to-trash');
  const spam = () => action('move-to-spam');
  const inbox = () => action('move-to-inbox');
  const archive = () => action('move-to-archived');
  const recategorize = () =>
    action('recategorize', {
      section: thread.section === 'convos' ? 'feeds' : 'convos',
    });

  // Dropdown
  const report = async () => {
    const text = prompt('please explain problem here:');
    if (!text) return;
    try {
      await client.post('/user-reports', {
        thread_id: thread.id,
        text,
      });
      toast(`Thread reported successfully`);
    } catch (e) {
      console.log('Error reporting', e.message);
    }
  };

  const userAccounts = useSelector((state) => state.user.accounts);
  const userAccountOptions = userAccounts.map((account) => ({
    value: account.id,
    label: account.email_address,
  }));

  const toggleModal = () => {
    setModal(!modal);
    setFade(!fade);
  };

  const accountSelected = (account) => {
    setTargetAccount(account);
  };

  const accounts = useSelector((s) => s.user.accounts);
  const accessToken = accounts.find(
    (a) => a.account_id === thread.account_id
  ).access_token;

  const copyEmail = async () => {
    const targetAccountId = targetAccount.value;
    const message = thread.message_ids[0]; // TODO: Figure out the highlighted message or modify copy the whole thread?

    const payload = {
      message_id: message,
      account_id: targetAccountId,
    };

    const copyResult = await client
      .post(`/messages/copy`, payload, {
        headers: { authorization: `Bearer ${accessToken}` },
      })
      .catch((error) => {
        console.log(error);
      });

    if (copyResult && copyResult.new_message) {
      toggleModal();
      toast(`Message copied succesfully`, { type: 'success' });
    } else {
      toast(`Could not copy message`, { type: 'error' });
    }
  };

  const options = [
    { caption: 'Report issue with thread', action: report },
    { caption: 'Annotate message', action: annotate },
    { caption: 'Copy to...', action: toggleModal },
  ];
  if (thread.section) {
    options.push({
      caption: `Recategorize to ${
        thread.section === 'convos' ? 'Feed' : 'Conversations'
      }`,
      action: recategorize,
    });
  }

  return (
    <Container>
      {/* Upper */}
      <Flex justifyBetween>
        <div className="truncated">
          <Subject className="truncated">{thread.subject}</Subject>
        </div>
        <Flex alignCenter noShrink>
          {(loading || patchPending) && (
            <Spinner size="sm" color="nylas" className="mr-3" />
          )}

          <Btn
            title={thread.starred ? 'Mark as unstarred' : 'Mark as starred'}
            onClick={star}
            active={thread.starred}
          >
            <StarIcon title />
          </Btn>

          <Btn
            title={thread.unread ? 'Mark as read' : 'Mark as unread'}
            onClick={read}
          >
            <ReadIcon title />
          </Btn>

          {!thread.trash && (
            <Btn disabled={loading} onClick={trash} title="Move to trash">
              <TrashIcon title="Move to trash" />
            </Btn>
          )}

          {!thread.archived && !thread.trash && !thread.spam && (
            <Btn title="Archive" onClick={archive}>
              <ArchiveIcon title />
            </Btn>
          )}

          {!thread.spam && (
            <Btn title="Move to spam" disabled={loading} onClick={spam}>
              <SpamIcon title="Move to spam" />
            </Btn>
          )}

          {(thread.trash || thread.archived || thread.spam) && (
            <Btn title="Move to inbox" onClick={inbox}>
              <ArchiveIcon title="Move to inbox" />
            </Btn>
          )}

          <AssignThread thread={thread} />

          <FixThreadBtn thread={thread} reloadThread={reloadThread} />
          <Dropdown open={open} toggle={toggle} options={options}>
            <Btn>
              <MenuIcon />
            </Btn>
          </Dropdown>
        </Flex>
      </Flex>
      {/* Bottom */}
      <Flex alignCenter noShrink>
        {thread.last_viewed_by_users && (
          <Flex className="mr-3">
            <AvatarStack
              size={24}
              users={thread.last_viewed_by_users}
              label="viewed by "
            />
          </Flex>
        )}
        {thread.assigned_to_users && thread.assigned_to_users.length > 0 && (
          <Flex alignCenter>
            <small className="mr-2 text-muted">Assigned to</small>

            <AvatarStack
              size={24}
              users={thread.assigned_to_users}
              label="assigned to "
            />
          </Flex>
        )}
        <Flex>
          <ThreadAnnotationStatus thread={thread} />
        </Flex>
      </Flex>

      <Modal isOpen={modal} toggle={toggleModal} fade={fade}>
        <ModalHeader toggle={toggleModal}>
          Copy Email To Another Inbox.
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={copyEmail}
            render={() => (
              <div>
                <FormGroup>
                  <Select
                    onChange={accountSelected}
                    options={userAccountOptions}
                  />
                </FormGroup>
                <FormGroup>
                  <button
                    type="submit"
                    className="btn btn-primary my-3"
                    style={{ width: '100%' }}
                    onClick={copyEmail}
                  >
                    Copy Email
                  </button>
                </FormGroup>
              </div>
            )}
          />
        </ModalBody>
      </Modal>
    </Container>
  );
}
