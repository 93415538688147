import React from 'react';
import { useHistory } from 'react-router-dom';
import Sidemenu from 'Components/SettingsSidemenu';
import styled from 'styled-components';
import NavBar from 'Components/NavBar';
import Flex from 'Components/Flex';

const Root = styled(Flex)`
  height: 100vh;
`;

const Content = styled(Flex)`
  padding: 40px;
`;

export default function SettingsLayout({ children }) {
  const history = useHistory();

  const handleBack = () => {
    history.push('/');
  };
  return (
    <Root column>
      <NavBar onBackClick={handleBack} search={false} />
      <Flex justifyBetween grow>
        {/* Left */}
        <Sidemenu />
        {/* Right */}
        <Content column grow>
          {children}
        </Content>
      </Flex>
    </Root>
  );
}
