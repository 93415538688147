const breakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export default {
  primary: 'var(--primary)', // #5d78ff
  secondary: 'var(--secondary)',
  bg: 'var(--bg)',
  border: 'var(--border)',
  text: 'var(--text)',
  muted: 'var(--muted)',
  light: 'var(--light)',
  success: 'var(--success)',
  primaryMuted: 'var(--primaryMuted)',
  warning: 'var(--warning)',
  danger: 'var(--danger)',
  dark: 'var(--dark)',
  borderDark: '#353544',
  nylas: 'var(--nylas)',
  backing: 'var(--backing)',
  bgDarker: 'var(--bgDarker)',
  icon: '#8e96b8',
  shadow: `0px 0px 13px 0px rgba(82, 63, 105, 0.05)`,
  breakpoints: {
    belowSm: `(max-width: ${breakpoints.sm})`,
    aboveSm: `(min-width: ${breakpoints.sm})`,
    belowMd: `(max-width: ${breakpoints.md})`,
    aboveMd: `(min-width: ${breakpoints.md})`,
    belowLg: `(max-width: ${breakpoints.lg})`,
    aboveLg: `(min-width: ${breakpoints.lg})`,
    belowXl: `(max-width: ${breakpoints.xl})`,
    aboveXl: `(min-width: ${breakpoints.xl})`,
    belowXxl: `(max-width: ${breakpoints.xxl})`,
    aboveXxl: `(min-width: ${breakpoints.xxl})`,
  },
};
