import React from 'react';
import SettingsLayout from 'Components/SettingsLayout';

export default function RulesPage() {
  return (
    <SettingsLayout>
      <h1 className="my-3">Rules</h1>
      <div>Coming soon!</div>
    </SettingsLayout>
  );
}
