import React from 'react';
import {
  Label,
  FormFeedback,
  Button,
  ButtonGroup as RSButtonGroup,
} from 'reactstrap';

export default function ButtonGroup(props) {
  const { input, meta, label } = props;
  let errorString;
  if (meta.error && Array.isArray(meta.error)) {
    [errorString] = meta.error;
  }
  if (
    !meta.dirtySinceLastSubmit &&
    meta.submitError &&
    Array.isArray(meta.submitError)
  ) {
    [errorString] = meta.submitError;
  }
  const handleChange = (val) => () => {
    input.onChange(val);
  };
  return (
    <>
      {label && <Label for={input.name}>{label}</Label>}
      <RSButtonGroup size={props.size} className={props.className || 'w-100'}>
        {props.options &&
          props.options.map((option) => {
            return (
              <Button
                outline={input.value !== option.value}
                color="primary"
                onClick={handleChange(option.value)}
                key={option.value}
              >
                {option.name}
              </Button>
            );
          })}
      </RSButtonGroup>
      <FormFeedback>{errorString}</FormFeedback>
    </>
  );
}
