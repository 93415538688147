import React from 'react';
import useAccount from 'Hooks/use-account';
import { Row, Col } from 'reactstrap';
import StringEditor from 'Components/StringEditor';
import SettingsTabHeader from 'Components/SettingsTabHeader';

export default function AccountProfile({ match }) {
  const { accountId } = match.params;
  const { account, patch } = useAccount(accountId);

  return (
    <Row>
      <Col md={8}>
        <SettingsTabHeader title="Profile" />

        <StringEditor
          caption="Name"
          value={account.name}
          onChange={({ value }) => patch(accountId, { name: value })}
        />

        <StringEditor caption="Email" editable={false} value={account.email} />
      </Col>
    </Row>
  );
}
