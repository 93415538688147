import React from 'react';
import styled from 'styled-components';
import { theme } from 'Components/Styled';

const Root = styled.div`
  border: 1px solid ${theme.border};
  border-radius: 4px;
  padding: 10px;
  border-left: 5px solid #eee;
  ${(p) => p.score === 'positive' && `border-left: 5px solid ${theme.success};`}
  ${(p) =>
    p.score === 'negative' &&
    `border-left: 5px solid ${theme.danger};`}
  margin-right: 10px;
`;
const Score = styled.span`
  font-size: 11px;
  color: ${theme.muted};
  margin-right: 10px;
`;

const Title = styled.div`
  font-style: italic;
`;

const Caption = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export default function SentimentCard({ sentiment, style }) {
  const { score, magnitude, label, model } = sentiment;
  let caption = 'positive';
  if (score > -0.4 && score < 0.4) caption = 'neutral';
  if (score <= -0.4) caption = 'negative';
  if (label) caption = label;
  return (
    <Root score={caption} style={style}>
      <Title>Sentiment score ({model})</Title>
      <Caption>{caption}</Caption>
      <Score>Score: {score.toFixed(3)}</Score>
      {'magnitude' in sentiment ? (
        <Score>Magnitude: {magnitude.toFixed(3)}</Score>
      ) : null}
    </Root>
  );
}
