import React, { useState } from 'react';
import { ReactComponent as FilterIcon } from 'Assets/ionic/funnel.svg';
import { Btn, theme } from 'Components/Styled';
import classnames from 'classnames';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import UserSearch from 'Components/UserSearch';
import AccountSelector from 'Components/AccountSelector';
import QuerySelector from 'Components/QuerySelector';
import ClickOutside from 'Components/ClickOutside';
import styled from 'styled-components';

const Root = styled.div``;

const Dialog = styled.div`
  position: absolute;
  background: #fff;
  width: 300px;
  z-index: 100;
  top: 35px;
  right: 30px;
  border-radius: 3px;
  border: 1px solid color(border);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 15px;
`;
export default function ThreadFilters() {
  const [open, toggle] = useState(false);
  const history = useHistory();
  const params = qs.parse(document.location.search);

  const setParams = (data) => {
    const newString = qs.stringify(data);
    history.push(`?${newString}`);
  };

  const handleSectionChange = (val) => {
    const newParams = {
      ...params,
      section: val === params.section ? undefined : val,
    };
    const newString = qs.stringify(newParams);
    history.push(`?${newString}`);
  };

  const handleAccountChange = (acc) => {
    setParams({ ...params, account_id: acc ? acc.id : undefined, $skip: 0 });
  };

  const defaultQuery = React.useMemo(() => {
    if (params.starred) return 'starred';
    if (params.unread) return 'unread';
    if (params.trash) return 'trash';
    if (params.spam) return 'spam';
    if (params.archived) return 'archived';
    return null;
  }, [params]);

  const handleQueryFilterChange = (val) => {
    const clonedParams = { ...params };
    delete clonedParams.trash;
    delete clonedParams.spam;
    delete clonedParams.archived;
    delete clonedParams.starred;
    delete clonedParams.unread;
    delete clonedParams.has_attachments;
    if (!val) return setParams({ ...clonedParams, $skip: 0 });
    return setParams({ ...clonedParams, ...val, $skip: 0 });
  };

  const handleUserChange = (data) => {
    if (data) {
      if (data.id === 'ASSIGNED') {
        return setParams({
          ...params,
          assigned_to_user_id: undefined,
          'assigned_to_user_id[$exists]': true,
        });
      }
      if (data.id === 'UNASSIGNED') {
        return setParams({
          ...params,
          assigned_to_user_id: undefined,
          'assigned_to_user_id[$exists]': false,
        });
      }

      return setParams({ ...params, assigned_to_user_id: data.id, $skip: 0 });
    }
    return setParams({
      ...params,
      assigned_to_user_id: undefined,
      'assigned_to_user_id[$exists]': undefined,
      $skip: 0,
    });
  };

  return (
    <Root>
      <Btn onClick={() => toggle(!open)} active={open} title="Filter threads">
        {open ? <span>&times;</span> : <FilterIcon title />}
      </Btn>
      {open && (
        <ClickOutside onClose={() => toggle(false)}>
          <Dialog>
            <div className="form-group">
              <label className="text-small text-muted">Email account:</label>
              <AccountSelector
                value={params.account_id}
                onChange={handleAccountChange}
                filter={params.query}
              />
            </div>
            <div className="form-group">
              <label className="text-small text-muted">Assigned to user:</label>
              <UserSearch
                placeholder="Assigned to"
                onChange={handleUserChange}
                defaultValue={params.assigned_to_user_id}
                additionalOptions={[
                  { id: 'UNASSIGNED', label: 'All Unassigned' },
                  { id: 'ASSIGNED', label: 'All Assigned' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="text-small text-muted">Filter by:</label>
              <QuerySelector
                onChange={handleQueryFilterChange}
                defaultValue={defaultQuery}
              />
            </div>

            <label className="text-small text-muted">Type of email:</label>

            <div
              className="btn-group w-100"
              role="group"
              aria-label="Basic example"
            >
              {['convos', 'feeds'].map((item) => (
                <button
                  type="button"
                  onClick={() => handleSectionChange(item)}
                  className={classnames('btn-sm', 'btn btn-outline-primary', {
                    active: params.section ? params.section === item : false,
                  })}
                  style={{
                    border: `1px solid ${theme.border}`,
                  }}
                  key={item}
                >
                  {item === 'convos' ? 'Conversations' : 'Feed Emails'}
                </button>
              ))}
            </div>
          </Dialog>
        </ClickOutside>
      )}
    </Root>
  );
}
