import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/UI/UPDATE');
const initialState = {
  minimized: false,
  maximized: false,
  sendLaterPicker: false,
  cc: false,
  bcc: false,
  last_reset_at: null,
}; // initial state

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return { ...initialState, last_reset_at: Date.now() };
    },

    [updateAction]: (state, action) => {
      if (!action.payload) return state;
      return { ...state, ...action.payload };
    },
  }
);
