import { useState, useCallback } from 'react';
import client from 'Clients/rest';
import validator from 'Utils/validator';
import { useSelector } from 'react-redux';
import appConfig from 'app.config';
import pDebounce from 'p-debounce';

let currentVal = {};

export default function useConnect() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((s) => s.user);
  const debouncedCheck = useCallback(
    pDebounce(async (val) => {
      try {
        await client.get('/accounts/check', {
          params: {
            email_address: val,
          },
        });
      } catch (e) {
        return ['Email account already connected'];
      }
    }, 750),
    []
  );
  const validateEmailAccount = async (val) => {
    if (!val) return Promise.resolve();
    if (currentVal.val && currentVal.val === val) return currentVal.validation;
    const validation = await debouncedCheck(val);
    currentVal = { val, validation };
    return validation;
  };
  const validate = validator({
    email: {
      presence: true,
      email: true,
    },
    shared: { presence: true },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    window.location = `${appConfig.restUrl}/connect/user/${user.id}?login_hint=${data.email}&shared=${data.shared}`;
  };

  return { onSubmit, validate, loading, validateEmailAccount };
}
