import React from 'react';
import useAnnotations from 'Hooks/use-annotations';
import { get } from 'lodash';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

export default function ReviewButton({ annotation, save, ts }) {
  const { patch } = useAnnotations({
    messageId: annotation.message_id,
  });

  const user = useSelector((s) => s.user);
  const isReviewed = React.useMemo(() => {
    return get(annotation, 'metadata.reviewed_by_user_id', false);
  }, [annotation]);

  const setReviewed = async () => {
    await save();
    await patch(annotation.id, {
      action: isReviewed ? 'mark-as-unreviewed' : 'mark-as-reviewed',
      duration: isReviewed ? null : ts,
    });
  };
  const isSamePerson = annotation.user_id === user.id;
  if (!['reviewer', 'admin'].includes(user.role)) return null;

  return (
    <>
      <button
        className={classnames('btn btn-sm btn-success btn-block', {
          'btn-danger': isReviewed,
        })}
        onClick={setReviewed}
        type="button"
        disabled={isSamePerson}
      >
        {!isReviewed ? 'Mark as Reviewed' : 'Remove review'}
      </button>
      {isSamePerson && (
        <Alert color="danger" style={{ marginTop: 5, fontSize: 11 }}>
          Review is disabled: You cannot review your own annotation.
        </Alert>
      )}

      <hr />
    </>
  );
}
