import { useMemo } from 'react';
import validator from 'Utils/validator';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { create, createSelector } from 'Store/api/accounts/invites';

export default function useInviteUsers(accountId, { onSuccess } = {}) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(createSelector);
  const initialValue = { email: '' };
  const initialValues = useMemo(() => {
    return {
      invites: [initialValue, initialValue, initialValue],
    };
  }, []); // eslint-disable-line

  const validate = (data) => {
    data.invites = data.invites.map((x) => (x.email ? x : { email: null }));
    const valid = validator({
      invites: {
        array: {
          email: { email: true },
        },
      },
    })(data);

    return valid;
  };
  const params = useParams();
  if (!params.method) params.method = 'accounts';
  const account = useSelector(
    (s) => s.user.accounts.find((a) => a.id === accountId) || {}
  );

  const onSubmit = async (data) => {
    const emails = data.invites.filter((x) => x.email).map((x) => x.email);
    if (!emails.length) return onSuccess();
    const requests = emails.map((email) => ({
      method: 'post',
      url: `/${params.method || ''}/${accountId}/invites`,
      data: { email },
    }));
    const res = await dispatch(
      create(null, null, { replaceRequest: requests })
    );
    if (res.error) return res.error;
    if (onSuccess) return onSuccess(res.data);
  };

  return {
    onSubmit,
    initialValues,
    validate,
    initialValue,
    loading,
    error,
    account,
  };
}
