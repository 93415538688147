import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetRequests } from '@redux-requests/core';
import { Confirm } from 'Components/Toasts';

import {
  FIND,
  get as getAction,
  find as findAliases,
  getSelector,
  findSelector,
  createSelector,
  patchSelector,
  removeSelector,
  patch as patchAction,
  create as createAction,
  remove as removeAction,
} from 'Store/api/aliases';
import validator from 'Utils/validator';
import { toast } from 'react-toastify';

export default function useAliases(
  options = {
    accountId: null,
    fetch: false,
    params: null,
  }
) {
  const defaultParams = {
    '$sort[created_at]': -1,
    $limit: 20,
    account_id: options.accountId,
  };

  const dispatch = useDispatch();
  const {
    data,
    loading: findPending,
    error: findError,
  } = useSelector(findSelector);
  const {
    data: getData,
    loading: getPending,
    error: getError,
  } = useSelector(getSelector);
  const { loading: createPending, error: createError } =
    useSelector(createSelector);
  const { loading: patchPending, error: patchError } =
    useSelector(patchSelector);
  const { loading: removePending, error: removeError } =
    useSelector(removeSelector);

  useEffect(() => {
    if (!options.fetch || !options.accountId) return;
    dispatch(findAliases(options.accountId, defaultParams || options.params));
    return () => dispatch(resetRequests([FIND]));
  }, []); // eslint-disable-line

  const create = (...args) => dispatch(createAction(...args));
  const patch = (...args) => dispatch(patchAction(...args));
  const removeAlias = (...args) => dispatch(removeAction(...args));
  const get = (...args) => dispatch(getAction(...args));
  const remove = (...args) => {
    toast(
      <Confirm
        confirm={() => removeAlias(...args)}
        text="Are you sure you want to remove this alias? This action cannot be undone."
      />
    );
  };
  const validate = validator({
    email: {
      presence: true,
      length: { minimum: 3, maximum: 30 },
      email: true,
    },
    name: { presence: true, length: { minimum: 3 } },
  });

  const handleForm = async (payload) => {
    if (payload.id) {
      await patch(payload.account_id, payload.id, payload);
      toast('Alias updated.');
    } else {
      await create(payload.account_id, payload);
      toast('Alias created.');
    }
  };
  return {
    data,
    getData,
    validate,
    handleForm,
    findPending,
    createPending,
    patchPending,
    removePending,
    getPending,
    findError,
    createError,
    patchError,
    removeError,
    getError,
    get,
    create,
    patch,
    remove,
  };
}
