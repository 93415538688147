import React from 'react';
import { Label, Input, FormFeedback } from 'reactstrap';

export default function FormInput(props) {
  const { input, meta, label, subtext } = props;
  let errorString;
  if (meta.error && Array.isArray(meta.error)) {
    [errorString] = meta.error;
  }
  if (
    !meta.dirtySinceLastSubmit &&
    meta.submitError &&
    Array.isArray(meta.submitError)
  ) {
    [errorString] = meta.submitError;
  }

  return (
    <>
      {label && <Label for={input.name}>{label}</Label>}
      <Input
        {...input}
        placeholder={props.placeholder}
        invalid={
          meta.invalid && (meta.touched || (props.showInvalid && meta.dirty))
        }
        disabled={props.disabled}
        style={props.style ? props.style(props) : null}
        valid={meta.valid && props.showValid && !meta.validating}
        bsSize={props.size}
      />
      {subtext && <small className="text-muted my-2">{subtext}</small>}
      <FormFeedback>{errorString}</FormFeedback>
    </>
  );
}
