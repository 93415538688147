import React from 'react';
import Flex from 'Components/Flex';
import useThreads from 'Hooks/use-threads';
import NylasLoader from 'Components/NylasLoader';
import ThreadItem from 'Components/ThreadListItem';
import Toolbar from './Toolbar';
import MultiSelectToolbar from './MultiSelectToolbar';

export default function ThreadList() {
  const hook = useThreads();
  // console.log('HOOK', hook);
  const {
    data,
    params,
    loading,
    fetchData,
    page,
    perPage,
    setPage,
    isInitialSync,
    multiselect,
  } = hook;

  return (
    <Flex grow column style={{ background: 'white' }}>
      <Toolbar
        page={page}
        setPage={setPage}
        perPage={perPage}
        loading={loading}
        refresh={fetchData}
        params={params}
        threads={data}
        multiselect={multiselect}
      />
      {/* Multiselect (if no toolbar) */}
      <MultiSelectToolbar threads={data} refresh={fetchData} />
      <Flex column style={{ minHeight: '70vh' }}>
        <Flex column style={{ minHeight: '70vh' }}>
          {/* Show Loader if no data and loading */}
          {!data.length && !isInitialSync && (
            <NylasLoader loading={loading} noResults={!data.length} />
          )}

          {/* --- Account(s) syncing --- */}
          {isInitialSync && (
            <Flex center grow style={{ fontSize: 14 }}>
              Your emails are currently being synced. This process should take
              about 5-20 minutes.
            </Flex>
          )}

          {/*  Show Threads */}
          {data.map((thread) => (
            <ThreadItem
              thread={thread}
              key={thread.id || thread._id}
              loading={loading}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
