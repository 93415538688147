import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuerySelector } from '@redux-requests/core';
import './style.scss';

const MERCHANTS_LIST = 'STUDIO/MERCHANT';
const action = (id) => ({
  type: MERCHANTS_LIST,
  request: {
    url: `/annotations/merchants/${id}`,
  },
  multiple: true,
});

export default function MerchantStats({ match }) {
  const { params } = match;
  const dispatch = useDispatch();
  const { data } = useSelector(getQuerySelector(action()));

  React.useEffect(() => {
    dispatch(action(params.merchantName));
  }, [dispatch, params.merchantName]);

  return (
    <div className="merchants-list">
      <div>
        <br />
        <h1>{params.merchantName}</h1>
        <br />
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Category</th>
              <th scope="col">Annotations</th>
              <th scope="col">Reviews</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr key={idx}>
                <td>{item._id[0]}</td>
                <td>{item.annotations_count || 0}</td>
                <td>{item.reviews_count || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
