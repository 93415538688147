import React from 'react';
import './style.scss';
import client from 'Clients/rest';
import AppLayout from 'Components/AppLayout';
import { Container } from 'Components/Styled';

const SAMPLE = `The following is a conversation with an Nylas AI assistant. The assistant is helpful, creative, clever, and very friendly.
Human: Hello, who are you?
AI: I am an AI created by Nylas. How can I help you today?
Human: Who created you?
AI:`;

export default function InboxPage() {
  const [input, setInput] = React.useState(SAMPLE);
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await client.post('/external/gpt3', {
        prompt: input,
      });
      setLoading(false);
      setResponse(res.choices);
    } catch (err) {
      setLoading(false);
      console.log('Submission error', err.message);
    }
  };
  return (
    <AppLayout>
      <Container className="m-3 p-3">
        <h2 className="mb-2">Nylas AI Playground</h2>
        <form action="" className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <textarea
              style={{ minHeight: 300 }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              name="GPTInput"
              placeholder="enter text here"
              className="form-control"
              cols="50"
              rows="10"
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </form>
      </Container>
      {response.length > 0 && (
        <Container className="m-3 p-3">
          <h4 className="mb-2">Response</h4>
          <ul>
            {response.map((choice, idx) => {
              return <li key={idx}>{choice.text}</li>;
            })}
          </ul>
        </Container>
      )}
    </AppLayout>
  );
}
