import FormInput from './Input';
import FormSelect from './Select';
import FormCheckbox from './Checkbox';
import FromButtonGroup from './ButtonGroup';
import FromEditor from './Editor';

export const Input = FormInput;
export const Select = FormSelect;
export const Checkbox = FormCheckbox;
export const ButtonGroup = FromButtonGroup;
export const Editor = FromEditor;
