import { FIND as SEARCH } from 'Store/api/threads-search';
import createActions from '../common/create-feathers-actions';

const onThreadPatch = (id, data) => ({
  // TODO: planned refactor when we have richer implementation
  updateData: (current, update) => {
    if (!current) return [];
    if (
      data.action === 'move-to-trash' ||
      data.action === 'move-to-archived' ||
      data.action === 'move-to-inbox' ||
      data.action === 'move-to-spam'
    ) {
      return current.filter((t) => t.id !== id);
    }
    return current.map((item) => {
      if (item._id === update._id) item = update;
      return item;
    });
  },

  updateDataOptimistic: (current) => {
    if (!current) return [];
    return current.map((t) => {
      if (t.id !== id) return t;
      if (data.action === 'mark-as-starred') {
        return { ...t, starred: true };
      }
      if (data.action === 'mark-as-unstarred') {
        return { ...t, starred: false };
      }
      if (data.action === 'mark-as-read') {
        return { ...t, unread: false };
      }
      if (data.action === 'mark-as-unread') {
        return { ...t, unread: true };
      }
      return t;
    });
  },

  revertData: (current) => {
    if (!current) return [];
    return current.map((t) => {
      if (t.id !== id) return t;
      if (data.action === 'mark-as-starred') {
        return { ...t, starred: false };
      }
      if (data.action === 'mark-as-unstarred') {
        return { ...t, starred: true };
      }
      if (data.action === 'mark-as-read') {
        return { ...t, unread: true };
      }
      if (data.action === 'mark-as-unread') {
        return { ...t, unread: false };
      }
      return t;
    });
  },
});

const THREADS = 'THREADS';
const {
  get,
  getSelectorByKey,
  find,
  findSelector,
  FIND,
  GET,
  create,
  createSelector,
  PATCH,
  patchSelector,
  patchSelectorByKey,
  patch,
  remove,
  removeSelector,
} = createActions({
  name: THREADS,
  url: '/threads',
  meta: {
    get: (id) => ({
      requestKey: id,
      requestsCapacity: 5,
      // onSuccess(data, current) {
      //   console.log({ here: 'here', data, current });
      //   return { id: 1 };
      // },
    }),

    patch: (id, data = {}) => ({
      requestKey: id,
      requestsCapacity: 5, // keep state for last 5
      mutations: {
        [`${THREADS}/GET${id}`]: {
          updateData(current, update) {
            return { ...update };
          },
        },
        [`${THREADS}/FIND`]: onThreadPatch(id, data),
        [`${SEARCH}`]: onThreadPatch(id, data),
      },
    }),
  },
});

export {
  get,
  GET,
  getSelectorByKey,
  create,
  createSelector,
  PATCH,
  patch,
  patchSelector,
  patchSelectorByKey,
  find,
  findSelector,
  FIND,
  remove,
  removeSelector,
};
