import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('SETTINGS_SIDEBAR/UPDATE');
export const toggleAccountsAction = createAction('SETTINGS_SIDEBAR/TOGGLE');

const initialState = {
  showAccounts: false,
}; // initial state

export default createReducer(
  initialState, // initial state
  {
    [toggleAccountsAction]: (state, action) => {
      state.showAccounts = action.payload;
    },
    [updateAction]: (state, action) => {
      if (!action.payload) return state;
      return { ...state, ...action.payload };
    },
  }
);
