import { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMutation from 'Hooks/use-mutation';
import getThreadSender from 'Utils/get-thread-sender';
import * as threadActions from 'Store/api/threads';
import { resetRequests } from '@redux-requests/core';
import timeAgo from 'Utils/timeAgo';
import { push } from 'connected-react-router';
import threadTags from 'Utils/thread-tags';
import useThreadMultiSelect from './use-thread-multiselect';

// thread can be Id or object
export default function useThread(thread, options = { fetch: false }) {
  const id = typeof thread === 'string' ? thread : thread.id;

  const dispatch = useDispatch();
  const mutations = useMutation({ actions: { ...threadActions } });
  const {
    select,
    isSelected,
    isLoading: batchPending,
  } = useThreadMultiSelect(id);

  const get = useCallback(
    () => dispatch(threadActions.get(id)),
    [id, dispatch]
  );

  // initial fetch
  useEffect(() => {
    if (!options.fetch) return;
    get();
  }, [options.fetch, get]);

  const { loading: patchPending, error: patchError } = useSelector(
    threadActions.patchSelectorByKey(id)
  );

  const {
    data,
    loading: getPending,
    error: getError,
  } = useSelector(threadActions.getSelectorByKey(id));

  // Computed fields
  const sender = useMemo(() => getThreadSender(thread), [thread.updated_at]); // eslint-disable-line

  const date = useMemo(() => timeAgo(thread.last_message_timestamp), [thread.updated_at]) // eslint-disable-line

  const snippet = useMemo(() => {
    if (!thread.snippet) return '';
    if (thread.section && thread.section !== 'convos') return;
    let sanitized = thread.snippet.trim();
    sanitized = sanitized.replace(/\s{2,}/g, ' '); // remove double spaces
    return sanitized;
  }, [thread.updated_at]) // eslint-disable-line

  const tags = useMemo(() => threadTags(thread), [thread.updated_at]); // eslint-disable-line
  // TODO return isTyping if needed
  // tags.push({ text: 'is typing', class_name: 'success' });

  // Actions
  const clearPatchError = () => {
    dispatch(resetRequests([threadActions.PATCH + id]));
  };

  const open = () => {
    dispatch(push(`/inbox/thread/${id}`));
  };
  return {
    ...mutations,
    data,
    sender,
    patchPending,
    patchError,
    clearPatchError,
    date,
    snippet,
    open,
    select,
    isSelected,
    tags,
    batchPending,
    getPending,
    getError,
  };
}
