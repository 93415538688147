import { LitElement, html, css, unsafeCSS } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js'; // eslint-disable-line
import { theme } from 'Components/Styled';

class MessageBody extends LitElement {
  static get properties() {
    return {
      html: { type: String },
      accessToken: { type: String },
      files: { type: String },
    };
  }

  static get styles() {
    return css`
      .pi-inline-image {
        max-width: 100%;
      }
      .nlp-entity {
        background: #eee;
        padding: 1px 5px;
        border-radius: 3px;
      }
      .nlp-entity--date {
        background: ${unsafeCSS(theme.primary)};
        color: white;
      }
      .nlp-entity--date::after {
        content: 'DATE';
        font-weight: bold;
        font-size: 10px;
        margin-left: 6px;
      }
      .nlp-entity--cardinal {
        background: ${unsafeCSS(theme.danger)};
        color: white;
      }
      .nlp-entity--cardinal::after {
        content: 'CARDINAL';
        font-weight: bold;
        font-size: 10px;
        margin-left: 6px;
      }
      .nlp-entity--org {
        background: ${unsafeCSS(theme.warning)};
        color: white;
      }
      .nlp-entity--org::after {
        content: 'ORG';
        font-weight: bold;
        font-size: 10px;
        margin-left: 6px;
      }
      .nlp-entity--person {
        background: ${unsafeCSS(theme.success)};
        color: white;
      }
      .nlp-entity--person::after {
        content: 'PERSON';
        font-weight: bold;
        font-size: 10px;
        margin-left: 6px;
      }
    `;
  }

  constructor() {
    super();
    this.html = '';
  }

  firstUpdated() {
    const contentLoaded = new CustomEvent('content-loaded', {
      detail: {},
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(contentLoaded);
    this.addEventListener('click', (e) => {
      if (!e.shiftKey) {
        e.preventDefault();
      }
    });
  }

  render() {
    return html`<div class="message-body">${unsafeHTML(this.html)}</div>`;
  }
}

customElements.define('message-body', MessageBody);
