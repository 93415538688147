import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Input from './Input';

export default function LineItems({ register, control }) {
  const {
    fields: products,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'order.line_items', // unique name for your Field Array
  });

  return (
    <>
      {products.map((p, index) => (
        <div key={p.id} className="product-box">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>Product {index + 1}</div>
            <button
              className="btn-x"
              onClick={() => remove(p.id)}
              type="button"
              tabIndex={-1}
            >
              ✕
            </button>
          </div>
          <Input
            r={register}
            name={`order.line_items.${index}.name`}
            caption=" Name:"
            text="Product name."
          />

          <Input
            r={register}
            name={`order.line_items.${index}.quantity`}
            caption="Quantity"
            text="Product Quantity."
          />

          <Input
            r={register}
            name={`order.line_items.${index}.unit_price`}
            caption=" Unit Price BEFORE Discount:"
            text="Unit price before discount."
            type="number"
          />

          <Input
            r={register}
            name={`order.line_items.${index}.unit_price_after_discount`}
            caption=" Unit Price AFTER Discount:"
            text="Unit price after discount."
            type="number"
          />

          <Input
            r={register}
            name={`order.line_items.${index}.product_line_brand`}
            caption="Product line brand:"
            text="Product line brand name"
            type="text"
          />

          <Input
            r={register}
            name={`order.line_items.${index}.product_line_identifier`}
            caption="Product line identifier:"
            text="Product line alphanumeric ASIN number"
            type="text"
          />

          <Input
            r={register}
            name={`order.line_items.${index}.image_url`}
            caption="Image URL:"
            text="Product image url."
            type="url"
          />
          <Input
            r={register}
            name={`order.line_items.${index}.url`}
            caption="URL:"
            text="Link to product page."
            type="url"
          />
          <Input
            r={register}
            name={`order.line_items.${index}.color`}
            caption="Color:"
            text="Color of the product."
            type="text"
          />
          <Input
            r={register}
            name={`order.line_items.${index}.size`}
            caption="Size:"
            text="Size of the product."
          />
        </div>
      ))}
      <button
        className="btn btn-info btn-sm btn-block"
        onClick={() => append({})}
        type="button"
        tabIndex={-1}
      >
        Add product
      </button>
    </>
  );
}
