import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'Components/Styled';
import { ReactComponent as Positive } from 'Assets/svg/positive.svg';
import { ReactComponent as Neutral } from 'Assets/svg/neutral.svg';
import { ReactComponent as Negative } from 'Assets/svg/negative.svg';
import classnames from 'classnames';
import SentimentCard from 'Components/Message/SentimentCard';
import client from 'Clients/rest';
import { get } from 'lodash';

const Root = styled.div`
  position: relative;
`;

const Modal = styled.div`
  position: absolute;
  min-width: 320px;
  min-height: 50px;
  background: #fff;
  box-shadow: ${theme.shadow};
  z-index: 10;
  border: 1px solid ${theme.border};
  border-radius: 4px;
  top: -120px;
  right: -4px;
`;
const Close = styled.button`
  color: ${theme.muted};
  font-size: 1.5rem;

  &:hover {
    color: ${theme.dark};
  }
`;

const Btn = styled.button`
  fill: ${theme.muted};
  svg {
    width: 28px;
    height: 28px;
  }
  &.active svg {
    fill: white;
  }
`;

const ModalBtn = styled.button`
  fill: ${theme.muted};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
  &.active svg {
    fill: white;
  }
`;

const Title = styled.h1`
  font-size: 12px;
  color: ${theme.muted};
`;

const Content = styled.div`
  padding: 10px;
`;

const ActionCaption = styled.div`
  color: ${theme.muted};
  text-align: center;
  margin-bottom: 5px;
`;

const Error = styled.div`
  text-align: center;
`;

export default function SentimentLabeller({ message }) {
  const [open, toggle] = useState(false);
  const [error, setError] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(null);
  const [sentiment, setSentiment] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSentiment, setLoadingSentiment] = useState(true);
  const handleOpen = () => toggle(!open);

  useEffect(() => {
    client
      .get(`/messages-sentiment`, { params: { message_id: message._id } })
      .then(([res]) => {
        setLoadingSentiment(false);
        if (!res) return;
        setValue(res.user_score);
        setSentiment({
          mlkit: {
            score: res.mlkit_score,
            magnitude: res.mlkit_magnitude,
            model: 'MLKit',
          },
          transformer: {
            score: res.transformer_score,
            label: res.transformer_label,
            model: 'Transformer',
          },
        });
      })
      .catch(() => setLoadingSentiment(false));
  }, [message._id]);

  const SentimentAPI = async () => {
    setLoading(true);
    setLoadError(false);
    try {
      const sent = await client.post('/external/ml-kit/nlp', {
        text: message.text || message.snippet,
      });
      setSentiment({
        mlkit: {
          score: sent.sentiment_score,
          magnitude: sent.sentiment_magnitude,
          model: 'MLKit',
        },
      });
      const [transf] = await client.post('/external/transformers/sentiment', {
        text: message.text || message.snippet,
      });
      setLoading(false);

      setSentiment({
        mlkit: {
          score: sent.sentiment_score,
          magnitude: sent.sentiment_magnitude,
          model: 'MLKit',
        },
        transformer: {
          score: transf.score,
          label: transf.label.toLowerCase(),
          model: 'Transformer',
        },
      });
    } catch (e) {
      setLoadError(true);
      setLoading(false);
    }
  };

  const handleSave = (val) => async () => {
    try {
      setValue(val);
      setSaving(true);
      setError(false);
      const data = {
        user_score: val,
        mlkit_score: get(sentiment, 'mlkit.score'),
        mlkit_magnitude: get(sentiment, 'mlkit.magnitude'),
        transformer_score: get(sentiment, 'transformer.score'),
        transformer_label: get(
          sentiment,
          'transformer.label',
          ''
        ).toLowerCase(),
        message_id: message._id,
        nylas_message_id: message.id,
        account_id: message.account_id,
        text: message.text,
      };
      await client.post('/messages-sentiment', data);

      setTimeout(() => {
        setSaving(false);
        toggle(false);
      }, 250);
    } catch (e) {
      setError(true);
      setSaving(false);
      setValue(null);
    }
  };

  useEffect(() => {
    if (!open || sentiment || loadError) return;
    SentimentAPI();
  }, [open, sentiment, loadError]); // eslint-disable-line

  let BUTTON_CAPTION = ' Label sentiment';
  if (value === 1) BUTTON_CAPTION = 'Positive';
  if (value === 0) BUTTON_CAPTION = 'Neutral';
  if (value === -1) BUTTON_CAPTION = 'Negative';

  return (
    <Root>
      <ModalBtn
        disabled={loadingSentiment}
        className={classnames({
          'btn btn-sm': true,
          'btn-outline-info': value === null,
          'btn-success active': value === 1,
          'btn-info active': value === 0,
          'btn-danger active': value === -1,
        })}
        onClick={handleOpen}
      >
        {value === 1 && <Positive />}
        {value === 0 && <Neutral />}
        {value === -1 && <Negative />}
        {loadingSentiment && (
          <div
            className="spinner-border text-info spinner-border-sm mr-3"
            role="status"
          />
        )}
        {BUTTON_CAPTION}
      </ModalBtn>
      {open && (
        <Modal className="d-flex justify-content-between flex-column">
          {saving && (
            <div className="progress" style={{ height: 5 }}>
              <div
                className="progress-bar progress-bar-animated progress-bar-striped bg-success"
                style={{ width: '100%' }}
              />
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center px-3 pt-2">
            <Title>Update Sentiment</Title>
            <Close onClick={handleOpen}>&times;</Close>
          </div>
          {/* Loader */}
          {loading && (
            <div className="d-flex align-items-center justify-content-center p-5">
              <div className="spinner-border text-info" role="status" />
            </div>
          )}

          {!loading && sentiment && (
            <Content>
              {sentiment.mlkit && (
                <SentimentCard
                  style={{ marginBottom: 5, marginRight: 0 }}
                  sentiment={sentiment.mlkit}
                />
              )}
              {sentiment.transformer && (
                <SentimentCard
                  style={{ marginRight: 0 }}
                  sentiment={sentiment.transformer}
                />
              )}
              {error && (
                <Error className="alert alert-danger mt-2">
                  Could not save results. Please try again
                </Error>
              )}
            </Content>
          )}

          {!loading && (
            <ActionCaption>Label sentiment for this message:</ActionCaption>
          )}

          {!loading && (
            <div
              className="btn-group w-100"
              style={{ borderTop: `1px solid ${theme.border}` }}
            >
              <Btn
                type="button"
                className={classnames({
                  btn: true,
                  'btn-light': value !== 1,
                  'btn-success active': value === 1,
                })}
                onClick={handleSave(1)}
              >
                <Positive />
              </Btn>
              <Btn
                type="button"
                className={classnames({
                  btn: true,
                  'btn-light': value !== 0,
                  'btn-info active': value === 0,
                })}
                onClick={handleSave(0)}
              >
                <Neutral />
              </Btn>
              <Btn
                type="button"
                className={classnames({
                  btn: true,
                  'btn-light': value !== -1,
                  'btn-danger active': value === -1,
                })}
                onClick={handleSave(-1)}
              >
                <Negative />
              </Btn>
            </div>
          )}
        </Modal>
      )}
    </Root>
  );
}
