import axios from 'axios';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios'; // or another driver
import appConfig from '../app.config';

// Redux-Request
const client = axios.create({
  baseURL: appConfig.restUrl,
  timeout: 60000,
});

client.interceptors.request.use((request) => {
  // Format response
  const token = localStorage.getItem(appConfig.localStorageTokenKey);
  const sessId = localStorage.getItem(appConfig.localStorageSessionKey);
  // Set header only if not set
  if (!request.headers.authorization)
    request.headers.authorization = token ? `Bearer ${token}` : null;
  request.headers['X-Session-Id'] = sessId;
  return request;
});

client.interceptors.response.use(null, (error) => {
  // Return Error response data if available
  if (error && error.isAxiosError) {
    if (error.response && error.response.data)
      return Promise.reject(error.response.data);
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
});

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(client),
});

export { requestsReducer, requestsMiddleware };
