import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import client from 'Clients/rest';

export default function GoogleOCRModal({ toggle, message, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    setLoading(true);
    setError(false);
    client
      .post(`/annotations-ocr`, {
        html: message.body,
      })
      .then((res) => {
        setLoading(false);
        setData(res);
        if (onSuccess) onSuccess(res);
        toggle(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          toggle(false);
        }, 1200);
      });
  }, [message, onSuccess, toggle]);

  return (
    <Modal size="lg" isOpen toggle={toggle}>
      <ModalHeader toggle={toggle}>Auto Annotate</ModalHeader>
      <ModalBody>
        {loading && (
          <div style={{}}>
            <center>
              <p>I am annotating the message, may take a while!</p>
              <iframe
                title=" "
                src="https://giphy.com/embed/905GG7MjDw61q"
                height="100%"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              />
            </center>
          </div>
        )}
        {error && (
          <center style={{ padding: 20 }}>
            <h4>Could not extract any data from this email. :|</h4>
            <br />
            {/* <img
              src="https://cms.afrotech.com/wp-content/uploads/2021/07/KhabyLame-copy.jpg"
              style={{ width: '60%' }}
              alt=""
            /> */}
          </center>
        )}

        {data && (
          <div>
            <center>Found Fields:</center>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {data.entity_types.map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <td>{data.entity_text[idx]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {/* <Button color="primary" onClick={toggle} disabled={loading}>
          Apply
        </Button>{' '} */}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
