import React from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { theme } from '../Styled';

const Root = styled.div`
  display: block;
  background: #fff;
  min-width: 150px;
  box-shadow: none;
  border: 1px solid ${theme.border};
`;
const Item = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${theme.dark};
  text-align: left;
  width: 100%;
  border-bottom: 1px solid ${theme.border};
  &:hover {
    background: ${theme.primaryMuted};
  }
`;
const IconWrapper = styled.div`
  display: inline-flex;
  margin-right: 10px;
  svg {
    width: 14px;
    height: 14px;
    fill: ${theme.icon};
  }
`;

export default function Drop({ children, open, options, toggle }) {
  const handleMenuClick = (fn) => (e) => {
    e.stopPropagation();
    if (fn) fn();
    toggle(false);
    const popper = document.querySelector('.tippy-popper');
    if (popper) {
      document.body.removeChild(popper);
    }
  };
  return (
    <Tooltip
      // options
      position="bottom"
      trigger="click"
      animateFill={false}
      interactive
      open={open}
      onRequestClose={() => {
        toggle(false);
      }}
      html={
        <Root>
          {options.map((option, i) => {
            const Icon = option.icon;
            return (
              <Item
                key={i}
                onClick={handleMenuClick(option.action)}
                icon={option.icon}
                data-cy="dropdown-option"
              >
                {Icon && (
                  <IconWrapper>
                    <Icon title />
                  </IconWrapper>
                )}
                {option.caption}
              </Item>
            );
          })}
        </Root>
      }
    >
      {children}
    </Tooltip>
  );
}
