import React from 'react';
import { Provider } from 'react-redux';
import Composer from './index';

export default function ComposerContainer({ store, ...props }) {
  return (
    <Provider store={store}>
      <Composer {...props} />
    </Provider>
  );
}
