import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ws from 'Clients/ws';
import { theme } from 'Components/Styled';

let timeout;

const Root = styled.div`
  margin-left: 20px;
  color: ${theme.primary};
`;

export default function IsTyping({ thread }) {
  const [userTyping, setTyping] = useState(false);
  const user = useSelector((s) => s.user);

  // is typing
  useEffect(() => {
    ws.service('chat/typing').on('created', (data) => {
      if (data.user_id === user.id) return;
      if (data.thread_id !== thread.id) return;
      clearTimeout(timeout);
      setTyping(data.username);
      timeout = setTimeout(() => {
        setTyping(false);
      }, 2000);
    });
    ws.service('messages').on('created', (data) => {
      if (
        data.user_id === user.id ||
        data.thread_id !== thread.id ||
        data.object !== 'chat'
      )
        return;
      // when chat is received hide typing...
      setTyping(false);
    });
    return () => {
      // Cleanup
      ws.service('messages').off('created');
      ws.service('chat/typing').off('created');
    };
  }, []); // eslint-disable-line

  return (
    <Root>
      {userTyping && (
        <div>
          <strong>{userTyping} </strong> is typing...
        </div>
      )}
    </Root>
  );
}
