import { get } from 'lodash';
import moment from 'moment';
import timeAgo from './timeAgo';

export default function threadTags(thread) {
  const tags = [];
  // Mentions
  const mentions = get(thread, 'mentioned_users', []);
  mentions.forEach((person) => {
    tags.push({
      text: `@${person.username}`,
      class_name: 'info',
      type: 'mention',
    });
  });

  // Custom Tags
  const customTags = get(thread, 'custom_tags', []);
  customTags.forEach((original) => {
    // Invoice Date tagger
    const tag = { ...original };
    if (tag.text.includes('invoice-due-')) {
      tag.type = 'invoice';
      const ts = tag.text.split('invoice-due-')[1];
      const dateString = moment(ts, 'X').format('L');
      const diff = moment(ts, 'X').diff(moment(), 'days');
      if (diff > 5) {
        // In Today
        tag.text = `Due ${dateString}`;
        tag.class_name = 'primary';
      } else if (diff <= 5 && diff > 0) {
        tag.text = `Due soon (${dateString})`;
        tag.class_name = 'warning';
      } else if (diff === 0) {
        tag.text = `Due today (${dateString})`;
        tag.class_name = 'warning';
      } else if (diff < 0) {
        tag.text = `Past due (${dateString})`;
        tag.class_name = 'danger';
      }
    }
    tags.push(tag);
  });

  // Viewed By - last_viewed_by_users
  const lastViewed = get(thread, 'last_viewed_by_users', []);
  lastViewed.forEach((item) => {
    tags.push({
      text: `${item.username} viewed (${timeAgo(item.date, 'X')})`,
      class_name: 'primary',
      type: 'view',
    });
  });

  return tags;
}
