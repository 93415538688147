import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AvatarStack from "Components/AvatarStack";
import useUsers from "Hooks/use-users";

const AccountUsers = styled.div`
  @media ${(p) => p.theme.breakpoints.belowSm} {
    display: none;
  }
`;

export default function Toolbar({ params }) {
  const accounts = useSelector((s) => s.user.accounts);
  const { data: users } = useUsers();

  const userStack = useMemo(() => {
    if (params.query && params.query.startsWith("account-")) {
      const accountId = params.query.split("account-")[1];
      const acc = accounts.find((a) => a.id === accountId);
      if (!acc) return [];
      return users.filter(
        (user) => user.account_ids && user.account_ids.includes(acc.id)
      );
    }
    return [];
  }, [params.query, accounts.length]); // eslint-disable-line

  return (
    <>
      {userStack.length > 0 && (
        <AccountUsers>
          <AvatarStack users={userStack} title={(u) => u.username} />
        </AccountUsers>
      )}
    </>
  );
}
