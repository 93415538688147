import React, { useState } from 'react';
import AuthLayout from 'Components/AuthLayout';
import { Button } from 'reactstrap';
import { ReactComponent as InvitesSentImage } from 'Assets/svg/invite-sent.svg';
import styled from 'styled-components';
import InviteForm from 'Components/InviteUsers/Form';
import qs from 'query-string';
import useRouter from 'Hooks/use-router';

const SentImage = styled(InvitesSentImage)`
  display: inline-block;
  margin-bottom: 30px;
`;

export default function InviteUsersPage() {
  const { accountId } = qs.parse(document.location.search);
  const [sent, setSent] = useState(false);
  const { next: nextRoute } = useRouter();
  const next = () => nextRoute('inviteUsers');

  if (!sent)
    return (
      <AuthLayout logo={false}>
        <h1 className="mb-5 mt-4">Invite users</h1>

        <InviteForm
          accountId={accountId}
          onSuccess={() => setSent(true)}
          onSkip={next}
        />
      </AuthLayout>
    );

  if (sent) {
    return (
      <AuthLayout logo={false}>
        <div className="text-center">
          <SentImage />
          <h2 className="mb-3">Your invites have been sent!</h2>
          <p>
            You can invite more users later from <br />{' '}
            <strong>Settings &gt; Accounts &gt; Invite Users</strong>.
          </p>
          <Button
            className="mt-5"
            color="primary"
            block
            size="lg"
            onClick={next}
          >
            <strong>Go to your Productivity Inbox</strong>
          </Button>
        </div>
      </AuthLayout>
    );
  }
}
