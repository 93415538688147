import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import md5 from 'md5';
import SingleFileUploadWithProgress from './SingleFileUploadWithProgress';

const DropZone = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  border: 2px dashed #5c77ff;
  border-radius: 3px;
  background: #f2f3f8;

  height: ${(p) => (p.small ? '120px' : '200px')};
  outline: 'none';
`;

const FileContainer = styled(Grid)`
  max-height: 300px;
  overflow-y: auto;
`;

export default function MultipleFileUploadField({
  files,
  setFiles,
  isUploading,
  handleRemove,
}) {
  const onDrop = useCallback(
    (accFiles, rejFiles) => {
      // Here we just parse the files and set their "ID" and "status"
      // @param status ready | error | rejected
      accFiles = accFiles.map((x) => ({
        file: x,
        status: 'ready',
        id: md5([x.name, x.size]),
        errors: [],
      }));

      rejFiles = rejFiles.map((x) => ({
        file: x.file,
        errors: x.errors,
        status: 'error',
        id: md5([x.name, x.size]),
      }));

      // Join all files and filter by id
      const allFiles = uniqBy([...files, ...accFiles, ...rejFiles], 'id');
      setFiles(allFiles);
    },
    [files, setFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['message/rfc822'],
    maxSize: 26214400,
  });

  return (
    <>
      <Grid item xs={12}>
        <DropZone {...getRootProps({})} small={files.length}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select them</p>
          <em>
            (Only <strong>*.eml</strong> will be accepted)
          </em>
        </DropZone>
      </Grid>
      {files.length ? (
        <Grid item xs={12}>
          <div style={{ height: '20px' }} />
        </Grid>
      ) : null}
      <FileContainer item container spacing={1}>
        {files.map((data, idx) => (
          <React.Fragment key={idx}>
            <SingleFileUploadWithProgress
              data={data}
              onDelete={handleRemove}
              isUploading={isUploading}
            />
          </React.Fragment>
        ))}
      </FileContainer>
    </>
  );
}
