import React, { useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { ReactComponent as Check } from 'Assets/metronic/Navigation/Check.svg';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Input from './Input';
import { Btn } from '../../Styled';
import AnalyzeLinkModal from './AnalyzeItemModal';
import Tip from './Tip';
import { tracking_categories, carriers, service_types } from './categories';

const qs = require('query-string');

let extractionResult;

const getOptionLabel = (arr) => (opt) => {
  const found = arr.find((x) => x.value === opt.value);
  if (found) {
    return found.label;
  }

  if (opt.value) return opt.value;
  return 'Unknown';
};

const Label = ({ text, caption, children, active }) => (
  // PreventDefault is only because of datePicker auto-close on click
  // eslint-disable-next-line
  <label className={classnames({ active })} onClick={(e) => e.preventDefault()}>
    <Tip text={text} />
    {caption}
    {children}
  </label>
);

function analyzeLink(trackingInfo, activateModal) {
  /*
   * Sanity checks
   */
  if (!trackingInfo || !trackingInfo.length) return;
  /*
   * Parse the link to retrieve the tracking number
   */
  const link = trackingInfo;
  const [, query] = link.split('?');
  const parsed = qs.parse(query);
  const keys = Object.keys(parsed);
  const filtered = keys.filter((x) => {
    x = x.toLowerCase();
    const v =
      x.includes('tracking') || x.includes('trknbr') || x.includes('trackn');
    return v;
  });
  const output = filtered.map((x) => {
    const key = x;
    const value = parsed[x];
    return {
      link,
      key,
      value,
    };
  });
  /*
   * Start the popup
   */
  extractionResult = output;
  activateModal();
}

export default function TrackingItems({ register, control }) {
  const {
    fields: trackingItems,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'tracking.tracking_objects', // unique name for your Field Array
  });
  const [open, setOpen] = useState(false);
  const activateModal = () => setOpen(!open);
  return (
    <>
      <AnalyzeLinkModal
        open={open}
        toggle={activateModal}
        result={extractionResult}
      />
      {trackingItems.map((t, index) => (
        <div key={t.id} className="tracking-box">
          <div className="tracking-box__title">
            <div>Tracking Item</div>
            {trackingItems.length > 1 && (
              <button
                className="btn-x"
                onClick={() => remove(index)}
                type="button"
                tabIndex={-1}
              >
                ✕
              </button>
            )}
          </div>
          <div className="tracking-box__info--wrap">
            <Label caption="Status:" text="Status of the tracking object.">
              <Controller
                name={`tracking.tracking_objects.${index}.status`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={tracking_categories}
                    isClearable
                    getOptionLabel={getOptionLabel(tracking_categories)}
                  />
                )}
              />
            </Label>
          </div>
          <div className="tracking-box__info--wrap">
            <Input
              r={register}
              name={`tracking.tracking_objects.${index}.tracking_link`}
              caption="Link:"
              text="Tracking link."
              onChange={(e) => (trackingItems[index].link = e.target.value)}
            />
            <Btn
              className="btn-add-traking-info"
              type="button"
              title="Analyze Link"
              onClick={() =>
                analyzeLink(trackingItems[index].link, activateModal)
              }
            >
              <Check />
            </Btn>
          </div>
          <div className="tracking-box__info--wrap">
            <Input
              r={register}
              name={`tracking.tracking_objects.${index}.tracking_number`}
              caption=" Number:"
              text="Tracking number."
              onChange={(e) => (trackingItems[index].number = e.target.value)}
            />
            <Btn
              className="btn-add-traking-info"
              type="button"
              title="Verify Number"
              onClick={(e) =>
                window.open(
                  `https://www.google.com/search?q=${trackingItems[index].number}`
                )
              }
            >
              <Check />
            </Btn>
          </div>
          <div className="tracking-box__info--wrap">
            <Label
              text="The logistics company that is shipping the package."
              caption="Shipping Carrier:"
            >
              <Controller
                name={`tracking.tracking_objects.${index}.carrier_name`}
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    options={carriers}
                    isClearable
                    getOptionLabel={getOptionLabel(carriers)}
                  />
                )}
              />
            </Label>
          </div>
          <div className="tracking-box__info--wrap">
            <Label
              text="Date by which the packaged is estimated to be delivered. Sometimes referred to with the words “Your package is expected to arrive on 9/1/21” or “The estimated delivery date is October 21, 2021”. Please only label the date. "
              caption="Estimated delivery date:"
            >
              <Controller
                name={`tracking.tracking_objects.${index}.estimated_delivery_date`}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={field.value}
                    shouldCloseOnSelect
                  />
                )}
              />
            </Label>
          </div>
          <div className="tracking-box__info--wrap">
            <Label caption="Service type:">
              <Controller
                name={`tracking.tracking_objects.${index}.service_type`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={service_types}
                    getOptionLabel={getOptionLabel(service_types)}
                    isClearable
                  />
                )}
              />
            </Label>
          </div>
        </div>
      ))}
      <button
        className="btn btn-info btn-sm btn-block"
        onClick={() => append({})}
        type="button"
        tabIndex={-1}
      >
        Add tracking item
      </button>
    </>
  );
}
