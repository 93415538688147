import React, { useState } from 'react';
import SettingsTabHeader from 'Components/SettingsTabHeader';
import { PlusIcon } from 'Assets';
import { Button, Spinner } from 'reactstrap';
import useAccount from 'Hooks/use-account';
import useEmailTemplates from 'Hooks/use-email-templates';
import TemplateModal from 'Components/TemplateModal';
import TemplateItem from 'Components/TemplateItem';
import SettingsEmptyState from 'Components/SettingsEmptyState';
import Flex from 'Components/Flex';

export default function AccountTemplates(props) {
  const { accountId } = props.match.params;
  const [modal, setModal] = useState(false);
  const { isOwner } = useAccount(accountId);
  const hook = useEmailTemplates({ fetch: true, accountId });
  const toggle = () => setModal(!modal);

  return (
    <>
      <SettingsTabHeader title="Templates">
        <Flex alignCenter>
          {hook.findPending && <Spinner color="primary" className="mr-2" />}
          {isOwner && (
            <Button color="light" size="sm" onClick={toggle}>
              <PlusIcon
                title
                style={{ width: 10, height: 10, opacity: 0.5 }}
                className="mr-2"
              />
              Add new
            </Button>
          )}
          <TemplateModal
            open={modal}
            accountId={accountId}
            isOwner={isOwner}
            toggle={toggle}
          />
        </Flex>
      </SettingsTabHeader>
      {hook.data.map((t) => (
        <TemplateItem key={t.id} isOwner={isOwner} template={t} />
      ))}
      {hook.data.length === 0 && !hook.findPending && (
        <SettingsEmptyState
          title="No Email Templates"
          subtitle={isOwner && 'Click below to add a new email template.'}
        >
          {isOwner && (
            <button className="btn btn-primary" onClick={toggle}>
              Add new
            </button>
          )}
        </SettingsEmptyState>
      )}
    </>
  );
}
