const MarketOptions = [
  { value: 'us@eml_import.com', label: '🇺🇸 \xa0US' },
  { value: 'uk@eml_import.com', label: '🇬🇧 \xa0UK' },
  { value: 'se@eml_import.com', label: `🇸🇪 \xa0Sweden` },
  { value: 'de@eml_import.com', label: '🇩🇪 \xa0Germany' },
  { value: 'at@eml_import.com', label: '🇦🇹 \xa0Austria' },
  { value: 'nl@eml_import.com', label: '🇳🇱 \xa0Netherlands' },
  { value: 'no@eml_import.com', label: '🇳🇴 \xa0Norway' },
  { value: 'fi@eml_import.com', label: '🇫🇮 \xa0Finland' },
  { value: 'dk@eml_import.com', label: '🇩🇰 \xa0Denmark' },
  { value: 'es@eml_import.com', label: '🇪🇸 \xa0Spain' },
  { value: 'fr@eml_import.com', label: '🇫🇷 \xa0France' },
  { value: 'it@eml_import.com', label: '🇮🇹 \xa0Italy' },
  { value: 'be@eml_import.com', label: '🇧🇪 \xa0Belgium' },
  { value: 'ch@eml_import.com', label: '🇨🇭 \xa0Switzerland' },
];

export default MarketOptions;
