import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { toast } from 'react-toastify';
import conf from 'app.config';
import { Confirm } from 'Components/Toasts';
import { useHistory } from 'react-router-dom';

import {
  get as getAccount,
  patchSelector,
  removeSelector,
  patch as patchAction,
  create as createAction,
  remove as removeAction,
} from 'Store/api/accounts';

export default function useAccount(accountId) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((s) => s.user);
  const accounts = useSelector((s) => s.user.accounts);
  const account = accounts.find((a) => a.id === accountId);

  const { loading: patchPending, error: patchError } =
    useSelector(patchSelector);
  const { loading: removePending, error: removeError } =
    useSelector(removeSelector);

  const create = (...args) => dispatch(createAction(...args));
  const patch = async (...args) => {
    const res = await dispatch(patchAction(...args));
    if (res.error) {
      toast.error('Error updating account!');
    }
    return res;
  };
  const get = (...args) => dispatch(getAccount(...args));

  // query_params can use login_hint
  const connect = (queryParams) => {
    if (!queryParams) return history.push('/connect');
    const q = qs.stringify(queryParams);
    document.location = `${conf.restUrl}/connect/user/${user.id}?${q}`;
  };

  const removeAccount = () => {
    dispatch(removeAction(accountId));
  };

  const remove = () => {
    toast(
      <Confirm
        confirm={removeAccount}
        text="Are you sure you want to remove this account? This action cannot be undone."
      />
    );
  };
  const shareAccount = (id, data) => {
    toast(
      <Confirm
        confirm={() => patch(id, data)}
        text={`Are you sure you want to make this account ${
          data.shared ? 'shared' : 'private'
        }?`}
      />
    );
  };

  return {
    account,
    accounts,
    patchPending,
    removePending,
    patchError,
    removeError,
    shareAccount,
    get,
    create,
    patch,
    remove,
    connect,
    isSyncing: account && account.sync_state === 'running',
    isOwner: account && account.user_id === user.id,
  };
}
