import React, { useState, useCallback, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import client from 'Clients/rest';
import AccountSelector from 'Components/AccountSelector';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import styled from 'styled-components';
import MarketSelector from 'Components/MarketSelector';
import MultipleFileUploadField from './MultipleFileUploadField';

async function uploadFile(file, account_id, market_email) {
  const formData = new FormData();

  const market_name = market_email.label.split(/\s+/)[1];
  formData.append('import_id', account_id);
  formData.append('market_email', market_email.value);
  formData.append('market_name', market_name);
  formData.append('file', file);
  return client.post('/import-eml', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

const BodyContainer = styled.div`
  padding: 16px;
`;

export default function EMLModal() {
  const { location } = useHistory();
  const [files, setFiles] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [market, setMarket] = useState(null);

  useEffect(() => {
    const { query } = qs.parse(location.search);
    if (query && query.includes('account-')) {
      const id = query.split('-')[1]; // extract accountId
      setAccountId(id);
    }
  }, [location.search]);

  // Update file by ID
  const updateFile = (id, payload) =>
    setFiles((current) =>
      current.map((f) => {
        if (f.id === id) return { ...f, ...payload };
        return f;
      })
    );

  // Remove file by id
  const removeFile = useCallback(
    (id) => setFiles((f) => f.filter((x) => x.id !== id)),
    [setFiles]
  );

  const handleAccountChange = (acc) => {
    setAccountId(acc ? acc.id : null);
  };

  const handleMarketChange = (mkt) => {
    setMarket(mkt || null);
  };

  const onSubmit = async () => {
    // Set status to "uploading"
    setFiles((current) =>
      current.map((f) => {
        if (f.status !== 'ready') return f; // upload only 'ready' files
        return { ...f, status: 'uploading' };
      })
    );
    const promises = files.map(async (x) => {
      try {
        if (x.status !== 'ready') return x;
        const res = await uploadFile(x.file, accountId, market);
        updateFile(x.id, { status: 'uploaded', threadId: res.thread_id });
        return res;
      } catch (e) {
        updateFile(x.id, { status: 'error', errors: ['File upload failed'] });
        return x;
      }
    });

    return Promise.all(promises);
  };

  const isUploading = files.some((x) => x.status === 'uploading');
  const isReady = files.some((x) => x.status === 'ready');
  const isUploaded = files.some((x) => x.status === 'uploaded');

  return (
    <BodyContainer>
      <Grid container spacing={2} justifyContent="center">
        <Grid container item spacing={2} alignItems="center">
          <Grid xs={2} item>
            <strong>Upload to:</strong>
          </Grid>
          <Grid xs={10} item>
            <AccountSelector value={accountId} onChange={handleAccountChange} />
          </Grid>
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid xs={2} item>
            <strong>Market:</strong>
          </Grid>
          <Grid xs={10} item>
            <MarketSelector
              placeholder="Market"
              value={market}
              onChange={handleMarketChange}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <MultipleFileUploadField
            files={files}
            setFiles={setFiles}
            handleRemove={removeFile}
            isUploading={isUploading}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          spacing={2}
          xs={12}
        >
          {!accountId && (
            <Grid item>
              <div>Please select an account to import to.</div>
            </Grid>
          )}

          {!market && (
            <Grid item>
              <div>Please select a market to import to.</div>
            </Grid>
          )}

          {isUploaded && (
            <Grid item>
              <div>
                Hover over email items to copy generated links to clipboard.
              </div>
            </Grid>
          )}

          <Grid xs={2} item>
            <Button
              variant="contained"
              color="primary"
              disabled={isUploading || !isReady || !accountId}
              type="submit"
              onClick={onSubmit}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BodyContainer>
  );
}
