import React from 'react';
import { ReactComponent as SendIcon } from 'Assets/metronic/Communication/Snoozed-mail.svg';
import { ReactComponent as ErrorIcon } from 'Assets/metronic/Communication/Mail-error.svg';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import moment from 'moment';
import client from 'Clients/nylas-outbox';
import { useSelector } from 'react-redux';

import './style.scss';

export default function OutboxItem({ item: message, fetchData }) {
  const account = useSelector((s) =>
    s.user.accounts.find((a) => a.id === message.account_id)
  );
  const handleRemove = async () => {
    console.log('account', account);
    await client.delete(`/outbox/${message.id}`, {
      headers: { authorization: `Bearer ${account.access_token}` },
    });
    fetchData();

    toast('Message removed');
  };
  return (
    <div className="outbox-item">
      <div
        className={classnames({
          'outbox-item__icon': true,
          [message._status]: true,
        })}
      >
        {message._status === 'scheduled' ? <SendIcon /> : <ErrorIcon />}
      </div>
      <div className="outbox-item__content">
        <div>
          <strong>
            {message.subject}
            <div
              className={classnames('badge ml-2', {
                'badge-danger': message._status === 'failed',
                'badge-success': message._status === 'scheduled',
              })}
            >
              {message._status}
            </div>
          </strong>

          <div>
            Scheduled send: {moment(message.send_at, 'X').format('LLL')}{' '}
            <span className="text-muted">
              {moment(message.send_at, 'X').fromNow()}
            </span>
          </div>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={handleRemove}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}
