import { takeEvery, select, put } from 'redux-saga/effects';
import { set as SET_USER } from 'Store/user';
import { PATCH as PATCH_ACCOUNT } from 'Store/api/accounts';
import { success } from '@redux-requests/core';

function onUserUpdate({ payload: user }) {
  if (!user) {
    return localStorage.removeItem('user');
  }
  localStorage.setItem('user', JSON.stringify(user));
}

function* onAccountUpdate({ response: { data } }) {
  const user = yield select((s) => s.user);
  const updatedUser = {
    ...user,
    accounts: user.accounts.map((a) => {
      if (a.id === data.id) return data;
      return a;
    }),
  };
  yield put(SET_USER(updatedUser));
}

function* onAliasUpdate({ response: { data } }) {
  // data.account_id
  const user = yield select((s) => s.user);
  console.log('Alias updated from (/store/user/saga.js)', data); // eslint-disable-line
  // const account = user.accounts.map((a) => {
  //   if (a.id === data.account_id) {
  //     const aliases = a.aliases.map();
  //   }
  // });
  const updatedUser = {
    ...user,
    // TODO: find account by data.account_id and update aliases with aliases.map
  };
  yield put(SET_USER(updatedUser));
}

export default function* saga() {
  yield takeEvery(SET_USER, onUserUpdate);
  yield takeEvery(success(PATCH_ACCOUNT), onAccountUpdate);
  yield takeEvery('ALIASES/PATCH_SUCCESS', onAliasUpdate);
  // yield takeEvery('ALIASES/CREATE_SUCCESS', onAliasCreate);
}
