import React from 'react';
import DataTable from 'Components/DataTable';
import AppLayout from 'Components/AppLayout';
import {
  find as fetchDrafts,
  findSelector as draftsSelector,
} from 'Store/api/drafts';
import DraftItem from 'Components/DraftListItem';
import { DraftIcon } from 'Assets';

export default function DraftsPage() {
  return (
    <AppLayout>
      <DataTable
        icon={DraftIcon}
        fetchAction={fetchDrafts}
        selector={draftsSelector}
        component={DraftItem}
        title="All Accounts"
        description="Drafts"
      />
    </AppLayout>
  );
}
