import React from 'react'; //eslint-disable-line
import useOnboarding from 'Hooks/use-onboarding';
import IntroModal from './IntroModal';
import StepGuide from './StepGuide';

export default function Onboarding() {
  const {
    showIntro,
    dismissIntro,
    showStepGuide,
    dismissStepGuide,
    steps,
    loading,
  } = useOnboarding();

  return (
    <>
      {showIntro && <IntroModal dismiss={dismissIntro} loading={loading} />}
      {showStepGuide && (
        <StepGuide dismiss={dismissStepGuide} steps={steps} loading={loading} />
      )}
    </>
  );
}
