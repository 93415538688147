import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'Hooks/use-fetch';

import {
  findSelectorByKey,
  find,
  loadMore,
  loadMoreSelectorByKey,
} from 'Store/api/messages';

export default function useMessages({ threadId, query, setQuery }) {
  const dispatch = useDispatch();

  const messages = useFetch({
    query,
    selector: findSelectorByKey(threadId),
    fetch: find,
  });

  messages.loadMore = async () => {
    const newQuery = {
      ...query,
      $skip: (query.$skip / query.$limit + 1) * query.$limit,
    };
    await dispatch(loadMore(newQuery));
    setQuery(newQuery);
  };

  const { loading: loadMorePending, error: loadMoreError } = useSelector(
    loadMoreSelectorByKey(threadId)
  );
  messages.loadMorePending = loadMorePending;
  messages.loadMoreError = loadMoreError;

  messages.hasMore =
    query.$skip > 0
      ? messages.data.length >= query.$skip + query.$limit &&
        messages.data.length
      : messages.data.length === query.$limit && messages.data.length;

  const lastMessage = useMemo(() => {
    const emailMessages = messages.data.filter((m) => m.object === 'message');
    return emailMessages[0];
  }, [messages.data.length]); // eslint-disable-line

  messages.lastMessage = lastMessage;
  messages.shouldCollapse = (msgId) => {
    return lastMessage && lastMessage._id !== msgId;
  };

  return messages;
}
