import React, { useState, useEffect } from 'react';
import ws from 'Clients/ws';
import client from 'Clients/rest';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tippy';
import './style.scss';

export default function ThreadViewers({ thread }) {
  const [viewers, setViewers] = useState([]);
  const user = useSelector((s) => s.user);

  // Notify about reading
  useEffect(() => {
    const notification = {
      type: 'thread-read',
      thread_id: thread.id,
      account_id: thread.account_id,
      ts: moment().unix(),
    };
    client.post('/notifier', notification);
    const interval = setInterval(() => {
      notification.ts = moment().unix();
      client.post('/notifier', notification);
    }, 1000 * 5);
    return () => clearInterval(interval); // eslint-disable-line
  }, [thread.id, thread.account_id, user.id]);
  useEffect(() => {
    const listener = (message) => {
      setViewers((current) => {
        if (message.type !== 'thread-read') return current;
        const merged = uniqBy([message, ...current], 'user_id');
        const now = moment().unix();
        return merged.filter((item) => {
          const diff = now - item.ts;
          return (
            diff <= 6 &&
            user.id !== item.user_id &&
            thread.id === item.thread_id
          );
        });
      });
    };
    ws.service('notifier').on('created', listener);
    return () => ws.service('notifier').off('created', listener);
  }, [user.id, thread.id]);
  if (!viewers.length) return null;
  return (
    <div className="thread-viewers">
      <div className="thread-viewers__caption">
        Currenlty reading this thread:
      </div>
      {viewers.map((viewer) => {
        return (
          <Tooltip
            title={viewer.user.username}
            delay={100}
            theme="light"
            key={viewer.user_id}
          >
            <div className="thread-viewers__viewer">
              <Avatar name={viewer.user.username} size={20} round />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
