import {
  Grid,
  LinearProgress,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import prettyBytes from 'pretty-bytes';
import styled from 'styled-components';

const EMLItem = styled(Grid)`
  &:hover {
    text-decoration: none;
    background: #f1f1f1;
    border-radius: 2px;
    transition-duration: 0.15s;
  }
  transition-duration: 0.5s;
`;

export default function SingleFileUploadWithProgress({
  data,
  onDelete,
  isUploading,
}) {
  const { file, threadId } = data;
  const baseURL = window.location.origin.toString();
  const [copied, setCopied] = React.useState(false);

  const renderStatus = () => {
    switch (data.status) {
      case 'uploaded':
        return <Typography style={{ color: 'green' }}>Done</Typography>;
      case 'error':
        return <Typography color="error">Error</Typography>;
      default:
        return (
          <Button
            size="small"
            disabled={isUploading}
            onClick={() => onDelete(data.id)}
            color="primary"
          >
            Cancel
          </Button>
        );
    }
  };

  return (
    <Tooltip
      title={
        !copied ? (
          <Typography>Copy Email Link To Clipboard</Typography>
        ) : (
          <Typography>Link Copied!</Typography>
        )
      }
      placement="right"
      arrow
      onClick={() => {
        navigator.clipboard.writeText(`${baseURL}/inbox/thread/${threadId}`);
        setCopied(true);
      }}
      onMouseEnter={() => setCopied(false)}
      disableFocusListener={data.status !== 'uploaded'}
      disableTouchListener={data.status !== 'uploaded'}
      disableHoverListener={data.status !== 'uploaded'}
    >
      <EMLItem
        item
        container
        direction="row"
        alignItems="center"
        xs={12}
        justifyContent="space-between"
      >
        <Grid item xs={8} style={{ textAlign: 'left' }}>
          <Typography noWrap>{file.name}</Typography>
        </Grid>

        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <Typography>{prettyBytes(file.size)}</Typography>
        </Grid>

        <Grid item>{renderStatus()}</Grid>

        {data.status === 'uploading' && <LinearProgress />}

        {/* TODO: Make it nicer */}
        {(data.errors || []).map((error) => (
          <div key={error.code}>
            <Typography color="error">{error.message}</Typography>
          </div>
        ))}
      </EMLItem>
    </Tooltip>
  );
}
