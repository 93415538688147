import React from 'react';
import { useDispatch } from 'react-redux';
import { addAction } from 'Store/composer/files';
import styled from 'styled-components';
import { ReactComponent as Paperclip } from './attachment.svg';

const Btn = styled.button`
  margin-left: 10px;
  cursor: pointer;
  border: none;
  background: none;
`;

const Icon = styled(Paperclip)`
  width: 18px;
  height: 18px;
  fill: #74788d;
  &:hover {
    fill: #595d6e;
  }
`;

export default function AttachmentButton() {
  const dispatch = useDispatch();
  const handleClick = () => {
    const el = document.getElementById('nc-add-attachment');
    if (el) el.click();
  };

  const handleFileChange = async (e) => {
    if (!e.target.files.length) return;
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    try {
      await dispatch(async (disp, rootState, { composer: client }) => {
        const {
          composer: { account },
        } = rootState();
        const res = await client.upload(formData, account.access_token);
        res[0]._url = fileUrl;
        console.log('Uploaded file', res);
        disp(addAction(res));
      });
    } catch (err) {
      console.log('Errror uploading', err.message);
    }
  };

  return (
    <>
      <Btn onClick={handleClick}>
        <Icon />
      </Btn>
      <input
        type="file"
        onChange={handleFileChange}
        id="nc-add-attachment"
        hidden
        multiple={false}
      />
    </>
  );
}
