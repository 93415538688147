import React, { useEffect, useState } from 'react';
import client from 'Clients/rest';
import useUsers from 'Hooks/use-users';
import { Link } from 'react-router-dom';
import './style.scss';

const StatsItem = ({ item }) => {
  const { get } = useUsers();
  const user = get(item.user_id);
  return (
    <tr>
      <td>@{user && user.username}</td>
      <td>N/A</td>
      <td>
        <Link to={`/studio?user_id=${item.user_id}`}>
          {item.annotations_count || 0}
        </Link>
      </td>
      <td>
        <Link to={`/studio?metadata.reviewed_by_user_id=${item.user_id}`}>
          {item.reviews_count || 0}
        </Link>
      </td>
    </tr>
  );
};

export default function AnnotatonStats({ history }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    client.get('/annotations/stats').then(setData);
  }, []);
  return (
    <div className="annotation-stats">
      <table className="table table-stats">
        <thead>
          <tr>
            <th scope="col">User</th>
            <th>Team</th>
            <th scope="col">Annotations Count</th>
            <th scope="col">Reviews Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <StatsItem key={item.user_id} item={item} />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <b>Total:</b>
            </td>
            <td />
            {/* TODO: memoize */}
            <td>
              {data.reduce((acc, x) => acc + (x.annotations_count || 0), 0)}
            </td>
            <td>{data.reduce((acc, x) => acc + (x.reviews_count || 0), 0)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
