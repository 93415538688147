import React from 'react';
import PropTypes from 'prop-types';
import { RefreshIcon, AngleLeft, AngleRight, ErrorIcon } from 'Assets';
import { Tooltip } from 'react-tippy';
import stc from 'string-to-color';

import NylasLoader from 'Components/NylasLoader';
import styled from 'styled-components';
import useFetchData from 'Hooks/use-fetch';
import { Btn, theme } from '../Styled';

import './style.scss';

const ErrorNotification = styled(ErrorIcon)`
  width: 24px;
  height: 24px;
  fill: ${theme.danger};
  margin: 0 5px;
`;

const Root = styled.div`
  background: ${(p) => p.theme.backing};
`;

export default function DataTable({
  fetchAction,
  selector,
  component,
  query,
  componentProps,
  title,
  description,
  icon,
  toolbar,
}) {
  const ItemComponent = component;
  const ToolbarComponent = toolbar;
  const Icon = icon;

  // Fetching Hook
  const {
    fetchData,
    page,
    perPage,
    setPage,
    loading,
    data = [],
    error,
  } = useFetchData({
    fetch: fetchAction,
    selector,
    query,
  });

  return (
    <Root className="data-table">
      <header className="data-table__header">
        <div className="data-table__title">
          {icon && (
            <div
              className="data-table__icon"
              style={{ background: stc(`${title}.${description}`) }}
            >
              <Icon />
            </div>
          )}
          <div>
            {description}
            <h1>{title}</h1>
          </div>
        </div>
        <div className="data-table__toolbar">
          <strong>
            {page * perPage || 1} - {page * perPage + perPage || perPage}
          </strong>
          {loading && (
            <div className="spinner-border text-primary mx-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {error && (
            <Tooltip title="Error Fetching Data" theme="light">
              <ErrorNotification />
            </Tooltip>
          )}
          <Btn
            lg
            title="Previous page"
            style={{ marginLeft: 15 }}
            disabled={page === 0}
            onClick={setPage(page - 1)}
          >
            <AngleLeft title />
          </Btn>
          <Btn lg onClick={setPage(page + 1)} title="Next page">
            <AngleRight title />
          </Btn>
          <Btn lg title="Refresh" disabled={loading} onClick={fetchData}>
            <RefreshIcon title />
          </Btn>
        </div>
      </header>
      {ToolbarComponent && <ToolbarComponent />}

      {data.map((item) => (
        <ItemComponent
          key={item.id}
          item={item}
          fetchData={fetchData}
          {...componentProps}
        />
      ))}

      <NylasLoader loading={loading && !data.length} noResults={!data.length} />
    </Root>
  );
}

DataTable.defaultProps = {
  query: {},
};

DataTable.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  ItemComponent: PropTypes.element,
  query: PropTypes.object,
  icon: PropTypes.object,
};
