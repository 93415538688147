import { FIND } from 'Store/api/messages';

export const CHATTER = 'CHATTER';

export const parse = (data) => ({
  type: `${CHATTER}/PARSE`,
  request: {
    url: '/external/chatter',
    method: 'post',
    data,
  },
  meta: {
    mutations: {
      [`${FIND}${data.thread_id}`]: (current = [], item) => {
        return current.map((x) => {
          if (x.id === item.id) return item;
          return x;
        });
      },
    },
  },
});

export const undo = (id, data) => ({
  type: `${CHATTER}/UNDO`,
  request: {
    url: `/messages/${id}`,
    method: 'patch',
    data,
  },
  meta: {
    mutations: {
      [`${FIND}${data.thread_id}`]: (current = [], item) => {
        return current.map((x) => {
          if (x.id === item.id) return item;
          return x;
        });
      },
    },
  },
});
