import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/BODY/UPDATE');

const defaultPayload = '<p></p>';
const initialState = { data: '', valid: true };
export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [updateAction]: (state, action) => {
      if (action.payload === defaultPayload) {
        state.data = '';
      } else {
        state.data = action.payload;
      }
    },
  }
);
