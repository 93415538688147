import createActions from '../common/create-feathers-actions';

const joinAccounts = (request, accounts) => {
  request.data = request.data.map((item) => {
    // Bind account object
    if (item.account_id) {
      item.account = accounts.find((a) => a.id === item.account_id);
    }
    return item;
  });
  return request;
};

const { FIND, find, findSelector } = createActions({
  name: 'CONTACTS',
  url: '/contacts',
  meta: {
    create: {
      onSuccess(request, action, store) {
        const {
          user: { accounts },
        } = store.getState();
        joinAccounts(request, accounts);
        return request;
      },
    },
  },
});

export { find, FIND, findSelector };
