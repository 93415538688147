import { FIND } from 'Store/api/messages';
import createActions from '../common/create-feathers-actions';

const {
  create,
  createSelectorByKey,
  remove,
  removeSelector,
  removeSelectorByKey,
  patch,
  patchSelectorByKey,
} = createActions({
  name: 'CHATS',
  url: '/chats',
  meta: {
    create: (payload) => ({
      requestKey: payload.thread_id,
      requestsCapacity: 1,
      mutations: {
        [`${FIND}${payload.thread_id}`]: {
          updateDataOptimistic: (current) => {
            const upd = {
              ...payload,
              id: payload.__id,
              _id: payload.id,
            };
            const newState = [upd, ...current];
            return newState;
          },
          updateData(current, created) {
            return current.map((item) => {
              if (item.__id === payload.__id) return created;
              return item;
            });
          },
          revertData: (current) => {
            return current.filter((x) => x.__id === payload.__id);
          },
        },
      },
    }),
    patch: (id, data) => ({
      requestKey: id,
      requestsCapacity: 1,
      mutations: {
        [`${FIND}${data.thread_id}`]: (current, patched) => {
          if (!current) return;
          return current.map((item) => {
            if (item._id === id) return patched;
            return item;
          });
        },
      },
    }),
    remove: (id, params, options = {}) => ({
      requestKey: id,
      requestsCapacity: 1,
      mutations: {
        [`${FIND}${options.thread_id}`]: (current, item) => {
          if (!current) return;
          return current.filter((x) => x._id !== item._id);
        },
      },
    }),
  },
});

export {
  create,
  createSelectorByKey,
  remove,
  removeSelector,
  patch,
  patchSelectorByKey,
  removeSelectorByKey,
};
