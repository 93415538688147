import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import metrics from 'Clients/metrics';
import AppLoader from 'Components/AppLoader';
import theme from 'Styles/theme';
import FullStory from 'react-fullstory';
import { login, leanLogin } from './Store/api/auth';
import store, { history } from './Store';
import Routes from './Pages';
import appConfig from './app.config';
import 'Styles/main.scss';

/* eslint-disable */
console.groupCollapsed(`App running in ${appConfig.env}`);
Object.keys(appConfig).forEach((key) => {
  console.log(`${key}:`, appConfig[key]);
});
console.groupEnd();
/* eslint-enable */
const ORG_ID = process.env.NODE_ENV === 'production' ? '15G7H2' : null; // Fill this in here

const ROOT = document.getElementById('root');

const renderLoader = () => {
  ReactDOM.render(<AppLoader />, ROOT);
};

const render = () => {
  ReactDOM.render(
    <StyledThemeProvider theme={theme}>
      {ORG_ID && <FullStory org={ORG_ID} />}
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </StyledThemeProvider>,
    ROOT
  );
};

const token = localStorage.getItem(appConfig.localStorageTokenKey);

if (token) {
  renderLoader(); // this is inital load before app auth is done
  // NOTE: lean login uses local storage to set the user without verifying on server (much faster loading time)
  // used only for development... If you have issues logging in switch loginFn to use login (instead of leanLogin)
  const isDev = process.env.NODE_ENV !== 'production';
  const user = JSON.parse(localStorage.getItem('user'));
  const useLeanLogin = false;
  if (isDev && user && useLeanLogin) {
    store.dispatch(leanLogin(user)).then(() => render(Routes));
  } else {
    store
      .dispatch(login({ access_token: token }, 'jwt'))
      .then(() => render(Routes));
  }
} else {
  render(Routes);
}

window.addEventListener('beforeunload', () => {
  metrics.endSession();
});

if (module.hot) {
  module.hot.accept('./Pages', () => {
    const Next = require("./Pages"); // eslint-disable-line
    render(Next);
  });
}
