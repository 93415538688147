import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuerySelector } from '@redux-requests/core';
import { Link } from 'react-router-dom';
import './style.scss';

const { uniq } = require('lodash');

const MERCHANTS_LIST = 'STUDIO/MERCHANTS_LIST';
const action = () => ({
  type: MERCHANTS_LIST,
  request: {
    url: `/annotations/merchants`,
  },
  multiple: true,
});
const CUTOFF = 5;

function MerchantItem({ merchant }) {
  const [expand, setExpand] = React.useState(false);

  const uniqueDomains = React.useMemo(() => {
    const unique = uniq(merchant.domains || []);
    return unique;
  }, [merchant.domains]);

  const domains = React.useMemo(() => {
    const unique = uniqueDomains;
    if (unique.length > CUTOFF && !expand) {
      const sliced = unique.slice(0, CUTOFF);
      sliced.push(`And ${unique.length - CUTOFF} more...`);
      return sliced;
    }
    return unique;
  }, [uniqueDomains, expand]);

  return (
    <tr>
      <td>{merchant._id || 'No merchant'}</td>
      <td>{merchant.annotations_count || 0}</td>
      <td>{merchant.reviews_count || 0}</td>
      <td>
        {domains.map((str, idx) => (
          <div style={{ marginRight: 5 }} key={idx}>
            {str}
          </div>
        ))}
        {uniqueDomains.length > CUTOFF && (
          <button
            className="btn btn-sm btn-primary"
            style={{ padding: '1px 10px' }}
            onClick={() => setExpand(true)}
          >
            Show all
          </button>
        )}
      </td>
      <td>
        <Link to={`/studio?merchant.name=${encodeURIComponent(merchant._id)}`}>
          Annotations
        </Link>
        {' | '}
        <Link to={`/studio/merchants/${encodeURIComponent(merchant._id)}`}>
          Details
        </Link>
      </td>
    </tr>
  );
}

export default function MerchantsList() {
  const dispatch = useDispatch();
  const [filter, setFilter] = React.useState('');
  const { data } = useSelector(getQuerySelector(action()));

  React.useEffect(() => {
    dispatch(action());
  }, [dispatch]);

  const filteredData = React.useMemo(() => {
    if (filter) {
      return data.filter((x) => {
        const name = x._id ? x._id.toLowerCase() : '';
        return name.includes(filter);
      });
    }
    return data;
  }, [filter, data]);

  return (
    <div className="merchants-list">
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Annotations</th>
              <th scope="col">Reviews</th>
              <th>Domains</th>
              <th>Actions</th>
            </tr>
            <tr>
              <td colSpan={4}>
                Filter merchants:
                <input
                  type="text"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((merchant) => (
              <MerchantItem key={merchant._id} merchant={merchant} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
