import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { CloseIcon, DownloadIcon } from 'Assets';
import { Btn } from 'Components/Styled';
import useAttachment from 'Hooks/use-attachment';

import './style.scss';

const ErrorScreen = () => <div>Error loading file</div>;

export default function Viewer({ close, file }) {
  const [url, setUrl] = useState();
  const { extension, getUrl, download } = useAttachment(file);

  const onError = (e) => {
    console.log('Error loading file', e);
  };

  useEffect(() => {
    getUrl().then((res) => setUrl(res.url));
  }, [file.id]); // eslint-disable-line

  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape' && close) close();
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, []); // eslint-disable-line
  return (
    <div className="file-viewer">
      <div className="file-viewer__header d-flex align-items-center justify-content-between">
        <div>{file.filename}</div>
        <div className="d-flex align-items-center">
          <Btn blank className="mr-2" onClick={download}>
            <DownloadIcon title />
          </Btn>
          <Btn blank onClick={close}>
            <CloseIcon title />
          </Btn>
        </div>
      </div>
      {url && (
        <div className="file-viewer__content">
          <FileViewer
            fileType={extension}
            filePath={url}
            onError={onError}
            errorComponent={ErrorScreen}
          />
        </div>
      )}
    </div>
  );
}
