import React from 'react';
import { ReactComponent as Logo } from 'Assets/svg/logo-white.svg';
import styled from 'styled-components';
import { theme } from 'Components/Styled';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
const Icon = styled(Logo)`
  svg,
  path {
    fill: ${(p) => p.fill || p.theme.nylas};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.size || 80}px;
  height: ${(p) => p.size || 80}px;
  animation: logoBlink 1.2s alternate infinite;
  animation-timing-function: ease-in-out;
  @keyframes logoBlink {
    from {
      opacity: 0.5;
      transform: scale(0.95);
    }
    top {
      opacity: 1;
    }
  }
`;
const Caption = styled.div`
  margin-top: 20px;
  color: ${theme.muted};
  font-size: 15px;
`;

export default function NylasLoader({
  loading,
  fill,
  size,
  style,
  className,
  loadingCaption,
  noResults,
  noResultsCaption,
}) {
  if (loading)
    return (
      <Root style={style} className={className}>
        <Icon fill={fill} size={size} />
        {loadingCaption && <Caption>{loadingCaption}</Caption>}
      </Root>
    );

  if (noResults && !loading)
    return (
      <Root style={style} className={className}>
        <Caption>{noResultsCaption || 'Nothing here.'}</Caption>
      </Root>
    );

  return null;
}
