import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from 'reactstrap';
import Flex from 'Components/Flex';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import useAccount from 'Hooks/use-account';

import 'react-toggle/style.css';

const SignatureContainer = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 3px;
  padding: 3px;
  margin-bottom: 15px;
`;

export default function SignatureItem({ account }) {
  const [html, setHtml] = useState(account.signature);
  const { patch, patchPending, isOwner } = useAccount(account.id);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await patch(account.id, { signature: html });
  };

  const toggleSignature = async () =>
    patch(account.id, {
      is_append_signature: !account.is_append_signature,
    });

  const onCancel = () => {
    setHtml(account.signature);
  };
  const isSaved = html === account.signature;
  return (
    <form onSubmit={handleSubmit}>
      <SignatureContainer>
        <Editor
          value={html}
          disabled={!isOwner}
          apiKey="yoes14bmgp0y9c64v3nf5v0pi4vkma9921u7mmu9972br4l4"
          init={{
            placeholder: 'Write signature here...',
            height: 200,
            menubar: false,
            statusbar: false,
            content_css: '/tinymce.css',
            toolbar_location: 'bottom',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              isOwner &&
              `formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | code preview`,
          }}
          onEditorChange={(text) => setHtml(text)}
        />
      </SignatureContainer>

      {/* Footer Controls */}
      {isOwner && (
        <Flex justifyBetween alignCenter>
          <div>
            <Button
              color="primary"
              disabled={patchPending || isSaved}
              type="submit"
            >
              {isSaved ? 'Saved' : 'Save'}
            </Button>
            {!isSaved && (
              <Button
                color="primary"
                outline
                className="ml-1"
                onClick={onCancel}
                type="button"
              >
                Cancel
              </Button>
            )}
          </div>
          {/* Right */}
          <Flex alignCenter>
            <span className="text-muted">Enable signature</span>
            <Toggle
              checked={account.is_append_signature}
              name="enable signature"
              className="ml-2"
              onChange={toggleSignature}
              icons={false}
            />
          </Flex>
        </Flex>
      )}
    </form>
  );
}
