import React from 'react';
import useAnnotations from 'Hooks/use-annotations';
import './style.scss';
import { Spinner } from 'reactstrap';
import { ErrorIcon, AngleLeft, AngleRight, MenuIcon } from 'Assets';
import styled from 'styled-components';
import useAnnotationsStore from 'Hooks/use-annotations-store';
import { Btn } from 'Components/Styled';
import FixMessageBtn from '../../ThreadView/FixMessageBtn';
import DownloadEMLBtn from '../DownloadEMLButton';

const ActionError = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  fill: ${(p) => p.theme.danger};
`;

export default function TopBar({ messageId, isLoading, reloadMessage }) {
  const { loading, error } = useAnnotations();
  const [fixMessagesPending, setFixMessagesPending] = React.useState(false);
  const {
    // data: annotations,
    count,
    current,
    currentIndex,
    next,
    prev,
  } = useAnnotationsStore({ messageId });

  return (
    <div className="studio-topbar">
      <div className="left" />
      <div className="middle">
        {(isLoading || loading) && (
          <Spinner size="sm" color="nylas" className="mr-3" />
        )}
        {error && (
          <div onClick={() => alert(JSON.stringify({ error }, null, 2))}>
            <ActionError /> <b>Error!</b>{' '}
            <small style={{ opacity: 0.7 }}>click here for info</small>
          </div>
        )}
      </div>
      <div className="right">
        {fixMessagesPending && (
          <Spinner size="sm" color="nylas" className="mr-3" />
        )}
        <DownloadEMLBtn messageId={messageId} />
        <FixMessageBtn
          messageId={messageId}
          reloadMessage={reloadMessage}
          setFixMessagesPending={setFixMessagesPending}
        />
        {current && (
          <>
            {currentIndex + 1}/{count}
            <Btn
              lg
              blank
              title="Previous email"
              style={{ marginLeft: 15 }}
              onClick={prev}
            >
              <AngleLeft title />
            </Btn>
            <Btn lg blank onClick={next} title="Next email">
              <AngleRight title />
            </Btn>
            <Btn lg blank title="Menu">
              <MenuIcon title />
            </Btn>
          </>
        )}
      </div>
    </div>
  );
}
