import React from 'react';
import { Tooltip } from 'react-tippy';
import styled, { css } from 'styled-components';
import styledTheme from 'Styles/theme';
import { match } from 'styled-is';

export const theme = styledTheme;

export const Checkbox = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: ${theme.bgDarker};
  border-radius: 4px;
  margin: 0;

  span {
    &:after {
      border: solid #5d78ff;
      content: '';
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      margin-left: -2px;
      margin-top: -6px;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
      -webkit-transform: rotate(45deg) /*rtl:ignore*/;
      transform: rotate(45deg) /*rtl:ignore*/;
      ${(props) =>
        props.checked &&
        css`
          display: block;
        `}
    }
  }
  input {
    position: absolute;
    z-index: -1;
  }
`;

export const Container = styled.div`
  background: #fff;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  border-radius: 4px;
`;

export const StyledBtn = styled.button`
  width: 30px;
  height: 30px;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.bg};
  border-radius: 4px;
  transition: 0.2s;
  svg {
    max-height: 30px;
  }
  svg path,
  circle {
    fill: ${theme.icon};
  }
  polyline {
    stroke: ${theme.icon} !important;
  }
  line {
    stroke: ${theme.icon};
  }

  &:hover {
    background: ${theme.primaryMuted};
    svg path,
    circle {
      fill: ${theme.primary};
    }
    line {
      stroke: ${theme.primary};
    }
  }
  &:disabled {
    opacity: 0.3;
  }

  ${(props) =>
    props.blank &&
    css`
      background: none;
      &:hover {
        background: none;
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${theme.primaryMuted};
      svg path,
      circle {
        fill: ${theme.primary} !important;
      }
    `}

  ${(props) =>
    props.lg &&
    css`
      width: 32px;
      height: 32px;
      border-radius: 6px;
    `};

  ${match('type', 'toolbar')`
      background: #fff;
      border: 1px solid ${theme.border};
      height: auto;
      width: auto;
      padding: 0 10px;

      flex-shrink: 0;
    `};
`;

export const Btn = (props) => (
  <Tooltip
    disabled={!props.title}
    title={props.title || props.disabled}
    delay={400}
    theme="light"
    position="top"
    {...(props.tooltip || {})}
  >
    <StyledBtn {...props} />
  </Tooltip>
);
