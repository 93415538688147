import { getQuerySelector } from '@redux-requests/core';
import { uniqBy } from 'lodash';

const FIND = 'THREADS_SEARCH/FIND';
// Find
const find = (params) => {
  return {
    type: FIND,
    request: {
      method: 'get',
      url: '/threads/search',
      params,
    },
    meta: {
      takeLatest: true,
      onSuccess(request, action, store) {
        const {
          requests: { queries },
        } = store.getState();
        if (queries[FIND].data) {
          const threads = uniqBy(
            [...queries[FIND].data, ...request.data],
            'id'
          );
          request.data = threads;
        }

        return request;
      },
    },
  };
};
const findSelector = getQuerySelector({ type: FIND, multiple: true });

export { find, findSelector, FIND };
