import React, { useState, useEffect } from 'react';
import UserSearch from 'Components/UserSearch';
import { Button, ButtonGroup } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { CloseIcon } from 'Assets';
import { Btn } from 'Components/Styled';
import { get } from 'lodash';

export default function AnnotationsFilters() {
  const history = useHistory();
  const location = useLocation();

  const prms = React.useMemo(() => qs.parse(location.search), []); // eslint-disable-line
  const [userId, setUserId] = useState(prms.user_id);
  const [merchant, setMerchant] = useState(prms['merchant.name']);
  const [reviewerId, setReviewerId] = useState(
    prms['metadata.reviewed_by_user_id']
  );
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const obj = {};
    obj.user_id = userId;
    obj['metadata.reviewed_by_user_id'] = reviewerId;
    if (status === 'annotated') {
      obj['metadata.reviewed_by_user_id[$exists]'] = false;
    } else if (status === 'reviewed') {
      obj['metadata.reviewed_by_user_id[$exists]'] = true;
    }
    if (merchant) {
      obj['merchant.name'] = merchant;
    }
    history.push(`?${qs.stringify(obj)}`);
  }, [userId, reviewerId, status, history, merchant]);

  const annotatorHandler = (user) => setUserId(get(user, 'id'));
  const reviewerHandler = (user) => setReviewerId(get(user, 'id'));
  const handleClear = () => {
    setUserId(null);
    setReviewerId(null);
    setStatus(null);
  };
  return (
    <div className="annotations-filters">
      <div>Annotator:</div>
      <div style={{ width: 150, flexShrink: 0, margin: '0 10px' }}>
        <UserSearch onChange={annotatorHandler} />
      </div>
      <div>Reviewer:</div>
      <div style={{ width: 150, flexShrink: 0, margin: '0 10px' }}>
        <UserSearch onChange={reviewerHandler} disabled={status} />
      </div>
      <div style={{ marginRight: 5 }}>merchant name:</div>
      <input
        type="text"
        className="merchant-input"
        disabled
        value={merchant}
        style={{ maxWidth: 180 }}
        onChange={(e) => setMerchant(e.target.value)}
      />

      <div style={{ marginLeft: 10, marginRight: 10 }}>Status:</div>
      <ButtonGroup size="sm">
        <Button outline={!!status} onClick={() => setStatus(null)}>
          All
        </Button>
        <Button
          outline={status !== 'annotated'}
          onClick={() => setStatus('annotated')}
          disabled={reviewerId}
        >
          Annotated
        </Button>
        <Button
          outline={status !== 'reviewed'}
          onClick={() => setStatus('reviewed')}
          disabled={reviewerId}
        >
          Reviewed
        </Button>
      </ButtonGroup>
      <Btn onClick={handleClear}>
        <CloseIcon title />
      </Btn>
    </div>
  );
}
