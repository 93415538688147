import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'Components/ProtectedRoute';
import Inbox from 'Pages/app/inbox';
import Thread from 'Pages/app/thread';
import Drafts from 'Pages/app/drafts';
import Files from 'Pages/app/files';
import Gpt3 from 'Pages/app/gpt3';
import Search from 'Pages/app/search';
import Outbox from 'Pages/app/outbox';
import Contacts from 'Pages/app/contacts';
import Reports from 'Pages/app/reports';

export default function AppRoutes() {
  return (
    <>
      <ProtectedRoute exact path="/contacts" component={Contacts} />
      <ProtectedRoute path="/drafts" component={Drafts} />
      <ProtectedRoute path="/files" component={Files} />
      <ProtectedRoute exact path="/gpt3" component={Gpt3} />
      <Switch>
        <ProtectedRoute path="/inbox/thread/:threadId" component={Thread} />
        <ProtectedRoute path="/inbox/:path?" component={Inbox} />
      </Switch>
      <ProtectedRoute path="/outbox" component={Outbox} />
      <ProtectedRoute path="/reports" component={Reports} />
      <ProtectedRoute path="/search" component={Search} />
    </>
  );
}
