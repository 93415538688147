import React, { useState } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

/*
 * This component is nested in AppLayout
 * Triggered only when user is not onboarded (user.onboarded_at)
 */

const items = [
  {
    src: '/onboarding-slides/1-with-bg.png',
    key: '1',
    title: 'Intelligent Categorization',
    description: 'Only see the most important emails, cut all the noise.',
    captionText: ' ',
  },
  {
    src: '/onboarding-slides/2-with-bg.png',
    key: '2',
    title: 'Collaborative from the ground-up',
    description:
      'Work together with your whole team, tag and comment all in one place.',
    captionText: ' ',
  },
  {
    src: '/onboarding-slides/3-with-bg.png',
    key: '3',
    title: 'Third Party Extensions',
    description: 'Bring all your popular business applications in one place.',
    captionText: ' ',
  },
];

export default function OnboardingIntro({ dismiss, loading }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeItem = items[activeIndex];

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem key={item.src}>
        <img src={item.src} alt={item.altText} style={{ width: '100%' }} />
        <CarouselCaption
          captionText={item.captionText}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Modal isOpen centered size="lg">
      <div>
        <Carousel
          items={items}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </div>
      <ModalBody className="text-center p-5">
        <h2>{activeItem.title}</h2>
        <p className="mb-5 mt-2 display-2 text-muted">
          {activeItem.description}
        </p>
        <Button color="primary" onClick={dismiss} disabled={loading}>
          <strong>Go to your Productivity Inbox</strong>
        </Button>
      </ModalBody>
    </Modal>
  );
}
