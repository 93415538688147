import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Flex from 'Components/Flex';
import { ReactComponent as Logo } from 'Assets/svg/logo-white.svg';
import styled from 'styled-components';
import './style.scss';

const NylasLogo = styled(Logo)`
  width: 32px;
  height: 32px;
  fill: ${(p) => p.theme.nylas};
`;

const backgrounds = [
  'https://images.unsplash.com/photo-1510906594845-bc082582c8cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1928&q=80',
  'https://images.unsplash.com/photo-1557264322-b44d383a2906?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
  'https://images.unsplash.com/photo-1557264337-e8a93017fe92?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1521114978264-2223e08aa0d2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80',
  'https://images.unsplash.com/photo-1562619425-c307bb83bc42?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80',
];

const bgImage =
  backgrounds[Math.floor(Math.random() * (backgrounds.length - 1))];

export default function AuthLayout({
  children,
  logo = true,
  title = 'Nylas — Productivity Inbox',
}) {
  useEffect(() => {
    document.title = title;
  }, [title]); // eslint-disable-line
  return (
    <div
      className="auth-layout__root"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <Flex center grow>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} sm={12}>
              <div className="auth-layout__dialog">
                {logo && <NylasLogo />}

                {children}
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>
    </div>
  );
}
