import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import './style.scss';
import { useSelector } from 'react-redux';

const Root = styled.div`
  input {
    background: ${(p) => p.theme.bg};
    border-color: ${(p) => p.theme.border};
  }
`;

function SearchBar() {
  const history = useHistory();
  const [input, setInput] = useState('');
  const [cursor, setCursor] = useState(0);
  const accounts = useSelector((s) => s.user.accounts);

  const query = qs.parse(document.location.search);

  React.useEffect(() => {
    setInput(query.q || '');
  }, [query.q]);

  const handleInput = (e) => setInput(e.target.value);

  const handleSearch = (account_id) => {
    history.push(`/search?q=${input}&account_id=${account_id}`);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const account = accounts[cursor];
    handleSearch(account.id);
  };
  const navigateAccounts = (e) => {
    const items = document.getElementsByClassName('search-dropdown__item');
    const input = document.getElementById('search-input');
    console.log(input);
    if (e.keyCode === 38) {
      if (cursor > 0) {
        // up arrow
        setCursor(cursor - 1);
      }
    } else if (e.keyCode === 40) {
      // down arrow
      if (cursor < items.length) {
        setCursor(cursor + 1);
      }
    } else if (e.keyCode === 13) {
      input.blur();
      setCursor(0);
    }
  };

  return (
    <Root className="search-bar">
      <form onSubmit={onSubmit}>
        <input
          type="text"
          onChange={handleInput}
          id="search-input"
          value={input}
          onKeyUp={navigateAccounts}
          placeholder="Search"
        />
        {input && (
          <div className="search-dropdown">
            <div className="search-dropdown__title">
              select account to search for <strong>{input}</strong>:
            </div>
            {accounts.map((account, i) => (
              <div
                key={account.id}
                className={
                  cursor === i
                    ? 'search-dropdown__item__active'
                    : 'search-dropdown__item'
                }
                onMouseEnter={() => setCursor(i)}
                onMouseDown={() => handleSearch(account.id)}
              >
                <Avatar
                  name={account.name || account.email}
                  size={24}
                  round
                  style={{ marginRight: 10 }}
                />
                {account.email}
              </div>
            ))}
          </div>
        )}
      </form>
    </Root>
  );
}

export default SearchBar;
