import client from 'Clients/rest';
import { toast } from 'react-toastify';

const downloadEml = function download(messageId) {
  return async () => {
    try {
      toast(`Downloading EML...`, {
        type: 'info',
        position: 'top-center',
      });

      const response = await client.get(`/download-eml/${messageId}`);

      const href = URL.createObjectURL(new Blob([response]));

      const a = Object.assign(document.createElement('a'), {
        href,
        style: 'display:none',
        download: `${messageId}.eml`,
      });
      document.body.appendChild(a);

      a.click();
      URL.revokeObjectURL(href);
      a.remove();

      toast(`EML downloaded!`, {
        type: 'success',
        position: 'top-center',
      });
    } catch (e) {
      toast(`Could not download EML`, {
        type: 'error',
        position: 'top-center',
      });
      console.error('Error downloading eml', e);
    }
  };
};

export default downloadEml;
