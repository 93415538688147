import React from 'react';
import { Btn } from 'Components/Styled';
import { RefreshIcon, TrashIcon } from 'Assets';
import Flex from 'Components/Flex';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { Label } from 'Components/Tag';
import useAccountInvites from 'Hooks/use-account-invites';

const Root = styled(Flex)`
  padding: 15px 0;
  border-top: 1px solid ${(p) => p.theme.border};
`;

export default function AccountInviteItem({ invite, isAccountOwner }) {
  const hook = useAccountInvites();

  return (
    <Root alignCenter>
      <Flex grow>
        <Avatar size={24} name={invite.name} style={{ marginRight: '10px' }} />
        <div>{invite.email}</div>
      </Flex>
      <Flex alignCenter>
        <Label color="primary" className="px-3">
          {invite.status}
        </Label>
        {isAccountOwner && (
          <>
            <Btn
              title="Resend invite"
              disabled={hook.invitePending}
              onClick={() =>
                hook.patch(
                  invite._id,
                  { action: 'resend' },
                  {},
                  { url: { accountId: invite.account_id } }
                )}
            >
              <RefreshIcon title />
            </Btn>
            <Btn
              title="Remove invite"
              disabled={hook.removePending}
              onClick={() =>
                hook.remove(invite._id, {
                  url: { accountId: invite.account_id },
                })}
            >
              <TrashIcon title />
            </Btn>
          </>
        )}
      </Flex>
    </Root>
  );
}
