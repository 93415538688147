import React from 'react';
import ThreadView from 'Components/ThreadView';
import AppLayout from 'Components/AppLayout';
import './thread.scss';

export default function ThreadPage({ match }) {
  return (
    <AppLayout backButton>
      <ThreadView threadId={match.params.threadId} />
    </AppLayout>
  );
}
