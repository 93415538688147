import ws from 'Clients/ws';
import { create } from 'Store/api/activities/create';
import { PATCH as USER_PATCH } from 'Store/api/users';
import { success } from '@redux-requests/core';
import { toast } from 'Utils/notification';

// Bind Websocket listeners and dispatch actions to store
// because of HMR, it may happen that you see multiple notifications

// TODO: refactor to redux-saga
export default (store) => {
  const activitiesListener = (activity) => {
    const state = store.getState();
    if (activity.user_id !== state.user.id) return;
    store.dispatch(create(activity));
    switch (activity.type) {
      case 'user_mentioned':
        toast(`${activity.from_user_username} mentioned you in a thread.`, {
          url: `/inbox/thread/${activity.thread_id}`,
        });
        break;
      case 'user_assigned':
        toast(`${activity.from_user_username} assigned you to thread.`, {
          url: `/inbox/thread/${activity.thread_id}`,
        });
        break;
      default:
        break;
    }
  };

  const usersListener = (user) => {
    const state = store.getState();
    if (state.user && state.user._id === user._id) {
      store.dispatch({ type: success(USER_PATCH), user, source: 'ws' });
    }
  };

  const messagesListener = (data) => {
    const state = store.getState();
    const userId = state.user.id;
    if (data.user_id === userId || data.object !== 'chat') return;
    console.log('Add message (websicjet)', data); // eslint-disable-line
  };

  ws.service('activities').on('created', activitiesListener);
  ws.service('users').on('patched', usersListener);
  ws.service('messages').on('created', messagesListener);
};
