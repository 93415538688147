import appConfig from 'app.config';
import metrics from 'Clients/metrics';
import { takeLatest, put, select } from 'redux-saga/effects';
import { resetRequests, getQuerySelector } from '@redux-requests/core';
import { find as findUsers } from 'Store/api/users';
import { push } from 'connected-react-router';
import { next } from 'Store/router';
import { identify } from 'react-fullstory';
import createActions from '../common/create-feathers-actions';
import { set as setUser } from '../../user';

const { CREATE, create, createSelector } = createActions({
  name: 'AUTH',
  url: '/auth',
});

export { CREATE, create, createSelector };
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Only for development, can be unreliable (uses localStorage as user object)
export const leanLogin = (user, access_token) => async (dispatch) => {
  try {
    if (access_token)
      localStorage.setItem(appConfig.localStorageTokenKey, access_token);
    const localUser = user || JSON.parse(localStorage.getItem('user'));
    dispatch(setUser(localUser));
    dispatch({ type: LOGIN, payload: localUser });
    return localUser;
  } catch (err) {
    dispatch({ type: LOGOUT });
    return null;
  }
};

export const login = (payload) => async (dispatch) => {
  try {
    const {
      data: { user, access_token },
    } = await dispatch(create(payload));
    localStorage.setItem(appConfig.localStorageTokenKey, access_token);
    dispatch(setUser(user));
    dispatch({ type: LOGIN, payload: user });
    // Fullstory
    identify(user.id, {
      displayName: user.username,
      email: user.email,
    });
    return user;
  } catch (err) {
    dispatch({ type: LOGOUT });
    return null;
  }
};

export const logout = () => async (dispatch) => {
  await metrics.logEvent({ event: 'user-logout' }).catch(() => {});
  dispatch(setUser(null));
  dispatch({ type: LOGOUT });
};

// Side Effects
export function* onLogin({ payload: user }) {
  metrics.logEvent({ event: 'user-login' });
  yield put(next('login'));
  yield put(findUsers({ 'account_ids[$in]': user.account_ids, $limit: 50 }));
}

function* onLogout() {
  localStorage.removeItem('user');
  localStorage.removeItem(appConfig.localStorageTokenKey);
  const user = yield select((s) => s.user);
  if (user) yield put(resetRequests()); // if !user === it's initial login (accesstoken)
  yield put(push('/login'));
}

export default function* saga() {
  yield takeLatest(LOGIN, onLogin);
  yield takeLatest(LOGOUT, onLogout);
}

export const SEND_MAGIC_LINK = 'SEND_MAGIC_LINK';

export const sendMagicLink = (email) => ({
  type: SEND_MAGIC_LINK,
  request: {
    url: '/auth/magic-link',
    data: { email },
    method: 'POST',
  },
  meta: {
    asMutation: false,
  },
});
export const magicLinkSelector = getQuerySelector({ type: SEND_MAGIC_LINK });
