import useThread from 'Hooks/use-thread';

export default function useAssignThread(thread) {
  const { patch } = useThread(thread);

  const handleSave = async (user) =>
    patch(thread.id, {
      action: 'assign-user',
      user_id: user.id,
    });

  const handleRemove = async (user) =>
    patch(thread.id, {
      action: 'unassign-user',
      user_id: user.id,
    });

  const isActive =
    thread.assigned_to_users && thread.assigned_to_users.length > 0;

  const users = thread.assigned_to_users || [];

  return { handleSave, handleRemove, users, isActive };
}
