import { createReducer, createAction } from '@reduxjs/toolkit';
import { get, uniq } from 'lodash';

export const addAction = createAction('ANNOTATIONS_LIST/APPEND');
export const removeAction = createAction('ANNOTATIONS_LIST/REMOVE');
export const updateAction = createAction('ANNOTATIONS_LIST/UPDATE');
export const replaceAction = createAction('ANNOTATIONS_LIST/REPLACE');

const initialState = {
  data: [],
};

export default createReducer(
  initialState, // initial state
  {
    [updateAction]: (state, action) => {
      if (!action.payload) return state;
      return { ...state, ...action.payload };
    },
    [replaceAction]: (state, action) => {
      const messageIds = get(action, 'payload.messageIds');
      state.data = messageIds.map((x) => ({ message_id: x }));
      state.data = uniq(state.data); // unique
      return state;
    },
    [addAction]: (state, action) => {
      state.data.push(action.payload);
      return state;
    },
    [removeAction]: (state, action) => {
      state.data = state.data.filter((x) => x.annotation_id !== action.payload);
      return state;
    },
  }
);
