import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useSelector } from 'react-redux';
import client from 'Clients/rest';
import styles from './styles';

const filterOptions = (options, inputValue) => {
  if (!inputValue) return options;
  const filtered = options.filter((i) => {
    if (i.name && i.name.toLowerCase().includes(inputValue.toLowerCase()))
      return true;
    if (i.email && i.email.toLowerCase().includes(inputValue.toLowerCase()))
      return true;

    return false;
  });
  return filtered;
};

export default function RecipientsPicker({
  onChange,
  options,
  value,
  tabIndex,
}) {
  const { account } = useSelector((s) => s.composer);

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    try {
      if (!account) return Promise.resolve([]);
      const res = await client.get('/autocomplete/contacts', {
        params: { term: inputValue, account_id: account.id },
      });
      return filterOptions(res);
    } catch (e) {
      console.log('Errorin loadOptions -- recipients.jsx', e.message);
      return Promise.resolve([]);
    }
  };

  const isValidNewOption = (str) => {
    if (str && str.includes('@')) return true; // TODO improve!
    return false;
  };

  const getNewOptionData = (inputValue) => {
    return {
      name: inputValue,
      email: inputValue,
    };
  };

  return (
    <AsyncCreatableSelect
      isMulti
      styles={styles}
      placeholder=""
      onChange={onChange}
      options={options}
      value={value}
      getOptionValue={(option) => option.email}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      cacheOptions={false}
      loadOptions={loadOptions}
      defaultOptions
      getNewOptionData={getNewOptionData}
      isValidNewOption={isValidNewOption}
      menuPortalTarget={document.body}
      tabIndex={tabIndex}
    />
  );
}
