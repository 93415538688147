import createActions from 'Store/api/common/create-feathers-actions';
import { getMutationSelector } from '@redux-requests/core';

const MESSAGES = 'MESSAGES';

const {
  FIND,
  find,
  findSelector,
  findSelectorByKey,
  createSelector,
  removeSelector,
  PATCH,
  patch,
  patchSelector,
} = createActions({
  name: MESSAGES,
  url: '/messages',
  meta: {
    find: (params) => ({
      requestKey: params.thread_id,
      requestsCapacity: 1,
    }),
  },
});
const LOAD_MORE = `${MESSAGES}/LOAD_MORE`;

export const loadMore = (params) => ({
  type: LOAD_MORE,
  request: {
    url: '/messages',
    params,
  },
  meta: {
    requestKey: params.thread_id,
    requestsCapacity: 1,
    asMutation: true,
    mutations: {
      [FIND + params.thread_id]: {
        updateData: (current = [], old = []) => {
          return [...current, ...old];
        },
        local: true,
      },
    },
  },
});

const loadMoreSelectorByKey = (id) =>
  getMutationSelector({ type: LOAD_MORE, multiple: true, requestKey: id });

export {
  find,
  FIND,
  findSelector,
  findSelectorByKey,
  createSelector,
  removeSelector,
  patch,
  patchSelector,
  PATCH,
  loadMoreSelectorByKey,
};
