import React from 'react';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export default function ({ component: Component, ...props }) {
  const user = useSelector((state) => state.user);

  const query = qs.stringify({
    entry: document.location.pathname + document.location.search,
  });
  return (
    <Route
      {...props}
      render={(parent) => {
        if (user && user.account_ids.length) {
          return <Component {...parent} />;
        }
        if (user) {
          return <Redirect to="/connect" />;
        }

        return <Redirect to={`/login?${query}`} />;
      }}
    />
  );
}
