import React from 'react';
import './alert-bar.scss';
import { useSelector } from 'react-redux';

export default function AlertBar() {
  const errors = useSelector(({ composer: state }) => ({
    contacts: state.contacts.error,
    accounts: state.accounts.error,
    noAccounts: !state.accounts.data.length && !state.accounts.data.loading,
  }));
  let errorMessage = '';
  if (errors.contacts || errors.accounts) {
    errorMessage = 'Could not load composer data.';
  }
  // else if (errors.noAccounts) {
  //   errorMessage = 'No sender accounts found.';
  // }
  if (!errorMessage) return null;
  return <div className="nylas-composer__alert">{errorMessage}</div>;
}
