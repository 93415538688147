import React from 'react';
import DataTable from 'Components/DataTable';
import AppLayout from 'Components/AppLayout';
import { fetchOutbox, outboxSelector } from 'Store/api/outbox';
import { OutboxIcon } from 'Assets';
import OutboxItem from 'Components/OutboxListItem';

export default function OutboxPage() {
  return (
    <AppLayout>
      <DataTable
        icon={OutboxIcon}
        fetchAction={fetchOutbox}
        selector={outboxSelector}
        component={OutboxItem}
        title="All Accounts"
        description="Outbox"
      />
    </AppLayout>
  );
}
