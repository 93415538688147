import styled from 'styled-components';

export default (SelectComponent) => styled(SelectComponent)`
  .select__control {
    background: ${(p) => p.theme.bg};
    border: none;
    min-height: 32px;
    border: 1px solid transparent;
    &:hover {
      border-color: transparent;
    }
  }
  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid ${(p) => p.theme.primary};
  }
  .select__placeholder {
    color: ${(p) => p.theme.muted};
    font-size: 12px;
  }
  .select__value-container {
    padding: 0px 8px;
  }
  .select__option {
    padding: 8px 10px;
    font-size: 12px;

    &.select__option--is-focused {
      background: ${(p) => p.theme.bg};
      color: ${(p) => p.theme.dark};
    }
  }
  .select__indicator {
    padding: 0px 8px;
    svg {
      width: 16px;
      height: 16px;
      fill: ${(p) => p.theme.muted};
    }
  }
  .select__indicator-separator {
    background: ${(p) => p.theme.border};
  }
`;
