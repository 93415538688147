import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';
import 'react-toggle/style.css'; // for ES6 modules

import './tinymce.scss';

export default function TinyMCE({ onChange, height }) {
  const html = useSelector(({ composer: state }) => state.message.body.data);

  const editorSetup = () => {
    // editor.ui.registry.addButton('customAttachmentButton', {
    //   icon: 'upload',
    //   tooltip: 'Insert attachment',
    //   onAction() {
    //     editor.insertContent("&nbsp;<strong>It's my button!</strong>&nbsp;");
    //   },
    // });
  };
  return (
    <Editor
      value={html}
      apiKey="yoes14bmgp0y9c64v3nf5v0pi4vkma9921u7mmu9972br4l4"
      init={{
        placeholder: 'message...',
        height: height || 300,
        menubar: false,
        statusbar: false,
        content_css: '/tinymce.css',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: `customAttachmentButton | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify \
            bullist numlist outdent indent | removeformat | code preview`,

        setup: editorSetup,
      }}
      onEditorChange={onChange}
    />
  );
}
