import React from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

const Stack = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 5px;
`;

const StyledAvatar = styled(Avatar)`
  margin-left: -3px;
  box-shadow: 0 0 0px 1.4px #fff;

  &:first-child {
    margin-left: 0;
  }
`;

export default function AvatarStack({
  users,
  size = 24,
  max = 10,
  label = '',
  title, // func
  name, // func
}) {
  return (
    <Stack>
      {users &&
        users.slice(0, max).map((user, idx) => {
          let displayTitle = `${label}${user.username} - ${moment(
            user.date,
            'X'
          ).fromNow()}`;

          if (title) {
            displayTitle = title(user);
          }
          let displayName = user.name || user.username;
          if (name) {
            displayName = name(user);
          }
          return (
            <Tooltip
              // options
              key={idx}
              title={displayTitle}
              position="top"
              delay={50}
              trigger="mouseenter"
              style={{ marginLeft: -10 }}
              theme="light"
            >
              <StyledAvatar
                key={user.id || idx}
                name={displayName}
                round
                size={size}
                style={{ zIndex: max - idx }}
              />
            </Tooltip>
          );
        })}
    </Stack>
  );
}
