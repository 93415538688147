import { useMemo } from 'react';
import timeAgo from 'Utils/timeAgo';
/**
 ** @argument ts{Number} -- unix timestamp
 ** @returns {String | Null} -- nicely formatted date
 */

export default (ts) =>
  useMemo(() => {
    return timeAgo(ts);
  }, [ts]);
