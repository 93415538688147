import createActions from '../common/create-feathers-actions';

const {
  FIND,
  PATCH,
  find,
  findSelector,
  get,
  getSelector,
  createSelector,
  patchSelector,
  removeSelector,
  patch,
  create,
  remove,
} = createActions({
  name: 'ACCOUNTS',
  url: '/accounts',
});

export {
  find,
  FIND,
  PATCH,
  findSelector,
  get,
  getSelector,
  createSelector,
  patchSelector,
  removeSelector,
  patch,
  create,
  remove,
};
