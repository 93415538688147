import { call, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash';
import qs from 'query-string';
import routes from './routes';

const PREDICTIVE_ROUTER = '@@predictive-router';

export const next = (name, payload) => ({
  type: `${PREDICTIVE_ROUTER}/${name}/next`,
  payload,
});

export const prev = (name, payload) => ({
  type: `${PREDICTIVE_ROUTER}/${name}/prev`,
  payload,
});

export const go = (name, action, payload) => ({
  type: `${PREDICTIVE_ROUTER}/${name}/${action}`,
  payload,
});

// Predictive Router
function* onNavigate(action) {
  const query = qs.parse(document.location.search, {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
  });
  const router = yield select((s) => s.router);
  const [, type, method] = action.type.split('/');
  const callback = get(routes, [type, method]);
  if (callback) {
    const cb = callback.bind(routes);
    yield call(cb, action.payload, { action, query, router });
  } else {
    console.error( // eslint-disable-line
      `No route callback defined for ${PREDICTIVE_ROUTER}: ${method} ${type} `
    );
  }
}

export default function* routerSaga() {
  yield takeLatest((action) => {
    const test = /^@@predictive-router\//.test(action.type);
    return test;
  }, onNavigate);
}
