import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import authSaga from 'Store/api/auth';
import usersSaga from 'Store/api/users';
import wsSaga from 'Store/api/ws/saga';
import actionbar from './action-bar';
import settingsSidebar from './settings-sidebar';
import ws from './api/ws';
import user from './user';
import userSaga from './user/saga';
import ui from './ui';
import routerSaga from './router';
import { requestsReducer, requestsMiddleware } from './redux-requests.config';
import threadMultiSelect from './thread-select';
import annotations from './studio/annotations';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const defaultMiddleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: {
    ignoredActionPaths: ['meta', 'request'],
    ignoreState: true,
  },
});
// defaultMiddleware.shift(); // remove immutability checking (causes problems w redux-requests)

const store = configureStore({
  reducer: {
    user,
    ui,
    threadMultiSelect,
    actionbar,
    annotations,
    settingsSidebar,
    requests: requestsReducer,
    router: connectRouter(history),
  },
  devTools: true, // process.env.NODE_ENV !== 'production',
  middleware: [
    ...defaultMiddleware,
    sagaMiddleware,
    routerMiddleware(history),
    ...requestsMiddleware,
  ],
});

// Register sagas
sagaMiddleware.run(authSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(routerSaga);
sagaMiddleware.run(wsSaga);

// Register Global listeners
ws(store);

// The store now has redux-thunk added and the Redux DevTools Extension is turned on

export default store;
