import React from 'react';
import styled from 'styled-components';
import { theme } from 'Components/Styled';

const Root = styled.div`
  border: 1px solid ${theme.border};
  border-radius: 4px;
  padding: 10px;
  border-left: 5px solid ${theme.success};
  padding-left: 30px;
`;

const Title = styled.div`
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Body = styled.div`
  word-break: break-all;
`;

export default function SummaryCard({ text }) {
  return (
    <Root>
      <Title>Message summary</Title>
      <Body>{text}</Body>
    </Root>
  );
}
