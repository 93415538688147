import { takeLatest, put } from 'redux-saga/effects';
import { success } from '@redux-requests/core';
import { leanLogin } from 'Store/api/auth';
import { next } from 'Store/router';
import createActions from 'Store/api/common/create-feathers-actions';
import { set as setUser } from 'Store/user';

const {
  FIND,
  find,
  findSelector,
  CREATE,
  PATCH,
  patch,
  patchSelector,
  create,
  createSelector,
} = createActions({
  name: 'USERS',
  url: '/users',
});

export {
  find,
  FIND,
  findSelector,
  CREATE,
  create,
  createSelector,
  patch,
  patchSelector,
  PATCH,
};

// // Side effects
function* onUserCreate(action) {
  const { user, access_token } = action.response.data;
  yield put(leanLogin(user, access_token));
  yield put(next('signup'));
}

function* onUserPatch(action) {
  if (action.source === 'ws') {
    yield put(setUser(action.payload));
  }
  if (action.response) {
    yield put(setUser(action.response.data));
  }
}

export default function* saga() {
  yield takeLatest(success(CREATE), onUserCreate);
  yield takeLatest(success(PATCH), onUserPatch);
}
