import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Spinner,
} from 'reactstrap';
import { Form, Field } from 'react-final-form';
import useAliases from 'Hooks/use-aliases';
import APIError from 'Components/APIError';
import { Input } from 'Components/Form';

export default function AliasModal({ open, toggle, alias, accountId }) {
  const {
    handleForm,
    validate,
    createError,
    patchError,
    createPending,
    patchPending,
  } = useAliases();

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {alias ? 'Edit' : 'Create'} Alias
      </ModalHeader>
      <ModalBody>
        <div className="p-3">
          {/* <Form id={alias._id} alias={alias} close={() => toggle(false)} /> */}
          <Form
            onSubmit={handleForm}
            validate={validate}
            initialValues={{
              account_id: alias ? alias.account_id : accountId,
              id: alias ? alias.id : '',
              name: alias ? alias.name : '',
              email: alias ? alias.email : '',
            }}
            render={({ handleSubmit, invalid }) => (
              <form
                onSubmit={async (payload) => {
                  await handleSubmit(payload);
                  toggle();
                }}
              >
                {/* <h1 className="mb-5 mt-4">Sign In</h1> */}
                {createError && <APIError error={createError} />}
                {patchError && <APIError error={patchError} />}
                {/* For invites only */}
                <Field component={Input} name="id" type="hidden" />

                <Field component={Input} name="account_id" type="hidden" />
                <FormGroup>
                  <Field
                    label="Email"
                    component={Input}
                    name="email"
                    placeholder="Alias email"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    label="Name"
                    component={Input}
                    name="name"
                    placeholder="Alias name"
                  />
                </FormGroup>
                <FormGroup className="mt-4">
                  <Button
                    block
                    disabled={invalid || createPending || patchPending}
                    color="primary"
                    size="lg"
                    type="submit"
                    className="font-weight-bold"
                  >
                    {alias ? 'Save' : 'Add'}
                    {(createPending || patchPending) && (
                      <Spinner color="light" size="sm" className="ml-3" />
                    )}
                  </Button>
                </FormGroup>
              </form>
            )}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
