import React from 'react';
import useAccount from 'Hooks/use-account';
import SignatureItem from 'Components/SignatureItem';
import SettingsTabHeader from 'Components/SettingsTabHeader';

export default function AccountSignatures(props) {
  const {
    match: {
      params: { accountId },
    },
  } = props;
  const { account } = useAccount(accountId);

  return (
    <div>
      <SettingsTabHeader title="Signature">
        {/* <Link to="/settings/templates/create">
          <button className="btn btn-primary">New template</button>
        </Link> */}
      </SettingsTabHeader>

      <SignatureItem account={account} />
    </div>
  );
}
