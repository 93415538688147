import React, { useState } from 'react';
import useAccount from 'Hooks/use-account';
import { PlusIcon } from 'Assets';
import { Button } from 'reactstrap';
import SettingsTabHeader from 'Components/SettingsTabHeader';
import useAccountInvites from 'Hooks/use-account-invites';
import useAccountUsers from 'Hooks/use-account-users';
import AccountInviteItem from 'Components/AccountInviteItem';
import AccountUserItem from 'Components/AccountUserItem';
import InviteUsersModal from 'Components/InviteUsers/Modal';
import { useSelector } from 'react-redux';

export default function AccountSharing(props) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const {
    match: {
      params: { accountId },
    },
  } = props;
  const user = useSelector((state) => state.user);
  const { account } = useAccount(accountId);
  const hookInvites = useAccountInvites({ fetch: true, accountId });
  const hookUsers = useAccountUsers({ fetch: true, accountId });
  return (
    <div>
      <SettingsTabHeader title="Team">
        {account.user_id === user.id && (
          <Button color="light" size="sm" onClick={toggle}>
            <PlusIcon
              title
              style={{ width: 10, height: 10, opacity: 0.5 }}
              className="mr-2"
            />
            Invite
          </Button>
        )}
      </SettingsTabHeader>
      {hookInvites.data && (
        <div>
          <div>Pending</div>
          {hookInvites.data.map((i) => (
            <AccountInviteItem
              key={i._id}
              invite={i}
              isAccountOwner={account.user_id === user.id}
            />
          ))}
        </div>
      )}
      <InviteUsersModal open={open} toggle={toggle} accountId={accountId} />
      {hookUsers.data && (
        <div>
          <div>Accepted</div>
          {hookUsers.data.map((u) => (
            <AccountUserItem
              key={u._id}
              user={u}
              accountId={accountId}
              isOwner={account.user_id === u.id}
              isAccountOwner={account.user_id === user.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}
