import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Avatar from 'react-avatar';
import { MenuIcon } from 'Assets';
import { Spinner } from 'reactstrap';
import Dropdown from 'Components/Dropdown';
import Attachment from 'Components/Attachment';
import Flex from 'Components/Flex';
import styled from 'styled-components';
import useChat from 'Hooks/use-chat';
import Responder from '../ChatResponder/ChatResponder';
import { Btn } from '../Styled';

const Container = styled.div`
  position: relative;
  width: 500px;
  background: ${(p) => p.theme.bgDarker};
  padding: 10px 20px;
  padding-right: 5px;
  border-radius: 6px;
`;

const MarkdownIt = require('markdown-it');

const md = new MarkdownIt();

export default function ChatMessage({ message, style, thread }) {
  const [open, toggle] = useState(false);
  const [editing, setEditing] = useState(false);
  const { remove, removePending, patchPending } = useChat(thread, message);
  const text = useMemo(() => {
    return md.render(message.text);
  }, [message.text]);

  const date = useMemo(() => {
    return moment(message.date, 'X').fromNow();
  }, [message.date]);
  const options = [
    { caption: 'Edit', action: () => setEditing(true) },
    {
      caption: 'Remove',
      action: () => {
        remove(message.id, null, { thread_id: message.thread_id });
      },
    },
  ];
  const from = get(message, 'from') || {};
  const isLoading = removePending || patchPending || message.__id;
  return (
    <Flex
      data-cy="chat-message"
      alignStart
      style={{ ...style, opacity: isLoading ? 0.5 : 1 }}
    >
      <Flex className="mr-2">
        <Avatar name={from.username} size={32} round="6px" />
      </Flex>
      <Flex>
        <Container>
          <Flex justifyBetween alignCenter>
            <strong>{from.username}</strong>
            <Flex alignCenter>
              {isLoading && (
                <Spinner color="success" size="sm" className="mr-2" />
              )}
              <span className="text-muted text-small">{date}</span>
              <Dropdown open={open} toggle={toggle} options={options}>
                <Btn blank onClick={() => toggle(true)} data-cy="chat-more">
                  <MenuIcon />
                </Btn>
              </Dropdown>
            </Flex>
          </Flex>
          {editing && (
            <Responder
              thread={thread}
              message={message}
              close={() => setEditing(false)}
            />
          )}
          {!editing && (
            <div
              className="chat-message__text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {message.uploads &&
            message.uploads.map((file) => (
              <Attachment key={file._id} attachment={file} />
            ))}
        </Container>
      </Flex>
    </Flex>
  );
}
