import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Btn } from 'Components/Styled';
import { EmojiIcon } from 'Assets';
import Flex from 'Components/Flex';

const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 0;
  z-index: 9;
`;

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default function EmojiPicker({ addEmoji }) {
  const [visible, setVisible] = useState(false);
  const ref = useRef();
  const handleAddEmoji = useCallback(
    (emoji) => {
      addEmoji(emoji);
      setVisible(false);
    },
    [addEmoji, setVisible]
  );
  const toggleEmoji = useCallback(
    (e) => {
      e.preventDefault();
      setVisible(!visible);
    },
    [visible, setVisible]
  );
  useOnClickOutside(ref, () => setVisible(false));
  return (
    <Flex center>
      <Btn type="button" title="Add emoji" onClick={toggleEmoji}>
        <EmojiIcon title />
      </Btn>
      {visible && (
        <EmojiWrapper ref={ref}>
          <Picker
            onSelect={handleAddEmoji}
            emojiSize={18}
            showPreview={false}
            showSkinTones={false}
          />
        </EmojiWrapper>
      )}
    </Flex>
  );
}
