import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('UI/UPDATE');
const initialState = {
  composer: false,
  sidemenu: false,
}; // initial state

export default createReducer(
  initialState, // initial state
  {
    [updateAction]: (state, action) => {
      if (!action.payload) return state;
      return { ...state, ...action.payload };
    },
  }
);
