import React from 'react';
import styled from 'styled-components';
import ProtectedRoute from 'Components/ProtectedRoute';
import SettingsAccountHeader from 'Components/SettingsAccountHeader';
import AccountProfile from './profile';
import AccountAliases from './aliases';
import AccountSignatures from './signatures';
import AccountTempaltes from './templates';
import AccountSharing from './sharing';
import AccountRules from './rules';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default function AccountPage(props) {
  const {
    match: {
      params: { accountId },
    },
  } = props;

  return (
    <>
      <SettingsAccountHeader accountId={accountId} />
      <Content>
        {/* Register routes here */}
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/profile"
          component={AccountProfile}
        />
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/signatures"
          component={AccountSignatures}
        />
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/aliases"
          component={AccountAliases}
        />
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/templates"
          component={AccountTempaltes}
        />
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/rules"
          component={AccountRules}
        />
        <ProtectedRoute
          exact
          path="/settings/accounts/:accountId/sharing"
          component={AccountSharing}
        />
      </Content>
    </>
  );
}
