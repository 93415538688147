import React from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${(p) => p.theme.border};
`;

export default function ContactListItem({ item: contact }) {
  return (
    <Root>
      <div style={{ width: 150 }} className="truncated">
        <Avatar
          name={contact.given_name || 'Unknown'}
          size={24}
          round="3px"
          style={{ marginRight: 10 }}
        />
        {contact.given_name || 'Unknown'}
      </div>
      <div style={{ width: 200, flexGrow: 1 }} className="truncated">
        {contact.emails &&
          contact.emails.map((email, idx) => (
            <div key={idx}>
              <span className="email-type">{email.type}</span>
              {email.email}
            </div>
          ))}
      </div>
    </Root>
  );
}

ContactListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
