import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import selectStyle from 'Components/Styled/Select';

const StyledSelect = selectStyle(Select);
export default function AccountSelector({
  onChange,
  placeholder,
  additionalOptions,
  value,
  filter,
  isClearable,
}) {
  const options = useSelector((state) => state.user.accounts);
  let filteredOptions = options;
  if (filter === 'private-inbox') {
    filteredOptions = options.filter((a) => !a.shared);
  } else if (filter === 'shared-inbox') {
    filteredOptions = options.filter((a) => a.shared);
  }

  const handleChange = (data) => {
    if (onChange) onChange(data);
  };

  const account = options.find((a) => a.id === value);

  return (
    <StyledSelect
      className="basic-single flex-grow-1"
      classNamePrefix="select"
      isLoading={false}
      isClearable={isClearable}
      placeholder={placeholder || 'Account'}
      name="acount-picker"
      options={[...filteredOptions, ...(additionalOptions || [])]}
      style={{
        menu: (own) => ({ ...own, zIndex: 500 }),
      }}
      value={account || null}
      getOptionValue={(x) => x.id}
      getOptionLabel={
        (x) => `${x.email} ${x.shared ? ' (shared)' : ' (private)'}`
        // eslint-disable-next-line react/jsx-curly-newline
      }
      onChange={handleChange}
    />
  );
}
