import { createReducer, createAction } from '@reduxjs/toolkit';

export const set = createAction('USER/SET');
export const unset = createAction('USER/UNSET');

const initialState = null;

export default createReducer(initialState, {
  [set]: (state, action) => {
    return { ...state, ...action.payload }; // merge
  },
  [unset]: () => {
    return initialState;
  },
});
