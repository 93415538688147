import React from 'react';
import './subject.scss';

export default function Subject({ subject, onChange }) {
  return (
    <input
      type="text"
      value={subject}
      placeholder="Subject"
      onChange={(e) => onChange(e.target.value)}
      name="nylas-composer-subject"
      className="nylas-composer__subject"
    />
  );
}
