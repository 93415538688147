import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Flex from 'Components/Flex';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';
import StringEditor from 'Components/StringEditor';
import { set } from 'Store/user';
import { patch } from 'Store/api/users';

export default function GeneralSettings() {
  const user = useSelector((s) => s.user);
  const dispatch = useDispatch();

  const handleUpdate = async ({ name, value }) => {
    try {
      const res = await dispatch(patch(user.id, { [name]: value }));
      if (res.error) throw new Error('failed to update');
      dispatch(set({ ...user, ...res.data }));
    } catch (e) {
      toast.error('Error updating user');
      throw e;
    }
  };

  const enableOnboarding = () => {
    const onboarding_steps = {};
    Object.keys(user.onboarding_steps || {}).forEach((key) => {
      onboarding_steps[key] = false;
    });
    delete onboarding_steps._id;
    dispatch(patch(user.id, { onboarded_at: null, onboarding_steps }));
  };

  return (
    <>
      <h1 className="mb-5">General</h1>
      <Row>
        <Col md={8}>
          <Flex alignCenter>
            <Avatar
              name={user.username}
              size={48}
              round="6px"
              style={{ marginRight: 10 }}
            />
            <div>
              <strong>{user.username}</strong>
              <br />
              <span className="text-muted">{user.email}</span>
            </div>
          </Flex>

          <div className="my-5" />

          <StringEditor
            name="username"
            caption="Username"
            value={user.username}
            onChange={handleUpdate}
          />
          <StringEditor
            name="name"
            placeholder="your name (for display purposes)"
            caption="name"
            value={user.name}
            onChange={handleUpdate}
          />
          <StringEditor
            name="email"
            type="email"
            caption="Email"
            value={user.email}
            onChange={handleUpdate}
          />
          <StringEditor
            caption="password"
            name="password"
            type="password"
            value=""
            placeholder="************"
            onChange={handleUpdate}
          />
          {user.onboarded_at && (
            <Button
              size="sm"
              color="light"
              className="mt-5"
              onClick={enableOnboarding}
            >
              Enable onboarding
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
