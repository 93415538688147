import React from 'react';
import { Spinner } from 'reactstrap';
import { MentionsInput, Mention } from 'react-mentions';
import { CloseIcon, SendIcon, AttachmentIcon } from 'Assets';
import useChat from 'Hooks/use-chat';
import styled from 'styled-components';
import AttachmentUpload from 'Components/AttachmentUpload';
import mentionStyle from './mentionStyle';
import { Btn } from '../Styled';
import EmojiPicker from './EmojiPicker';
import './chat-responder.scss';

const AttachmentContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='file'] {
    position: absolute;
    left: 0;
    display: none;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  &:hover svg {
    fill: #595d6e;
  }
`;

export default function Responder({ thread, message, close }) {
  const hook = useChat(thread, message, { close });
  const {
    onSubmit,
    text,
    createPending,
    patchPending,
    files,
    inputRef,
    handleInputChange,
    getUsers,
    insertEmoji,
    uploadFile,
    removeFile,
    uploadRef,
    handleUploadClick,
    uploading,
    removingFile,
    isEdit,
  } = hook;
  const isLoading = createPending || uploading || removingFile || patchPending;
  return (
    <form className="chat-responder" onSubmit={onSubmit}>
      {files.map((file) => (
        <AttachmentUpload
          key={file._id}
          file={file}
          removeFile={removeFile}
          loading={isLoading}
        />
      ))}
      <MentionsInput
        value={text}
        inputRef={inputRef}
        singleLine
        className="chat-mention"
        onChange={handleInputChange}
        placeholder="Enter your chat message here..."
        allowSuggestionsAboveCursor
        style={mentionStyle}
      >
        <Mention
          trigger="@"
          data={getUsers}
          markup="[@__display__](#__id__)"
          appendSpaceOnAdd
        />
      </MentionsInput>
      <div className="chat-responder__buttons">
        <EmojiPicker addEmoji={insertEmoji} />
        {!message && (
          <AttachmentContainer>
            <Btn title="upload file" type="button" onClick={handleUploadClick}>
              <AttachmentIcon />
            </Btn>
            <input
              type="file"
              onChange={uploadFile}
              ref={uploadRef}
              multiple={false}
            />
          </AttachmentContainer>
        )}
        {message && (
          <Btn title="Close" type="button" onClick={close}>
            <CloseIcon title />
          </Btn>
        )}
        <Btn
          title={isEdit ? 'Edit' : 'Send'}
          type="submit"
          onClick={onSubmit}
          disabled={text === '' || isLoading}
        >
          {isLoading ? <Spinner size="sm" color="primary" /> : <SendIcon />}
        </Btn>
      </div>
    </form>
  );
}
