import React, { useState } from 'react';
import AliasItem from 'Components/AliasItem';
import SettingsTabHeader from 'Components/SettingsTabHeader';
import SettingsEmptyState from 'Components/SettingsEmptyState';
import { PlusIcon } from 'Assets';
import { Button, Spinner } from 'reactstrap';
import useAliases from 'Hooks/use-aliases';
import AliasModal from 'Components/AliasModal';
import useAccount from 'Hooks/use-account';

import Flex from 'Components/Flex';

export default function AccountAliases(props) {
  const { accountId } = props.match.params;
  const hook = useAliases({ accountId, fetch: true });
  const { isOwner } = useAccount(accountId);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <SettingsTabHeader title="Aliases">
        <Flex alignCenter>
          {hook.findPending && <Spinner color="primary" className="mr-2" />}
          {isOwner && (
            <Button color="light" size="sm" onClick={toggle}>
              <PlusIcon
                title
                style={{ width: 10, height: 10, opacity: 0.5 }}
                className="mr-2"
              />
              Add new
            </Button>
          )}
        </Flex>
      </SettingsTabHeader>
      <AliasModal open={modal} toggle={toggle} accountId={accountId} />
      {hook.data &&
        hook.data.map((a) => (
          <AliasItem refresh={fetch} key={a._id} alias={a} isOwner={isOwner} />
        ))}
      {hook.data.length === 0 && !hook.findPending && (
        <SettingsEmptyState
          title="No Aliases"
          subtitle={isOwner && 'Click below to add a new alias.'}
        >
          {isOwner && (
            <Button color="primary" onClick={toggle}>
              Add new
            </Button>
          )}
        </SettingsEmptyState>
      )}
    </>
  );
}
