import { useMemo, useEffect } from 'react';
import validator from 'Utils/validator';
import { useDispatch, useSelector } from 'react-redux';
import { create, createSelector, CREATE } from 'Store/api/users';
import useRouter from 'Hooks/use-router';
import { get } from 'lodash';
import qs from 'query-string';
import { resetRequests } from '@redux-requests/core';

export default function useRegister() {
  const { prev } = useRouter();
  const { loading, error } = useSelector(createSelector);
  const dispatch = useDispatch();
  const validate = validator({
    name: { length: { minimum: 2, maximum: 60 } },
    invite_code: { presence: true },
    username: {
      presence: true,
      length: { minimum: 3, maximum: 30 },
    },
    email: {
      presence: true,
      email: true,
    },
    password: { presence: true, length: { minimum: 8 } },
    confirmPassword: {
      equality: 'password',
      presence: true,
    },
  });
  useEffect(() => {
    // Reset request on mount if user tries to signup twice in session
    dispatch(resetRequests([CREATE]));
  }, [dispatch]);

  const query = useMemo(() => {
    return qs.parse(document.location.search);
  }, []);

  const initialValues = useMemo(
    () => ({
      invite_code: query.invite_code,
      name: query.name,
      email: query.email,
    }),
    [query.invite_code, query.name, query.email]
  );

  const onSubmit = async (data) => {
    const res = await dispatch(create(data, { auth: true }));
    return get(res, 'error.errors');
  };

  const hasEmail = !!query.email;

  const back = () => prev('signup');

  return {
    onSubmit,
    validate,
    initialValues,
    loading,
    error,
    back,
    hasEmail,
  };
}
