import validator from 'validate.js';
import { isEmpty } from 'lodash';

validator.validators.array = (arrayItems, itemConstraints) => {
  const arrayItemErrors = arrayItems.map((item) => {
    const error = validator(item, itemConstraints);
    if (error) return error;
    return undefined;
  });

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors;
};

export default (constraints) => (data) => validator(data, constraints);
