import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import is from 'styled-is';
import { Spinner } from 'reactstrap';
import Flex from 'Components/Flex';
import { AttachmentIcon, ErrorIcon, StarIcon } from 'Assets';
import AvatarStack from 'Components/AvatarStack';
import useThread from 'Hooks/use-thread';
import { Btn, Checkbox } from 'Components/Styled';
import { Label as Tag } from 'Components/Tag';
import ThreadAnnotationStatus from 'Components/studio/ThreadAnnotationStatus';
import HoverActions from './HoverActions';

const Root = styled(Flex)`
  // debug
  /* border: 2px solid red;
  z-index: -20;
  pointer-events: none;  */

  padding: 6px 15px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom: 1px solid ${(p) => p.theme.border};
  &:hover {
    background: ${(p) => p.theme.bg};
    border-top: 1px solid ${(p) => p.theme.border};
    border-bottom: 1px solid ${(p) => p.theme.border};
    .thread-hover-actions {
      display: flex;
    }
  }
  ${is('selected')`
    background: ${(p) => p.theme.bg};
    border-bottom: 1px solid ${(p) => p.theme.border};
  `};
  @media ${(p) => p.theme.breakpoints.belowMd} {
    padding: 15px 15px;
  }
`;

const Actions = styled(Flex)`
  width: 30px;
`;

const ActionError = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  fill: ${(p) => p.theme.danger};
`;

const StarContainer = styled(Flex)`
  @media ${(p) => p.theme.breakpoints.belowMd} {
    display: none;
  }
`;

export const Star = styled(StarIcon)`
  flex-shrink: 0;
  path {
    fill: ${(p) =>
      p.starred
        ? `${p.theme.primary} !important`
        : `${p.theme.bgDarker} !important;`};
  }
  @media ${(p) => p.theme.breakpoints.belowMd} {
    display: none;
  }
`;

const Sender = styled(Flex)`
  width: 15%;
  flex-shrink: 0;
  align-self: stretch;
  padding-left: 10px;
  ${is('unread')`
    font-weight: bold;
  `};
  @media ${(p) => p.theme.breakpoints.belowMd} {
    width: 25%;
  }
`;

const Body = styled(Flex)`
  align-self: stretch;
  padding: 0 10px;

  ${is('unread')`
    font-weight: bold;
    span:not(.thread-subject) {
      font-weight: normal;
    }
  `};
`;

const TagsContainer = styled(Flex)`
  width: 170px;

  @media ${(p) => p.theme.breakpoints.belowMd} {
    display: none;
  }
`;

const AssignmentsContainer = styled(Flex)`
  width: 100px;
  @media ${(p) => p.theme.breakpoints.belowMd} {
    display: none;
  }
`;

const AttachmentContainer = styled(Flex)`
  @media ${(p) => p.theme.breakpoints.belowMd} {
    display: none;
  }
`;

const Attachment = styled(AttachmentIcon)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  path {
    fill: ${(p) => p.theme.muted};
  }
`;

const DateContainer = styled(Flex)`
  width: 80px;
  font-size: 12px;
`;

export default function ThreadItem({ thread, loading }) {
  const {
    patch,
    sender,
    patchPending,
    patchError,
    clearPatchError,
    date,
    snippet,
    open,
    select,
    isSelected,
    batchPending,
    tags,
  } = useThread(thread);

  const star = () =>
    patch(thread.id, {
      action: `mark-as-${thread.starred ? 'unstarred' : 'starred'}`,
    });

  return (
    <Root data-cy="thread-list-item" alignCenter selected={isSelected}>
      {/* Chekcbox / Loading spinner */}
      <Actions noShrink>
        {!(patchPending || batchPending) && !patchError && (
          <Checkbox
            data-cy="thread-list-item-checkbox"
            checked={isSelected ? 1 : 0}
          >
            <input type="checkbox" onClick={select} />
            <span />
          </Checkbox>
        )}
        {(patchPending || batchPending) && !patchError && (
          <Flex noShrink>
            <Spinner size="sm" color="light" />
          </Flex>
        )}
        {patchError && (
          <Flex noShrink>
            <ReactTooltip
              id={`thread-${thread.id}`}
              place="right"
              effect="solid"
            />
            <ActionError
              title
              onClick={clearPatchError}
              data-tip={patchError.message}
              data-for={`thread-${thread.id}`}
            />
          </Flex>
        )}
      </Actions>

      {/* Star */}
      <StarContainer noShrink>
        <Btn blank onClick={star} title={thread.starred ? 'Unstar' : 'Star'}>
          <Star starred={thread.starred ? 1 : 0} title />
        </Btn>
      </StarContainer>
      {/* Sender */}
      <Sender
        className="truncated"
        unread={thread.unread}
        alignCenter
        onClick={open}
      >
        <span className="truncated">{sender && sender.name}</span>
      </Sender>
      {/* Attachment */}
      <AttachmentContainer
        noShrink
        onClick={open}
        style={{ visibility: thread.has_attachments ? 'visible' : 'hidden' }}
      >
        <Btn blank title="Thread has attachment(s)">
          <Attachment title />
        </Btn>
      </AttachmentContainer>
      {/* Body */}
      <Body
        grow
        className="truncated"
        alignCenter
        unread={thread.unread}
        onClick={open}
      >
        {/* Annotation Status */}
        {!loading && <ThreadAnnotationStatus thread={thread} />}
        <span className="thread-subject" data-cy="thread-list-subject">
          {thread.subject}
        </span>
        <span className="text-muted truncated ml-2 text-small">{snippet}</span>
      </Body>
      {/* Assigned users */}
      <AssignmentsContainer noShrink justifyEnd className="px-4" onClick={open}>
        {thread.assigned_to_users && (
          <AvatarStack
            size={22}
            users={thread.assigned_to_users}
            max={5}
            label="assigned to "
          />
        )}
      </AssignmentsContainer>
      {/* Tags */}
      <TagsContainer onClick={open} noShrink justifyEnd className="px-4">
        {tags.length > 0 && <Tag tag={tags[0]} maxChars={25} />}
      </TagsContainer>
      <DateContainer justifyEnd className="text-muted" noShrink>
        {date}
      </DateContainer>
      {!isSelected && <HoverActions thread={thread} />}
    </Root>
  );
}
