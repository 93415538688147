import React from 'react';
import classNames from 'classnames';
import Tip from './Tip';

const Input = ({
  r,
  caption,
  name,
  text,
  description,
  type = 'text',
  active,
  ...other
}) => {
  return (
    <label htmlFor={name} className={classNames({ active })}>
      <Tip text={text} />
      {caption}
      {description && <small className="input-info">{description}</small>}
      <input type={type} {...r(name)} {...other} />
    </label>
  );
};

export default Input;
