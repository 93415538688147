import React from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { theme } from 'Components/Styled';

const Root = styled.div`
  padding: 10px;
  overflow-x: auto;
  max-height: 40vh;
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: 1.5;
  .caption {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    color: ${theme.muted};
    margin-right: 10px;
    width: 50px;
    text-align: right;
    flex-shrink: 0;
    line-height: 1.8;
  }
  .value {
    color: ${theme.dark};
    font-size: 12px;
    white-space: nowrap;
  }
`;

export default function MessageInfo({ message, children, className }) {
  const parseParticipant = (p) => {
    if (p.name) return `${p.name} <${p.email}>`;
    return p.email;
  };

  return (
    <Tooltip
      delay={500}
      theme="light"
      interactive
      html={
        <Root>
          <Item>
            <div className="caption">from:</div>
            <div className="value">{parseParticipant(message.from)}</div>
          </Item>
          <Item>
            <div className="caption">to:</div>
            <div>
              {message.to &&
                message.to.map((p, idx) => (
                  <div className="value" key={idx}>
                    {parseParticipant(p)}
                  </div>
                ))}
            </div>
          </Item>
          <Item>
            <div className="caption">cc:</div>
            <div>
              {message.cc &&
                message.cc.map((p, idx) => (
                  <div className="value" key={idx}>
                    {parseParticipant(p)}
                  </div>
                ))}
            </div>
          </Item>
          <Item>
            <div className="caption">bcc:</div>
            <div>
              {message.bcc &&
                message.bcc.map((p, idx) => (
                  <div className="value" key={idx}>
                    {parseParticipant(p)}
                  </div>
                ))}
            </div>
          </Item>
        </Root>
      }
    >
      <div className={className}>{children}</div>
    </Tooltip>
  );
}
