import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { toast as noti } from 'Utils/notification';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ws from 'Clients/ws';

const ChatToast = ({ message }) => {
  const history = useHistory();
  const open = () => {
    history.push(`/inbox/thread/${message.thread_id}`);
  };
  return (
    <div>
      {message.sender.username} just responded to chat
      <br />
      <button className="btn btn-sm btn-info" onClick={open}>
        View
      </button>
    </div>
  );
};

const MentionToast = ({ data }) => {
  const history = useHistory();
  const open = () => {
    history.push(`/inbox/thread/${data.thread_id}`);
  };
  return (
    <div>
      {data.username} mentioned you in chat
      <br />
      <button className="btn btn-sm btn-info" onClick={open}>
        View
      </button>
    </div>
  );
};

export default function Toaster() {
  const user = useSelector((s) => s.user);

  useEffect(() => {
    ws.service('chat').on('created', (message) => {
      if (message.user_id === user.id) return;
      if (document.location.href.includes(message.thread_id)) return;

      toast(<ChatToast message={message} />);
      noti(`${message.sender.username} just responded to chat`, {
        url: `/inbox/thread/${message.thread_id}`,
      });
    });

    ws.service('chat/mention').on('created', (data) => {
      toast(<MentionToast data={data} />);
      noti(`${data.username} mentioned you in chat`, {
        url: `/inbox/thread/${data.thread_id}`,
      });
    });

    const whMessagesListener = (data) => {
      // "Received"
      if (data.status === 'received') {
        const params = {};
        if (data.thread_id) params.url = `/inbox/thread/${data.thread_id}`;
        noti(`New message received: ${data.subject || ''}`);
      }
    };
    ws.service('webhooks/messages').on('created', whMessagesListener);

    return () => {
      // Cleanup
      ws.service('chat').off('created');
      ws.service('chat/mention').off('created');
      ws.service('webhooks/messages').off('created', whMessagesListener);
    };
    }, []); // eslint-disable-line

  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
