import React from 'react';
import { Btn } from 'Components/Styled';
import { TrashIcon } from 'Assets';
import Flex from 'Components/Flex';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import useAccountUsers from 'Hooks/use-account-users';

const Root = styled(Flex)`
  padding: 15px 0;
  border-top: 1px solid ${(p) => p.theme.border};
`;

export default function AccountUserItem({
  user,
  isOwner,
  accountId,
  isAccountOwner,
}) {
  const hook = useAccountUsers();
  return (
    <Root alignCenter>
      <Flex grow>
        <Avatar
          size={24}
          name={user.name || user.username}
          style={{ marginRight: '10px' }}
        />
        <div>
          {user.name || user.username}{' '}
          <span className="text-primary">{isOwner && 'Owner'}</span>
        </div>
      </Flex>
      <Flex alignCenter>
        {isAccountOwner && (
          <Btn
            title="Remove user"
            disabled={hook.removePending}
            onClick={() => hook.remove(user.id, { url: { accountId } })}
          >
            <TrashIcon title />
          </Btn>
        )}
      </Flex>
    </Root>
  );
}
