import moment from 'moment';
import { merge } from 'lodash';

export default (ts, options) => {
  options = merge(
    {
      // defaults, relative to "now"
      formats: {
        thisOrNextWeek: 'ddd h A',
        tomorrow: '[Tomorrow] h A',
        today: 'h:mm A',
        yesterday: '[Yesterday]',
        week: 'ddd',
        month: 'M/D',
        year: 'M/D',
        older: 'M/D/YYYY',
      },
    },
    options
  );

  if (typeof ts === 'number') {
    ts = moment(ts, 'X');
  } else {
    ts = moment(ts);
  }

  if (!moment.isMoment(ts)) {
    return '';
  }

  const { formats } = options;

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const week = moment().subtract(9, 'days').startOf('day');
  const year = moment().startOf('year');
  const tomorrow = moment().add(1, 'day').startOf('day');
  const thisOrNextWeek = moment().add(2, 'days').startOf('day');
  const afterNextWeek = moment().add(7, 'days').startOf('day');
  let f;
  if (ts.isSameOrAfter(afterNextWeek)) {
    f = 'older';
  } else if (ts.isSameOrAfter(thisOrNextWeek)) {
    f = 'thisOrNextWeek';
  } else if (ts.isSameOrAfter(tomorrow)) {
    f = 'tomorrow';
  } else if (ts.isSameOrAfter(today)) {
    f = 'today';
  } else if (ts.isSameOrAfter(yesterday)) {
    f = 'yesterday';
  } else if (ts.isSameOrAfter(week)) {
    f = 'week';
  } else if (ts.isSameOrAfter(year)) {
    f = 'year';
  } else {
    f = 'older';
  }

  return ts.format(formats[f]);
};
