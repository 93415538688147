import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  Spinner,
} from 'reactstrap';
import { Form, Field } from 'react-final-form';
import useEmailTemplates from 'Hooks/use-email-templates';
import APIError from 'Components/APIError';
import { Input, Editor } from 'Components/Form';

export default function TemplateModal({
  open,
  toggle,
  template,
  accountId,
  isOwner,
}) {
  const {
    handleForm,
    validate,
    createError,
    patchError,
    createPending,
    patchPending,
  } = useEmailTemplates();
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {isOwner && template && 'Edit '}
        {isOwner && !template && 'Create '}
        {!isOwner && 'Preview '}template
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={handleForm}
          validate={validate}
          initialValues={{
            account_id: template ? template.account_id : accountId,
            body: template ? template.body : '',
            name: template ? template.name : '',
            id: template ? template.id : '',
          }}
          render={({ handleSubmit, invalid }) => (
            <form
              onSubmit={async (payload) => {
                await handleSubmit(payload);
                toggle();
              }}
            >
              {createError && <APIError error={createError} />}
              {patchError && <APIError error={patchError} />}
              <Field component={Input} name="id" type="hidden" />

              <Field component={Input} name="account_id" type="hidden" />
              <FormGroup>
                <Field
                  label="Name"
                  component={Input}
                  name="name"
                  disabled={!isOwner}
                  placeholder="Email Template name"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  label="Body"
                  component={Editor}
                  disabled={!isOwner}
                  name="body"
                  placeholder="Email Template body"
                />
              </FormGroup>
              {isOwner && (
                <FormGroup className="mt-4">
                  <Button
                    block
                    disabled={invalid || createPending || patchPending}
                    color="primary"
                    size="lg"
                    type="submit"
                    className="font-weight-bold"
                  >
                    {template ? 'Save' : 'Create'}
                    {(createPending || patchPending) && (
                      <Spinner color="light" size="sm" className="ml-3" />
                    )}
                  </Button>
                </FormGroup>
              )}
            </form>
          )}
        />
      </ModalBody>
    </Modal>
  );
}
