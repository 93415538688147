import React from 'react';
import DataTable from 'Components/DataTable';
import AppLayout from 'Components/AppLayout';
import { find, findSelector } from 'Store/api/contacts';
import Contact from 'Components/ContactListItem';
import { UserIcon } from 'Assets';

export default function ContactsPage() {
  return (
    <AppLayout>
      <DataTable
        icon={UserIcon}
        fetchAction={find}
        selector={findSelector}
        component={Contact}
        title="All Accounts"
        description="Contacts"
      />
    </AppLayout>
  );
}
