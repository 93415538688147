import { toast } from 'react-toastify';
import useMessageBody from 'Hooks/use-message-body';
import { parse, undo } from 'Store/api/external/chatter';
import { useDispatch } from 'react-redux';

const MarkdownIt = require('markdown-it');

const md = new MarkdownIt();
const mdOptions = {
  html: true,
  linkify: true,
  typographer: true,
};

export default function useMessage(message) {
  const dispatch = useDispatch();
  // Chatter markdown
  message.body = message.chatter_body
    ? md.render(message.chatter_body)
    : message.body;

  message.signature = message.chatter_signature
    ? md.render(message.chatter_signature, mdOptions)
    : null;

  const { html, filteredFiles } = useMessageBody(message);
  const { id, thread_id } = message;

  const chatter = async () => {
    await dispatch(parse({ message_id: id, thread_id }));
    toast('Message signature parsed');
  };

  const undoChatter = async () => {
    await dispatch(
      undo(id, {
        thread_id,
        chatter_body: null,
        chatter_signature: null,
      })
    );
  };

  return { chatter, html, filteredFiles, undoChatter };
}
