import { createReducer, createAction } from '@reduxjs/toolkit';

export const addAction = createAction('COMPOSER/FILES/ADD');
export const removeAction = createAction('COMPOSER/FILES/REMOVE');

const initialState = { data: [], loading: false, error: false };

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [addAction]: (state, action) => {
      state.data = [...state.data, ...action.payload];
    },
    [removeAction]: (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
  }
);
