import React from 'react';
import Avatar from 'react-avatar';
import { Button, Spinner } from 'reactstrap';
import AuthLayout from 'Components/AuthLayout';
import APIError from 'Components/APIError';
import useInvite from 'Hooks/use-invite';
import Loader from 'Components/NylasLoader';

export default function InvitePage() {
  const {
    data,
    user,
    actions,
    acceptInvite,
    error,
    loading,
    initialLoading,
  } = useInvite();

  // Loading the request
  if (initialLoading)
    return (
      <AuthLayout logo={false}>
        <div className="text-center my-5">
          <Loader loading />
        </div>
      </AuthLayout>
    );
  // Error on initial load
  if ((!initialLoading && !data) || (data && data.status !== 'pending')) {
    return (
      <AuthLayout logo={false}>
        <h1 className="text-center my-3">Error!</h1>
        <p className="text-center">Invite does not exist or it is expired</p>
        {!user && (
          <Button
            block
            size="lg"
            className="mt-1"
            outline
            color="primary"
            onClick={actions.skip}
          >
            Go to Login
          </Button>
        )}
        {user && (
          <Button
            block
            size="lg"
            className="mt-1"
            outline
            color="primary"
            onClick={actions.skip}
          >
            Go to app
          </Button>
        )}
      </AuthLayout>
    );
  }
  const name = data.user.name || data.user.username;

  return (
    <AuthLayout logo={false}>
      <div className="text-center">
        <Avatar name={name} size={48} round="6px" className="mb-2" />
        <h1 className="mb-4 mt-4">You have been invited by {name}</h1>

        {/* Actions depending if logged in */}
        {user && (
          <>
            {error && (
              <APIError error={error} entity="Invite" useErrorMessage />
            )}
            <p>
              Do you want to add <strong>{data.account.email_address}</strong>{' '}
              to your productivity inbox?
            </p>
            <Button
              block
              className="mt-5"
              color="primary"
              size="lg"
              disabled={loading}
              onClick={acceptInvite}
            >
              Accept
              {loading && <Spinner color="light" size="sm" className="ml-3" />}
            </Button>
            <Button
              block
              size="lg"
              onClick={actions.reject}
              disabled={loading}
              className="mt-1"
              outline
              color="danger"
            >
              Reject
            </Button>
            <div className="text-muted mt-3">
              Logged in as <strong>{user.username}</strong>
            </div>
          </>
        )}
        {!user && (
          <>
            <p>Login or signup to accept the invite.</p>
            <Button
              block
              onClick={actions.signup}
              className="mt-5"
              size="lg"
              color="primary"
            >
              Signup
            </Button>
            <Button
              block
              size="lg"
              onClick={actions.login}
              className="mt-1"
              outline
              color="primary"
            >
              Login
            </Button>
          </>
        )}
      </div>
    </AuthLayout>
  );
}
