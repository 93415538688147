import React from 'react';
import { theme } from 'Components/Styled';
import classnames from 'classnames';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Chevron } from 'Assets/metronic/Navigation/Angle-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateAction as setUi } from 'Store/ui';

const LinkContainer = styled.div``;
const LinkContainerFixed = styled.div`max-height:200px; overflow:auto;`;

const Link = styled(NavLink)`
  display: block;
  padding: 12px 5px;
  border-radius: 3px;
  color: #9494a8;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  &:hover {
    text-decoration: none;
    background: #262638;
    color: #9494a8;
  }
  &.active {
    text-decoration: none;
    color: ${theme.primary};
    background: #2b2b42;
    color: #fff;
    svg path,
    circle {
      fill: #3699ff;
      stroke: #3699ff;
    }
  }
  .expander {
    svg,
    path,
    circle {
      fill: ${theme.muted} !important;
    }
  }
  &.sub {
    opacity: 0.8;
    padding-left: 15px;
    font-size: 12px;
    &.active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
    .menu-icon {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path,
    circle {
      fill: ${theme.muted};
      stroke: ${theme.muted};
    }
  }
`;

const SubmenuIcon = styled(Chevron)`
  transform: rotate(90deg);
  &.active {
    transform: rotate(-90deg);
  }
  svg {
    width: 15px;
    margin-right: 0 !important;
  }
`;

export default function SidemenuNavigation({
  links,
  className,
  linkState,
  setLinkState,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const ui = useSelector((s) => s.ui);
  const handleClick = (e, id, isActive) => {
    // Close sidemenu (affects only mobile repsonsive)
    if (ui.sidemenu) dispatch(setUi({ sidemenu: false }));

    if (!linkState) return;
    const currentVal = linkState[id];

    const active = isActive(null, location);
    let val = false;
    if (currentVal && active) {
      val = false;
    } else if (!currentVal && !active) {
      val = true;
    } else {
      val = true;
    }

    const newState = { ...linkState, [id]: val };
    setLinkState(newState);
    localStorage.setItem('sidemenu-data', JSON.stringify(newState));
  };
  React.useEffect(() => {
    const data = localStorage.getItem('sidemenu-data');
    if (!data) return;
    try {
      const newState = JSON.parse(data);
      setLinkState(newState);
    } catch (e) {
      // NO OP
    }
  }, [setLinkState]);
  return (
    <>
      {links.map((link) => {
        if (link.type === 'divider') return <hr key={link.id} />;
        const Icon = link.icon;
        return (
          <LinkContainer key={link.id}>
            <Link
              to={link.to}
              className={className}
              isActive={link.active}
              onClick={(e) => handleClick(e, link.id, link.active)}
            >
              <span className="d-flex align-items-center truncated">
                {Icon && (
                  <Icon title style={{ flexShrink: 0 }} className="menu-icon" />
                )}
                {link.caption}
              </span>
              <span style={{ width: 15 }} className="expander">
                {link.items && (
                  <SubmenuIcon
                    title
                    className={classnames({ active: !!linkState[link.id] })}
                  />
                )}
              </span>
            </Link>
            {link.items && linkState[link.id] && (
              <LinkContainerFixed>
                <SidemenuNavigation links={link.items} className="sub" />
              </LinkContainerFixed>
            )}
          </LinkContainer>
        );
      })}
    </>
  );
}
