import { getQuerySelector, getMutationSelector } from '@redux-requests/core';

const name = 'ALIASES';
const base = '/accounts';
const GET = `${name}/GET`;
const FIND = `${name}/FIND`;
const CREATE = `${name}/CREATE`;
const PATCH = `${name}/PATCH`;
const REMOVE = `${name}/REMOVE`;

// Find
const get = (accountId, aliasId, params) => ({
  type: GET,
  request: {
    method: 'get',
    url: `${base}/${accountId}/${name.toLowerCase()}/${aliasId}`,
    params,
  },
  meta: {
    takeLatest: true,
  },
});
const getSelector = getQuerySelector({ type: GET });

// Find
const find = (accountId, params) => ({
  type: FIND,
  request: {
    method: 'get',
    url: `${base}/${accountId}/${name.toLowerCase()}`,
    params,
  },
  meta: {
    takeLatest: true,
  },
});
const findSelector = getQuerySelector({ type: FIND, multiple: true });

// // Create
const create = (accountId, data) => ({
  type: CREATE,
  request: {
    method: 'post',
    url: `${base}/${accountId}/${name.toLowerCase()}`,
    data,
  },
  meta: {
    mutations: {
      [FIND]: (current, item) => {
        if (current) return [item, ...current];
        return [item];
      },
    },
  },
});
const createSelector = getMutationSelector({ type: CREATE });

// Patch
const patch = function Patch(accountId, aliasId, data, params) {
  return {
    type: PATCH,
    request: {
      method: 'patch',
      url: `${base}/${accountId}/${name.toLowerCase()}/${aliasId}`,
      data,
      params,
    },
    meta: {
      mutations: {
        [FIND]: (current, update) => {
          if (!current) return;
          return current.map((item) => {
            if (item._id === update._id) item = update;
            return item;
          });
        },
      },
    },
  };
};
const patchSelector = getMutationSelector({ type: PATCH });

// Remove
const remove = (accountId, aliasId) => ({
  type: REMOVE,
  request: {
    method: 'delete',
    url: `${base}/${accountId}/${name.toLowerCase()}/${aliasId}`,
  },
  meta: {
    mutations: {
      [FIND]: (current, item) => {
        if (!current) return;
        return current.filter((x) => x._id !== item._id);
      },
    },
  },
});
const removeSelector = getMutationSelector({ type: REMOVE });

export {
  get,
  FIND,
  getSelector,
  find,
  findSelector,
  create,
  createSelector,
  patch,
  patchSelector,
  remove,
  removeSelector,
};
