import React from 'react';
import get from 'lodash/get';
import './style.scss';
import useUsers from 'Hooks/use-users';

export default function AnnotationStatus({ annotation }) {
  const { get: getUser } = useUsers();
  const owner = getUser(annotation.user_id);
  const reviewerId = get(annotation, ['metadata', 'reviewed_by_user_id']);
  const reviewer = getUser(reviewerId);

  return (
    <div className="annotation-status">
      <b>Annotation status:</b>
      <br />
      {owner && `Annotated by ${owner.username}`}
      <br />
      {reviewer && `Reviewed by ${reviewer.username}`}
    </div>
  );
}
