import React, { useMemo } from 'react';
import styled from 'styled-components';
import usePrettyBytes from 'Hooks/use-pretty-bytes';
import { useDispatch } from 'react-redux';
import { removeAction } from 'Store/composer/files';
import toIcon from 'Utils/extensionIcon';

const Container = styled.div`
  border-top: 1px solid #ebedf2;
  padding: 15px 20px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`;

const IconContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 6px;
  background: #74788d;
  margin-right: 10px;
  flex-shrink: 0;
  background-size: cover;
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
`;

const Info = styled.div`
  flex-grow: 1;
  .filename {
    color: #595d6e;
  }
  .filesize {
    color: #74788d;
  }
`;

const DiscardButton = styled.button`
  display: flex;
  padding: 10px 20px;
  background: none;
  border: none;
  border-left: 1px solid #ebedf2;
  cursor: pointer;
  font-size: 16px;
`;

export default function AttachmentCard({ attachment }) {
  const dispatch = useDispatch();
  const size = usePrettyBytes(attachment.size);
  const iconUrl = useMemo(() => toIcon(attachment), [attachment]);
  const isImage = useMemo(() => attachment.content_type.includes('image'), [
    attachment,
  ]);
  const handleRemove = () => {
    dispatch(async (disp, rootState, { composer: client }) => {
      try {
        const {
          composer: { account },
        } = rootState();
        disp(removeAction(attachment.id));
        await client.removeAttachment(attachment.id, account.access_token);
      } catch (e) {
        console.log('could not remove attachment', e.message);
      }
    });
  };
  return (
    <Container>
      {isImage ? (
        <IconContainer
          style={{
            backgroundImage:
              attachment._url && isImage
                ? `url(${attachment._url})`
                : undefined,
          }}
        />
      ) : (
        <Icon src={iconUrl} />
      )}

      <Info>
        <div className="filename">{attachment.filename}</div>
        <div className="filesize">{size}</div>
      </Info>
      <DiscardButton onClick={handleRemove}>&times;</DiscardButton>
    </Container>
  );
}
