import appConfig from '../app.config';

const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');

const socket = io(appConfig.restUrl, {
  autoConnect: true,
  reconnection: true,
  transports: ['websocket'],
  secure: true,
});
const app = feathers();

// Set up Socket.io client with the socket
app.configure(socketio(socket));

socket.on('connect', () => {
  socket.emit('create', 'auth', {
    strategy: 'jwt',
    accessToken: localStorage.getItem(appConfig.localStorageTokenKey),
  });
});

// Receive real-time events through Socket.io
export default app;
