import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuery } from '@redux-requests/core';
import 'Components/Message/Body';
import MessageIframe from 'Components/Message/MessageIframe';
import Form from './MessageAnnotationForm';
import TopBar from './TopBar';
import AnnotationStatus from './AnnotationStatus';
import './style.scss';
import EmailHeaderArea from './EmailHeaderArea';

const MESSAGES_GET = 'STUDIO/MESSAGES_GET';
const ANNOTATIONS_FIND = 'STUDIO/ANNOTATIONS_FIND';
const fetchMessage = (id) => ({
  type: MESSAGES_GET,
  request: {
    url: `/messages/${id}`,
  },
});

const fetchMessageAnnotation = (message_id, annotation_id) => ({
  type: ANNOTATIONS_FIND,
  request: {
    url: `/annotations`,
    params: { message_id, id: annotation_id },
  },
});

export default function MessageAnnotationView(props) {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const {
    data: msg,
    pristine: msgPristine,
    loading: msgLoading,
  } = getQuery(state, {
    type: MESSAGES_GET,
  });
  const {
    data: [annotation],
    pristine: anPristine,
    loading: anLoading,
  } = getQuery(state, { type: ANNOTATIONS_FIND, multiple: true });

  const isFetched = [anPristine, msgPristine, msgLoading, anLoading].every(
    (x) => x === false
  );

  const msgContainer = useRef(null);
  const {
    match: {
      params: { messageId, annotationId },
    },
  } = props;

  const [needToReloadMessage, reloadMessage] = React.useState();

  useEffect(() => {
    if (!messageId) return;

    dispatch(fetchMessage(messageId));
  }, [dispatch, messageId, needToReloadMessage]);

  const refresh = () =>
    dispatch(fetchMessageAnnotation(messageId, annotationId));
  useEffect(() => {
    if (!messageId) return;
    dispatch(fetchMessageAnnotation(messageId, annotationId));
  }, [dispatch, messageId, annotationId, needToReloadMessage]);
  const viewKey = `${msg?.id || 'msg'}:${annotation?.id || 'annotation'} `;
  return (
    <div className="studio-view" key={viewKey}>
      <TopBar
        messageId={messageId}
        isLoading={!isFetched}
        annotation={annotation}
        reloadMessage={reloadMessage}
        refresh={refresh}
      />
      {isFetched && msg && (
        <div className="annotation-view">
          <div className="sidebar">
            {annotation && <AnnotationStatus annotation={annotation} />}
            <Form msg={msg} annotation={annotation} />
          </div>

          {msg && (
            <>
              <div className="msg-body" ref={msgContainer}>
                <EmailHeaderArea header={msg.raw_headers} />
                <div className="msg-subject">{msg.subject}</div>
                <MessageIframe src={msg.body} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
