import axios from 'axios';
import appConfig from '../app.config';

const client = axios.create({
  baseURL: appConfig.restUrl,
  timeout: 30000,
});

client.interceptors.request.use((request) => {
  // Format response
  const token = localStorage.getItem(appConfig.localStorageTokenKey);
  const sessId = localStorage.getItem(appConfig.localStorageSessionKey);
  request.headers.authorization = token ? `Bearer ${token}` : null;
  request.headers['X-Session-Id'] = sessId;
  return request;
});

client.interceptors.response.use(
  (response) => {
    const disposition = response.headers['content-disposition'];

    if (disposition) {
      console.log(disposition);
    }

    // Format response
    return response.data;
  },
  (error) => {
    // Return Error response data if available
    if (error && error.isAxiosError) {
      if (error.response && error.response.data)
        return Promise.reject(error.response.data);
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

export default client;
