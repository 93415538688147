import React, { useState } from 'react';

export default function TrackingLinkButton({ data }) {
    const [buttonText, setButtonText] = useState("Copy");
    const changeText = (data) => {
        setButtonText("Copied");
        navigator.clipboard.writeText(data);
    }
    
    return (
        <button
            className="btn btn-info"
            type="button"
            onClick={() => changeText(data)}
        >
            { buttonText }
        </button>
    );
}
