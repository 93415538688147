import React, { useState } from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import Tag from 'Components/Tag';
import moment from 'moment';
import { Btn } from 'Components/Styled';
import { EditIcon } from 'Assets';
import { useHistory } from 'react-router-dom';
import Annotation from './AnnotationItem';

import './style.scss';

const AnnotationListItem = ({ item, fetchData }) => {
  const msg = item.message;
  const isReviewed = item.annotations.find((x) => {
    return get(x, 'metadata.reviewed_by_user_id');
  });
  const history = useHistory();

  const [latestAnnotation] = item.annotations.sort(
    (a, b) => a.updated_at >= b.updated_at
  );
  const handleEdit = () =>
    history.push(`/studio/annotate/${item.id}/${latestAnnotation.id}`);

  const ts = React.useMemo(() => {
    if (!latestAnnotation) return;
    return moment(latestAnnotation.created_at, 'X').fromNow();
  }, [latestAnnotation]);

  const tag = React.useMemo(() => {
    if (isReviewed) {
      return {
        text: `Reviewed`,
        class_name: 'success',
      };
    }
    return {
      text: `Annotated`,
      class_name: 'warning',
    };
  }, [isReviewed]);

  const [expanded, expand] = useState(false);
  return (
    <div
      className={classnames('annotation-list-item', {
        reviewed: isReviewed,
      })}
    >
      <div
        className={classnames('annotation-list-item__header')}
        onClick={() => expand(!expanded)}
      >
        <div className="title">{msg.subject}</div>
        {item.annotations.length > 1 && (
          <div
            className="badge badge-info badge-pill ml-2"
            title="total annotations "
          >
            {item.annotations.length}
          </div>
        )}
        <div className="status">
          <Tag tag={tag} style={{ marginLeft: 10 }} />
        </div>
        <div className="timestamp">{ts}</div>
        <div className="message-actions">
          {!isReviewed && item.annotations.length === 1 && (
            <Btn title="Edit">
              <EditIcon title onClick={handleEdit} />
            </Btn>
          )}
        </div>
      </div>

      <div className="annotation-list-item__annotations">
        {expanded &&
          item.annotations.map((annotation) => (
            <Annotation
              key={annotation.id}
              item={annotation}
              fetchData={fetchData}
            />
          ))}
      </div>
    </div>
  );
};

export default AnnotationListItem;
