import React, { useState } from 'react';
import Avatar from 'react-avatar';
import styled from 'styled-components';
import moment from 'moment';
import useMessage from 'Hooks/use-message';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MenuIcon, ReplyIcon, InfoIcon } from 'Assets';
import client from 'Clients/rest';
import downloadEml from 'Utils/download-eml';
import timeAgo from 'Utils/timeAgo';
import Dropdown from 'Components/Dropdown';
import SentimentLabeller from 'Components/SentimentLabeller';
import createStore from 'Store/composer';
import Composer from 'Components/Composer/Container';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import MessageIframe from './MessageIframe';

import { Btn, theme } from '../Styled';
import Attachment from '../Attachment';
import SentimentCard from './SentimentCard';
import SummaryCard from './SummaryCard';
import MessageInfo from './MessageInfo';
import './Body';

const ActionButton = styled.button`
  display: block;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 0 !important;
  background: ${(p) => p.theme.backing};
  border: none !important;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 20px !important;
  svg {
    width: 16px;
    height: 16px;
    transform: translateY(-2px);
    margin-right: 8px;
    path {
      fill: ${theme.primary};
    }
  }
  &:hover {
    background: ${(p) => p.theme.backing};
    color: ${(p) => p.theme.primary};
    svg path {
      fill: ${(p) => p.theme.primary};
    }
  }
`;

const Container = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.border};
  background: #fff;
  border-radius: 3px;
`;

const SignatureToggle = styled.div`
  background: #eee;
  display: inline-block;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
  color: ${(p) => p.theme.muted};
  line-height: 1;
  font-size: 20px;
`;

const Footer = styled.div`
  margin: 10px 0;
`;
const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.1;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Sender = styled.div`
  .sender__info {
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    color: ${theme.muted};
  }
`;
const Body = styled.div`
  margin-top: 10px;
  line-height: 1.4;
  ${(p) => p.collapsed && `display: none`};
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  p:last-child {
    margin: 0 !important;
  }
`;
const MessageDate = styled.div`
  color: ${theme.muted};
`;
const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  > div {
    margin-left: 5px;
  }
`;

const ShareToast = ({ data }) => {
  const copy = () => {
    const copyText = document.getElementById('share-input');
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand('copy');
  };
  return (
    <div>
      Messages Shared
      <br />
      <input
        className="form-control"
        type="text"
        value={data.url}
        id="share-input"
      />
      <button className="btn btn-sm btn-info" onClick={copy}>
        Copy Link
      </button>
    </div>
  );
};

export default function Message({ message, collapsed }) {
  const { chatter, html, filteredFiles, undoChatter } = useMessage(message);
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const [showResponder, setShowResponder] = useState(false);
  const [store, setStore] = useState(false);
  const [replyMode, setReplyMode] = useState('reply');
  const [open, toggle] = useState();
  const [sentiment] = useState();
  const [transformerSentiment] = useState();
  const [summary] = useState();
  const user = useSelector((state) => state.user);
  const [showSignature, setShowSignature] = useState(false);
  const history = useHistory();

  // const NerAPI = async () => {
  //   setCollapsed(false);
  //   const ner = await client.post('/external/nlp/named-entities', {
  //     text: message.text || message.snippet,
  //   });
  //   setBody(ner.html);
  //   console.log('NER', ner);
  // };

  const onShare = async () => {
    try {
      const res = await client.post('/messages-share', {
        user_id: user.id,
        message_id: message.id,
        account_id: message.account_id,
      });
      toast(<ShareToast data={res} />);
    } catch (e) {
      // no op
      console.warn('could not share', e.message);
    }
  };

  // const SentimentAPI = async () => {
  //   setCollapsed(false);
  //   const sent = await client.post('/external/ml-kit/nlp', {
  //     text: message.text || message.snippet,
  //   });
  //   const [transf] = await client.post('/external/transformers/sentiment', {
  //     text: message.text || message.snippet,
  //   });
  //
  //   setSentiment({
  //     score: sent.sentiment_score,
  //     magnitude: sent.sentiment_magnitude,
  //     model: 'MLKit',
  //   });
  //   setTransformerSentiment({
  //     score: transf.score,
  //     label: transf.label.toLowerCase(),
  //     model: 'Transformer',
  //   });
  // };

  const report = async () => {
    const text = prompt('please explain problem here:');
    if (!text) return;
    try {
      await client.post('/user-reports', {
        message_id: message.id,
        thread_id: message.thread_id,
        text,
      });
      toast(`Message reported successfully`);
    } catch (e) {
      console.log('Error reporting', e.message);
    }
  };

  const afterSend = (msg) => {
    msg.thread_id = message.thread_id;
    client.patch(`/threads/${message.thread_id}`, {
      last_responded_by: {
        user_id: user.id,
        username: user.username,
        name: user.name,
        date: moment().unix(),
      },
    });
    msg._id = msg.id;
    [msg.from] = msg.from;
    toast('Message sent');
    setShowResponder(false);
  };

  const respond = () => {
    const redux = createStore({ name: `Responder-${message.id}` });
    setReplyMode('reply');
    setStore(redux);
    setShowResponder(true);
  };

  const respondAll = () => {
    const redux = createStore({ name: `Responder-${message.id}` });
    setReplyMode('replyAll');
    setStore(redux);
    setShowResponder(true);
  };

  const fwd = () => {
    const redux = createStore({ name: `Responder-${message.id}` });
    setReplyMode('forward');
    setStore(redux);
    setShowResponder(true);
  };

  const annotate = () => {
    history.push(`/studio/annotate/${message.id}`);
  };

  const options = [
    { caption: 'Reply', action: respond },
    { caption: 'Reply All', action: respondAll },
    { caption: 'Forward', action: fwd },

    // { caption: 'Named Entity Recognition', action: NerAPI },
    // { caption: 'Sentiment Analysis', action: SentimentAPI },
    // { caption: 'Summarize message', action: SummarizerAPI },
    // { caption: 'Ask question', action: QandaAPI },
    { caption: 'Share Message', action: onShare },
    { caption: 'Report issue with message', action: report },
    { caption: 'Annotate', action: annotate },
  ];

  if (!message.chatter_body) {
    options.push({ caption: 'Parse message', action: chatter });
    options.push({ caption: 'Download EML', action: downloadEml(message.id) });
  } else {
    options.push({ caption: 'Undo message parsing', action: undoChatter });
  }

  const accountList = user.accounts.filter((a) => a.id === message.account_id);

  if (!message) return <div>Message cannot be rendered.</div>;

  if (!message.from) {
    message.from = [{}];
    console.warn(
      `message ${message.id || message._id} has no "from".`,
      message
    );
  }
  if (!message.to) {
    message.to = [{}];
    console.warn(`message ${message.id || message._id} has no "to".`, message);
  }

  let sendersString = '';
  if (message.to) {
    sendersString = message.to
      .map((sender) => sender.name || sender.email)
      .join(', ');
    if (sendersString.length >= 40) {
      sendersString = `${sendersString.substring(0, 40)}...`;
    }
  }
  return (
    <div className="message" id={`message-${message._id}`}>
      <Container onClick={() => setCollapsed(false)}>
        <Toolbar>
          <Left>
            <MessageInfo
              message={message}
              className="d-flex align-items-center "
            >
              <Avatar
                name={message.from.name || message.from.email}
                size={36}
                round="3px"
                style={{ marginRight: 10 }}
                color={Avatar.getRandomColor('john jung', [
                  '#fd397a',
                  '#0abb87',
                  '#e6f8f3',
                ])}
              />
              <Sender>
                <strong>{message.from.name || message.from.email}</strong>
                <div className="sender__info">to {sendersString}</div>
              </Sender>
            </MessageInfo>
          </Left>
          <Right>
            {message.chatter_body && (
              <Btn title="This message is parsed" className="mr-2">
                <InfoIcon title />
              </Btn>
            )}
            {message.text && !isCollapsed && (
              <div className="mr-3">
                <SentimentLabeller message={message} />
              </div>
            )}
            <MessageDate
              className="text-small"
              data-for={`message-${message.id}-date`}
              data-tip={moment(message.date, 'X').format('LLLL')}
            >
              <ReactTooltip
                id={`message-${message.id}-date`}
                place="right"
                effect="solid"
              />
              {timeAgo(message.date)}
            </MessageDate>
            <Dropdown open={open} toggle={toggle} options={options}>
              <Btn blank>
                <MenuIcon />
              </Btn>
            </Dropdown>
          </Right>
        </Toolbar>
        {summary && (
          <div className="my-3">
            <SummaryCard text={summary} />
          </div>
        )}
        <Body collapsed={isCollapsed ? 1 : 0}>
          <MessageIframe src={html} />
          {/* Signature */}
          {message.signature && (
            <>
              {!showSignature && (
                <SignatureToggle
                  onClick={() => setShowSignature(true)}
                  className="my-3"
                  title="Show signature"
                >
                  ···
                </SignatureToggle>
              )}
              {showSignature && (
                <div dangerouslySetInnerHTML={{ __html: message.signature }} />
              )}
            </>
          )}

          <AttachmentsContainer>
            {filteredFiles &&
              filteredFiles.length > 0 &&
              filteredFiles.map((file, idx) => (
                <Attachment
                  attachment={file}
                  account_id={message.account_id}
                  key={idx}
                />
              ))}
          </AttachmentsContainer>
          <div className="d-flex">
            {sentiment && <SentimentCard sentiment={sentiment} />}
            {transformerSentiment && (
              <SentimentCard sentiment={transformerSentiment} />
            )}
          </div>
        </Body>
        <Footer>
          {accountList.length === 0 && (
            <pre>
              Debug: you cannot reply to this message (account_id:
              {message.account_id}), your account_ids:{' '}
              {user.accounts.map((a) => a.id).join(',')}
            </pre>
          )}
        </Footer>
      </Container>
      {!isCollapsed &&
        !showResponder &&
        !message._optimistic &&
        accountList.length > 0 && (
          <>
            <ActionButton
              className="btn btn-outline-primary btn-rounded btn-sm px-4 py-2"
              style={{ borderRadius: 30 }}
              onClick={respond}
            >
              <ReplyIcon />
              Reply to this conversation
            </ActionButton>
          </>
        )}

      {showResponder && (
        <div className="mb-3">
          <Composer
            inline
            store={store}
            accounts={accountList}
            to={[message.from]}
            height={150}
            close={() => setShowResponder(false)}
            discard={() => setShowResponder(false)}
            key={message.id}
            replyAll={replyMode === 'replyAll'}
            replyToMessageId={
              replyMode === 'reply' || replyMode === 'replyAll'
                ? message.id
                : null
            }
            forwardToMessageId={replyMode === 'forward' ? message.id : null}
            afterSend={afterSend}
            style={{ marginBotom: 5 }}
          />
        </div>
      )}
    </div>
  );
}
