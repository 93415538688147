import React from 'react';
import Select from 'react-select';
import selectStyle from 'Components/Styled/Select';
import MarketOptions from 'Data/data';

const StyledSelect = selectStyle(Select);
export default function MarketSelector({ onChange, placeholder, isClearable }) {
  const handleChange = (data) => {
    if (onChange) onChange(data);
  };

  return (
    <StyledSelect
      className="basic-single flex-grow-1"
      classNamePrefix="select"
      isLoading={false}
      isClearable={isClearable}
      placeholder={placeholder || ''}
      name="market-picker"
      options={MarketOptions}
      style={{
        menu: (own) => ({ ...own, zIndex: 500 }),
      }}
      onChange={handleChange}
    />
  );
}
