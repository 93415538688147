import React from 'react';
import { InfoIcon } from 'Assets';

const Tip = ({ text }) => (
  <div className="info" data-tip={text} data-html data-for="annotation-form">
    <InfoIcon title />
  </div>
);

export default Tip;
