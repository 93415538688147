import { go as goAction, toggle as toggleAction } from 'Store/action-bar';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { set } from 'Store/user';
import { patch } from 'Store/api/users';
import useActivities from 'Hooks/use-activities';

export default () => {
  const dispatch = useDispatch();
  const data = useSelector((s) => s.actionbar);

  const toggle = (val) => {
    dispatch(toggleAction(val));
  };

  const go = (route) => () => {
    if (!data.open) toggle(true);
    if (data.open && route === data.route) toggle(false);
    dispatch(goAction(route));
  };

  const user = useSelector((s) => s.user);
  const activitiesHook = useActivities();

  const updateUser = async () => {
    const res = await dispatch(
      patch(user.id, { activities_last_viewed_at: moment().unix() })
    );
    dispatch(set({ ...user, ...res.data }));
  };
  useEffect(() => {
    if (data.open && data.route === 'notifications') {
      updateUser();
    }
  }, [data]); // eslint-disable-line
  const newActivitesCheck = () => {
    const newActivites = activitiesHook.data.filter((activity) => {
      return activity.created_at > user.activities_last_viewed_at;
    });
    return newActivites.length > 0;
  };
  return {
    data,
    go,
    toggle,
    newActivitesCheck,
  };
};
