import React from 'react';
import AuthLayout from 'Components/AuthLayout';
import { Form, Field } from 'react-final-form';
import { Row, Col, FormGroup, Button, FormText, Spinner } from 'reactstrap';
import { Input } from 'Components/Form';
import useRegister from 'Hooks/use-register';
import classnames from 'classnames';
import APIError from 'Components/APIError';

export default function Signup() {
  const {
    onSubmit,
    validate,
    initialValues,
    loading,
    error,
    hasEmail,
    back,
  } = useRegister();
  return (
    <AuthLayout>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          handleSubmit,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className="mb-5 mt-4">Create Account</h1>
            {error && !dirtySinceLastSubmit && (
              <FormGroup>
                <APIError error={error} entity="User" />
              </FormGroup>
            )}
            {/* For invites only */}
            <Field component={Input} name="invite_code" type="hidden" />
            <Field component={Input} name="name" type="hidden" />
            <FormGroup>
              <Field
                label="Username"
                component={Input}
                name="username"
                placeholder="your username"
              />
              <FormText>You can use letters, numbers & symbols (_ .)</FormText>
            </FormGroup>
            <FormGroup>
              <Field
                label="Email"
                component={Input}
                type="email"
                disabled={hasEmail}
                showValid={hasEmail}
                name="email"
                placeholder="your email for login"
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <Field
                    label="Password"
                    component={Input}
                    type="password"
                    name="password"
                    placeholder="password"
                  />
                </Col>
                <Col md={6}>
                  <Field
                    label="Confirm password"
                    component={Input}
                    type="password"
                    name="confirmPassword"
                    placeholder="confirm password"
                  />
                </Col>
              </Row>
              <FormText>
                Use 8 or more characters with a mix of letters, numbers &
                symbols.
              </FormText>
            </FormGroup>

            <FormGroup className="mt-md-5">
              <Row>
                <Col md={6}>
                  <Button
                    block
                    outline
                    color="primary"
                    size="lg"
                    onClick={back}
                  >
                    I have an account
                  </Button>
                </Col>
                <Col md={6} className="mt-3 mt-sm-3 mt-md-0">
                  <Button
                    block
                    disabled={loading || hasValidationErrors}
                    color="primary"
                    size="lg"
                    className={classnames('font-weight-bold', {
                      disabled: hasValidationErrors,
                    })}
                  >
                    Next
                    {loading && (
                      <Spinner color="light" size="sm" className="ml-3" />
                    )}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </form>
        )}
      />
    </AuthLayout>
  );
}
