const appUrl = process.env.REACT_APP_API_URL || 'http://localhost:3030';
const sendUrl =
  process.env.REACT_APP_NYLAS_OUTBOX_URL || 'https://nylas-composer.june.ai';

export default {
  env: process.env.NODE_ENV || 'development',
  restUrl: appUrl,
  nylasUrl: 'https://api.nylas.com',
  nylasOutboxUrl: sendUrl,
  localStorageTokenKey: 'feathers-jwt',
  localStorageSessionKey: 'session-id',
  nylasLocalStorageKey: 'nylas-jwt',
  version: process.env.REACT_APP_VERSION || 'local',
};
