import { useMemo, useState } from 'react';
import client from 'Clients/rest';
import toIcon from 'Utils/extensionIcon';
import { useHistory } from 'react-router-dom';
import usePrettyBytes from './use-pretty-bytes';
import useFileExtension from './use-file-extension';
import useTimeAgo from './use-time-ago';

export default function (attachment) {
  const history = useHistory();
  const [viewer, toggleViewer] = useState(false);
  const size = usePrettyBytes(attachment.size);
  const iconUrl = useMemo(() => toIcon(attachment), [attachment]);
  const isImage = useMemo(
    () => attachment.content_type.includes('image'),
    [attachment]
  );

  const url =
    attachment.object === 'upload'
      ? `/uploads/${attachment.id}/download`
      : `/files/preview`; // TODO: refactor to new route

  const preview = async (e) => {
    if (e) e.stopPropagation();
    if (attachment.url) {
      return window.open(attachment.url);
    }
    const res = await client.post(url, {
      file_id: attachment.id,
      content_disposition: 'inline',
    });
    window.open(res.url, '_blank');
  };

  const getUrl = async () => {
    if (attachment.url) return Promise.resolve({ url: attachment.url });
    return client.post(url, {
      file_id: attachment.id,
      content_disposition: 'inline',
    });
  };

  const download = async (e) => {
    if (e) e.stopPropagation();
    if (attachment.url) {
      return window.open(attachment.url);
    }
    const res = await client.post(url, {
      file_id: attachment.id,
      content_disposition: 'attachment',
    });
    window.open(res.url, '_blank');
  };

  const jumpToThread = async (e) => {
    if (e) e.stopPropagation();
    history.push(`/inbox/thread/${attachment.last_thread_id}`);
  };

  return {
    size,
    download,
    preview,
    iconUrl,
    isImage,
    viewer,
    toggleViewer,
    jumpToThread,
    getUrl,
    extension: useFileExtension(attachment),
    date: useTimeAgo(attachment.message_date || attachment.created_at),
  };
}
