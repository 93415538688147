import React from 'react';
import NylasLoader from 'Components/NylasLoader';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import theme from 'Styles/theme';

const Root = styled(Flex)`
  width: 100vw;
  height: 100vh;
`;

export default function AppLoader() {
  return (
    <Root center>
      <NylasLoader loading fill={theme.nylas} />
    </Root>
  );
}
