import React from 'react';
import { ReactComponent as DownloadIcon } from 'Assets/metronic/Files/Download.svg';
import downloadEml from 'Utils/download-eml';
import { Btn } from '../../Styled';

export default function DownloadEMLBtn({ messageId }) {
  return (
    <Btn title="Download EML" onClick={downloadEml(messageId)}>
      <DownloadIcon title />
    </Btn>
  );
}
