import { createReducer, createAction } from '@reduxjs/toolkit';
import metrics from 'Clients/metrics';
import { updateAction as updateUi } from '../ui';

const { log } = console;
const { error } = console;

export const sendStart = createAction('COMPOSER/SEND/SEND_START');
export const sendSuccess = createAction('COMPOSER/SEND/SEND_SUCCESS');
export const sendError = createAction('COMPOSER/SEND/SEND_ERROR');

export const send =
  (message) =>
  async (dispatch, getState, { composer: client }) => {
    // TODO: validations
    const { composer: rootState } = getState();
    const data = {};
    Object.keys(message).forEach((key) => {
      data[key] = message[key].data;
    });

    const { account } = rootState;
    data.account_id = account.id;
    if (account.is_append_signature && account.signature) {
      log('appending signature', account.signature);
      data.body += `<div class="nylas-signature">${account.signature}</div>`;
    }

    if (rootState.quoted_text.data) {
      log('appending quoted text');
      data.body += rootState.quoted_text.data;
    }
    // Enable tracking
    data.tracking = {
      links: true,
      opens: true,
      thread_replies: true,
    };
    const accessToken = account.access_token;
    log('Sending...', data);
    try {
      dispatch(sendStart());
      dispatch(updateUi({ minimized: true }));
      const res = await client.send(data, accessToken);
      const response = { ...res, _optimistic: true };
      log('Sent message', res);
      await dispatch(sendSuccess(response));
      metrics.logEvent({
        event: 'message-send',
        event_properties: { account_id: data.account_id },
      });
      return response;
    } catch (e) {
      dispatch(sendError(e.message));
      error('failed to send', e.message);
      return null;
    }
  };

const initialState = {
  message: null,
  sending: false,
  error: null,
  sent: false,
};

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [sendStart]: (state) => {
      state.sending = true;
      state.error = null;
    },
    [sendSuccess]: (state) => {
      state.sending = false;
      state.sent = true;
      state.error = null;
    },
  }
);
