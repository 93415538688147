import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as FixIcon } from 'Assets/metronic/Tools/Tools.svg';

import { Btn } from '../Styled';
import useMessageId from '../../Hooks/use-message-id';

export default function FixMessageBtn({
  messageId,
  reloadMessage,
  setFixMessagesPending,
}) {
  const id = messageId;
  const { patch } = useMessageId(messageId);

  const messageFix = async () => {
    try {
      setFixMessagesPending(true);
      const response = await patch(id, { action: 'message-fix' });
      if (response.status === 200) {
        toast(`Message fix completed`);
        reloadMessage(Date.now());
      } else {
        toast.error(response?.error?.message || 'Error fixing message');
      }
    } catch (e) {
      toast.error('Error fixing message');
    } finally {
      setFixMessagesPending(false);
    }
  };

  return (
    <Btn title="Fix Message" onClick={messageFix}>
      <FixIcon title />
    </Btn>
  );
}
