import React from 'react';
import { Btn } from 'Components/Styled';
import Dropdown from 'Components/Dropdown';
import moment from 'moment';
import { ReactComponent as ClockIcon } from 'Assets/metronic/Home/Clock.svg';
import useThreadMultiSelect from 'Hooks/use-thread-multiselect';

export default function SnoozeBtn({ threads }) {
  const [open, toggle] = React.useState();
  const { patch } = useThreadMultiSelect();

  const handleSnooze = (date) => async () => {
    if (!date) {
      await patch(
        threads.map((t) => t.id),
        { action: 'unsnooze' }
      );
    } else {
      await patch(
        threads.map((t) => t.id),
        { action: 'snooze', snoozed_until: date }
      );
    }
  };

  const options = [
    {
      caption: '2 minutes',
      action: handleSnooze(moment().add(2, 'minutes').unix()),
    },
    {
      caption: `Later today (${moment()
        .add(4, 'hours')
        .minutes(0)
        .format('hh:mma')})`,
      action: handleSnooze(moment().add(4, 'hours').minutes(0).unix()),
    },
    {
      caption: 'Tomorrow (8AM)',
      action: handleSnooze(moment().add(1, 'days').hours(8).minutes(0).unix()),
    },
    {
      caption: 'Next week (Mon 8AM)',
      action: handleSnooze(
        moment()
          .add(1, 'week')
          .startOf('week')
          .add(1, 'days')
          .hours(8)
          .minutes(0)
          .unix()
      ),
    },
    {
      caption: 'Unsnooze',
      action: handleSnooze(null),
    },
  ];

  return (
    <Dropdown options={options} open={open} toggle={toggle} title="Snooze">
      <Btn title="Snooze">
        <ClockIcon title />
      </Btn>
    </Dropdown>
  );
}
