import { createReducer, createAction } from '@reduxjs/toolkit';

export const setDraft = createAction('COMPOSER/DRAFT/SET_DRAFT');
export const removeDraft = createAction('COMPOSER/DRAFT/REMOVE_DRAFT');

const initialState = null;

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },
    [setDraft]: (state, action) => {
      return action.payload;
    },
  }
);
