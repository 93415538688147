export const notificationPrompt = () => {
  document.addEventListener('DOMContentLoaded', function ask() {
    if (!Notification) return;
    if (Notification.permission !== 'granted') Notification.requestPermission();
  });
};

export const toast = (body, opts = {}) => {
  if (!Notification) return;
  if (Notification.permission !== 'granted') return;

  const notification = new Notification('Nylas Shared Inbox', {
    icon: 'https://lever-client-logos.s3.amazonaws.com/e3ec22f8-ced2-464b-a9f6-590e02ea5a42-1565029162818.png',
    body,
  });
  if (opts.url) {
    notification.onclick = () => {
      window.open(opts.url);
    };
  }
};
