// import { getQuery } from '@redux-requests/core';
import createActions from 'Store/api/common/create-feathers-actions';

const DRAFTS = 'DRAFTS';

export const {
  findSelector,
  find,
  create,
  patch,
  remove,
  patchSelector,
  createSelector,
  removeSelector,
} = createActions({
  name: DRAFTS,
  url: '/drafts',
});

// export const FETCH_DRAFTS = 'FETCH_DRAFTS';

// export const fetchDrafts = (params) => ({
//   type: FETCH_DRAFTS,
//   request: {
//     url: '/drafts',
//     params: { ...params },
//   },
// });

// export const draftsSelector = (state) =>
//   getQuery(state, { type: FETCH_DRAFTS, multiple: true });
