import React from 'react';
import { getQuery } from '@redux-requests/core';
import DataTable from 'Components/DataTable';
import { groupBy } from 'lodash';
import qs from 'query-string';
import AnnotatedMessageItem from './AnnotatedMessageItem';
import AnnotationsFilters from './Filters';
import './style.scss';

const selector = (state) =>
  getQuery(state, { type: 'ANNOTATION_LIST', multiple: true });

export default function AnnotationList({ location }) {
  const { search } = location;

  const params = React.useMemo(() => qs.parse(search), [search]);

  const fetchAction = React.useCallback(
    () => ({
      type: 'ANNOTATION_LIST',
      request: {
        url: '/annotations',
        params: { ...params, populateMessage: true },
      },
      meta: {
        getData: (data) => {
          // Grouping by message id
          if (data.length) {
            const grouped = groupBy(data, 'message_id');
            data = Object.entries(grouped).map(([k, v]) => {
              const [msg] = v;
              return { id: k, annotations: v, message: msg.message };
            });
            return data || [];
          }
        },
      },
    }),
    [params]
  );

  return (
    <div className="annotation-list">
      <div className="annotation-list__table">
        <DataTable
          toolbar={AnnotationsFilters}
          title="Annotations are grouped by message"
          description="Annotation List"
          fetchAction={fetchAction}
          component={AnnotatedMessageItem}
          selector={selector}
        />
      </div>
    </div>
  );
}
