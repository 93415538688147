import React, { useState } from 'react'
import { Btn } from '../Styled';
import { ReactComponent as Expand } from 'Assets/metronic/Navigation/Angle-double-down.svg';
import { ReactComponent as Collapse } from 'Assets/metronic/Navigation/Angle-double-up.svg';

export default function EmailHeader({ children }) {

  const [open, setOpen] = useState(false);
  const expandText = () => setOpen(!open);

  return (
    <>
        <div
            style={{ 
                maxWidth: "100%", 
                borderRadius: "3px",
                border: "1px solid#ccc",
                width: "100%",
            }}
        >
            <Btn
                className="btn-add-traking-info"
                type="button"
                title="Expand"
                onClick={()=>expandText()}
                style={{
                    display: "inline-block",
                }}
            >
                {open ? (
                    <Collapse />
                    ) : <Expand />
                }
                
            </Btn>
            <div
                style={{
                    display: "inline-block",
                    textAlign: "center",
                }}>
                Raw header
            </div>
        </div>
        {open ? (
            <div className='content'>
                {children}
            </div>
        ) : null}
    </>
  )
}