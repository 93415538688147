import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import NoEmailAlert from 'Components/NoEmailAlert';
import useLogin from 'Hooks/use-login';
import { updateAction as setUi } from 'Store/ui';
import styled from 'styled-components';
import { Btn } from 'Components/Styled';
import SearchBar from 'Components/SearchBar';
import {
  HamburgerIcon,
  ChevronLeft,
  LogoutIcon,
  SettingsIcon,
  AnnotateIcon,
} from 'Assets';

import Dropdown from 'Components/Dropdown';

import './style.scss';

const Root = styled.div`
  background: ${(p) => p.theme.backing};
  border-bottom: 1px solid ${(p) => p.theme.border};
  padding: 10px 20px;
  padding-right: 10px;
  z-index: 10;
  width: 100%;
  color: ${(p) => p.theme.muted};
`;

export default function NavBar({ backButton, onBackClick, search }) {
  const { handleLogout } = useLogin();
  const user = useSelector((state) => state.user);
  const [open, toggle] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const back = () => {
    if (onBackClick) return onBackClick();
    history.go(-1);
  };

  const options = [
    {
      caption: 'Settings',
      action: () => {
        history.push('/settings/general');
      },
      icon: SettingsIcon,
    },
    {
      caption: 'Annotations',
      action: () => {
        history.push('/studio');
      },
      icon: AnnotateIcon,
    },
    {
      caption: 'Logout',
      action: () => {
        handleLogout();
      },
      icon: LogoutIcon,
    },
  ];

  const toggleSidemenu = () => dispatch(setUi({ sidemenu: true }));

  if (!user) return null;
  return (
    <>
      {!user.email && <NoEmailAlert />}
      <Root className="d-flex justify-content-between align-items-center ">
        <button className="navbar__sidemenu-toggle" onClick={toggleSidemenu}>
          <HamburgerIcon />
        </button>
        {(backButton || onBackClick) && (
          <Btn title="Back" onClick={back} tooltip={{ position: 'right' }}>
            <ChevronLeft title />
          </Btn>
        )}
        <div />

        {search !== false && <SearchBar />}
        <div data-cy="navbar-dropdown">
          <Dropdown open={open} toggle={toggle} options={options}>
            <span className="text-small text-muted">{user.username}</span>
            <Avatar
              name={user.username}
              size={24}
              className="ml-1"
              round="24px"
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
        </div>
      </Root>
    </>
  );
}
