import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

// props has closeToast
export default function ConfirmToast(props) {
  return (
    <div className="p-3">
      <h3 className="my-3 text-center">Please confirm</h3>
      <p className="text-center">{props.text || 'Are you sure?'}</p>
      <ButtonGroup className="w-100">
        <Button color="primary" outline onClick={props.confirm}>
          Ok
        </Button>
        <Button color="primary" outline onClick={props.cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
}
