import React from 'react';
import Select from 'react-select';
import styles from './styles';

export default ({ options, onChange, value }) => {
  return (
    <Select
      name="sender"
      options={options}
      onChange={onChange}
      value={value}
      styles={styles}
      getOptionValue={(option) => option.email}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      placeholder=""
      menuPortalTarget={document.body}
    />
  );
};
