import createActions from '../common/create-feathers-actions';

const {
  create,
  createSelector,
  find,
  findSelector,
  remove,
  removeSelector,
  patch,
  patchSelector,
} = createActions({
  name: 'ACCOUNT_INVITES',
  url: '/accounts/:accountId/invites',
});

export {
  create,
  createSelector,
  find,
  findSelector,
  remove,
  removeSelector,
  patch,
  patchSelector,
};
