import React from 'react';
import useUsers from 'Hooks/use-users';
import moment from 'moment';
import './style.scss';
import Avatar from 'react-avatar';
import { Btn } from 'Components/Styled';
import { EditIcon, TrashIcon } from 'Assets';
import { useHistory } from 'react-router-dom';
import useAnnotations from 'Hooks/use-annotations';
import { get } from 'lodash';

const AnnotationItem = ({ item, fetchData }) => {
  const { remove } = useAnnotations();
  const { get: getUser } = useUsers();
  const user = getUser(item.user_id) || {};
  const history = useHistory();

  // const fieldCount = React.useMemo(() => {
  //   const len = (obj) => Object.keys(obj || {}).length;
  //   return (
  //     len(item) + len(item.order) + len(item.tracking) + len(item.merchant)
  //   );
  // }, [item]);

  const metadata = get(item, 'metadata') || {};

  const annotationTime = metadata.annotation_duration_ms;
  const reviewTime = metadata.review_duration_ms;

  const handleEdit = () =>
    history.push(`/studio/annotate/${item.message_id}/${item.id}`);
  const reviewer = getUser(metadata.reviewed_by_user_id);

  const handleRemove = async () => {
    const p = confirm('Please confirm'); // eslint-disable-line
    if (p) {
      await remove(item.id);
      fetchData();
    }
  };

  return (
    <div className="annotation">
      <div className="al-col" style={{ width: '10%' }}>
        <div className="col-name">Annotated by:</div>
        <Avatar
          name={user.username}
          size="16"
          round
          style={{ marginRight: 2 }}
        />{' '}
        {user.username}
      </div>

      <div className="al-col" style={{ width: '10%' }}>
        <div className="col-name">Reviewed by:</div>
        {reviewer && (
          <>
            <Avatar
              name={reviewer.username}
              size="16"
              round
              style={{ marginRight: 2 }}
            />{' '}
            {reviewer.username}
          </>
        )}
        {!reviewer && <span>N/A</span>}
      </div>
      {/* <div className="al-col">
        <div className="col-name">Field Count:</div>
        {fieldCount}
      </div> */}
      <div className="al-col" style={{ width: '10%' }}>
        <div className="col-name">Date:</div>
        <small>{moment(item.updated_at, 'X').format('LLL')}</small>
      </div>
      <div className="al-col" style={{ width: '5%' }}>
        <div className="col-name">Annotation Duration:</div>
        <small>{annotationTime ? `${annotationTime / 1000}s` : 'N/A'}</small>
      </div>
      <div className="al-col" style={{ width: '5%' }}>
        <div className="col-name">Review Duration:</div>
        <small>{reviewTime ? `${reviewTime / 1000}s` : 'N/A'}</small>
      </div>
      <div className="al-col actions">
        <Btn title="Edit">
          <EditIcon title onClick={handleEdit} />
        </Btn>
        <Btn title="Remove">
          <TrashIcon title onClick={handleRemove} />
        </Btn>
      </div>
      <hr />
    </div>
  );
};

export default AnnotationItem;
