import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { patch, patchSelector } from 'Store/api/users';
import moment from 'moment';

export default function useOnboarding() {
  const user = useSelector((s) => s.user);
  const dispatch = useDispatch();
  const onboarding = get(user, 'onboarding_steps', {});
  const { loading } = useSelector(patchSelector);

  const dismissIntro = () => {
    dispatch(
      patch(user._id, { onboarding_steps: { 'onboarding-slides': true } })
    );
  };
  const dismissStepGuide = () => {
    dispatch(patch(user._id, { onboarded_at: moment().unix() }));
  };

  const steps = [
    {
      title: 'Connect Team Account',
      done: onboarding['connect-shared-account'],
    },
    {
      title: 'Connect Private Account',
      done: onboarding['connect-private-account'],
    },
    {
      title: 'Mention a team member in a thread',
      done: onboarding['thread-mention'],
    },
    {
      title: 'Assign 1 thread to a team member',
      done: onboarding['thread-assign'],
    },
  ];

  const showStepGuide =
    onboarding['onboarding-slides'] && // saw the intro
    steps.filter((s) => !s.done) &&
    !user.onboarded_at;

  return {
    showIntro: !onboarding['onboarding-slides'] && !user.onboarded_at,
    dismissIntro,
    showStepGuide,
    dismissStepGuide,
    steps,
    loading,
  };
}
