import React from 'react';
import { ReactComponent as NotificationIcon } from 'Assets/ionic/notifications.svg';
import Retool from 'Assets/png/retool.webp';
import { ReactComponent as Zendesk } from 'Assets/svg/zendesk.svg';
import useActionBar from 'Hooks/use-action-bar';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import './style.scss';

export default function Navigation() {
  const { data, go, newActivitesCheck } = useActionBar();
  const { threadId } = useParams();
  const isActive = (key) => classnames({ active: data.route === key });

  return (
    <div className="actionbar-navigation">
      <button
        className={isActive('notifications')}
        onClick={go('notifications')}
      >
        <NotificationIcon />
        {newActivitesCheck() && (
          <span className="bg-primary p-1 rounded-circle" />
        )}
      </button>
      {threadId && (
        <>
          <button className={isActive('zendesk')} onClick={go('zendesk')}>
            <Zendesk />
          </button>
          <button
            style={{ backgroundImage: `url(${Retool})` }}
            className={isActive('retool')}
            onClick={go('retool')}
          />
        </>
      )}
    </div>
  );
}
