import React from 'react';
import AppLayout from 'Components/AppLayout';
import client from 'Clients/rest';
import { Container } from 'Components/Styled';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

export default function ReportsPage() {
  const [receivedData, setReceivedData] = React.useState([]);
  const [sentData, setSentData] = React.useState([]);
  const [countData, setCountData] = React.useState({});
  const accounts = useSelector((s) => s.user.accounts);
  const [accountId, setAccountId] = React.useState(accounts[0].id);
  const options = [
    {
      id: 'all-accounts',
      email: 'All accounts',
      custom: true,
      query: accounts.map((a) => a.id),
    },
    {
      id: 'shared-accounts',
      email: 'Team Inbox',
      custom: true,
      query: accounts.filter((a) => a.shared).map((a) => a.id),
    },
    {
      id: 'private-accounts',
      email: 'My Inbox',
      custom: true,
      query: accounts.filter((a) => !a.shared).map((a) => a.id),
    },
    ...accounts,
  ];

  const account = options.find((acc) => acc.id === accountId);

  React.useEffect(() => {
    const params = Array.isArray(accountId)
      ? { 'account_id[$in]': accountId }
      : { account_id: accountId };

    const fetchReceived = () =>
      client
        .get('/reports/received-emails-per-day', {
          params,
        })
        .then(setReceivedData);

    const fetchSent = () =>
      client
        .get('/reports/sent-emails-per-day', {
          params,
        })
        .then(setSentData);

    const countFeeds = () =>
      client
        .get('/reports/received-emails-by-section', {
          params: { ...params, section: 'feeds' },
        })
        .then((res) => {
          setCountData((s) => ({ ...s, ...res }));
        });

    const countConvos = () =>
      client
        .get('/reports/received-emails-by-section', {
          params: { ...params, section: 'convos' },
        })
        .then((res) => {
          setCountData((s) => ({ ...s, ...res }));
        });

    fetchReceived();
    fetchSent();
    countFeeds();
    countConvos();
  }, [accountId]);

  const handleAccountChange = (acc) => {
    if (!acc) return;
    // Custom items must define query
    if (acc.custom) {
      setAccountId(acc.query);
    } else {
      setAccountId(acc.id);
    }
  };

  // Data aggregation
  const sentAggregation = React.useMemo(() => {
    const data = {
      total: 0,
      daily_average: 0,
    };
    sentData.forEach((item) => {
      data.total += item.count;
    });
    data.average = data.total / 7;
    data.average = data.average.toFixed(2);
    return data;
  }, [sentData]);

  const receivedAggregation = React.useMemo(() => {
    const data = {
      total: 0,
      convos: countData.convos || 0,
      feeds: countData.feeds || 0,
    };
    receivedData.forEach((item) => {
      data.total += item.count;
    });
    data.average = data.total / 7;
    data.average = data.average.toFixed(2);
    return data;
  }, [receivedData, countData]);

  return (
    <AppLayout>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Container className="my-4 p-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Reports</h3>
                <div style={{ width: 300 }}>
                  <label htmlFor="account">Account</label>
                  <Select
                    placeholder="Select Account"
                    options={options}
                    onChange={handleAccountChange}
                    value={account}
                    getOptionLabel={(opt) => opt.email}
                    getOptionValue={(opt) => opt.id}
                    isClearable={false}
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <Container>
              <div className="text-center py-4">
                <h3>Messages Received the in past 7 days</h3>
              </div>
              <LineChart
                width={500}
                height={300}
                data={receivedData}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </Container>
          </div>

          <div className="col-6">
            <Container>
              <div className="text-center py-4">
                <h3>Messages Sent in the past 7 days</h3>
              </div>
              <LineChart
                width={500}
                height={300}
                data={sentData}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </Container>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-4">
            <Container className="p-3">
              <h2>Received</h2>
              Total Received: {receivedAggregation.total} <br />
              Convos: {receivedAggregation.convos} <br />
              Feeds: {receivedAggregation.feeds}
            </Container>
          </div>
          <div className="col-4">
            <Container className="p-3">
              <h2>Sent</h2>
              Total Sent: {sentAggregation.total} <br />
              Average/day: {sentAggregation.average} <br />
            </Container>
          </div>
          <div className="col-4">
            <Container className="p-3">
              <h2>Average response time</h2>
              4.5hrs
            </Container>
          </div>
        </div>
      </div>
      <div className="my-3" />
    </AppLayout>
  );
}
