import { useCallback } from 'react';
import validator from 'Utils/validator';
import client from 'Clients/rest';
import pDebounce from 'p-debounce';
import useRouter from 'Hooks/use-router';

let currentVal = {};

export default function useInviteCode() {
  const { next, prev } = useRouter();

  const debouncedCheck = useCallback(
    pDebounce(async (val) => {
      try {
        await client.post('/invites/check', { invite_code: val });
      } catch (e) {
        return ['invite code is invalid'];
      }
    }, 750),
    []
  );

  const validate = validator({
    invite_code: { presence: true },
    agreement: { presence: true, inclusion: [true] },
  });

  const validateCode = async (val) => {
    if (!val) return Promise.resolve();
    if (currentVal.val && currentVal.val === val) return currentVal.validation;
    const validation = await debouncedCheck(val);
    currentVal = { val, validation };
    return validation;
  };
  const onSubmit = async (data) => {
    next('inviteCode', data);
  };

  const back = () => prev('inviteCode');

  return { onSubmit, validate, validateCode, back };
}
