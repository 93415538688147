import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuery } from '@redux-requests/core';
import Tag from 'Components/Tag';
import { get } from 'lodash';
import { Spinner } from 'reactstrap';

const ACTION = (thread) => ({
  type: 'THREAD_ANNOTATIONS',
  request: {
    url: '/annotations',
    params: { 'message_id[$in]': thread.message_ids },
  },
  meta: {
    requestKey: thread.id,
    requestsCapacity: 50,
    takeLatest: true,
  },
});

export default function ThreadAnnotationStatus({ thread }) {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);

  const { data, loading } = getQuery(state, {
    type: `THREAD_ANNOTATIONS${thread.id}`,
    multiple: true,
  });

  useEffect(() => {
    if (data && data.length) return;
    dispatch(ACTION(thread));
  }, []); // eslint-disable-line
  const isAnnotated = data.some((x) => get(x, 'metadata.reviewed_by_user_id'));

  const reviewedTag = { text: 'Annotated', class_name: 'info' };
  const annotatedTag = { text: 'Reviewed', class_name: 'success' };
  if (loading)
    return <Spinner size="sm" color="info" style={{ marginRight: 5 }} />;
  if (data && data.length)
    return (
      <Tag
        style={{ marginRight: 5 }}
        tag={isAnnotated ? annotatedTag : reviewedTag}
      />
    );
  return null;
}
