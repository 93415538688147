import React from 'react';
import Header from '../Header';

export default function Zendesk() {
  return (
    <>
      <Header title="Zendesk" />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        Zendesk Integration Coming soon
      </div>
    </>
  );
}
