// Batch patch
import { clear } from 'Store/thread-select';
import { find } from 'Store/api/threads';
import qs from 'query-string';
import { getMutationSelector } from '@redux-requests/core';
import { toast } from 'react-toastify';

export const THREADS_BATCH = 'THREADS_BATCH';

export default (ids, data) => ({
  type: THREADS_BATCH,
  request: ids.map((id) => ({
    url: `/threads/${id}`,
    method: 'PATCH',
    data,
  })),
  meta: {
    onSuccess(action, b, { dispatch }) {
      const query = qs.parse(document.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      });
      dispatch(clear());
      dispatch(find(query));
      return action;
    },
    onError(error) {
      toast.error(`Error during batch action: ${error.message}`);
      throw error;
    },
  },
});

export const selector = getMutationSelector({
  type: THREADS_BATCH,
  multiple: true,
});
