import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import client from 'Clients/rest';
import { theme } from 'Components/Styled';
import style from './styles';

const styles = {
  ...style,
  container: (native) => ({
    ...native,
    width: 150,
  }),
  control: (native) => {
    return {
      ...native,
      fontSize: 12,
      borderColor: theme.border,
      borderRadius: 6,
      minHeight: '40px',
      boxShadow: 'none',
    };
  },
};

export default function TemplateSelector({ onChange, account }) {
  const [val, setVal] = useState(null);

  const loadOptions = async (input) => {
    const res = await client.get(`/email-templates`, {
      params: { $search: input, account_id: account.id },
    });
    return Promise.resolve(res);
  };

  const handleInputChange = (v) => {
    if (!v) return;
    setVal(v);
    onChange(v);
  };

  return (
    <AsyncSelect
      cacheOptions
      key={account.id}
      styles={styles}
      placeholder="Select template"
      loadOptions={loadOptions}
      defaultOptions
      getOptionValue={(o) => o}
      getOptionLabel={(o) => o.name}
      value={val}
      menuPlacement="top"
      onChange={handleInputChange}
    />
  );
}
