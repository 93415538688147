import React, { useState } from 'react';
import { ReactComponent as Ellipsis } from 'Assets/ionic/ellipsis-horizontal.svg';
import Dropdown from 'Components/Dropdown';
import styled from 'styled-components';
import FileViewer from 'Components/FileViewer';
import useAttachment from 'Hooks/use-attachment';
import Flex from 'Components/Flex';
import OCRModal from './OCRModal';
import { Btn } from '../Styled';

const Container = styled.div`
  display: inline-flex;
  border-radius: 4px;
  background: ${(p) => p.theme.backing};
  border: 1px solid ${(p) => p.theme.border};
  color: ${(p) => p.theme.dark};
  height: 50px;
  border-radius: 4px;
  box-shadow: ${(p) => p.theme.shadow};
  cursor: pointer;
  transition: border-color 0.5s;
  &:hover {
    border-color: ${(p) => p.theme.primary};
  }
`;

const Icon = styled.div`
  background: white(0.8);
  padding: 0 15px;
  padding-right: 20px;
  border-right: 1px solid ${(p) => p.theme.border};
  @include flex(center);
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.primaryMuted};
  height: 50px;
  .attachment-icon {
    width: 42px;
    height: 42px;
    stroke: color(primary);
  }
`;

const Content = styled.div`
  max-width: 150px;
  min-width: 150px;
  padding: 10px 15px;
  background: white(1);
  font-size: 14px;
  color: white(8);
  @include flex(start);
  flex-direction: column;
  justify-content: center;
  .attachment__filename {
    color: ${(p) => p.theme.dark};
  }
  .attachment__size {
    font-size: 12px;
    color: ${(p) => p.theme.muted};
  }
`;

const Actions = styled(Flex)`
  padding: 0 10px;
  background: white(1);
  border-left: 1px solid white(0.5);
`;

const More = styled(Ellipsis)``;

export default function Attachment({ attachment }) {
  const {
    size,
    download,
    preview,
    iconUrl,
    viewer,
    toggleViewer,
  } = useAttachment(attachment);

  const [open, toggle] = useState();
  const [modal, toggleModal] = useState();

  const options = [
    { caption: 'Download', action: download },
    { caption: 'Open in new window', action: preview },
    {
      caption: 'Open in Viewer',
      action: () => toggleViewer(true),
    },
  ];
  if (attachment.object === 'file') {
    options.push({
      caption: 'OCR',
      action: () => toggleModal(true),
    });
  }
  // Some files do not have filename, so they are ignored (generally invites)
  if (!attachment.filename) return null;
  return (
    <div>
      <Container>
        <Flex justifyBetween alignCenter onClick={() => toggleViewer(true)}>
          <Icon>
            <img src={iconUrl} alt="" />
          </Icon>
          <Content className="truncated">
            <div className="attachment__filename">{attachment.filename}</div>
            <div className="attachment__size">{size}</div>
          </Content>
        </Flex>
        <Actions grow center onClick={(e) => e.stopPropagation()}>
          <Dropdown open={open} toggle={toggle} options={options}>
            <Btn blank>
              <More />
            </Btn>
          </Dropdown>
        </Actions>
        {modal && (
          <OCRModal file={attachment} close={() => toggleModal(false)} />
        )}
      </Container>
      {viewer && (
        <FileViewer file={attachment} close={() => toggleViewer(false)} />
      )}
    </div>
  );
}
