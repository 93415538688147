import React from 'react';
import ThreadList from 'Components/ThreadList';
import AppLayout from 'Components/AppLayout';

export default function InboxPage() {
  return (
    <AppLayout>
      <ThreadList />
    </AppLayout>
  );
}
