import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { useDispatch, useSelector } from 'react-redux';
import { every } from 'lodash';
import useThreadMultiSelect from 'Hooks/use-thread-multiselect';
import UserSearch from 'Components/UserSearch';
import useAnnotationsStore from 'Hooks/use-annotations-store';
import {
  InboxIcon,
  OutgoingInboxIcon,
  TrashIcon,
  ArchiveIcon,
  AnnotateIcon,
  SpamIcon,
  CloseIcon,
  RemoveUserIcon,
} from 'Assets';

import SnoozeBtn from 'Components/SnoozeButton';
import { Star } from 'Components/ThreadListItem';
import { Btn, Checkbox } from '../Styled';

// Checkbox
const Container = styled(Flex)`
  border-bottom: 1px solid ${(p) => p.theme.border};
  padding: 0 15px;
  height: 50px;
`;

export default function MultiSelectToolbar({ threads }) {
  const {
    ids,
    isActive,
    replace,
    clear,
    patch,
    loading,
  } = useThreadMultiSelect();
  const { replace: replaceAnnotations } = useAnnotationsStore();

  const isChecked = threads.length === ids.length;
  const dispatch = useDispatch();
  const me = useSelector((s) => s.user);
  const toggle = () => {
    if (!isChecked) {
      replace(threads.map((t) => t.id));
    } else {
      clear();
    }
  };

  const selectedThreads = threads.filter((t) => ids.includes(t.id));
  const everyStarred = every(selectedThreads, { starred: true });
  const everyRead = every(selectedThreads, ['unread', false]);

  const action = async (name, payload) => {
    await patch(ids, { action: name, ...payload });
  };

  const handleSelectUser = (user) =>
    action('assign-user', { user_id: user.id });

  const handleUnselectUser = () => {
    action('unassign-user', { user_id: me.id });
  };

  const handleStar = () =>
    action(`mark-as-${everyStarred ? 'unstarred' : 'starred'}`);

  const handleAnnotate = () => {
    replaceAnnotations(selectedThreads);
  };
  const handleRead = () => action(`mark-as-${everyRead ? 'unread' : 'read'}`);
  const trash = () => action('move-to-trash');
  const spam = () => action('move-to-spam');
  const inbox = () => action('move-to-inbox');
  const archive = () => action('move-to-archived');

  const starredLabel = everyStarred ? 'Unstar' : 'Mark as starred';
  const unreadLabel = everyRead ? 'Mark as unread' : 'Mark as read';
  if (!isActive)
    return (
      <Container alignCenter>
        <Checkbox className="mr-3">
          <input
            type="checkbox"
            onClick={() => replace(threads.map((t) => t.id))}
          />
          <span />
        </Checkbox>
        <small className="text-muted">Select Threads</small>
      </Container>
    );
  if (isActive)
    return (
      <Container justifyBetween>
        <Flex alignCenter>
          <Checkbox style={{ marginRight: 10 }} checked={isChecked}>
            <input type="checkbox" onClick={toggle} />
            <span />
          </Checkbox>
          <Btn
            title={starredLabel}
            active={everyStarred}
            disabled={loading}
            onClick={handleStar}
          >
            <Star title />
          </Btn>
          <Btn
            title={unreadLabel}
            active={everyRead}
            disabled={loading}
            onClick={handleRead}
          >
            <InboxIcon title />
          </Btn>
          <Btn title="Move to inbox" disabled={loading} onClick={inbox}>
            <OutgoingInboxIcon title />
          </Btn>
          <Btn title="Archive" disabled={loading} onClick={archive}>
            <ArchiveIcon title />
          </Btn>

          <Btn title="Move to trash" disabled={loading} onClick={trash}>
            <TrashIcon title />
          </Btn>
          <Btn title="Move to spam" disabled={loading} onClick={spam}>
            <SpamIcon title />
          </Btn>
          <SnoozeBtn threads={selectedThreads} />
          <Btn title="Annotate messages">
            <AnnotateIcon onClick={handleAnnotate} title />
          </Btn>

          <UserSearch
            className="ml-1"
            placeholder="Assign to"
            onChange={handleSelectUser}
            loading={loading}
            style={{ width: 200 }}
          />
          <Btn title="Unassign me">
            <RemoveUserIcon onClick={handleUnselectUser} title />
          </Btn>
        </Flex>

        <Flex alignCenter>
          <Btn onClick={() => dispatch(clear())}>
            <CloseIcon />
          </Btn>
        </Flex>
      </Container>
    );
}
