import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { Btn } from 'Components/Styled';
import { PlusIcon, ChevronDown, ChevronUp } from 'Assets';
import Flex from 'Components/Flex';
import Avatar from 'react-avatar';
import ConnectModal from 'Components/Connect/Modal';
import useSettingsSidebar from 'Hooks/use-settings-sidebar';

const bottomMargin = '2px';

const Sidemenu = styled.aside`
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 260px;
  flex-shrink: 0;
  border-radius: 1px solid ${(p) => p.theme.border};
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  padding: 10px;
`;

const BaseLink = styled(NavLink)`
  font-weight: 600;
  font-size: 14px;
  padding: 10px 10px;
  border-radius: 6px;
  margin-bottom: ${bottomMargin};
  &:hover {
    text-decoration: none;
    background: ${(p) => p.theme.bg};
  }
  &.active {
    background: ${(p) => p.theme.primary};
    color: ${(p) => p.theme.backing};
  }
`;

const AccountLink = styled(BaseLink)`
  .account-title {
    font-size: 12px;
    color: ${(p) => p.theme.muted};
  }
  &.active {
    .account-title {
      color: ${(p) => p.theme.backing};
    }
  }
`;

// Custom component that imitates BaseLink
const Dropdown = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding: 10px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${bottomMargin};
  color: ${(p) => p.theme.primary};
  &:hover {
    text-decoration: none;
    background: ${(p) => p.theme.bg};
  }
`;

const Label = styled.div`
  padding: 0 10px;
  color: ${(p) => p.theme.muted};
  font-weight: 600;
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const AccountItem = ({ account }) => (
  <AccountLink to={`/settings/accounts/${account.id}/profile`}>
    <Flex alignCenter>
      <Avatar
        size={24}
        name={account.name || account.email}
        className="mr-2"
        round="3px"
      />
      <span className="account-title truncated">{account.email}</span>
    </Flex>
  </AccountLink>
);
function useSettingsSidemenu() {
  const accounts = useSelector((s) => s.user.accounts);
  const privateAccounts = accounts.filter((a) => !a.shared);
  const sharedAccounts = accounts.filter((a) => a.shared);
  return { privateAccounts, sharedAccounts };
}

export default function SettingsSidemenu() {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const { privateAccounts, sharedAccounts } = useSettingsSidemenu();
  const { data, toggleAccounts } = useSettingsSidebar();
  return (
    <Sidemenu>
      <ConnectModal open={open} toggle={toggle} />
      <Flex justifyBetween alignCenter className="mb-4">
        <h2>Settings</h2>
        <Btn title="Connect account" onClick={toggle}>
          <PlusIcon title />
        </Btn>
      </Flex>
      <BaseLink to="/settings/general">General</BaseLink>
      {/* eslint-disable-next-line */}
      <Dropdown onClick={() => toggleAccounts(!data.showAccounts)}>
        Accounts
        {data.showAccounts ? (
          <ChevronUp
            title
            style={{ width: 15, height: 15, opacity: 0.5 }}
            className="mr-2"
          />
        ) : (
          <ChevronDown
            title
            style={{ width: 15, height: 15, opacity: 0.5 }}
            className="mr-2"
          />
        )}
      </Dropdown>
      {data.showAccounts && (
        <>
          {privateAccounts.length > 0 && <Label>Private</Label>}
          {privateAccounts.map((acc) => (
            <AccountItem key={acc.id} account={acc} />
          ))}
          {sharedAccounts.length > 0 && <Label>Shared</Label>}
          {sharedAccounts.map((acc) => (
            <AccountItem key={acc.id} account={acc} />
          ))}
          <Button color="light" size="sm" className="mx-2" onClick={toggle}>
            <PlusIcon
              style={{ width: 10, height: 10, opacity: 0.5 }}
              className="mr-2"
            />
            Add new account
          </Button>
        </>
      )}
      <br />
    </Sidemenu>
  );
}
