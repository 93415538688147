import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Form from './Form';

export default function ConnectModal({ open, toggle }) {
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Connect an email address</ModalHeader>
      <ModalBody style={{ padding: '60px 60px' }}>
        <Form />
      </ModalBody>
    </Modal>
  );
}
