import React from 'react';
import Flex from 'Components/Flex';
import styled from 'styled-components';

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.5px;
`;

export default function SettingsAccountHeader(props) {
  return (
    <>
      <Flex justifyBetween alignCenter className="mb-5">
        <Title>{props.title}</Title>
        <div>{props.children}</div>
      </Flex>
    </>
  );
}
