import { useState, useRef, useEffect } from 'react';
import md5 from 'md5';
import client from 'Clients/rest';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as chat from 'Store/api/chats';
import useUploads from 'Hooks/use-uploads';

export default function useChat(thread, message, options = {}) {
  const inputRef = useRef(null);
  const uploadRef = useRef(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const uploads = useUploads(thread.account_id);
  const isEdit = !!message;

  const [files, setFiles] = useState([]);
  const [text, setText] = useState('');
  const [mentions, setMentions] = useState([]);

  const { loading: createPending, error: createError } = useSelector(
    chat.createSelectorByKey(thread.id)
  );
  const { loading: patchPending, error: patchError } = useSelector(
    isEdit
      ? chat.patchSelectorByKey(message.id)
      : chat.patchSelectorByKey(thread.id)
  );
  const { loading: removePending, error: removeError } = useSelector(
    isEdit ? chat.removeSelectorByKey(message.id) : chat.removeSelector
  );

  const reset = () => {
    setText('');
    setFiles([]);
    setMentions([]);
    if (options.close) options.close();
  };

  // ACTIONS
  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      thread_id: thread.id,
      account_id: thread.account_id,
      text,
      date: moment().unix(),
      object: 'chat',
      mentioned_user_ids: mentions.map((m) => m.id),
      upload_ids: files.map((f) => f.id || f._id),
      from: {
        username: user.username,
        name: user.name,
      },
    };
    const id = md5(JSON.stringify(payload));
    payload.__id = id; // this is "optimistic" identifier
    if (isEdit) {
      delete payload.date;
      dispatch(chat.patch(message.id, payload));
    } else {
      dispatch(chat.create(payload));
    }
    reset();
  };

  const getUsers = async (kw, cb) => {
    const res = await client.get('/autocomplete/users', {
      params: { term: kw, $limit: 5, account_id: thread.account_id },
    });
    const prepared = res.map((usr) => ({
      ...usr,
      display: usr.username,
      account_ids: usr.account_ids,
    }));
    return cb(prepared);
  };

  const remove = (...args) => dispatch(chat.remove(...args));
  const patch = (...args) => dispatch(chat.patch(...args));

  const handleInputChange = (e, b, c, mentioned) => {
    setText(e.target.value);
    setMentions(mentioned || []);
  };

  const insertEmoji = (em) => {
    inputRef.current.focus();
    const elem = inputRef.current;
    const cursorPosition = elem.selectionStart;
    const textBeforeCursorPosition = text.substring(0, cursorPosition);
    const textAfterCursorPosition = text.substring(cursorPosition, text.length);
    setText(
      `${textBeforeCursorPosition + em.native + textAfterCursorPosition}`
    );
    setTimeout(() => {
      elem.setSelectionRange(cursorPosition, cursorPosition);
    }, 20);
  };

  const uploadFile = async (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    const res = await uploads.createUpload(file);
    if (res.data) setFiles((f) => [res.data, ...f]);
  };

  const removeFile = async (fileId) => {
    const res = await uploads.removeUpload(fileId);
    if (res.data) setFiles((s) => s.filter((file) => file.id !== fileId));
  };

  const handleUploadClick = () => uploadRef.current.click();

  useEffect(() => {
    if (message) {
      setText(message.text);
      // Focus the input when entering the edit mode.
      if (inputRef.current) inputRef.current.focus();
    }
  }, [message, setText, inputRef]); // eslint-disable-line

  // useEffect(() => {
  //   if (!text) return;
  //   const typing = throttle(
  //     () => {
  //       client.post('/chat/typing', {
  //         thread_id: thread.id,
  //         username: user.username,
  //         user_id: user.id,
  //         account_id: thread.account_id,
  //       });
  //     },
  //     750,
  //     { trailing: true }
  //   );
  //   typing();
  // }, [text]); // eslint-disable-line

  return {
    onSubmit,
    text,
    mentions,
    setMentions,
    createPending,
    createError,
    inputRef,
    files,
    handleInputChange,
    getUsers,
    insertEmoji,
    uploadFile,
    patchPending,
    patchError,
    patch,
    remove,
    removeFile,
    removePending,
    removeError,
    uploadRef,
    handleUploadClick,
    uploading: uploads.createPending,
    removingFile: uploads.removePending,
    isEdit,
  };
}
