import createActions from '../common/create-feathers-actions';

const UPLOADS = 'UPLOADS';

const { create, remove, createSelector, removeSelector } = createActions({
  name: UPLOADS,
  url: '/uploads',
});

export { create, remove, createSelector, removeSelector };
