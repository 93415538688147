import moment from 'moment';
import { map } from 'lodash';

export default (body, msg) => {
  const fmt = (people, tag = 'span') =>
    map(people, ({ name, email }) => {
      const link = `<a href="mailto:${email}">${email}</a>`;
      return name ? `<${tag}>${name}</${tag}> &lt;${link}&gt;` : link;
    }).join(', ');

  const date = moment(msg.date, 'X').format('ddd, MMM D, YYYY [at] h:mm A');
  // TODO conditionally show cc and bcc
  const metahtml = [
    `---------- Forwarded message ----------`,
    `From: ${fmt(msg.from, 'b')}`,
    `Date: ${date}`,
    `Subject: ${msg.subject}`,
    `To: ${fmt(msg.to)}`,
    `Cc: ${fmt(msg.cc)}`,
  ];
  return `<div>${metahtml.join(
    '<br>'
  )}</div><br><blockquote>${body}</blockquote>`;
};
