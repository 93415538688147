import React from 'react';
import useInviteUsers from 'Hooks/use-invite-users';
import { Form, Field } from 'react-final-form';
import { FormGroup, Button, Row, Col, Spinner } from 'reactstrap';
import APIError from 'Components/APIError';
import { Input } from 'Components/Form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

export default function InviteUsersForm({ accountId, onSuccess, onSkip }) {
  const form = useInviteUsers(accountId, { onSuccess });
  return (
    <Form
      onSubmit={form.onSubmit}
      validate={form.validate}
      initialValues={form.initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
        form: {
          mutators: { push },
        },
        values,
      }) => {
        const filledFields = values.invites
          .filter((x) => x.email)
          .map((x) => x.email);
        return (
          <form onSubmit={handleSubmit}>
            <p>
              List the contacts you want to invite to your shared inbox. <br />
              You are inviting to <strong>{form.account.email}</strong>.
            </p>
            {form.error && <APIError error={form.error} entity="Account" />}

            <FieldArray name="invites">
              {({ fields }) =>
                fields.map((name, index) => (
                  <FormGroup key={index}>
                    <Field
                      component={Input}
                      name={`${name}.email`}
                      style={({ input }) => ({
                        opacity: input.value ? 1 : 0.5,
                      })}
                      showValid={!!fields.value[index].email}
                      placeholder="enter email here"
                    />
                  </FormGroup>
                ))
              }
            </FieldArray>
            <div className="text-right">
              <Button
                size="sm"
                color="primary"
                outline
                type="button"
                onClick={() => push('invites', form.initialValue)}
              >
                + Add Email
              </Button>
            </div>

            <FormGroup className="mt-md-5 mt-3">
              <Row>
                {onSkip && (
                  <Col md={6}>
                    <Button block color="link" onClick={onSkip}>
                      Skip
                    </Button>
                  </Col>
                )}
                <Col md={onSkip ? 6 : 12} className="mt-3 mt-sm-3 mt-md-0">
                  <Button
                    block
                    color="primary"
                    size="lg"
                    disabled={
                      hasValidationErrors || submitting || !filledFields.length
                    }
                    className="font-weight-bold"
                  >
                    Invite
                    {submitting && (
                      <Spinner color="light" size="sm" className="ml-3" />
                    )}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </form>
        );
      }}
    />
  );
}
