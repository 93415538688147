import React from 'react';
import Sidemenu from 'Components/Sidemenu';
import { theme } from 'Components/Styled';
import Flex from 'Components/Flex';
import styled from 'styled-components';
import NavBar from 'Components/NavBar';
import { toast } from 'react-toastify';
import { notificationPrompt } from 'Utils/notification';
import 'react-toastify/dist/ReactToastify.css';
import Composer from 'Components/Composer/Container';
import createStore from 'Store/composer';
import { useSelector, useDispatch } from 'react-redux';
import { updateAction as updateUi } from 'Store/ui';
import { Editor } from '@tinymce/tinymce-react';
import Onboarding from 'Components/Onboarding';
import ActionBar from 'Components/ActionBar';

const store = createStore({ name: 'Main Composer' });

const Root = styled(Flex)`
  background: ${theme.bg};
`;

const Content = styled.div`
  overflow-y: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 50px;
  max-height: 100vh;
`;
export default function AppLayout({ children, backButton, onBackClick }) {
  const composer = useSelector((s) => s.ui.composer);
  const accounts = useSelector((s) => s.user.accounts);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updateUi({ composer: false }));
  };
  const afterSend = () => {
    toast('Message sent');
  };

  React.useEffect(() => {
    notificationPrompt();
  }, []);

  return (
    <>
      <Root justifyBetween data-cy="app-layout">
        <Sidemenu />
        <Content className="flex-grow-1">
          <NavBar backButton={backButton} onBackClick={onBackClick} />
          {children}
          <Onboarding />
        </Content>
        <ActionBar />
        {composer && (
          <Composer
            store={store}
            accounts={accounts}
            close={handleClose}
            afterSend={afterSend}
          />
        )}
      </Root>
      {/* explanation: preload editor in background (it loads external scripts, css etc) */}
      <div style={{ display: 'none' }}>
        <Editor apiKey="yoes14bmgp0y9c64v3nf5v0pi4vkma9921u7mmu9972br4l4" />
      </div>
    </>
  );
}
