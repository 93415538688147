import React, { useState } from 'react';
import { Button, Progress } from 'reactstrap';
import './style.scss';
import { Checkbox } from 'Components/Styled';

export default function StepGuide({ dismiss, steps, loading }) {
  const [collapsed, setCollapsed] = useState(
    !!localStorage.getItem('step-guide')
  );

  const toggle = () => {
    const val = !collapsed;
    setCollapsed(val);
    return val
      ? localStorage.setItem('step-guide', collapsed)
      : localStorage.removeItem('step-guide');
  };

  const percentCompleted =
    (steps.filter((s) => s.done).length / steps.length) * 100;

  return (
    <div className="step-guide-container">
      {!collapsed && (
        <div className="step-guide">
          <div className="step-guide__header">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="mb-1">Getting Started</h3>

              <button
                className="step-guide-collapse-btn text-right"
                onClick={toggle}
              >
                &#9660;
              </button>
            </div>

            <small>Finish your setup to make the most of your outbox.</small>
            <Progress className="mt-3 mb-2" value={percentCompleted} />
          </div>
          <ul className="step-guide-steps">
            {steps.map((item, idx) => {
              return (
                <li key={idx}>
                  <Checkbox checked={item.done}>
                    <input type="checkbox" />
                    <span />
                  </Checkbox>
                  <span className="ml-3">{item.title}</span>
                </li>
              );
            })}
          </ul>
          <div className="step-guide__footer text-right">
            <Button
              color="light"
              size="sm"
              onClick={dismiss}
              disabled={loading}
            >
              Dismiss
            </Button>
          </div>
        </div>
      )}
      <div className="text-right">
        <Button
          onClick={toggle}
          className="mt-3 px-3 py-2"
          size="sm"
          color="primary"
        >
          Getting Started
        </Button>
      </div>
    </div>
  );
}
