// Actions -- uses redux-requests middleware
import createActions from 'Store/api/common/create-feathers-actions';
// import { getMutationSelector } from '@redux-requests/core';

const TEMPLATES = 'TEMPLATES';

export const {
  findSelector,
  find,
  FIND,
  create,
  patch,
  remove,
  patchSelector,
  createSelector,
  removeSelector,
  get,
  getSelector,
} = createActions({
  name: TEMPLATES,
  url: '/email-templates',
  request: {
    get: {
      params: { $populate: 'user account' },
    },
    create: {
      params: { $populate: 'user account' },
    },
    patch: {
      params: { $populate: 'user account' },
    },
  },
  meta: {
    create: {
      mutations: {
        'TEMPLATES/FIND': (current, item) => {
          if (current) return [item, ...current];
          return [item];
        },
      },
    },
  },
});
//
// export const newSelector = (id) =>
//   getMutationSelector({ type: 'TEMPLATES/PATCH', requestKey: id });
