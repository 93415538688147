import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('COMPOSER/CC/UPDATE');
const initialState = { data: [], valid: true }; // initial state

export default createReducer(
  initialState, // initial state
  {
    'COMPOSER/RESET': () => {
      return initialState;
    },

    [updateAction]: (state, action) => {
      if (!action.payload) {
        state.data = [];
        return;
      }
      state.data = action.payload.map((item) => ({
        name: item.name,
        email: item.email,
      }));
      state.valid = !!action.payload;
    },
  }
);
