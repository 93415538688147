import React from 'react';
import { InboxIcon, TrashIcon, ArchiveIcon } from 'Assets';
import useThread from 'Hooks/use-thread';
import styled from 'styled-components';
import { Btn } from '../Styled';
import FixThreadBtn from '../ThreadView/FixThreadBtn';

const Root = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(p) => p.theme.bg};
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
`;

export default function HoverActions({ thread }) {
  const { patch } = useThread(thread);

  const action = (name) => () => patch(thread.id, { action: name });
  const reloadThread = () => {
    // add actions on reload if needed
  };

  return (
    <Root className="thread-hover-actions">
      <FixThreadBtn thread={thread} reloadThread={reloadThread} />
      <Btn
        title={thread.unread ? 'Mark as read' : 'Mark as unread'}
        onClick={
          thread.unread ? action('mark-as-read') : action('mark-as-unread')
        }
      >
        <InboxIcon title={thread.unread ? 'Mark as read' : 'Mark as unread'} />
      </Btn>
      {!thread.archived && !thread.trash && !thread.spam && (
        <Btn title="Archive" onClick={action('move-to-archived')}>
          <ArchiveIcon title />
        </Btn>
      )}
      {(thread.trash || thread.archived || thread.spam) && (
        <Btn title="Move to inbox" onClick={action('move-to-inbox')}>
          <ArchiveIcon title="Move to inbox" />
        </Btn>
      )}
      {!thread.trash && (
        <Btn title="Move to trash" onClick={action('move-to-trash')}>
          <TrashIcon title="Move to trash" />
        </Btn>
      )}
    </Root>
  );
}
