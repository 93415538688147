import styled from 'styled-components';
import { theme } from 'Components/Styled';

const HeaderContainer = styled.div`
  padding: 8px 10px;
  text-align: center;
  color: ${theme.muted};
  font-weight: 500;
  font-size: 14px;
  background: #1e1e2d;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-content: space-between;
  button {
    background: none;
    color: ${theme.muted};
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
    &:hover {
      color: #fff;
    }
  }
`;

export default HeaderContainer;
