import { createReducer, createAction } from '@reduxjs/toolkit';

export const updateAction = createAction('ACTIONBAR/UPDATE');
export const toggle = createAction('ACTIONBAR/TOGGLE');
export const go = createAction('ACTIONBAR/NAVIGATE');

const initialState = {
  open: false,
  route: 'notifications',
}; // initial state

export default createReducer(
  initialState, // initial state
  {
    [go]: (state, action) => {
      state.route = action.payload;
    },
    [toggle]: (state, action) => {
      state.open = action.payload;
    },
    [updateAction]: (state, action) => {
      if (!action.payload) return state;
      return { ...state, ...action.payload };
    },
  }
);
