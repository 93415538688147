import { useEffect } from 'react';
import {
  magicLinkSelector,
  sendMagicLink,
  login,
  SEND_MAGIC_LINK,
} from 'Store/api/auth';
import { useSelector, useDispatch } from 'react-redux';
import validator from 'Utils/validator';
import { resetRequests } from '@redux-requests/core';
import qs from 'query-string';

export default function useMagicLink() {
  const { loading, error, data } = useSelector(magicLinkSelector);
  const dispatch = useDispatch();

  const validate = validator({
    email: {
      presence: true,
      email: true,
    },
  });
  const { access_token } = qs.parse(document.location.search);

  useEffect(() => {
    if (access_token) {
      dispatch(login({ access_token }));
    }
    dispatch(resetRequests([SEND_MAGIC_LINK]));
    // reset requests
  }, [dispatch, access_token]);

  const sendLink = ({ email }) => {
    dispatch(sendMagicLink(email));
  };

  return { validate, sendLink, loading, error, data, access_token };
}
