import React, { useCallback, useEffect, useRef } from 'react';

export default ({ onClose, children }) => {
  const ref = useRef(null);
  const escapeListener = useCallback(
    (e) => {
      if (e.key === 'Escape' && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  const clickListener = useCallback(
    (e) => {
      if (!ref.current.contains(e.target)) {
        if (onClose) onClose();
      }
    },
    [onClose]
  );
  // Below is the 10 lines of code you need.
  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);
  return <div ref={ref}>{children}</div>;
};
