import { useEffect } from 'react';
import qs from 'query-string';
import { get, getSelector, join, createSelector } from 'Store/api/invites';
import { useSelector, useDispatch } from 'react-redux';
import useRouter from 'Hooks/use-router';

export default function useInvite() {
  const { go } = useRouter();
  const user = useSelector((s) => s.user);
  const select = useSelector(getSelector); // data loading error
  const { loading, error } = useSelector(createSelector);
  const dispatch = useDispatch();
  const { data } = select;

  useEffect(() => {
    const query = qs.parse(document.location.search);
    if (!query.invite_id) return;
    dispatch(
      get(query.invite_id, {
        invite_code: query.invite_code,
        '$populate[0]': 'user',
        '$populate[1]': 'account',
      })
    );
  }, []) // eslint-disable-line

  const actions = {
    accept: () => go('invite', 'accept', data),
    reject: () => go('invite', 'reject', data),
    signup: () => go('invite', 'signup', data),
    login: () => go('invite', 'login', data),
    skip: () => go('invite', 'skip'),
  };

  const acceptInvite = async () => {
    const payload = { invite_id: data.id, invite_code: data.invite_code };
    const res = await dispatch(join(data.account_id, payload));
    if (!res.error) {
      actions.accept();
      window.location.reload();
    }
  };

  return {
    data: select.data,
    user,
    acceptInvite,
    loading,
    error,
    actions,
    initialLoading: select.loading,
  };
}
