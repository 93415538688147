import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';

import { Spinner } from 'reactstrap';
import useThread from 'Hooks/use-thread';
import AvatarStack from 'Components/AvatarStack';
import ThreadAnnotationStatus from 'Components/studio/ThreadAnnotationStatus';

import { theme } from '../Styled';
import FixThreadBtn from "./FixThreadBtn";

const Container = styled.div`
  border-bottom: 1px solid ${theme.border};
  padding: 20px 20px;
`;

const Subject = styled.div`
  font-size: 22px;
  letter-spacing: -0.3px;
  font-weight: 900;
  color: ${theme.dark};
`;

export default function ThreadWithoutMessages({ thread, loading, reloadThread}) {
  const { patchPending } = useThread(thread);
  return (
    <Container>
      {/* Upper */}
      <Flex justifyBetween>
        <div className="truncated">
          <Subject className="truncated">{thread.subject}</Subject>
        </div>
        <Flex alignCenter noShrink>
          {(loading || patchPending) && (
            <Spinner size="sm" color="nylas" className="mr-3" />
          )}
          <FixThreadBtn thread={thread} reloadThread={reloadThread} />
        </Flex>
      </Flex>
      {/* Bottom */}
      <Flex alignCenter noShrink>
        {thread.last_viewed_by_users && (
          <Flex className="mr-3">
            <AvatarStack
              size={24}
              users={thread.last_viewed_by_users}
              label="viewed by "
            />
          </Flex>
        )}
        {thread.assigned_to_users && thread.assigned_to_users.length > 0 && (
          <Flex alignCenter>
            <small className="mr-2 text-muted">Assigned to</small>

            <AvatarStack
              size={24}
              users={thread.assigned_to_users}
              label="assigned to "
            />
          </Flex>
        )}
        <Flex>
          <ThreadAnnotationStatus thread={thread} />
        </Flex>
      </Flex>
    </Container>
  );
}
