import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetRequests } from '@redux-requests/core';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import {
  find as findAction,
  findSelector,
  FIND,
} from 'Store/api/threads-search';

export default function useThreadsSearch(accountId, q) {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = qs.parse(document.location.search);

  const {
    data,
    loading: findPending,
    error: findError,
  } = useSelector(findSelector);
  const find = (...args) => dispatch(findAction(...args));

  const resetThreads = () => {
    dispatch(resetRequests([FIND]));
  };
  const fetch = async (offset) => {
    return find({
      account_id: accountId,
      term: q,
      $limit: 20,
      $skip: offset || 0,
    });
  };
  const loadMore = () => {
    fetch(data.length);
  };

  const formattedThreads = data.map((t) => {
    return {
      ...t,
      sender: t.participants[0],
    };
  });

  useEffect(() => {
    if (!q) return;
    resetThreads();
    fetch();
  }, [q, accountId]); // eslint-disable-line

  const toggleUnread = () => {
    const active = q && q.includes('is:unread');
    const newQ = active ? q.replace('is:unread', '') : `${q} is:unread`;
    history.push({
      search: qs.stringify({ ...query, q: newQ }),
    });
  };

  const toggleStarred = () => {
    const active = q && q.includes('is:starred');
    const newQ = active ? q.replace('is:starred', '') : `${q} is:starred`;
    history.push({
      search: qs.stringify({ ...query, q: newQ }),
    });
  };

  const toggleAttachment = () => {
    const active = q && q.includes('has:attachment');
    const newQ = active
      ? q.replace('has:attachment', '')
      : `${q} has:attachment`;
    history.push({
      search: qs.stringify({ ...query, q: newQ }),
    });
  };

  const handleAccChange = (a) => {
    if (!a) return;
    history.push({
      search: qs.stringify({ ...query, account_id: a.id }),
    });
  };
  return {
    FIND,
    loadMore,
    find,
    data: formattedThreads,
    toggleUnread,
    toggleStarred,
    toggleAttachment,
    findPending,
    handleAccChange,
    findError,
  };
}
