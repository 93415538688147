import React, { lazy, Suspense } from 'react';
import useActivities from 'Hooks/use-activities';
import camelcase from 'camelcase';
import Loader from 'Components/NylasLoader';
import Header from '../Header';

const UserAssigned = lazy(() => import('./UserAssigned'));
const UserMentioned = lazy(() => import('./UserMentioned'));
const RuleTriggered = lazy(() => import('./RuleTriggered'));

const cards = { UserAssigned, UserMentioned, RuleTriggered };

export default function Activities() {
  const { data, loading } = useActivities();
  return (
    <>
      <Header title="Notifications" />
      <Suspense fallback={<Loader loading />}>
        {data.length > 0 && (
          <div className="flex-grow-1" style={{ overflowY: 'auto' }}>
            {data.map((activity) => {
              if (!activity.type) return null;
              const componentName = camelcase(activity.type, {
                pascalCase: true,
              });
              const Component = cards[componentName];
              if (!Component) return null;
              return (
                <Component
                  component={activity.type}
                  key={activity._id}
                  activity={activity}
                />
              );
            })}
          </div>
        )}
      </Suspense>

      <Loader loading={loading && !data.length} noResults={!data.length} />
    </>
  );
}
